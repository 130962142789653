import React from "react";
// react component for creating beautiful carousel
// import Altivator from 'utils/imageAltivator';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "components/Modal";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class ListLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  handleDrawerToggle = (link) => {
    this.setState({ 
      modalOpen: !this.state.modalOpen,
      modalLink: link
    });
  }

  structureLogos=()=>{
    const { classes, FOOTERLOGOS } = this.props;
    const logosState = FOOTERLOGOS.map(item=>{
        return (
          <li key={'FooterLi-'+Math.floor(Math.random()*901)} >
            <div style={{cursor: "pointer"}} onClick={()=>this.handleDrawerToggle(item.urlLogo)}>
              <LazyLoadImage className={classes.logo} src={item.imageLogo} alt="" title="" />
            </div>
          </li>
        )
      })
    this.setState({...this.state, logosList : logosState})
  }

  componentDidMount(){
    this.structureLogos();
  }
  render() {
    const { classes } = this.props;
    const logosList= this.state.logosList

    return (
      <React.Fragment>
        <GridItem xs={12} >
          <ul className={classes.contentLogo}>
            {logosList}
          </ul>
        </GridItem>
        <Modal
          key = {'Modal-'+Math.floor(Math.random()*901)}
          title="Visitar página externa"
          footerLogo
          bgGray
          open={this.state.modalOpen}
          onClose={()=>this.handleDrawerToggle()}
        >
          <div style={{textAlign:"center"}}>
            <p>Estás a punto de visitar una página web fuera del dominio de bursametrica.com</p>
            <p><strong>¿Estás seguro de esta acción?</strong></p>
            <Button disableRipple onClick={()=>this.handleDrawerToggle()} size="" border color="borderWhite" style={{marginRight:"2px"}} >
              Cancelar
            </Button>
            <Button disableRipple href={this.state.modalLink} onClick={()=>this.handleDrawerToggle()} target="_blank" rel="noopener noreferrer" size="" border color="borderWhite" >
              Abrir link
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(ListLogo);
