import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";
// import { secondaryColor } from "assets/jss/material-kit-pro-react";

const headerStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    // borderRadius: "3px",
    padding: "0.625rem 0",
    color: grayColor[15],
    width: "100%",
    backgroundColor: "rgba(0, 59, 114, 0.95) !important",
    boxShadow: "none !important",
    maxHeight: "73px",
    height: "73px",
    transition: "all 0.3s ease-out 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    [theme.breakpoints.down("xs")]:{
      maxHeight: "117px",
      height: "117px",
      padding: "0.3rem 0",
    }
  },
  appBarOffice: {
    display: "flex",
    border: "0",
    // borderRadius: "3px",
    padding: "0.625rem 0",
    color: grayColor[15],
    width: "100%",
    backgroundColor: "rgba(0, 59, 114, 0.95) !important",
    boxShadow: "none !important",
    maxHeight: "72px",
    height: "72px",
    transition: "all 0.3s ease-out 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    [theme.breakpoints.down("xs")]:{
      padding: "0.3rem 0",
    }
  },
  contentNoFixed: {
    width:"100%",
    height: "67px",
    backgroundColor: "white",
    display: "inline-block",
    [theme.breakpoints.down("xs")]:{
      maxHeight: "111px",
      height: "111px",
      padding: "0.3rem 0",
    }
  },
  contentNoFixedOffice: {
    width:"100%",
    height: "72px",
    backgroundColor: "white",
    display: "inline-block",
    [theme.breakpoints.down("xs")]:{
      // maxHeight: "72px",
      // height: "111px",
      padding: "0.3rem 0",
    }
  },
  absolute: {
    position: "absolute",
    top: "auto"
  },
  fixed: {
    position: "fixed",
  },
  container: {
    ...container,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "100%"
    }
  },
  containerOffice: {
    ...container,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    // [theme.breakpoints.down("xs")]: {
    //   flexDirection: "column",
    //   height: "100%"
    // }
  },
  title: {
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    }
  },
  headerDrawer: {
    padding: "15px",
  },
  bodyDrawer: {
    padding: "15px 40px",
  },
  titleSearch: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "1.26rem",
    lineHeight: "1.3em",
    textTransform: "uppercase",
    // marginTop: "2rem",
    // marginBottom: "0",
  },
  contentSearch: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    "& input": {
      fontFamily: '"Open Sans", sans-serif !important',
      color: '#555 !important',
      "&::placeholder": {
        color: '#949494 !important'
      }
    },
  },
  appResponsive: {
    display: 'flex',
    flexDirection: 'column',
    margin: "20px 10px",
    marginTop: "4.3rem",
    '& ul': {
      flexDirection: "column !important",
      '& li': {
        width: "100% !important"
      }
    }
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    // boxShadow:
    //   "0 4px 20px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.14), 0 7px 12px -5px rgba(" +
    //   hexToRgb(primaryColor[0]) +
    //   ", 0.46)"
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46)"
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46)"
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46)"
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: whiteColor
  },
  black: {
    color: whiteColor,
    backgroundColor:"rgba(23,23,23,1) !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  blackScroll: {
    color: whiteColor,
    backgroundColor:"rgba(23,23,23,0.95) !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  dark: {
    color: whiteColor,
    backgroundColor:"rgba(0, 59, 114, 1) !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  darkScroll: {
    color: whiteColor,
    backgroundColor: "rgba(0, 59, 114, 0.95) !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: primaryColor[0],
    backgroundColor: whiteColor + " !important",
    boxShadow: "none"
      // "0 4px 18px 0px rgba(" +
      // hexToRgb(blackColor) +
      // ", 0.12), 0 7px 10px -5px rgba(" +
      // hexToRgb(blackColor) +
      // ", 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    // position: "fixed",
    position: "relative",
    display: "inline-table",
    top: "2rem",
    marginBottom: "4rem !important",
    height: "auto",
    right: "0",
    // left: "calc(50% - 150px)",
    margin: "0 auto",
    // width: drawerWidth,
    width: "calc(100% - 6rem)",
    maxWidth: "898px",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    backgroundColor: "rgba(0, 59, 114, 1)",
    color: "white",
    borderRadius: "6px",
    ...transition,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 4rem)",
    },
  },
  drawerPaperGray: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    // position: "fixed",
    position: "relative",
    display: "inline-table",
    top: "2rem",
    marginBottom: "4rem !important",
    height: "auto",
    right: "0",
    // left: "calc(50% - 150px)",
    margin: "0 auto",
    // width: drawerWidth,
    width: "calc(100% - 6rem)",
    maxWidth: "898px",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    backgroundColor: "rgba(0, 0, 0, 1)",
    color: "white",
    borderRadius: "6px",
    ...transition,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 4rem)",
    },
  },
  hidden: {
    width: "100%"
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto"
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center"
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },
  logo: {
    height: "50px",
    "@media (max-width: 480px)": {
      height: "40px"
    },
  },
  nopadding: {
    padding: '0 !important',
    margin: '0 !important',
    color: "inherit",
    "&:hover,&:focus": {
      color: "inherit"
    },
  },
  spaceItems: {
    '& button, & a': {
      marginLeft: '20px',
      padding: '8px 16px',
      fontSize: '.96rem',
      height: '33px',
    },
    '& button:first-child, & a:first-child': {
      marginLeft: '0 !important',
    },
    [theme.breakpoints.down("xs")]: {
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
  },
  footerMenuMovil: {
    marginTop: "1.5rem",
    textAlign: "center"
  },
  logoSearch: {
    maxWidth: "160px",
    margin: "0 auto",
    padding: "0 0 20px 0",
    opacity: ".5"
  },
  contactBtn: {
    cursor: 'pointer',
    fontFamily: '"Open Sans", sans-serif',
    display: "inline-block",
    position: "fixed",
    zIndex: 4,
    color:"white",
    fontSize: "16px",
    bottom: "22px",
    left: "20px",
    height: "40px",
    width: "150px",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "40px",
    backgroundColor: primaryColor[0],
    '& svg, img': {
      marginRight: '5px'
    }
  },
  up: {
    cursor: 'pointer',
    fontFamily: '"Open Sans", sans-serif',
    display: "inline-block",
    position: "fixed",
    zIndex: 3,
    color:"white",
    fontSize: "16px",
    bottom: "22px",
    right: "20px",
    height: "40px",
    width: "40px",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "40px",
    backgroundColor: primaryColor[0],
  },
  titeMessage:{
      fontSize: "1.2rem",
      lineHeight: "1.2em",
      fontWeight: 400,
  },
  textMessage:{
      fontSize: "1.1rem",
      lineHeight: "1.2em",
      fontWeight: 400,
  },
  // dropdown:{
  //   minWidth: "185px"
  // }
});

export default headerStyle;
