import {
    marginSection,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const styleHome = {
    marginSection,
    logo: {
      maxWidth: "100%",
      width: "auto !important",
      "@media (max-width: 380px)":{
        // maxHeight: 40,
      },
    },
    mB: {
      marginBottom: "10px"
    }
  };
  
  export default styleHome;
  