import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableActualPositionStyle";
// import Title from "components/Title";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import TableActualPositionContent from "./TableActualPositionContent";

class TableActualPosition extends React.Component {
  constructor(props){
    super(props);

    this.state= {
      row1Open:false,
      row2Open:false,
      row3Open:false,
      row4Open:false,
    }
  }

  handleClickRow=(rowName)=>{
    switch (rowName) {
      case 'row1':
        this.setState({ row1Open: !this.state.row1Open })
        break;
      
      case 'row2':
        this.setState({ row2Open: !this.state.row2Open })
        break;

      case 'row3':
        this.setState({ row3Open: !this.state.row3Open })
        break;

      case 'row4':
        this.setState({ row4Open: !this.state.row4Open })
        break;

      default:
        break;
    }
  }

  moneyMarketStructure=(DATA)=>{
    
    const {row1Open} = this.state;
    let moneyStructure = [];
    let moneyArray = [];
    if (DATA){
      moneyArray = DATA.posicion.filter(item=>{
        return item.MERCADO === "2" ;
      })
    }

    moneyStructure = <React.Fragment>
        {row1Open && <TableActualPositionContent moneyMarket={true} data={moneyArray} />
        }
      </React.Fragment>
    return moneyStructure;
  }

  capitalMarketStructure=(DATA)=>{

    const {row2Open} = this.state;
    let capitalStructure = [];
    let capitalArray = [];
    if (DATA){
      capitalArray = DATA.posicion.filter(item=>{
        return item.MERCADO === "1" ;
      })
    }
    
    capitalStructure = <React.Fragment>
        {row2Open && <TableActualPositionContent data={capitalArray} />}
      </React.Fragment>

    return capitalStructure;
  }

  fundsMarketStructure=(DATA)=>{

    const {row3Open} = this.state;
    let fundsStructure = [];
    let fundsArray = [];
    if (DATA){
      fundsArray = DATA.posicion.filter(item=>{
        return item.MERCADO === "3" ;
      })
    }
    
    fundsStructure = <React.Fragment>
        {row3Open && <TableActualPositionContent data={fundsArray} />}
      </React.Fragment> 
    
    return fundsStructure;
  }

  cashMarketStructure=(DATA)=>{
    const {classes} = this.props;
    const {row4Open} = this.state;
    let cashStructure = [];
    
    cashStructure = DATA.saldo.map((item,index)=>{
      return <React.Fragment>
        {row4Open && <React.Fragment>
          <Hidden mdUp>
            <GridItem key={"cashGridTitle"+index} xs={5} lg={12}>
              <GridContainer spacing={2} style={{margin:"-8px"}}>
                <GridItem lg={4}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}></p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>MONEDA</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>EFECTIVO ACTUAL</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>EFECTIVO POR LIQUIDAR</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}></p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </Hidden>
          <GridItem key={"cashGridResults"+index} xs={7} lg={12}>
            <GridContainer spacing={2} style={{margin:"-8px"}}>
              <GridItem key={"cashGrid"+index} lg={4}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}></p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.CveDivisa}</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.SaldoActual}</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.SaldoXLiquidar}</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}></p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </React.Fragment>}
        </React.Fragment>
    })
    return cashStructure;
  }

  render() {
    const { classes, data, actualPositionData, resultsData } = this.props;
    const {titleRow1, titleRow2, titleRow3, titleRow4, titleTotal} = data;
    const {resMoney, resCapital, resInvestment, resCash } = resultsData;
    const CONTENT_MONEY_MARKET = this.moneyMarketStructure(actualPositionData);
    const CONTENT_CAPITAL_MARKET = this.capitalMarketStructure(actualPositionData);
    const CONTENT_FUNDS_MARKET = this.fundsMarketStructure(actualPositionData);
    const CONTENT_CASH_MARKET = this.cashMarketStructure(actualPositionData);
    const {row1Open, row2Open, row3Open, row4Open} = this.state
    return (
      <React.Fragment>
        {/* FILA 1 */}
        <GridItem xs={12}> <h2 className={classes.titleLine}>{titleRow1}
          <div className={classes.actionBtn} onClick={()=>this.handleClickRow('row1')}>
            <FontAwesomeIcon icon={row1Open ? faMinus : faPlus } />
          </div>
          </h2>
        </GridItem>
        <GridItem xs={12} className={classNames(classes.animateRow2, row1Open && classes.animateRow2Active)} >
          <div className={classNames(classes.box)}>
            <GridContainer spacing={0}>
            { row1Open && <React.Fragment>
                <Hidden mdDown>
                  <GridItem xs={5} lg={12}>
                  <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                  </GridItem>
                  <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>COSTO POR TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>COSTO PROMEDIO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>VALOR MERCADO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>PLUSVALÍA / MINUSVALÍA</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Hidden>
              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
                {CONTENT_MONEY_MARKET}

              <Hidden lgUp>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>

              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              </React.Fragment>
              }
              <GridItem xs={12}>
                <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem xs={5} lg={8}>
                    <p className={classNames(classes.cellTitle, classes.textRight, classes.subtotal)}>SUBTOTAL</p>
                  </GridItem>
                  <GridItem xs={7} lg={4}>
                    <p className={classNames(classes.cellText, classes.sizeLgMin, classes.subtotal)}><strong>{resMoney}</strong></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>


        {/* FILA 2 */}
        <GridItem xs={12}> <h2 className={classes.titleLine}>{titleRow2}
          <div className={classes.actionBtn} onClick={()=>this.handleClickRow('row2')}>
            <FontAwesomeIcon icon={row2Open ? faMinus : faPlus } />
          </div>
          </h2>
        </GridItem>
        <GridItem xs={12} className={classNames(classes.animateRow2, row2Open && classes.animateRow2Active)}>
          <div className={classes.box}>
            <GridContainer spacing={0}>
            { row2Open && <React.Fragment>
                <Hidden mdDown>
                  <GridItem xs={5} lg={12}>
                  <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                  </GridItem>
                  <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                    </GridItem>
                    {/* <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                    </GridItem> */}
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>COSTO POR TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>COSTO PROMEDIO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>VALOR MERCADO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>PLUSVALÍA / MINUSVALÍA</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Hidden>
              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              {CONTENT_CAPITAL_MARKET}

              <Hidden lgUp>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>

              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              </React.Fragment>
              }
              <GridItem xs={12}>
                <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem xs={5} lg={8}>
                    <p className={classNames(classes.cellTitle, classes.textRight)}>SUBTOTAL</p>
                  </GridItem>
                  <GridItem xs={7} lg={4}>
                    <p className={classNames(classes.cellText, classes.sizeLgMin)}><strong>{resCapital}</strong></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>


        {/* FILA 3 */}
        <GridItem xs={12}> <h2 className={classes.titleLine}>{titleRow3}
          <div className={classes.actionBtn} onClick={()=>this.handleClickRow('row3')}>
            <FontAwesomeIcon icon={row3Open ? faMinus : faPlus } />
          </div>
          </h2>
        </GridItem>
        <GridItem xs={12} className={classNames(classes.animateRow3, row3Open && classes.animateRow3Active)}>
          <div className={classes.box}>
            <GridContainer spacing={0}>
              { row3Open && <React.Fragment>
                <Hidden mdDown>
                  <GridItem xs={5} lg={12}>
                  <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                  </GridItem>
                  <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                    </GridItem>
                    {/* <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                    </GridItem> */}
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={1}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>COSTO POR TÍTULOS</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>COSTO PROMEDIO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>VALOR MERCADO</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.textRightS)}>PLUSVALÍA / MINUSVALÍA</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Hidden>
              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              {CONTENT_FUNDS_MARKET}

              <Hidden lgUp>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>

              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              </React.Fragment>
              }
              <GridItem xs={12}>
                <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem xs={5} lg={8}>
                    <p className={classNames(classes.cellTitle, classes.textRight)}>SUBTOTAL</p>
                  </GridItem>
                  <GridItem xs={7} lg={4}>
                    <p className={classNames(classes.cellText, classes.sizeLgMin)}><strong>{resInvestment}</strong></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>


        {/* FILA 4 */}
        <GridItem xs={12}> <h2 className={classes.titleLine}>{titleRow4}
          <div className={classes.actionBtn} onClick={()=>this.handleClickRow('row4')}>
            <FontAwesomeIcon icon={row4Open ? faMinus : faPlus } />
          </div>
          </h2>
        </GridItem>
        <GridItem xs={12} className={classNames(classes.animateRow4, row4Open && classes.animateRow4Active)}>
          <div className={classes.box}>
            <GridContainer spacing={0}>
              { row4Open && <React.Fragment>
                <Hidden mdDown>
                  <GridItem xs={5} lg={12}>
                  <GridContainer spacing={2} style={{margin:"-8px"}}>
                    <GridItem lg={4}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}></p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>MONEDA</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>EFECTIVO ACTUAL</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}>EFECTIVO POR LIQUIDAR</p>
                    </GridItem>
                    <GridItem lg={2}>
                      <p className={classNames(classes.cellTitle, classes.mdRight)}></p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Hidden>
              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              {CONTENT_CASH_MARKET}

              <Hidden lgUp>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>

              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              </React.Fragment>
              }
              <GridItem xs={12}>
                <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem xs={5} lg={8}>
                    <p className={classNames(classes.cellTitle, classes.textRight)}>SUBTOTAL</p>
                  </GridItem>
                  <GridItem xs={7} lg={4}>
                    <p className={classNames(classes.cellText, classes.sizeLgMin)}><strong>{resCash}</strong></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>


        {/* FILA TOTAL */}
        <GridItem xs={12}> 
          <h2 className={classes.titleLine}>{titleTotal}</h2>
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.box}>
            <GridContainer spacing={0}>
              <GridItem xs={12}>
                <GridContainer spacing={2} style={{margin:"-8px"}}>
                  <GridItem xs={5} lg={8}>
                    <p className={classNames(classes.cellTitle, classes.textRight)}>TOTAL</p>
                  </GridItem>
                  <GridItem xs={7} lg={4}>
                    <p className={classNames(classes.cellText, classes.sizeLgMin)}><strong>{actualPositionData.total}</strong></p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableActualPosition);