import React, { Component } from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// @material-ui/core components
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
// import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import style from "./style.jsx"; 
import { Hidden } from "@material-ui/core";
import ListIcon from "components/List/ListIcon.jsx";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

class ImageAndContent extends Component {
  render(){
    const { classes, } = this.props
  const {
    image,
    content,
    bgGray,
  } = this.props.data;

  return (
    <MuiThemeProvider theme={theme}>
      <GridItem xs={12}>
        <GridContainer spacing={0} className={ bgGray ? classes.bgGray : classes.bgWhite} justify="space-between">
          <Hidden xsDown>
            <GridItem sm={3} md={6} style={{backgroundImage: "url(" + image + ")"}} className={classes.bgImage}>
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={8} md={5} className={classNames(classes.bgContent, classes.contentMov)}>
            <GridContainer spacing={4}>
              <ListIcon data={content} />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    </MuiThemeProvider>
  );
}
};

export default withStyles(style)(ImageAndContent);
