import {
  box,
  divider,
  title,
  text,
} from "components/Table/tableStyle"

import {
  inputBootstrap,
  inputGroup,
  contentIcon,
} from "components/CustomInput/style"

import { primaryColor } from "assets/jss/material-kit-pro-react";

  const tableMovementsStyle = {
    box: {
      ...box,
      paddingBottom: "25px !important"
    },
    divider: {
      ...divider
    },

    titleLine: {
      lineHeight: "1.05em",
      fontFamily: `'Lato', sans-serif`,
      color: "#003B72",
      fontWeight: 600,
      margin: '0 !important',
      fontSize: "1.46rem !important",
      position: 'relative',
      textTransform: 'uppercase',
      marginBottom: '1rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    },

    // TableActualPosition and Movements
    cellTitle: {
      ...title,
      lineHeight: "1.1rem !important",
      color: "#136EC3"
    },
    cellText: {
      ...text,
      lineHeight: "1.1rem !important",
    },
    sizeLgMin:{
      fontSize: "12px",
      "@media only screen and (max-width: 1366px) and (min-width: 1000px)": {
        fontSize: "10px",
      },
      "@media only screen and (max-width: 1199px) and (min-width: 1000px)": {
        fontSize: "11px",
      },
    },
    mdRight: {
      "@media (max-width: 1279px)": {
        textAlign: "right",
      },
      "@media (max-width: 999px)": {
        textAlign: "left",
      },
      "@media (max-width: 639px)": {
        textAlign: "right",
      },
    },
    textRight: {
      textAlign: "right"
    },

    animateContent:{
      height: "100px",
      overflow: "hidden",
      transition: "height 2s",
    },
    animateContentActive:{
      transition: "height 2s",
      height: "300px",
    },
    
    
    
    inputBootstrap: {
      ...inputBootstrap,
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      fontFamily: '"Open Sans", sans-serif',
    },
    inputGroup:{
        ...inputGroup
    },
    contentIcon:{
        ...contentIcon,
        borderTopLeftRadius: "5px ",
        borderBottomLeftRadius: "5px",
    },
    label:{
      color: "#727272",
      fontSize: ".96rem",
      lineHeight: "1.3em",
      fontWeight: 400,
      margin: "0 0 10px",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
    }
  };
  
  export default tableMovementsStyle;
  