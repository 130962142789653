import React from "react";
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import CardBg from "components/Cards/CardBg.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import money from "assets/img/jpg/bg_monedas.jpg"
// Style
import bannerStyle from "./cardBgStyle.jsx";

class FluidCardBg extends React.Component {
  cardRowStructure = (row) => {
    if (row.rowStyle === 'one') {
      return <GridItem xs={12} style={{padding: 0, minHeight: "450px"}}>
        <CardBg
          {...row.data[0]}
          image = {row.data[0].bgImage}
          fullHeight
          // buttonColor="white"
        />
      </GridItem>
    } else if (row.rowStyle === 'two') {
      return <React.Fragment>
        {row.data.map((card, key) => {
          if (key < 2) {
            return <GridItem xs={12} sm={6} style={{padding: 0, minHeight: "450px"}}>
              <CardBg
                  {...card}
                  image = {card.bgImage}
                  fullHeight
              />
            </GridItem>
          } else {
            return null;
          }
        })}
      </React.Fragment>
    } else if (row.rowStyle === 'three') {
      return <React.Fragment>
        {row.data.map((card, key) => {
          if (key < 3) {
            return <GridItem xs={12} sm={12} md={4} style={{padding: 0, minHeight: "450px"}}>
              <CardBg
                  {...card}
                  image = {card.bgImage}
                  fullHeight
              />
            </GridItem>
          } else {
            return null;
          }
        })}
      </React.Fragment>
    } else if (row.rowStyle === 'four') {
      return <React.Fragment>
        {row.data.map((card, key) => {
          if (key < 4) {
            return <GridItem xs={12} sm={6} md={6} lg={3} style={{padding: 0, minHeight: "450px"}}>
              <CardBg
                  {...card}
                  image = {card.bgImage}
                  fullHeight
              />
            </GridItem>
          } else {
            return null;
          }
        })}
      </React.Fragment>
    } 
  }

  render(){
    const { rows } = this.props.data;
    let CARDROWS = []
    rows.sort((card1, card2) => card1.id - card2.id ).map(row => {
        CARDROWS.push(this.cardRowStructure(row));
        return null;
    })
    return (
        <React.Fragment>
          {CARDROWS}
        </React.Fragment>
    );
  }
}

export default withStyles(bannerStyle)(FluidCardBg);
