import {
  box,
  divider,
  title,
  text,
} from "components/Table/tableStyle"
import { primaryColor } from "assets/jss/material-kit-pro-react";

  const tableActualPositionStyle = {
    box: {
      ...box,
      padding: "9px !important"
    },
    divider: {
      ...divider,
      marginBottom: "16px !important"
    },

    titleLine: {
      lineHeight: "1.05em",
      fontFamily: `'Lato', sans-serif`,
      color: "#003B72",
      fontWeight: 600,
      textTransform: 'uppercase',
      margin: '0 !important',
      fontSize: "1.46rem !important",
      position: 'relative',
      marginBottom: '1rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    },

    cellTitle: {
      ...title,
      lineHeight: "1.1rem !important",
      height: "50px",
      color: "#136EC3"
    },
    cellText: {
      ...text,
      lineHeight: "1.1rem !important",
      height: "50px",
    },
    sizeLgMin:{
      fontSize: "12px",
      // "@media (min-width: 1280px)": {
      //   fontSize: "11px",
      // },
    },
    mdRight: {
      "@media (max-width: 1279px)": {
        textAlign: "right",
      },
      "@media (max-width: 999px)": {
        textAlign: "left",
      },
      "@media (max-width: 639px)": {
        textAlign: "right",
      },
    },
    textRightS: {
      textAlign: "left",
      "@media (min-width: 1280px)": {
        textAlign: "right",
      },
      // "@media (max-width: 999px)": {
      //   textAlign: "left",
      // },
    },
    textRight: {
      textAlign: "right"
    },
    actionBtn:{
      cursor: "pointer",
      padding: "10px 16px",
      color: "#7c7c7c",
      backgroundColor: "#ffffff",
      border: "1px solid #BEBEBE",
      borderRadius: "2px",
      margin: "-5px 0 0 0",
      float: "right",
      transition: "all 0.3s ease-out 0s",
      display: "inline-block",
      fontSize: ".96rem",
      fontWeight: 400,
      textAlign: "center",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
      "&:hover":{
        color: "#136ec3",
        backgroundColor: "#ffffff",
        border: "1px solid #136ec3",
        transition: "all 0.3s ease-out 0s",
      }
    },
    animateRow2:{
      height: "102px",
      overflow: "hidden",
      transition: "all 0.3s ease-out 0s",
    },
    animateRow2Active:{
      transition: "all 0.3s ease-out 0sheight 2s",
      height: "auto",
      "@media (max-width: 1279px)": {
        height: "auto",
      },
    },
    animateRow3:{
      height: "102px",
      overflow: "hidden",
      transition: "all 0.3s ease-out 0s",
    },
    animateRow3Active:{
      transition: "all 0.3s ease-out 0s",
      height: "auto",
      "@media (max-width: 1279px)": {
        height: "auto",
      },
    },
    animateRow4:{
      height: "102px",
      overflow: "hidden",
      transition: "all 0.3s ease-out 0s",
    },
    animateRow4Active:{
      transition: "all 0.3s ease-out 0s",
      height: "auto",
      "@media (max-width: 1279px)": {
        height: "auto",
      },
    },
    // subtotal:{
    //   height: "50px"
    // }
  };
  
  export default tableActualPositionStyle;
  