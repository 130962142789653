import React from 'react'
import * as axios from 'axios';
// import { createBrowserHistory } from "history";
// import moment from 'moment';
import {
  ReactiveBase,
  // MultiDataList,
  DataSearch,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import GridItem from "components/Grid/GridItem.jsx";
import NotiCard from "components/Cards/NotiCard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SubTitle from "components/Title/SubTitle.jsx";
// import Video from "components/Video";
import Button from "components/CustomButtons/Button.jsx";
// import Badge from "components/Badge/Badge"
import Pagination from "components/Pagination/Pagination.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import style from "./style.jsx";
// import videoStyle from 'components/Video/videoStyle.jsx';

// var hist=createBrowserHistory();


class Search extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      tagList: [],
      selectedTag: [],
      searchValue: '',
      hostingVariables: this.props.hostingVariables,
      key: 'Search'+Math.floor(Math.random()*901)
    }
  }

  reformatDate=(date)=>{
    let dateDay='';
    let dateMonth='';
    let dateYear='';
    if (date && typeof(date)==="number"){
      const spreadDate = new Date(date)
      dateDay = spreadDate.getDate();
      dateMonth = spreadDate.getMonth()+1;
      dateYear = spreadDate.getFullYear();
      if (dateDay<10) dateDay = '0'+dateDay
      if (dateMonth<10) dateMonth = '0'+dateMonth
      return dateDay+"/"+dateMonth+"/"+dateYear ;
    } else {
      return null;
    }
    
  }
  
  async getTagData(){
    let query={"query":{"bool":{"must":[{"bool":{"must":[{"bool":{"must":[{"term":{"groupId":"33818"}},{"term":{"assetCategoryIds":"33985"}},{"term":{"latest":"true"}},{"term":{"status":"0"}}]}}]}}]}},"size":5,"_source":{"includes":["*"],"excludes":[]},"from":0,"sort":{"publishDate_sortable":{"order":"desc"}}}
    let bodys = '{"preference":"SearchResults"},'
    await axios.get(
      this.state.hostingVariables.elasticHost+"/liferaygg-20101/_msearch?",
      {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        source: JSON.stringify(bodys)+"\n"+JSON.stringify(query)+"\n",
        source_content_type: 'application/json'
        }
    })
    .then(resp => {
      let tempTags=[]
      resp.data.responses[0].hits.hits.map(item=>{
        if (item._source.assetTagNames) {
          if (Array.isArray(item._source.assetTagNames)) {
            item._source.assetTagNames.map(innerItem=>{
              if (tempTags.length===0 || !tempTags.find(tag=>tag.label === innerItem)){
                tempTags.push({label:innerItem, value:innerItem})
              }
              return null;
            })
          } else {
            if (this.state.tagList.indexOf(item._source.assetTagNames)<0) {
              tempTags.push({label:item._source.assetTagNames, value:item._source.assetTagNames})
            }
          }
        } 
        return null;
      })
      this.setState({
        tagList: tempTags
      })
    })
    .catch(error =>{
      console.log(error);
      /* hist.push('/error'); */
    });
  }

  handleTagChange = (value) => {
    this.setState({selectedTag: value})
  }

  handleSearchInput = (value) => {
    /* this.setState ({searchValue: value}, () => triggerQuery()); */
    this.setState({searchValue: value});
  }

  handleEnter = (e, triggerQuery) => {
    if (e.key === "Enter") {
      triggerQuery();
    }
  };

  handleOnClear = (component, value) => {
    if (component === "mainSearch" || !component){
      /* hist.push(this.state.hostingVariables.searchHost); */
      this.setState({ searchValue: ''});
    }
  }

  triggerSearch = () => {
    /* 
    hist.push(this.state.hostingVariables.searchHost+'?mainSearch="'+this.state.searchValue+'"');
    window.location.assign(hist.location.pathname+hist.location.search); */
  }

  getFriendly = (item) => {
    const {pages} = this.props.data;
    let detailPage= ''
    let friendlyRoute = ''
    pages.map(pagesItem=>{
      if (parseInt(item.classTypeId) === pagesItem.classTypeId) {
        detailPage = pagesItem.page;
      }
      return null;
    })
    if (detailPage) {
      friendlyRoute = `${this.state.hostingVariables.lifeRayHost+detailPage}/${item.articleId}`
    } else if (item.assetTagNames){
      detailPage = item.assetTagNames
      friendlyRoute = `${this.state.hostingVariables.lifeRayHost}/${detailPage}`
    }
    
    return friendlyRoute;
  }

  filterFolders = ()=> {
    let miQuery=null;

    miQuery = {
      should: [{
        bool: {must: [
        {"term": {"groupId": this.state.hostingVariables.groupId}},
        {"term": {"assetCategoryIds":"33985"}},
        {"term": {"priority_sortable": "0"}},
            {"exists": {"field": "publishDate_sortable"}},
            {"exists": {"field": "classTypeId"}},
        {"term": {"classTypeId" : "33978"}},
        {"term": {"status": "0"}},
          {bool: {should: [
            {"term": {"latest": "true"}},
          ]}}
      ]}},
      {
        bool: {must: [
          {"exists": {"field": "assetTagNames"}},
          {"term": {"groupId": this.state.hostingVariables.groupId}},
          {bool: {should: [
            {"term": {"expando__keyword__custom_fields__searchAttribute": "Si"}},
            {"exists": {"field": "name_es_ES"}},
          ]}}
        ],
        }
      },
    ],
    "minimum_should_match" : 1,
    must_not:[
      {"term": {"expando__keyword__custom_fields__searchAttribute": ""}},
      {"term": {"hidden": "1"}},
      {"term": {"entryClassName": "com.liferay.dynamic.data.lists.model.DDLRecord"}},
      {"term": {"extension": "jpg"}},
      {"term": {"extension": "png"}},
      {"term": {"extension": "gif"}},
      {"term": {"extension": "svg"}},
      {"term": {"extension": "mp4"}},
      {"term": {"extension": "mp3"}},
      {"term": {"extension": "pdf"}},
      {"term": {"extension": "lar"}},
    ]
    }
    
    /* return { query: { bool: miQuery }, sort: {
      "publishDate_sortable" : {"order": "desc"}
      } }  ; */
    return { query: { bool: miQuery }, sort: {
      "publishDate_sortable" : {"order": "desc"}
      } }  ;
  }
  
  componentDidMount(){
    this.getTagData();
  }

  render(){
    const { classes, data } = this.props
    const { labels,  
      // videoList 
    } = data
    // const tagList = this.state.tagList
    
    return (
      <GridItem xs={12}>
        <ReactiveBase
        /* key={this.state.key} */
        className={classNames('')}
        app={this.state.hostingVariables.lifeRayApp}
        credentials={this.state.hostingVariables.lifeRayCredentials}
        url={this.state.hostingVariables.elasticHost}
        /* transformRequest={(e) => console.log(e.body.split('\n')[1])} */
      >
        <GridContainer spacing={4}>
          <GridItem xs={12}>
            <GridContainer>
              <SubTitle title={labels.searchBoxTitle} />
            </GridContainer>
            <div className={classes.contentSearch}>
            <DataSearch
                componentId="mainSearch"
                className={classNames(classes.inputSimple, classes.w100)}
                innerClass={{
                  input: 'dataSearchClassInput bgWhite'
                }}
                dataField={["title", "expando__custom_fields__searchableText", "name_es_ES", "localized_title", "content_es_ES", "ddm__keyword__33978__authorName_es_ES", "ddm__keyword__33978__nameNotice_es_ES" ]}
                queryFormat="or"
                placeholder={labels.keyword}
                autosuggest={false}
                value={this.state.searchValue}
                onChange={this.handleSearchInput}
                onKeyPress={this.handleEnter}
                showFilter={true}
                URLParams={true}
                filterLabel={labels.keyword}
                showIcon={true}
                iconPosition={'right'}
                debounce={3000000}
                icon={<Button 
                  disableRipple
                  disabled={!this.state.searchValue}
                  size={null} 
                  color="searchWhite" 
                  /* onClick={()=>this.triggerSearch()}  */
                >
                    {labels.searchBtnTxt}
                </Button>}
              />
            </div>
          </GridItem>
          <GridItem xs={12}>
            <SelectedFilters
              showClearAll={true}
              clearAllLabel={labels.filterClearAll}
              onClear={(component, value) => {
                this.handleOnClear(component, value);
              }}
            />
            <ReactiveList
              /* style={{ minHeight: 600 }} */
              componentId="SearchResults"
              dataField={"title"}
              showResultStats={true}
              scrollOnChange={false}
              size={5}
              pagination={true}
              defaultQuery={this.filterFolders}
              react={{
                and: ['mainSearch', 'tagSelector']
              }}
              renderResultStats={(stats) => {
                return `${stats.numberOfResults} resultados encontrados`;
              }}
              renderNoResults={() => {
                return `0 resultados encontrados`;
              }}
              render={({ data }) => {
                return data.map((item, index) => {
                  /* if (item.assetTagNames && item.assetTagNames.length >0) {
                    this.updateTagList(item.assetTagNames_es_ES)
                  } */
                  /* const FRIENDLYROUTE = this.getFriendly(item);
                  const DESCRIPTION = this.getDescription(item); */
                  /* const READMORE = item.articleId ? labels.readMore : labels.download */
                  return (
                    <NotiCard 
                      horizontal
                      title={item.ddm__keyword__33978__nameNotice_es_ES ? item.ddm__keyword__33978__nameNotice_es_ES : item.localized_title_es_ES ? item.localized_title_es_ES : item.name_es_ES ? item.name_es_ES : 'Sin'}
                      autor={item.ddm__keyword__33978__authorName_es_ES}
                      date={this.reformatDate(item.displayDate_sortable)}
                      description={item.ddm__text__33978__descriptionNotice_es_ES ? item.ddm__text__33978__descriptionNotice_es_ES : item.expando__keyword__custom_fields__descriptionPage ? item.expando__keyword__custom_fields__descriptionPage : null}
                      blog
                      isSearch
                      searchTarget={item.ddm__keyword__33978__redirectLink_es_ES ? "_blank" : "_self"}
                      /* readMore={READMORE} */
                      buttonText={labels.resultBtnTxt}
                      urlBtn={item.ddm__keyword__33978__redirectLink_es_ES ? item.ddm__keyword__33978__redirectLink_es_ES : item.expando__keyword__custom_fields__searchAttribute ? "/"+item.assetTagNames : null}
                    />
                  )
                })
              }}

              renderPagination={({ pages, totalPages, currentPage, setPage, setSize }) => {
                const hasPages = Number.isFinite(totalPages);
                // currentPage += 1;
                const handlePrevPage = () => {
                  let page=currentPage;
                  const prevPage = page-1;
                  setPage(prevPage)
                }
              
                const handleCurrentPage= (num) => {
                  setPage(num)
                }
              
                const handleNextPage = () => {
                  let page=currentPage;
                  const nextPage = page+1;
                  setPage(nextPage)
                }
                if(!!hasPages) {
                  var paginationContent=[];
                  var pageNumbers = [];
                  for (let i = 1; i <= totalPages ; i++) {
                    pageNumbers.push(i);
                  }
                  if (totalPages > 1){
                    if (totalPages >= 10 && currentPage >4){
                      paginationContent.push({onClick:()=>handleCurrentPage(0), text: labels.paginatorFirst})
                    }
                    if (currentPage < 1){
                      paginationContent.push({disabled: true, text: labels.paginatorPrev })
                    }else{
                      paginationContent.push({onClick: ()=>handlePrevPage(), text: labels.paginatorPrev })
                    }
                    pageNumbers.map((item, index)=>{
                      if(item===currentPage+1){
                        paginationContent.push({active:true, text: item})
                      }else{
                        if (totalPages >= 10 && currentPage>4 && (index >= currentPage-5 && index <= currentPage+4) ){
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }else if (totalPages >=10 && currentPage <=4 && index <10){
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }else if (totalPages <10) {
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }
                      }
                      return null;
                    })
                    if (currentPage < pageNumbers.length-1){
                      paginationContent.push({onClick: ()=>handleNextPage(), text: labels.paginatorNext });
                      if(totalPages>=10){
                        paginationContent.push({onClick:()=>handleCurrentPage(pageNumbers.length-1), text: labels.paginatorLast });
                      }
                    }else{
                      paginationContent.push({disabled: true, text: labels.paginatorNext })
                    }
                  }
                  return (
                    <GridContainer>
                      <GridItem xs={12}>
                        <Pagination 
                          pages={paginationContent}
                        />
                      </GridItem>
                    </GridContainer>
                    
                  )
                } else {
                  return <div></div>
                }
              }}

              />
            </GridItem>
            
          </GridContainer>
        </ReactiveBase>
      </GridItem>
    )
  }
}

export default withStyles(style)(Search);