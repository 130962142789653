import React from "react";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// @material-ui/core components
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Cards/Card.jsx";
import CardFooter from "components/Cards/CardFooter"
import CardBody from "components/Cards/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
// images


import cardIconStyle from "./cardIconStyle.jsx";

function CardIcon({ ...props }) {

  const { 
      classes,
      image,
      imageHover,
      title,
      jumpLine,
      txtBtn,
      btnUrl} = props;
    return (
        <Card profile border className={classNames(classes.cardIcon)}>
            { imageHover ? <div className={classes.contentImageHover}>
                                <img src={image} className={classes.view} alt="" title="" />
                                <img src={imageHover} className={classes.viewHover} alt="" title="" />
                            </div> : <img src={image} className={classes.image} alt="" title="" />
            }
            <CardBody plain className={classes.noPaddingX}>
                <h4 className={classes.cardTitle}>{title}</h4>
                <h4 className={classes.cardTitle}>{jumpLine}</h4>
            </CardBody>
            <CardFooter plain center className={classes.cardIconFooter}>
                {btnUrl && <Button disableRipple border size="" color="borderGray" onClick={()=>breadcrumbsUpdate()} href={btnUrl} >
                {txtBtn ? txtBtn : 'falta configurar'}
                </Button>}
            </CardFooter>
        </Card>
      );
}

export default withStyles(cardIconStyle)(CardIcon);
