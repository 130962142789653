
  const simpleListStyle = {
    siteMap: {
      padding: 0,
      margin: 0,
      listStyleType: "none",
      '& li': {
        padding: 0,
        margin: "0 0 8px",
        display: "block",
        lineHeight: "1.6em",
        fontSize: ".96rem",
        fontWeight: 400,
        color: "#727272",
        '& > ul': {
          paddingLeft: "30px",
          listStyleType: "none",
        }
      },
      '& a': {
        color: "#003b72",
        textDecoration: "none",
        outline: "medium none !important",
        margin: "0 15px 0 0"
      },
    }
  };
  
  export default simpleListStyle;
  