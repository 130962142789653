import React from "react";
import * as axios from 'axios';
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableInfoResultStyle";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faDownload} from '@fortawesome/free-solid-svg-icons'
// import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.jsx";

class TableInfoResults extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      selectedInfoData: {},
      selectedMonth: 0,
    }
  }

  formatDate = (testDate) => {
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear().toString();
    let hours = testDate.getHours();
      if (hours < 10) hours = '0'+hours.toString();
    let minutes = testDate.getMinutes();
      if (minutes < 10) minutes = '0'+minutes.toString();
    let seconds = testDate.getSeconds();
      if (seconds < 10) seconds = '0'+seconds.toString();
    return day+"/"+month+"/"+year.substr(2,2)
  }

  getResultsInfoData= async (type)=>{
    const endpoint = this.props.endpoint;
    let response = {};
    let d = new Date();
    const month = d.getMonth();
      d.setMonth(month-type);
      d.setDate(0);
      
    const checkDate = this.formatDate(d);
    await axios.post(
      endpoint,
      {
        "contrato": this.props.sessionContract,
        "fecha": checkDate
      },
      {
        "headers": {
          "Authorization": this.props.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        if(resp.data.code === "401") {
          this.props.closeSession();
        } else {
          this.setState({selectedInfoData: resp.data})
        }
      }
    }).catch(error =>{
      console.log('getTableResultsInfoError: ',error)
      this.props.callInactivity();
    });
    this.setState({selectedMonth: type})
    return response;
  }

  checkSelectedMonth = () => {
    let tempSelectedMonth = ""
    switch (this.state.selectedMonth) {
      case 0:
        tempSelectedMonth = "MES ACTUAL"
        break;
      case 1:
        tempSelectedMonth = "MES ANTERIOR"
        break;
      case 2:
        tempSelectedMonth = "HACE 2 MESES"
        break;
      case 3:
        tempSelectedMonth = "HACE 3 MESES"
        break;
      default:
        break;
    }
    return tempSelectedMonth;
  }

  componentDidMount(){
    if (this.state.selectedInfoData === {}){
      this.getResultsInfoData(0);
    }
  }

  render() {
    const { classes, title, resultsInfoData } = this.props;
    const infoData = this.state.selectedInfoData.ValorCarteraIniMes ? this.state.selectedInfoData : resultsInfoData;
    const dropListYears = [
      <Button 
        disableRipple
        className={classes.userPopover} 
        color="transparent" 
        key="date0"
        onClick={()=>{this.getResultsInfoData(0)}}
      >
        Mes actual
      </Button>,
      <Button 
        disableRipple
        className={classes.userPopover} 
        color="transparent" 
        key="date1"
        onClick={()=>{this.getResultsInfoData(1)}}
      >
        Mes anterior
      </Button>,
      <Button 
        disableRipple
        className={classes.userPopover} 
        color="transparent" 
        key="date2"
        onClick={()=>{this.getResultsInfoData(2)}}
      >
        Hace 2 meses
      </Button>,
      <Button 
        disableRipple
        className={classes.userPopover} 
        color="transparent" 
        key="date3"
        onClick={()=>{this.getResultsInfoData(3)}}
      >
        Hace 3 meses
      </Button>,
    ]
    const selectedMonthTitle = this.checkSelectedMonth();
    return (
      <React.Fragment>
        { title && <GridItem xs={12}><h4 className={classes.titleLine}>{title}</h4></GridItem>}
        <GridItem xs={12}>
            <List id="monthSelect" style={{padding: 0}} className={classes.marginX}>
              <ListItem style={{padding: 0,}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={"transparent"}
                  buttonText={selectedMonthTitle}
                  buttonProps={{
                    className: classes.dropdown,
                    color: "primaryPrimary"
                  }}
                  dropdownList={dropListYears}
                />
              </ListItem>
            </List>
              {/* <Button disableRipple size="" color="primary" >
                CONTRATO (S) <FontAwesomeIcon icon={faSortDown} style={{margin: "-3px 0 0 4px"}}/>
              </Button> */}
            </GridItem>
        <GridItem xs={12}>
          <div className={classes.box}>
            <GridContainer spacing={2} style={{margin: "-8px"}}>
              <GridItem xs={4}>
                <p className={classes.title}>CONCEPTO</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.title}>{selectedMonthTitle}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.title}>ACUMULADO ANUAL</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Valor Cartera Inicial</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.ValorCarteraIniMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.ValorCarteraIniAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Sus Depósitos (+)</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.DepositosMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.DepositosAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Sus Retiros (-)</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RetirosMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RetirosAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Valuación de Cartera</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.ValorCarteraMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.ValorCarteraAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Inversión</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.InversionMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.InversionAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Utilidad</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.UtilidadMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.UtilidadAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Capital Promedio Invertido</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.CapitalPromInvMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.CapitalPromInvAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Tiempo de Inversión (días)</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.TiempoInvMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.TiempoInvAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Rendimiento del período</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoPeriodoMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoPeriodoAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Rendimiento Simple</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoSimpleMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoSimpleAcu}</p>
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>

              <GridItem xs={4}>
                <p className={classes.titleRow}>Rendimiento Compuesto</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoCompuestoMes}</p>
              </GridItem>
              <GridItem xs={4}>
                <p className={classes.textRow}>{infoData.RendimientoCompuestoAcu}</p>
              </GridItem>
              
            </GridContainer>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableInfoResults);