import React from "react";
import ReactDOM from "react-dom";
import App from 'App'
import "assets/scss/material-kit-pro-react.scss?v=1.3.0";
import 'assets/css/main.css';
import 'assets/font-awesome/css/fontAwesome.css';
import 'assets/css/animate.min.css';


ReactDOM.render(
  <App />,
  document.getElementById("root")
); 