import React from "react";
// @material-ui/core components
import './ErrorPage.css';
import logo from '../../assets/img/png/logo_small.png'

class ErrorPage extends React.Component {
 state ={
	 timer: null
 }
	getHomePage=()=>{
		window.location.assign('#')
	}

  componentDidMount() {
    window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		// this.setState({
		// 	timer: setInterval(()=> this.getHomePage(), 5000),
		// })
	}
	
	componentWillUnmount(){
		if (this.state.timer !== null){
			clearInterval(this.state.timer)
		}
	}
  render() {
    return (
			<div className="bg">
			<div className="container-fluid no-padding">
				<div className="main-container">
					<div className="content">
						<div className="contentLogo">
							<img src={logo} alt="Bursametrica" title="Bursametrica"/>
						</div>
						<div className="error-404"></div>
						{/* <p className="text1">OH, NO!</p> */}
						<p className="text2">Lo sentimos, no hemos encontrado la página buscada.</p>
						<a href="https://bursametrica.com" className="btn-home">VOLVER AL HOME</a>
					</div>
				</div>
			</div>
		</div>
    );
  }
}

export default ErrorPage;
