import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.jsx";
// import RssDialog from 'components/Corfi/Dialog/RssDialog';
// images - 

import style from "./style.jsx";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter, faLinkedin, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
// import { faRss } from '@fortawesome/free-solid-svg-icons'

class BtnRedes extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false,
    }
  }
  handleClickOpen = (e) => {
    e.preventDefault();
    this.setState({open: true})
  };
  handleClose = () => {
      this.setState({open: false})
  }; 
  render() {
    const { classes, w100, twitterLink, linkedInLink, whatsappLink, facebookLink, rssLink  } = this.props;
    return (
      <div className={classNames(classes.contentBtn, w100 && classes.w100)}>
        {(rssLink && rssLink !== '') && <Button color="complementario" className={classes.btnRedes} onClick={this.handleClickOpen} href='#'>
        {/* <FontAwesomeIcon icon={faRss} /> */}
          <i className={"fa fa-rss"} />
        </Button>}
        {/* <RssDialog
          open={this.state.open}
          onClose={this.handleClose}
          link={rssLink}
        /> */}
        {(linkedInLink && linkedInLink !== '')&&<Button color="complementario" className={classes.btnRedes} href={linkedInLink} target="_blank" rel="noopener noreferrer" >
          {/* <FontAwesomeIcon icon={faLinkedin} /> */}
          <i className={"fab fa-linkedin-in"} />
        </Button>}
        {(twitterLink && twitterLink !== '')&&<Button color="complementario" className={classes.btnRedes} href={twitterLink} target="_blank" rel="noopener noreferrer" >
          {/* <FontAwesomeIcon icon={faTwitter} /> */}
          <i className={"fab fa-twitter"} />
        </Button>}
        {(whatsappLink && whatsappLink !== '')&&<Button color="complementario" className={classes.btnRedes} href={whatsappLink} target="_blank" rel="noopener noreferrer" >
          {/* <FontAwesomeIcon icon={faWhatsapp} /> */}
          <i className={"fab fa-whatsapp"} />
        </Button>}
        {(facebookLink && facebookLink !== '')&&<Button color="complementario" className={classes.btnRedes} href={facebookLink} target="_blank" rel="noopener noreferrer" >
          {/* <FontAwesomeIcon icon={faFacebook} /> */}
          <i className={"fab fa-facebook"} />
        </Button>}
        {/* <Button color="complementario" className={classes.btnRedes} href={} target="_blank" rel="noopener noreferrer" >
          <i className={"fas fa-plus"} />
        </Button> */}
      </div>
    );
  }
}

export default withStyles(style)(BtnRedes);
