import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Divider from "components/Divider";
import VideoContent from "components/Video/VideoContent";
import CarouselTimeLine from "components/Carousel/CarouselTimeLine"
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// sections for this page
import style from "./style.jsx"

function TimeLineBlock(props){
  if (!props.data) return <></>;
  const { title, videoUrl, previousBtnTxt, nextBtnTxt, timeLineContent } = props.data
  return (
    <React.Fragment>
        <GridItem xs={12}>
          <GridContainer spacing={4}>
            <VideoContent video={videoUrl} title={title} />
              {videoUrl && <Divider />}
            <CarouselTimeLine 
              previousBtnTxt={previousBtnTxt}
              nextBtnTxt={nextBtnTxt}
              timeLineContent={timeLineContent}
            />
          </GridContainer>
        </GridItem>
    </React.Fragment>
  )
}

export default withStyles(style)(TimeLineBlock);