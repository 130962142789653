import React from "react";
import * as axios from 'axios';
// import moment from 'moment';
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableMovementsStyle";
// import Title from "components/Title";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Pagination from "components/Pagination/Pagination"
import Loading from "components/Loader/Loading";
// import FilterDate from "components/FilterDate"
// import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

class TableMovements extends React.Component {
  constructor(props){
    super(props);

    this.state={
      searching: false,
      rowContent: null,
      totalRows: null,
      rowContentArray: [],
      loadedData: false,
      notFound: false,
      buttonDisable: true,
      tableArray: null,
      selectedInitialDate: this.formatStateDate(new Date()),
      unformattedInitialDate: null,
      rangeBtnDisable: true,
      endDateDisable: true,
      selectedEndDate: this.formatStateDate(new Date()),
      currentPage: 1,
    }
  }

  getMovementsData = async () => {
    const endpoint = this.props.data.endpoint;
    let response = [];

    this.setState({searching: true, loadedData: false, notFound: false})

    await axios.post(
      endpoint,
      {
        "contrato": this.props.contrato,
        "tipo": 1,
        "fechaIni": this.state.selectedInitialDate,
        "fechaFin": this.state.selectedEndDate
      },
      {
        "headers": {
          "Authorization": this.props.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        if(resp.data.code === "401") {
          this.props.closeSession();
        } else {
          if (resp.data){
            if (resp.data.movimiento){
              this.setState({
                searching: false,
                loadedData: true,
                tableArray: resp.data.movimiento,
                currentPage: 1,
                notFound: false
              })
              this.dataStructure(resp.data.movimiento)
            } else if (resp.data.descripcion === "No se encontraron registros"){
              this.setState({...this.state, loadedData: true, notFound: true})
            }
  
          }
        }
      }
    }).catch(error =>{
      console.log(error)
    });
    return response;
  }

  addDay = (testDate) => {
    if (testDate){
      testDate = new Date(testDate);
      testDate.setDate(testDate.getDate() + 1)
      let month = testDate.getMonth() + 1;
      if (month < 10) month = '0'+month.toString();
      let day = testDate.getDate();
        if (day < 10) day = '0'+day.toString();
      const year = testDate.getFullYear();

      return year+"-"+month+"-"+day;
    }
    return null;
  }

  formatDate = (testDate) => {
    testDate = new Date(testDate);
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear();

    return year+"-"+month+"-"+day;
  }

  formatStateDate = (testDate) => {
    testDate = new Date(testDate);
    testDate.setDate(testDate.getDate() + 1) // add 1 day for UTC pairing
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear();
    return day+"/"+month+"/"+year;
  }

  getMaxDate = () => {
    const currentDate= new Date();
    return this.formatDate(currentDate)
  }

  getMinDate = () => {
    let currentDate= new Date();
    currentDate = currentDate.setFullYear(currentDate.getFullYear() -5)
    return this.formatDate(currentDate)
  }

  handleDateChange = (event, name) => {
    let formatedDate = ""
    switch(name){
      case 'date':
        formatedDate = this.formatStateDate(document.getElementById("date").value)
        this.setState({buttonDisable: false, selectedInitialDate: formatedDate, selectedEndDate: formatedDate})
        break;
      case 'initialDate':
        const unformattedInitialDate = event.target.value;
        formatedDate = this.formatStateDate(document.getElementById("initialDate").value)
        this.setState({endDateDisable: false, selectedInitialDate: formatedDate, unformattedInitialDate: unformattedInitialDate})
        break;
      case 'endDate':
        formatedDate = this.formatStateDate(document.getElementById("endDate").value)
        this.setState({rangeBtnDisable: false, selectedEndDate: formatedDate})
        break;
      default:
        return;
    }
  }

  /* ////// Begin Pagination ////// */

  handlePrevPage = () => {
    let page=this.state.currentPage;
    const prevPage = page-1;
    this.setState({currentPage: prevPage})
  }
  
  handleCurrentPage= (num) => {
    this.setState({currentPage: num})
  }
  
  handleNextPage = () => {
    let page=this.state.currentPage;
    const nextPage = page+1;
    this.setState({currentPage: nextPage})
  }

  rowStructure = (item) => {
    const {classes} = this.props;
    const DATA = item;
    return (
      <React.Fragment>
        <Hidden mdUp>
          <GridItem xs={5} lg={12}>
            <GridContainer spacing={2} style={{margin: "-8px"}}>

              <GridItem lg={5}> 
                <GridContainer spacing={2} style={{margin: "-8px"}}>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>CONCEPTO</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                  </GridItem>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                  </GridItem>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                  </GridItem>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                  </GridItem>
                  <GridItem lg={3}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>PRECIO</p>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem lg={7}>
                <GridContainer spacing={2} style={{margin: "-8px"}}>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>TÍTULOS</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>IMPORTE BRUTO</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>COMISIÓN</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>IVA</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>ISR</p>
                  </GridItem>
                  <GridItem lg={2}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>IMPORTE NETO</p>
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          </GridItem>
        </Hidden>
      
      <GridItem xs={7} lg={12}>
        <GridContainer spacing={2} style={{margin: "-8px"}}>

          <GridItem lg={5}>
            <GridContainer spacing={2} style={{margin: "-8px"}}>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}><strong>{DATA.FECOPERACION}</strong></p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.DESCRIPTION}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.EMISORA}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.SERIE}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.PLAZO}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.TASA}</p>
              </GridItem>
              <GridItem lg={3}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.PRECIO}</p>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem lg={7}>
            <GridContainer spacing={2} style={{margin: "-8px"}}>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.TITULOS}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.IMPORTEBRUTO}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.COMISION}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.IVA}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.ISR}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{DATA.IMPORTENETO}</p>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>

      </React.Fragment>
    )
  }
  
  sortTableContent=(DATA)=>{
    const TABLECONTENT = [];
    DATA.map(item => {
        TABLECONTENT.push(this.rowStructure(item));
        return null;
    })
    return TABLECONTENT
  }

  dataStructure=(data=this.state.tableArray)=>{
    const ROWS = this.sortTableContent(data);
    let rowContentArray=[]
      for(let i=0, j=ROWS.length; i<j; i+=10){ 
        rowContentArray.push(ROWS.slice(i, i + 10)); 
      }
    
      this.setState({
        totalRows: ROWS.length,
        rowContent: ROWS,
        rowContentArray: rowContentArray,
      })
  }

  /* ////// End Pagination ////// */

  /* componentDidMount(){
    this.getMovementsData();
  } */
  render() {
    const { classes, data } = this.props;
    const {
      dateSearchTitle,
      dateSearchBtnTxt,
      dateRangeTitle,
      labelInitialDate,
      labelEndDate,
      dateRangeBtnTxt,
      titleResults,
      // paginatorFirst,
      // paginatorLast,
      paginatorPrev,
      paginatorNext,
    } = data;
    const { searching, loadedData, buttonDisable, rangeBtnDisable, endDateDisable, unformattedInitialDate } = this.state;
    const maxDate = this.getMaxDate();
    const minDate = this.getMinDate();
    let rangeMinDate = (unformattedInitialDate && !endDateDisable) ? this.addDay(unformattedInitialDate) : minDate; 
    const NOTFOUND = this.state.notFound;
    //Code for Pagination
    const pageNumbers = [];
    var paginationContent = [];
    let MAINCONTENT;
    if (this.state.rowContentArray!== null){
      MAINCONTENT= this.state.rowContentArray[this.state.currentPage-1];
    } else {MAINCONTENT = []}
    
    if (this.state.totalRows !== null) {
      for (let i = 1; i <= Math.ceil(this.state.totalRows / 10); i++) { 
        pageNumbers.push(i);
      }
      if (pageNumbers.length > 1){
        if (this.state.currentPage === 1){
          paginationContent.push({disabled: true, text: paginatorPrev })
        }else{
          paginationContent.push({onClick: ()=>this.handlePrevPage(), text: paginatorPrev })
        }
        pageNumbers.map((item, index)=>{
          if (window.innerWidth < 800){
            if(item===this.state.currentPage){
              paginationContent.push({active:true, text: item})
            }else if(index >= this.state.currentPage-1 && index <= this.state.currentPage+2 ){
              paginationContent.push({onClick:()=>this.handleCurrentPage(item), text: item})
            }
          } else {
            if(item===this.state.currentPage){
              paginationContent.push({active:true, text: item})
            }else{
              paginationContent.push({onClick:()=>this.handleCurrentPage(item), text: item})
            }
          }
          
          return null;
        })
        if (this.state.currentPage < pageNumbers.length){
          paginationContent.push({onClick: ()=>this.handleNextPage(), text: paginatorNext })
        }else{
          paginationContent.push({disabled: true, text: paginatorNext })
        }
      }
    }
    
    //End code for pagination


    return (
      <React.Fragment>
        <GridItem xs={12} style={{marginBottom: "2.5rem"}}>
          <GridContainer spacing={4}>
            <GridItem sm={6}>
              <GridContainer spacing={2} style={{margin: "-8px"}} >
                {dateSearchTitle && <GridItem xs={12}> <h3 className={classes.titleLine}>{dateSearchTitle}</h3> </GridItem>}
                <GridItem xs={12}>
                  <div className={classes.inputGroup}>
                    <div className={classes.contentIcon}>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <input 
                      type="date"
                      id="date"
                      name="date"
                      className={classes.inputBootstrap} 
                      max={maxDate}
                      min={minDate}
                      onChange={(e)=>this.handleDateChange(e,"date")}
                      placeholder="MM/DD/YY"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}> 
                  <Button onClick={()=>this.getMovementsData()} disabled={buttonDisable} disableRipple color="primary">
                    {dateSearchBtnTxt}
                  </Button> 
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem sm={6}>
              <GridContainer spacing={2} style={{margin: "-8px"}} >
                {dateRangeTitle && <GridItem xs={12}> <h3 className={classes.titleLine}>{dateRangeTitle}</h3> </GridItem>}
                <GridItem xs={12}>
                  <p className={classes.label}>{labelInitialDate}</p>
                  <div className={classes.inputGroup}>
                    <div className={classes.contentIcon}>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <input 
                      type="date"
                      id="initialDate"
                      name="initialDate"
                      className={classes.inputBootstrap} 
                      max={maxDate}
                      min={minDate}
                      onChange={(e)=>this.handleDateChange(e,"initialDate")}
                      placeholder="MM/DD/YY"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <p className={classes.label}>{labelEndDate}</p>
                  <div className={classes.inputGroup}>
                    <div className={classes.contentIcon}>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <input 
                      type="date"
                      id="endDate"
                      name="endDate"
                      className={classes.inputBootstrap} 
                      max={maxDate}
                      min={rangeMinDate}
                      onChange={(e)=>this.handleDateChange(e,"endDate")}
                      placeholder="MM/DD/YY"
                      disabled={endDateDisable}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}> <Button onClick={()=>this.getMovementsData()} disabled={rangeBtnDisable} disableRipple color="primary">{dateRangeBtnTxt}</Button> </GridItem>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </GridItem>
        {(loadedData && titleResults) && <GridItem xs={12}> <h2 className={classes.titleLine}>{titleResults}</h2> </GridItem>}
        {NOTFOUND && <GridItem xs={12}> <h3>No se encontraron movimientos en la fecha (s) seleccionada (s).</h3> </GridItem>}
        {loadedData ? <GridItem xs={12}>
          <div className={classes.box}>
            <GridContainer spacing={4}>
            <Hidden mdDown>
              <GridItem xs={5} lg={12}>
                <GridContainer spacing={2} style={{margin: "-8px"}}>

                  <GridItem lg={5}>
                    <GridContainer spacing={2} style={{margin: "-8px"}}>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>CONCEPTO</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                      </GridItem>
                      <GridItem lg={1}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                      </GridItem>
                      <GridItem lg={1}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                      </GridItem>
                      <GridItem lg={1}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                      </GridItem>
                      <GridItem lg={3}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>PRECIO</p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem lg={7}>
                    <GridContainer spacing={2} style={{margin: "-8px"}}>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>TÍTULOS</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>IMPORTE BRUTO</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>COMISIÓN</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>IVA</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>ISR</p>
                      </GridItem>
                      <GridItem lg={2}>
                        <p className={classNames(classes.cellTitle, classes.mdRight)}>IMPORTE NETO</p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                </GridContainer>
              </GridItem>

              
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              {MAINCONTENT}

              {/* <Hidden lgUp> */}
                {pageNumbers.length >1 && <GridItem xs={12}>
                  <Pagination
                    pages={paginationContent}
                  />
                </GridItem>}
              {/* </Hidden> */}
              
              <Hidden mdDown>
                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
              </Hidden>
              
            </GridContainer>
          </div>
        </GridItem> 
        : 
        searching && <div style={{width: '100%', height: '100vh'}}><Loading /></div>}
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableMovements);