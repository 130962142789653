import React from "react";
import * as axios from 'axios';
import { createBrowserHistory } from "history";
import browserInfo from 'utils/browserInfo';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Cards/Card.jsx";
import AlertPop from './AlertPop';
import CaptchaComp from 'utils/CaptchaComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faLock } from '@fortawesome/free-solid-svg-icons'
// sections for this page
import formStyle from "./formStyle.jsx"

const contractRef = React.createRef();
const passwordRef = React.createRef();
const gCaptchaResponseRef = React.createRef();
var hist=createBrowserHistory();

class FormLogin extends React.Component {
  constructor(props){
    super(props);

    this.state={
      // publicKey: "6LcS_v8UAAAAAI4U0BPb9-zek-6ANlwY_O71EKV2",
      hostingVariables: this.props.hostingVariables,
      publicKey: "",
      enablePassword: false,
      enablePhoneCheck: false,
      numOfTries: 1,
      lockForm: false,
      openAlerts: {
        status:false, 
        anchorEl: null, 
        content: "",
      },
      values: {
        contrato: "",
        password: "",
        testPhone: ""
      },
      tempSession: {
        changePassReq: "",
        changeNipReq: "",
        email: "",
        token: "",
        contrato: "",
        customerNum: "",
        userName: "",
        contratos: "",
        phoneTester: ""
      }
    }

  }

  formatDate = (testDate) => {
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear();
    let hours = testDate.getHours();
      if (hours < 10) hours = '0'+hours.toString();
    let minutes = testDate.getMinutes();
      if (minutes < 10) minutes = '0'+minutes.toString();
    let seconds = testDate.getSeconds();
      if (seconds < 10) seconds = '0'+seconds.toString();
    return day+"-"+month+"-"+year+" "+hours+":"+minutes+":"+seconds ;
  }

  checkSession=()=>{
    if (sessionStorage.getItem('BurSes-13axWl87T3Pz')){
      const dashboardUrl = this.props.data.dashboardUrl;
      const sessionData = JSON.parse(sessionStorage.getItem('BurSes-13axWl87T3Pz'));
      const currentDate = new Date().getTime();
      if (sessionData.end>currentDate){
        hist.push(dashboardUrl);
        window.location.assign(dashboardUrl); 
      }
    }
  }

  saveSession=(changePassReq, changeNipReq, email, token, contrato, customerNum, userName, contratos)=>{
    const dashboardUrl = this.props.data.dashboardUrl;
    const sessionLength = this.props.data.sessionLength ? this.props.data.sessionLength*60000 : 900000;
    const startDate = new Date().getTime();
    const endDate= startDate+sessionLength;
    let contractsArray = contratos.split(',')
    const data= {
      "dashboardUrl": dashboardUrl,
      "start": startDate,
      "end": endDate,
      "changePassReq": changePassReq,
      "changeNipReq": changeNipReq,
      "token": token,
      "contrato": contrato,
      "customerNum": customerNum,
      "userName": userName,
      "email": email,
      "contratos": contractsArray
    }
    sessionStorage.setItem('BurSes-13axWl87T3Pz',JSON.stringify( data ));
   }

  scrollToId = (idName) => {
    if (idName){
      if (idName.current) {
        // this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
        idName.current.scrollIntoView({ behavior: 'smooth'})
      }
    }
  }

  openPopAlert = (name, content) => {
    const toggleStatus = !this.state.openAlerts.status
    const anchorEl = document.getElementById(name);
    // const styledContent = <div style={{backgroundColor: "orange"}}><h4>{content}</h4></div>
    this.setState({openAlerts: {status: toggleStatus, anchorEl: anchorEl, content: content}})
  };

  closeAlerts = () => {
    this.setState({openAlerts: {status: false, anchorEl: null, content: ""}})
  }

  getCaptchaKey = async () => {
    let publicKey = this.state.publicKey;
    const captchaUrl=this.props.data.captchaUrl;
    if (!publicKey || publicKey === ''){
      await axios.get(captchaUrl)
      .then(resp => {
        publicKey = resp.data.data.value;
      })
      .catch(error =>{
        console.log('Error getCaptchaKey: ',error)
      });
      this.setState({publicKey: publicKey})
      return publicKey
    }
  }
  resetCaptcha = () => {
    if (window.grecaptcha){
      window.grecaptcha.reset();
    }
    this.setState({values: {...this.state.values,'gCaptchaResponse':null}, buttonDisabled: true});
  }

  verifyCallback = (token) => {
    this.setState({values: {...this.state.values,'gCaptchaResponse':token}, buttonDisabled: false});
    /*const captchaEndpoint = this.props.data.captchaEndpoint;
    const groupId = this.state.hostingVariables.groupId;
    await axios.post(
      captchaEndpoint,
      {
        "groupId": groupId,
        "gCaptchaResponse": token
      }
    ).then(resp => {
      if (resp.status === 200){
        if (resp.data.code === 200){
          this.setState({values: {...this.state.values,'gCaptchaResponse':token}, buttonDisabled: false});
        }
      }
    }).catch(error =>{
      console.log(error)
    }); 
    return;*/
  }

  handleFormInputChange = (event, name) => {
    const invalidChars = /[$%&/()=?¿!¡*{}[\]:;_\-`¨+çÇªº\\·#^¬'|]/g;
    const numbers = /^$|^[0-9]+$/g;
    // const invalidNumbers = /[0-9]/g; 

    switch(name){
      case 'contrato': 
      if(event.target.value.match(numbers) && event.target.value.length < 11){
        this.setState({values: {...this.state.values,[name]:event.target.value}})
        return;
      } else {
        return;
      }

      case 'password':
        if(event.target.value.length >40){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
      
      case 'testPhone':
        if(event.target.value.match(numbers) && event.target.value.length < 9){
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        } else {
          return;
        }
      default:
        if(event.target.value.length>100 || invalidChars.test(event.target.value)){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
    }
  }

  sendUserLog = async (contract) =>{
    const logEndPoint = this.props.data.logEndPoint;
    const userBrowser = browserInfo();
    const userIP = this.props.data.userIP;
    const date = this.formatDate(new Date());
    const logValues = {
      "contract": contract,
      "ip": userIP,
      "browser": userBrowser,
      "date": date
    }
    await axios.post( logEndPoint,logValues ).catch(error =>{
      console.log(error)
    });
  }

  validatePhone = async () => {
    const {values, tempSession} = this.state
    const {dashboardUrl, phoneCheckEndPoint } = this.props.data;
    const testPhone = values.testPhone;
    const intento = this.state.numOfTries;

    if (!testPhone){
      this.openPopAlert("testPhone", "el campo teléfono es requerido")
      return
    } else {
      await axios.post(
      // 'http://10.20.50.230/o/ProxyServices/phoneValidation',
      phoneCheckEndPoint,
        {
          "contract": values.contrato,
          "phone": testPhone,
          "gCaptchaResponse": values.gCaptchaResponse
        }
      ).then(resp => {
        this.resetCaptcha();
        if (resp.status === 200){
          if (resp.data.isValid==="BCB001") {
            const changePassReq = tempSession.changePassReq
            const changeNipReq = tempSession.changeNipReq
            const email = tempSession.email
            const contratos = tempSession.contratos
            const token = tempSession.token
            const customerNum = tempSession.customerNum
            const userName = tempSession.userName
            const contrato = values.contrato
            this.saveSession(changePassReq, changeNipReq, email, token, contrato, customerNum, userName, contratos)
            this.setState({
              numOfTries: 1,
              values:{
                contract: "",
                password: "",
                testPhone: ""
              }
            })
            this.resetCaptcha();
            hist.push(dashboardUrl);
            window.location.assign(dashboardUrl); 
          } else {
            this.sendUserLog(values.contrato);
            if (intento >= 4){
              this.setState({
                lockForm: true
              })
            contractRef.current.focus()
            this.openPopAlert("contract", "Número de contrato o telefono incorrecto(s)")
            } else {
              this.setState({
                numOfTries: intento+1
              })
              contractRef.current.focus()
              this.openPopAlert("contract", "Número de contrato o telefono incorrecto(s)")
              return;
            }
          }
        } 
      }).catch(error =>{
        console.log(error)
        this.handleBadRequest(error);
      }); 
    }
  }

  handleBadRequest = (error) => {
    try{
      this.resetCaptcha();
      if(error.response.status === 400 && error.response.data.description === 'wrong-captcha'){
        gCaptchaResponseRef.current.focus();
        this.openPopAlert("gCaptchaResponse", "Debe aceptar el captcha para continuar");
      }
    }catch(e){
      console.log(e);
    }
  }

  closeSession = () => {
    axios.post(this.props.footerData.urlCloseSession,
      {
        'contract': this.state.values.contrato,
      },
      {
        "headers": {
          "Authorization": this.state.tempSession.token,
        }
      }).then(resp => {
        if (resp.status === 200) {
          const loginUrl = this.props.hostingVariables.loginBtnUrl;
          sessionStorage.removeItem('BurSes-13axWl87T3Pz');
          hist.push(loginUrl);
          window.location.assign(loginUrl);
        }
      }).catch(error => {
        console.log(error);
      });
  }

  sendForm = async () => {
    const formEndPoint = this.props.data.formEndPoint;
    const dashboardUrl = this.props.data.dashboardUrl;
    const intento = this.state.numOfTries;
    const values = this.state.values;
    
    if (!values.contrato) {
      contractRef.current.focus()
      this.openPopAlert("contract", "el campo contrato es requerido")
      return;
    }  else {
        await axios.post(
          formEndPoint,
          {
            "contrato": values.contrato,
            "password": " ",
            "intento": intento,
            "gCaptchaResponse": values.gCaptchaResponse
          }
        ).then(resp => {
          this.resetCaptcha();
          if (resp.status === 200){
            if (resp.data.respuesta==="Ok" && resp.data.requiereCambio==="True"){
              const changePassReq = resp.data.requiereCambio==="True" ? true : false;
              const changeNipReq = resp.data.requiereCambio==="True" ? true : false;
              const email = resp.data.email
              const contratos = resp.data.contratos
              const token = resp.headers.authorization
              const customerNum = resp.data.cliente
              const userName = resp.data.nombre
              const contrato = values.contrato
              const phoneTester = resp.data.telefono
              // this.saveSession(changePassReq, changeNipReq, token, contrato, customerNum, userName, contratos)
              this.setState({...this.state, 
                numOfTries: 1,
                enablePhoneCheck: true,
                values:{
                  contrato: contrato,
                  password: " ",
                },
                tempSession: {
                  changePassReq: changePassReq,
                  changeNipReq: changeNipReq,
                  email: email,
                  token: token,
                  contrato: contrato,
                  customerNum: customerNum,
                  userName: userName,
                  contratos: contratos,
                  phoneTester: phoneTester
                }
              });

              /* hist.push(dashboardUrl);
              window.location.assign(dashboardUrl);  */
            } else if (resp.data.respuesta==="Error" && this.state.enablePassword===false ) {
              this.setState({...this.state, enablePassword: true})
            } /* else {
              this.sendUserLog(values.contrato);
              if (intento >= 4){
                this.setState({
                  lockForm: true
                })
              } else {
                contractRef.current.focus()
                this.openPopAlert("contract", "Número de contrato o password incorrecto(s)")
                this.setState({
                  numOfTries: intento+1
                })
                return;
              }
            } */
          }
        }).catch(error =>{
          console.log(error);
          this.handleBadRequest(error);
        }); 
    }
  }

  sendFormWithPass = async () => {
    const formEndPoint = this.props.data.formEndPoint;
    const dashboardUrl = this.props.data.dashboardUrl;
    const intento = this.state.numOfTries;
    const values = this.state.values;
    
    if (!values.contrato) {
      contractRef.current.focus()
      this.openPopAlert("contract", "el campo contrato es requerido")
      return;
    } else if (!values.password) {
      passwordRef.current.focus()
      this.openPopAlert("password", "el campo contraseña es requerido")
      return;
    } else if (!values.gCaptchaResponse) {
      gCaptchaResponseRef.current.focus()
      this.openPopAlert("gCaptchaResponse", "Debe aceptar el captcha para continuar")
      return;
    } else {
        await axios.post(
          formEndPoint,
          {
            "contrato": values.contrato,
            "password": values.password,
            "intento": intento,
            "gCaptchaResponse": values.gCaptchaResponse,
          }
        ).then(resp => {
          this.resetCaptcha();
          if (resp.status === 200){
            if (resp.data.respuesta==="Ok"){
              const changePassReq = resp.data.requiereCambio==="True" ? true : false;
              const changeNipReq = resp.data.requiereCambio==="True" ? true : false;
              const email = resp.data.email;
              const contratos = resp.data.contratos
              const token = resp.headers.authorization
              const customerNum = resp.data.cliente;
              const userName = resp.data.nombre
              const contrato = values.contrato
              this.saveSession(changePassReq, changeNipReq, email, token, contrato, customerNum, userName, contratos)
              this.setState({
                numOfTries: 1,
                values:{
                  contract: "",
                  password: "",
                }
              })
              hist.push(dashboardUrl);
              window.location.assign(dashboardUrl); 
            } else {
              this.sendUserLog(values.contrato);
              if (intento >= 4){
                this.setState({
                  lockForm: true
                })
              } else {
                contractRef.current.focus()
                this.openPopAlert("contract", "Número de contrato o password incorrecto(s)")
                this.setState({
                  numOfTries: intento+1
                })
                return;
              }
            }
          }
        }).catch(error =>{
          console.log(error);
          this.handleBadRequest(error);
        }); 
    }

    
  }

  componentDidMount(){
    this.checkSession();

    if (!this.state.publicKey) {
      const theCaptchaKey = this.getCaptchaKey();
      if (theCaptchaKey) {this.setState({publicKey: theCaptchaKey })}
    }
    if (this.props.data.portletKey){
      this.setState({
        values: {
        ...this.state.values,
        'portletKey':this.props.data.portletKey
      }})
    }
  }

  render() {
    const {publicKey, lockForm, enablePassword, enablePhoneCheck, tempSession} = this.state;
    const {phoneTester} = tempSession;
    const { classes, data } = this.props;
    const { 
      title, 
      titleContract, 
      contract, 
      titlePassword, 
      password,
      maxTriesMsg,
      sendBtnTxt 
    } = data;

    return (
      <React.Fragment>
        <AlertPop 
          open={this.state.openAlerts.status} 
          anchorEl={this.state.openAlerts.anchorEl} 
          handleClose={()=>this.closeAlerts()}
        >
          {this.state.openAlerts.content}
        </AlertPop>
        <GridItem sm={12} md={7}>
          <Card  
            plain
            className={classes.cardForm}
            blog
            border
          >
            {lockForm && <GridContainer spacing={3}>
              <GridItem xs={12}>
                <label className={classes.labelText}>{maxTriesMsg}</label>
              </GridItem>
            </GridContainer>}
            {!lockForm && <GridContainer spacing={3}>
              <GridItem xs={12}>
                <h4 className={classes.formTitle}>{title}</h4>
              </GridItem>
              <GridItem xs={12} className={classes.root}>
                <label className={classes.labelText}>{titleContract}</label>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="text"  
                    id="contract"
                    ref={contractRef}
                    name="contrato"
                    value={this.state.values.contrato}
                    onChange={(e)=>this.handleFormInputChange(e,"contrato")}
                    placeholder={contract}
                  />
                  <span>
                    <FontAwesomeIcon icon={faFile} />
                  </span>
                </label>
              </GridItem>
              {enablePassword&&<GridItem xs={12} className={classes.root}>
                <label className={classes.labelText}> {titlePassword} </label>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="password" 
                    id="password"
                    ref={passwordRef}
                    name="password"
                    value={this.state.values.password}
                    onChange={(e)=>this.handleFormInputChange(e,"password")}
                    placeholder={password}
                  />
                  <span>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </label>
              </GridItem>}
              {enablePhoneCheck && <GridItem xs={12} className={classes.root}>
              <label className={classes.labelText}> Número de teléfono </label>
              <label className={classes.labelText}> {phoneTester} </label>
              <p className={classes.defaultFont}>Deberás capturar el número de teléfono proporcionado a Bursamétrica, considerando los últimos 8 dígitos únicamente</p>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="text" 
                    id="testPhone"
                    name="testPhone"
                    value={this.state.values.testPhone}
                    onChange={(e)=>this.handleFormInputChange(e,"testPhone")}
                    placeholder="Número de telefono"
                  />
                  <span>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </label>
              </GridItem>}
              {publicKey ? <GridItem xs={12}>
            <div style={{display:"inline-block"}} id="gCaptchaResponse" ref={gCaptchaResponseRef}>
            <CaptchaComp 
              siteKey = {this.state.publicKey}
              verifyCallback={this.verifyCallback}
            /> 
            </div>
            </GridItem>: <></>}

              <GridItem xs={12}>
                {/* <div style={{marginBottom: "1.5rem"}}>
                  <Button disableRipple  size="" color="showBorderHover" href='/' >
                    ¿Olvidaste tu contraseña?
                  </Button>
                </div> */}
                <Button disableRipple  size="" color="primary" onClick={enablePassword ? ()=>this.sendFormWithPass() : enablePhoneCheck ? ()=>this.validatePhone() : ()=>this.sendForm()} >
                  {sendBtnTxt}
                </Button>
              </GridItem>
            </GridContainer>}
          </Card>
        </GridItem>
      </React.Fragment>
    );
  }
}

export default withStyles(formStyle)(FormLogin);
