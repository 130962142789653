import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import logoSmall from "assets/img/png/logo_small.png"


class ThanksMessage extends Component {
  render(){

//   const onKeyDownHandler = e => {
//     if (e.key === 'Enter') {
//       let value = searchString;
//       hist.push(props.hostingVariables.searchHost+'?mainSearch="'+value+'"');
//                     window.location.assign(hist.location.pathname+hist.location.search);
//     }
//   };

  const { classes, 
    // hostingVariables
  } = this.props;
  // const { contactFormData } = hostingVariables;
  // const contactFormLabels = this.props.contactFormData.data
  return (
    <React.Fragment>
        {/* <div className={classes.headerDrawer}>
          <h5 className={classes.titleSearch}>{contactFormData.title}</h5>
        </div> */}
        <div className={classes.bodyDrawer}>
            <GridContainer justify="center" spacing={2} style={{textAlign:"center"}} >
              <GridItem xs={12} md={12}>
                <h5 className={classes.titeMessage}>¡GRACIAS!</h5>
                <h6 className={classes.textMessage}>Hemos recibido tu mensaje, en breve te contactaremos.</h6>
                {/* <h5 className={classes.titeMessage}>{contactFormLabels.thanksMsgTitle}</h5>
                <h6 className={classes.textMessage}>{contactFormLabels.thanksMsg}</h6> */}
              </GridItem>
            </GridContainer>
        </div>
        <div className={classes.footerMenuMovil}>
            <img src={logoSmall} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logoSearch} />
        </div>
    </React.Fragment>
  );
}
};

export default withStyles(headerStyle)(ThanksMessage);
