import {
  box,
  divider,
  tableTitle,
  title,
  text,
} from "components/Table/tableStyle"

import { primaryColor } from "assets/jss/material-kit-pro-react";

  const tableAcountStateStyle = {
    box: {
      ...box,
    },
    divider: {
      ...divider
    },
    tableTitle: {
      ...tableTitle
    },
    title: {
      ...title,
    },
    titleBlue: {
      ...title,
      color: "#136EC3"
    },
    text: {
      ...text
    },

    titleLine: {
      lineHeight: "1.05em",
      fontFamily: `'Lato', sans-serif`,
      color: "#003B72",
      fontWeight: 600,
      margin: '0 !important',
      fontSize: "1.46rem !important",
      position: 'relative',
      textTransform: 'uppercase',
      marginBottom: '1rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    },

    pl:{
      paddingLeft: "15px"
    }

  };
  
  export default tableAcountStateStyle;
  