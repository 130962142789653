import React from "react";
import htmlText from 'utils/htmlFormattedText';
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// react component for creating beautiful carousel
// core components
// import Title from "components/Title";
import GridItem from "components/Grid/GridItem.jsx";
// import Hidden from "@material-ui/core/Hidden";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./circleTagListStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Parallax from "components/Parallax";

class CircleTagList extends React.Component {
    cardCreator=(item)=>{
      const {classes} = this.props;
      return <GridItem xs={12} >
        <div className={classes.circleTagItem}>
            <div className={classes.circleTag}>
                {item.icon ? <img src={item.icon} alt="..." /> : item.id }
            </div>
            <div>
                {item.titleUrl && <a onClick={()=>breadcrumbsUpdate()} href={item.titleUrl} rel="noopener noreferrer">
                {item.title && <p className={classes.itemTitle}>
                  {htmlText(item.title)}
                </p>}
                </a>}
                {(item.title && !item.titleUrl) && <p className={classes.itemTitle}>
                  {htmlText(item.title)}
                </p>}
                <p className={classes.description}>
                  {htmlText(item.description)}
                </p>
            </div>
        </div>
    </GridItem>

    }
    render() {
      const { classes, data } = this.props;
      const {title, imageUrl, bgImage} = data;
      var CARDS = [];
      if (data.data){
        data.data.sort((card1, card2) => card1.id - card2.id)
        .map(item => {
            CARDS.push(this.cardCreator(item))
            return null;
        })
      }
      return (
        <Parallax image={bgImage} filter="dark" >
          <GridContainer className={classes.container} spacing={4}  justify="center">
              <GridItem xs={12} style={{margin: "3rem 0"}}>
                <GridContainer spacing={6}>
                  {
                    imageUrl && 
                    // <Hidden mdDown>
                      <GridItem xs={12} md={4}>
                        {/* <div className={classes.imageBg} style={{ backgroundImage: "url(" + imageUrl + ")" }} ></div> */}
                        <img src={imageUrl} alt="" className="responsiveImage" style={{margin: "0 auto"}}/>
                      </GridItem>
                    // </Hidden>
                  }

                  <GridItem xs={12} md={imageUrl ? 8 : 12}>
                    <GridContainer spacing={2}>
                      {/* {
                        title && <Title title={title} line/>
                      } */}
                      {
                        title && <GridItem xs={12}>
                          <h4 className={classes.titleLine}>{htmlText(title)}</h4>
                        </GridItem>
                      }
                      {CARDS}
                    </GridContainer>
                  </GridItem>

                </GridContainer>
              </GridItem>
          </GridContainer>
        </Parallax>
      );
    }
}

export default withStyles(style)(CircleTagList);
