import React from 'react'
import * as axios from 'axios';
// import { createBrowserHistory } from "history";
import {
  ReactiveBase,
  MultiDataList,
  DataSearch,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import GridItem from "components/Grid/GridItem.jsx";
import NotiCard from "components/Cards/NotiCard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SubTitle from "components/Title/SubTitle.jsx";
import Video from "components/Video";
import Button from "components/CustomButtons/Button.jsx";
import Badge from "components/Badge/Badge"
import Pagination from "components/Pagination/Pagination.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import style from "./style.jsx";

// var hist=createBrowserHistory();


class SearchEconomy extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      tagList: [],
      selectedTag: [],
      hostingVariables: this.props.hostingVariables,
      key: 'SearchEconomy'+Math.floor(Math.random()*901)
    }
  }

  scrollToMyRef = (cause) => {

    if (document.getElementById('searchTop')){
      this.setState({ addMargin:true })
      var dropdownElement = document.getElementById('searchTop');
      setTimeout(() => {
        dropdownElement.scrollIntoView({ behaviour: "smooth" });
      }, 500);
      
      
    }
  }

  reformatDate=(date)=>{
    let dateDay='';
    let dateMonth='';
    let dateYear='';
    if (date && typeof(date)==="number"){
      const spreadDate = new Date(date)
      dateDay = spreadDate.getDate();
      dateMonth = spreadDate.getMonth()+1;
      dateYear = spreadDate.getFullYear();
      if (dateDay<10) dateDay = '0'+dateDay
      if (dateMonth<10) dateMonth = '0'+dateMonth
    }
    return dateDay+"/"+dateMonth+"/"+dateYear
  }
  
  async getTagData(){
    let query={"query":{"bool":{"must":[{"bool":{"must":[{"bool":{"must":[{"term":{"groupId":"33818"}},{"term":{"assetCategoryIds":"33985"}},{"term":{"latest":"true"}},{"term":{"status":"0"}}]}}]}}]}},"size":5,"_source":{"includes":["*"],"excludes":[]},"from":0,"sort":{"publishDate_sortable":{"order":"desc"}}}
    let bodys = '{"preference":"SearchResults"},'+JSON.stringify(query)
    await axios.get(
      this.state.hostingVariables.elasticHost+"/liferaygg-20101/_msearch?",
      {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        source: JSON.stringify(bodys)+"\n"+JSON.stringify(query)+"\n",
        source_content_type: 'application/json'
        }
    })
    .then(resp => {
      let tempTags=[]
      resp.data.responses[0].hits.hits.map(item=>{
        if (item._source.assetTagNames) {
          if (Array.isArray(item._source.assetTagNames)) {
            item._source.assetTagNames.map(innerItem=>{
              if (tempTags.length===0 || !tempTags.find(tag=>tag.label === innerItem)){
                tempTags.push({label:innerItem, value:innerItem})
              }
              return null;
            })
          } else {
            if (this.state.tagList.indexOf(item._source.assetTagNames)<0) {
              tempTags.push({label:item._source.assetTagNames, value:item._source.assetTagNames})
            }
          }
        }
        return null;
      })
      this.setState({
        tagList: tempTags
      })
    })
    .catch(error =>{
      console.log(error);
      /* hist.push('/error'); */
    });
  }

  handleTagChange = (value) => {
    this.setState({selectedTag: value})
  }

  handleSearchInput = (value) => {
    /* this.setState ({searchValue: value}, () => triggerQuery()); */
    this.setState ({searchValue: value});
  }

  handleEnter = (e, triggerQuery) => {
    if (e.key === "Enter") {
      triggerQuery();
      this.scrollToMyRef()
    }
  };

  handleOnClear = (component, value) => {
    if (component === "mainSearch" || !component){
      this.setState({ searchValue: ''});
    }
  }

  filterFolders = ()=> {
    let miQuery=null;

    miQuery = {
      must: [
        {"term": {"groupId": this.state.hostingVariables.groupId}},
        {"term": {"assetCategoryIds":"33985"}},
        {"term": {"latest": "true"}},
        {"term": {"status": "0"}}
        
      ],

    }
    
    return { query: { bool: miQuery }, sort: {
      "publishDate_sortable" : {"order": "desc"}
      } }  ;
  }
  
  componentDidMount(){
    this.getTagData();
  }

  render(){
    const { classes, data } = this.props
    const { labels,  videoList } = data
    const tagList = this.state.tagList
    const marginClass = this.state.addMargin ? classes.searchTopActive : null
    return (
      <React.Fragment>
        <div id="searchTop" className={marginClass} />
      <GridItem xs={12} >
        <ReactiveBase
        className={classNames('')}
        app={this.state.hostingVariables.lifeRayApp}
        credentials={this.state.hostingVariables.lifeRayCredentials}
        url={this.state.hostingVariables.elasticHost}
      >
        <GridContainer spacing={4}>
          <GridItem xs={12} md={8}>
            <SelectedFilters
              showClearAll={true}
              clearAllLabel={labels.filterClearAll}
              onClear={(component, value) => {
                this.handleOnClear(component, value);
              }}
            />
            <ReactiveList
              /* style={{ minHeight: 600 }} */
              componentId="SearchResults"
              dataField={"title"}
              showResultStats={true}
              scrollOnChange={false}
              size={5}
              pagination={true}
              defaultQuery={this.filterFolders}
              react={{
                and: ['mainSearch', 'tagSelector']
              }}
              renderResultStats={(stats) => {
                return `${stats.numberOfResults} resultados encontrados`;
              }}
              renderNoResults={() => {
                return `0 resultados encontrados`;
              }}
              render={({ data }) => {
                return data.map((item, index) => {
                  return (
                    <NotiCard 
                      key={index}
                      horizontal
                      title={item.ddm__keyword__33978__nameNotice_es_ES}
                      autor={item.ddm__keyword__33978__authorName_es_ES}
                      date={this.reformatDate(item.displayDate_sortable)}
                      description={item.ddm__text__33978__descriptionNotice_es_ES}
                      blog
                      isSearch
                      buttonText={labels.resultBtnTxt}
                      searchTarget="_blank"
                      urlBtn={item.ddm__keyword__33978__redirectLink_es_ES}
                    />
                  )
                })
              }}

              renderPagination={({ pages, totalPages, currentPage, setPage, setSize }) => {
                const hasPages = Number.isFinite(totalPages);
                const handlePrevPage = () => {
                  let page=currentPage;
                  const prevPage = page-1;
                  setPage(prevPage)
                }
              
                const handleCurrentPage= (num) => {
                  setPage(num)
                }
              
                const handleNextPage = () => {
                  let page=currentPage;
                  const nextPage = page+1;
                  setPage(nextPage)
                }
                if(!!hasPages) {
                  var paginationContent=[];
                  var pageNumbers = [];
                  for (let i = 1; i <= totalPages ; i++) {
                    pageNumbers.push(i);
                  }
                  if (totalPages > 1){
                    if (totalPages >= 10 && currentPage >4){
                      paginationContent.push({onClick:()=>handleCurrentPage(0), text: labels.paginatorFirst})
                    }
                    if (currentPage < 1){
                      paginationContent.push({disabled: true, text: labels.paginatorPrev })
                    }else{
                      paginationContent.push({onClick: ()=>handlePrevPage(), text: labels.paginatorPrev })
                    }
                    pageNumbers.map((item, index)=>{
                      if(item===currentPage+1){
                        paginationContent.push({active:true, text: item})
                      }else{
                        if (totalPages >= 10 && currentPage>4 && (index >= currentPage-5 && index <= currentPage+4) ){
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }else if (totalPages >=10 && currentPage <=4 && index <10){
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }else if (totalPages <10) {
                          paginationContent.push({onClick:()=>handleCurrentPage(item-1), text: item})
                        }
                      }
                      return null;
                    })
                    if (currentPage < pageNumbers.length-1){
                      paginationContent.push({onClick: ()=>handleNextPage(), text: labels.paginatorNext });
                      if(totalPages>=10){
                        paginationContent.push({onClick:()=>handleCurrentPage(pageNumbers.length-1), text: labels.paginatorLast });
                      }
                    }else{
                      paginationContent.push({disabled: true, text: labels.paginatorNext })
                    }
                  }
                  return (
                    <GridContainer>
                      <GridItem xs={12}>
                        <Pagination 
                          pages={paginationContent}
                        />
                      </GridItem>
                    </GridContainer>
                    
                  )
                } else {
                  return <div></div>
                }
              }}

              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <GridContainer >
                {tagList.length>0 && <GridItem xs={12}>
                  <GridContainer>
                    <SubTitle title="Etiquetas"/>
                  </GridContainer>
                  <MultiDataList
                    componentId="tagSelector"
                    dataField={"assetTagNames"}
                    filterLabel="Tags"
                    showSearch={false}
                    showCheckbox={false}
                    URLParams={false}
                    data={tagList}
                    value={this.state.selectedTag}
                    onChange={this.handleTagChange}
                    render={({ data, handleChange }) => {
                      return (<div>
                        {data.map(item => {
                          let colorclass = "primaryTags";
                          if (this.state.selectedTag && this.state.selectedTag.indexOf(item.label)>=0) colorclass = "primary"
                          return (
                            <span onClick={() => {this.scrollToMyRef(); handleChange(item.label)}} key={item.label}>
                                <Badge color={colorclass}>{item.label}</Badge>
                            </span>
                          )
                        })}
                      </div>)
                      }
                    }
                  />
                </GridItem>}
                <GridItem xs={12}>
                  <GridContainer>
                    <SubTitle title="Búsqueda"/>
                  </GridContainer>
                  <div className={classes.contentSearchSimple}>
                    <DataSearch
                      componentId="mainSearch"
                      className={classNames(classes.inputSimple, classes.w100)}
                      innerClass={{
                        input: 'dataSearchClassInput bgWhite'
                      }}
                      dataField={["title", "localized_title", "content_es_ES", "ddm__keyword__33978__authorName_es_ES", "ddm__keyword__33978__nameNotice_es_ES" ]}
                      queryFormat="or"
                      placeholder={labels.keyword}
                      autosuggest={false}
                      value={this.state.searchValue}
                      onChange={this.handleSearchInput}
                      onKeyPress={this.handleEnter}
                      onValueSelected={()=>this.scrollToMyRef()}
                      showFilter={true}
                      URLParams={true}
                      filterLabel={labels.keyword}
                      showIcon={true}
                      iconPosition={'right'}
                      debounce={3000000}
                      icon={<Button 
                        disableRipple
                        disabled={!this.state.searchValue}
                        size={null} 
                        color="searchWhite"
                      >
                          {labels.searchBtnTxt}
                      </Button>}
                    />
                    {/* <Button 
                      disableRipple
                      disabled={!this.state.searchValue}
                      size={null} 
                      color="searchWhite" 
                      onClick={()=>this.triggerSearch()} 
                    > 
                      {labels.searchBtnTxt}
                    </Button> */}
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer>
                    <SubTitle title="Videos"/>
                  </GridContainer>
                    {videoList && videoList.map((video, index)=>
                      <Video key={index} isSearch video={video.video}/>
                    )}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ReactiveBase>
      </GridItem>
      </React.Fragment>
    )
  }
}

export default withStyles(style)(SearchEconomy);