import {
    defaultFont,
    primaryColor,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const StrategyBlockStyle = {
    description: {
      ...defaultFont,
      textAlign: "center",
      margin: "1rem 0 0 0",
      lineHeight: "1.6em !important",
      "& strong": {
        fontSize: "1rem"
      }
    },
    preTitle: {
      display: "block",
      fontFamily: "Lato, sans-serif",
      fontSize: "1.5rem",
      lineHeight: "1.36em",
      fontWeight: "normal",
      color: "#272727",
      marginBottom: "1.36rem",
      marginTop: 0,
      textAlign: "center"
    },
    title: {
      display: "block",
      fontFamily: "Lato, sans-serif",
      fontSize: "1.86rem",
      lineHeight: "1.1em",
      fontWeight: "normal",
      color: "#272727",
      marginBottom: "2rem",
      marginTop: 0,
      textTransform: "uppercase",
      textAlign: "center"
    },
    blueText:{
      color: primaryColor[0]
    }
  }
  
  export default StrategyBlockStyle;
  