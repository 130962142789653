import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import LogoSmall from "assets/img/png/logo_small.png"

import style from "./style.jsx"; 

class Cintillo extends Component {
  render(){
  const {
    title,
    footerLogo,
    classes,
    children,
    bgGray,
    bgWhite,
    open,
    onClose
  } = this.props;

  const tempClass = bgGray ? classes.drawerPaperGray : bgWhite ? classes.drawerPaperWhite : classes.drawerPaper;
  return (
    <Drawer
      variant="temporary"
      anchor={"top"}
      open={open}
      classes={{
        paper: tempClass
      }}
      onClose={onClose}
    >
      
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={onClose}
        className={classes.closeButtonDrawer}
      >
        <Close />
      </IconButton>
      <div className={classes.headerDrawer}>
        <h5 className={classes.titleDrawer}>{title}</h5>
      </div>
      <div className={classes.bodyDrawer}>
        {children}
      </div>
      {footerLogo && <div className={classes.footerMenuMovil}>
        <img src={LogoSmall} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logoSearch} />
      </div>}
    </Drawer>
  );
}
};

export default withStyles(style)(Cintillo);
