import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import Banner from "./Banner";
import GridItem from "components/Grid/GridItem.jsx";
// Style
import bannerStyle from "./bannerStyle.jsx";

class SingleImageBanner extends React.Component {

  render(){
    const { bannerData  } = this.props;
    return (
          <GridItem xs={12} style={{padding: 0, minHeight: "250px"}}>
            <Banner { ...bannerData } />
          </GridItem>
    );
  }
}

export default withStyles(bannerStyle)(SingleImageBanner);
