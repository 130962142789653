import React from "react";
import * as axios from 'axios';
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableAcountStateStyle";
import classNames from "classnames";
// import Title from "components/Title";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
// import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import Loading from "components/Loader/Loading";

class TableAcountState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContract: "",
      selectedYear: "",
      disabled: false,
      isLoading: false,
    }
  }

  formatMonth = (month) => {
    let monthText = "DICIEMBRE";
    switch (month) {
      case 0:
        monthText = "ENERO"
        break;

      case 1:
        monthText = "FEBRERO"
        break;

      case 2:
        monthText = "MARZO"
        break;

      case 3:
        monthText = "ABRIL"
        break;

      case 4:
        monthText = "MAYO"
        break;

      case 5:
        monthText = "JUNIO"
        break;

      case 6:
        monthText = "JULIO"
        break;

      case 7:
        monthText = "AGOSTO"
        break;

      case 8:
        monthText = "SEPTIEMBRE"
        break;

      case 9:
        monthText = "OCTUBRE"
        break;

      case 10:
        monthText = "NOVIEMBRE"
        break;

      default:
        break;
    }
    return monthText;
  }

  yearListStructure = () => {
    const { classes } = this.props
    const currentYear = new Date().getFullYear();
    const years = new Array(5).fill(null).map((_, index) => {
      const year = currentYear - index;
      return (
        <Button
          disableRipple
          className={classes.userPopover}
          color="transparent"
          key={year}
          onClick={() => this.setState({ selectedYear: year })}
        >
          {year}
        </Button>
      );
    });
    return years;
    // return [
    //   <Button 
    //       disableRipple
    //       className={classes.userPopover} 
    //       color="transparent" 
    //       key={"onlyYear"}
    //       onClick={()=>this.setState({selectedYear: currentYear})}
    //     >
    //       {currentYear}
    //     </Button>,
    //     <Button 
    //     disableRipple
    //     className={classes.userPopover} 
    //     color="transparent" 
    //     key={"onlyYear"}
    //     onClick={()=>this.setState({selectedYear: currentYear-1})}
    //   >
    //     {currentYear-1}
    //   </Button>
    // ]
  }
  dropdownListStructure = () => {
    const { classes, contrato, contratos } = this.props;
    if (contratos) {
      return contratos.sort((item1, item2) => item1 - item2).map((item, key) =>
        <Button
          disableRipple
          className={classes.userPopover}
          color="transparent"
          key={key}
          onClick={() => this.setState({ selectedContract: item })}
        >
          {item}
        </Button>
      )
    } else if (contrato) {
      return [<Button
        disableRipple
        className={classes.userPopover}
        color="transparent"
        onClick={() => this.setState({ selectedContract: contrato })}
      >
        {contrato}
      </Button>]
    } else {
      return []
    }
  }

  convertFilePDF = (fileStr, month) => {
    if (fileStr) {
      var b64 = fileStr;
      const certDate = this.state.selectedYear;
      // Insert a link that allows the user to download the PDF file
      var link = document.createElement('a');
      link.innerHTML = '';
      link.download = 'Estado_' + month + '_' + certDate + '.pdf';
      link.href = 'data:application/pdf;base64,' + b64;
      link.click();
    }
  }

  convertFileXML = (fileStr, month) => {
    if (fileStr) {
      var b64 = fileStr;
      // const crtTypeName = this.getCertType(certType);
      const certDate = this.state.selectedYear;
      // Insert a link that allows the user to download the XML file
      var link = document.createElement('a');
      link.innerHTML = '';
      link.download = 'Estado_' + month + '_' + certDate + '.xml';
      link.href = 'data:application/xml;base64,' + b64;
      link.click();
    }
  }

  getFilePDF = async (month) => {
    const axiosHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.props.token,
        'X-APP-CONTRACT': this.state.contractLogin,
      }
    }
    const endpoint = this.props.data.endpoint;
    const customerNum = this.props.customerNum;
    const selContract = this.state.selectedContract;
    const currentYear = this.state.selectedYear;
    const date = moment().year(currentYear).month(month).date(1);
    const formatedMonth = date.format('MM');
    date.endOf('month');
    const strDate = date.format('YYYYMMDD');
    this.setState({
      isLoading: true
    });
    await axios.post(
      endpoint,
      {
        "contrato": Number(selContract),
        "fecha": strDate,
        "pdf": "1"
      },
      axiosHeaders
    ).then(resp => {
      if (resp.status === 200) {
        if (resp.data.code === "401") {
          this.props.closeSession();
        } else {
          this.convertFilePDF(resp.data.data, formatedMonth);
        }
      }
    }).catch(error => {
      console.log('getResultsInfoData: Error', error)
      this.props.closeSession();
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    });
  }

  getFileXML = async (month) => {
    const axiosHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.props.token,
        'X-APP-CONTRACT': this.state.contractLogin,
      }
    }
    const endpoint = this.props.data.endpoint;
    const customerNum = this.props.customerNum;
    const selContract = this.state.selectedContract;
    const currentYear = this.state.selectedYear;
    const date = moment().year(currentYear).month(month).date(1);
    const formatedMonth = date.format('MM');
    date.subtract(1, 'days').endOf('month');
    const strDate = date.format('YYYYMMDD');
    this.setState({
      isLoading: true
    })
    await axios.post(
      endpoint,
      {
        "contrato": Number(selContract),
        "fecha": strDate,
        "pdf": "0"
      },
      axiosHeaders
    ).then(resp => {
      if (resp.status === 200) {
        if (resp.data.code === "401") {
          this.props.closeSession();
        } else {
          this.convertFileXML(resp.data.data, formatedMonth);
        }
      }
    }).catch(error => {
      console.log('getResultsInfoData Error: ', error)
      this.props.closeSession();
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    });
  }

  handleAuthorization = async (type, month) => {
    /* const axiosHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization' : this.props.token
      }
     } */

    if (type === 'PDF') {
      this.getFilePDF(month)
    } else if (type === 'XML') {
      this.getFileXML(month);
    }

  }

  rowStructure = (month, last) => {
    const { classes,
      // data
    } = this.props
    const monthTitle = this.formatMonth(month);
    return (
      <React.Fragment>
        <GridItem xs={4}>
          <p className={classNames(classes.title, classes.pl)}>{monthTitle}</p>
        </GridItem>
        <GridItem xs={4}>
          <Button onClick={() => this.handleAuthorization('PDF', month)} disableRipple size="" color="borderGrayPrimary" style={{ height: "42px" }}>
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </GridItem>
        <GridItem xs={4}>
          <Button onClick={() => this.handleAuthorization('XML', month)} disableRipple size="" color="borderGrayPrimary" style={{ height: "42px" }}>
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </GridItem>

        {!last && <GridItem xs={12}>
          <div className={classes.divider}></div>
        </GridItem>}
      </React.Fragment>
    )
  }

  rowListStructure = () => {
    const rowList = [];
    const selectedYear = this.state.selectedYear;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    if (currentYear === selectedYear) {
      for (let m = 0; m < currentMonth; m++) {
        rowList.push(this.rowStructure(m, m + 1 === currentMonth));
      }
    } else {
      let firstMonth = (currentYear - 5 === selectedYear) ? currentMonth : 0;
      for (let m = firstMonth; m < 12; m++) {
        rowList.push(this.rowStructure(m, m + 1 === 12));
      }
    }




    return rowList;
  }

  componentDidMount() {
    const contract = this.props.contrato;
    const currentYear = new Date().getFullYear();
    let contractLogin = '';
    try {
      contractLogin = JSON.parse(sessionStorage.getItem('BurSes-13axWl87T3Pz')).contrato;
    } catch (error) {
      console.error('CANNOT_LOAD_CONTRACT_LOGIN', JSON.stringify(error));
    }
    this.setState({
      selectedContract: contract,
      selectedYear: currentYear,
      contractLogin
    })
  }

  render() {
    const { classes, data } = this.props;
    const { title } = data;
    const { selectedYear, selectedContract } = this.state;
    const DROPLISTCONTRACTS = this.dropdownListStructure();
    const DROPLISTYEAR = this.yearListStructure();
    const ROWS = this.rowListStructure();

    return (
      <React.Fragment>
        <GridItem xs={12}>
          <GridContainer spacing={4} >
            <GridItem xs={12}> <h2 className={classes.titleLine}>{title}</h2> </GridItem>
            <GridItem xs={6} sm={3}>
              <List id="interest" style={{ padding: 0 }}>
                <ListItem style={{ padding: 0, }} key={1 + 'ListItem' + Math.floor(Math.random() * 901)} >
                  <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={"transparent"}
                    buttonText={selectedContract ? selectedContract : "CONTRATO (S)"}
                    buttonProps={{
                      className: classes.dropdown,
                      color: "primaryPrimary"
                    }}
                    dropdownList={DROPLISTCONTRACTS}
                  />
                </ListItem>
              </List>
              {/* <Button disableRipple size="" color="primary" >
                CONTRATO (S) <FontAwesomeIcon icon={faSortDown} style={{margin: "-3px 0 0 4px"}}/>
              </Button> */}
            </GridItem>
            <GridItem xs={6} sm={3}>
              <List id="interest" style={{ padding: 0 }}>
                <ListItem style={{ padding: 0, }} key={1 + 'ListItem' + Math.floor(Math.random() * 901)} >
                  <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={"transparent"}
                    buttonText={selectedYear ? selectedYear : "AÑO"}
                    buttonProps={{
                      className: classes.dropdown,
                      color: "primaryPrimary"
                    }}
                    dropdownList={DROPLISTYEAR}
                  />
                </ListItem>
              </List>
              {/* <Button disableRipple size="" color="primary" >
                AÑO <FontAwesomeIcon icon={faSortDown} style={{margin: "-3px 0 0 4px"}}/>
              </Button> */}
            </GridItem>
            <GridItem xs={12}>
              <div><strong>NOTA: Los estados de cuenta se generan durante los primeros 5 días hábiles de inicio de cada mes.</strong></div>
              <div className={classes.box}>
                <GridContainer spacing={2} style={{ margin: "-8px" }}>
                  <GridItem xs={4}>
                    <p className={classNames(classes.titleBlue, classes.pl)}>MES</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <p className={classes.titleBlue}>ARCHIVO PDF</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <p className={classes.titleBlue}>ARCHIVO XML</p>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  {ROWS}
                  {/*   
                  <GridItem xs={4}>
                    <p className={classes.title}>ENERO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>FEBRERO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>MARZO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>ABRIL</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>MAYO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>JUNIO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>JULIO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>AGOSTO</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>SEPTIEMBRE</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>OCTUBRE</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>NOVIEMBRE</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>

                  <GridItem xs={12}>
                    <div className={classes.divider}></div>
                  </GridItem>

                  <GridItem xs={4}>
                    <p className={classes.title}>DICIEMBRE</p>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem>
                  <GridItem xs={4}>
                    <Button disableRipple size="" border color="borderGrayPrimary" >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </GridItem> */}

                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        {this.state.isLoading && <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%'
          }}>
          <Loading isOffice={true} />
        </div>}
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableAcountState);
