import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";

import style from "./dividerStyle.jsx"; 


class Divider extends Component {
  render(){
  const {
    classes,
  } = this.props;

  return (
    <React.Fragment>
      <GridItem xs={12}>
        <div className={classes.divider}></div>
      </GridItem>
    </React.Fragment>
  );
}
};

export default withStyles(style)(Divider);
