import React, { Component } from "react";
import htmlText from 'utils/htmlFormattedText';
// @material-ui/core components
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
// import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import style from "./style.jsx"; 


class Scheme extends Component {

  schemeItemStructure=(item, key) => {
    const { classes, } = this.props;
    return <GridItem xs={6} sm={6} md={2} key={key}>
      <div className={classes.box}>
        <div className={classes.iconBox}>
          <div className={classes.digit}>{item.tag}</div>
          <img src={item.image} alt="" title=""/>
        </div>
        <h4>{item.label}</h4>
      </div>
    </GridItem>
  }

  render(){
    const { classes, } = this.props;
    const {
      // title,
      description,
      tagList,
      bgGray
    } = this.props.data;
    var CARDS=[]

    if (tagList){
      tagList.sort((card1, card2) => card1.id - card2.id)
      .map((item,key)=>{
        CARDS.push(this.schemeItemStructure(item,key));
        return null;
      })
    }

    return (
      <React.Fragment>
        <div className={classNames(classes.wrapper, bgGray && classes.bgGray)}>
          <GridContainer spacing={4} justify="center" className={classes.container}>
            {/* { title && <GridItem xs={12}>
                <h5 className={classes.title}>
                  {title}
                </h5>
              </GridItem>
            } */}
            { description &&  <GridItem xs={12}><p className={classes.description}>{htmlText(description)}</p></GridItem>}
            {CARDS}
          </GridContainer>
        </div>
      </React.Fragment>
    );
  }
};

export default withStyles(style)(Scheme);
