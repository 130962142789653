import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import breadcrumbsStyle from "./breadcrumbsStyle.jsx"; 
import GridItem from "components/Grid/GridItem.jsx";

class Migas extends Component {
  constructor(props){
    super(props);

    this.state = {
      path: [],
      isLongStr: false,
      isMobile: false,
      hideBtn: true
    }
  }

  checkBreads=()=>{
    if (localStorage.getItem('BurSes-breWl87T3Pz')){
      const breadcrumbsData = JSON.parse(localStorage.getItem('BurSes-breWl87T3Pz'));
      this.setState({path: breadcrumbsData.pathArray})
    }
  }

  changePagination=()=>{
     if ( this.state.screenWidth < 600 && this.state.isMobile !== true && this.state.hideBtn !== true){
      this.setState({isMobile: true})
    } else if (this.state.screenWidth >= 600 && this.state.hideBtn !== true && !this.state.isLongStr){
      this.setState({
        isLongStr: false,
        isMobile: false,
        hideBtn: true
      })
    }
    if (document.getElementById('breadcrumbHeight')){
      const divHeight = document.getElementById('breadcrumbHeight').clientHeight;
      const isLongStr = this.state.isLongStr;
      if (divHeight>28 && isLongStr !== true){
        this.setState({isLongStr: true})
      }
    }
    
  return null;
}

updateWindowDimensions=()=>{
  this.setState({ screenWidth: window.innerWidth});
}

timeToggle = () => {
  setTimeout(() => {
    this.changePagination();
  }, 400);
}

componentDidMount(){
  window.addEventListener('resize', this.updateWindowDimensions);
  this.checkBreads();
  this.setState({
    screenWidth: window.innerWidth,
  },this.timeToggle );
}
toggleBtnExpand=()=>{
  const prevState = this.state.hideBtn;
  this.setState({hideBtn: !prevState})
}

updateBreadcrumbs=(selectedLink)=>{
  if (localStorage.getItem('BurSes-breWl87T3Pz')){
    const breadcrumbsData = JSON.parse(localStorage.getItem('BurSes-breWl87T3Pz'));
    let tempPath = breadcrumbsData.pathArray;
    let cutFromIndex = tempPath.indexOf(selectedLink)
    if (cutFromIndex !== -1) {
      tempPath.splice(cutFromIndex, tempPath.length);
      localStorage.setItem('BurSes-breWl87T3Pz',JSON.stringify( {"pathArray": tempPath, isInternalLink: true} ));
    }
  }
}

  render(){
    this.changePagination();
    const { classes } = this.props;
    let {initialPageName, actualPageName} = this.props.data;
    let path = this.state.path;
    let breadArray = [...path];
    let roadArray = breadArray.map(item=>item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ").join('-'))

    if(breadArray.length >0) breadArray.pop();
    if(roadArray.length >0) roadArray.pop();
    let BREADCRUMB_LINK;
    let BREADCRUMB_DATA;
    const show=((this.state.hideBtn && !this.state.isLongStr)|| (!this.state.hideBtn && this.state.isLongStr))
    
    if (show === true){
      BREADCRUMB_LINK = roadArray.map(item => item);

      BREADCRUMB_DATA = breadArray.map((item, index) => {
        let cleanName= item.substring(1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split("-").join(' ')
        /* if (breadArray.length > 0 && index === 0){
          
          return (
            <Typography className={classes.ellipsis} color="">{item}</Typography>
          )
        } else { */
          return(
            <Link color="primary" onClick={()=>this.updateBreadcrumbs(BREADCRUMB_LINK[index])} href={BREADCRUMB_LINK[index]} className={classes.link} >
              {cleanName}
            </Link>
          )
      /*   } */
      });
    } else if(breadArray.length >0){
      BREADCRUMB_LINK = roadArray[0]
      let cleanName= breadArray[0].substring(1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split("-").join(' ')
      BREADCRUMB_DATA = <Typography className={classes.ellipsis} color="">
        {cleanName}
      </Typography>
    }
    return (
      <GridItem xs={12} className={classNames(classes.wrapper)}>
        <div id="breadcrumbHeight" className={classNames(classes.container, classes.olNowrap)}>
          <Breadcrumbs maxItems={12} aria-label="Breadcrumb">
            <Link color="" className={classes.link} href="/" >
              {initialPageName}
            </Link> 
            {BREADCRUMB_DATA}
            {(show) && <Typography className={classes.ellipsis} color="">{(actualPageName !== undefined )? actualPageName : null }</Typography>}
            {/* {(show) && <Typography className={classes.ellipsis} >Página actual</Typography>} */}
    {(this.state.isMobile || this.state.isLongStr)&& <div onClick={()=>this.toggleBtnExpand()}className={classNames(classes.seeMore, classes.show)}>{show ? '-' : '+'}</div>}
          </Breadcrumbs> 
        </div>
      </GridItem>
    );
  }
}

export default withStyles(breadcrumbsStyle)(Migas);
