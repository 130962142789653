  
  const dividerStyle = {
    divider: {
      width: "100%",
      margin: "1.5rem 0",
      border: "1px dashed #e4e4e4"
    },
  };
  
  export default dividerStyle;
  