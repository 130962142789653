/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ListLogo from "components/List/ListLogo.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPhoneAlt, faFax, faEnvelope, faMapMarkerAlt, faAngleRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import footerStyle from "assets/jss/material-kit-pro-react/components/footerStyle.jsx";

function Footer(props){

  const footerContactSectionStructure = (contactSectionArray) => {
    if (!contactSectionArray || contactSectionArray.length<=0) return;
    const { classes } = props
    const contactSectionStructure = contactSectionArray.map((item, index) => {
      let columnUrl = ''
      
      const textWithBreaks = item.columnContent.replace(/(\r\n|\n|\r)/gm, '</br>')

      if (index=== 0){
        return (
          <GridItem xs={12} sm={6} md={3} key={'footerContainer-'+Math.floor(Math.random()*901)} >
            <div className={classes.contactItem}>
              <div className={classes.contentCircle}>
                <img src={item.columnIcon} alt="" title="" />
              </div>
              <div className={classes.contactInfo}>
                <h4>{item.columnTitle}</h4>
                {item.columnType === 'address' && <a href={item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'phone' && <a href={"tel:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'email' && <a href={"mailto:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                  <p className={classes.text}>
                    <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                  </p>
                </a>
                }
              </div>
            </div>
          </GridItem>)
      } else if (index===1){
        return (
          <GridItem xs={12} sm={6} md={4} key={'footerContainer-'+Math.floor(Math.random()*901)} >
            <div className={classes.contactItem}>
              <div className={classes.contentCircle}>
                <img src={item.columnIcon} alt="" title="" />
              </div>
              <div className={classes.contactInfo}>
                <h4>{item.columnTitle}</h4>
                {item.columnType === 'address' && <a href={item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'phone' && <a href={"tel:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'email' && <a href={"mailto:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                  <p className={classes.text}>
                    <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                  </p>
                </a>
                }
              </div>
            </div>
          </GridItem>
        )
      } else if (index===2){
        return(<GridItem xs={12} sm={6} md={5} key={'footerContainer-'+Math.floor(Math.random()*901)} >
          <div className={classes.contactItem}>
            <div className={classes.contentCircle}>
              <img src={item.columnIcon} alt="" title="" />
            </div>
            <div className={classes.contactInfo}>
              <h4>{item.columnTitle}</h4>
              {item.columnType === 'address' && <a href={item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'phone' && <a href={"tel:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                    <p className={classes.text}>
                      <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                    </p>
                  </a>
                }
                {item.columnType === 'email' && <a href={"mailto:"+item.columnUrl} target="_blank" rel="noopener noreferrer">
                  <p className={classes.text}>
                    <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
                  </p>
                </a>
                }
            </div>
          </div>
        </GridItem>)
      }
      
    })
    return contactSectionStructure;
  }

  const footerContentStructure = (dataArray) => {
    if (!dataArray || dataArray.length<=0) return;
    const { classes, isOffice } = props
    const contentStructure = dataArray.map(item => {
      if (!item.content || item.content.length<=0) return;
      return (
        <GridItem xs={12} sm={12} md={3} lg={3} key={'footerGridItem-'+Math.floor(Math.random()*901)} >
          {item.title && <h4>{item.title}</h4>}
          <ul className={classes.linksVertical}>
            {item.content.map(contentLink => {
              if (isOffice === true && contentLink.textLink === "MAPA DEL SITIO"){
                return null;
              } else if (isOffice === true) {
                return (
                  <li key={'footerLi-'+Math.floor(Math.random()*901)} >
                    <a href={contentLink.urlLink} target="_blank" rel="noopener noreferrer" className={classes.link}>
                      <FontAwesomeIcon icon={faChevronRight} /> 
                      {contentLink.textLink}
                    </a>
                  </li>
                )
              } else {
                return (
                  <li key={'footerLi-'+Math.floor(Math.random()*901)} >
                    <a href={contentLink.urlLink} className={classes.link}>
                      <FontAwesomeIcon icon={faChevronRight} /> 
                      {contentLink.textLink}
                    </a>
                  </li>
                )
              }
            })}
          </ul>
        </GridItem>
      )
    })
    return contentStructure;
  }

  const footerContactColumnStructure = (contactColumnContentArray) => {
    if (!contactColumnContentArray || contactColumnContentArray.length<=0) return;
    const { classes } = props
    const contactColumnStructure = contactColumnContentArray.map(item => {
      const textWithBreaks = item.contactLineContent.replace(/(\r\n|\n|\r)/gm, '</br>')
      return (
        <li className={classes.contentIcon} key={'footerLi2-'+Math.floor(Math.random()*901)} >
          {item.contactLineIcon && <img src={item.contactLineIcon} alt="" title="" />}
          <p className={classes.text}>
            <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
          </p>
        </li>
      )
    })

    return contactColumnStructure;
  }

  const { children, classes, theme, big, className, data } = props;
  const { footerLogos, contactSection, footerLogo, contactColumn, copyRights } = data;
  const themeType =
    (theme === "transparent" || theme == undefined) ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });

  return (
    <footer className={footerClasses}>
      {footerLogos && <div style={{backgroundColor:"#f5f5f5"}}>
        <div className={classes.blend}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <ListLogo FOOTERLOGOS={footerLogos} />
          </GridContainer>
        </div>
        </div>
      </div>} 
      <div className={classes.contactContent}>
        <div className={classes.container}>
          <GridContainer spacing={4}>
            {footerContactSectionStructure(contactSection)}
          </GridContainer>
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <div className={classes.content}>
            <div className={classes.footer}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={3} lg={3} className={classes.centerMovil}>
                  <img src={footerLogo} alt="" style={{marginTop: "20px", height: "50px", maxWidth: "100%"}} />
                </GridItem>
                {footerContentStructure(data.data)}
                {contactColumn && <GridItem xs={12} sm={12} md={3} lg={3} >
                  {contactColumn.title && <h4>{contactColumn.title}</h4>}
                  <ul className={classes.linksVertical}>
                    {footerContactColumnStructure(contactColumn.content)}
                  </ul>
                </GridItem>}
              </GridContainer>
            </div>
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>
      {copyRights && <div className={classes.copyContent}>
        <small>
          Copyright &copy; {1900 + new Date().getYear()} | {copyRights}
        </small>
      </div>}
    </footer>
  )
}

export default withStyles(footerStyle)(Footer);
