import React, { Component } from "react";
import * as axios from 'axios';
import htmlText from 'utils/htmlFormattedText';
// @material-ui/core components
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";

import style from "./style.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import Loading from "components/Loader/Loading.jsx";


class ButtonBeltOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostingVariables: this.props.hostingVariables,
      contactOpen: false,
      selectedContract: "",
      currentYear: "",
      isLoading: false,
    };
  }

  convertFilePDF = (fileStr, month, currentYear) => {
    if (fileStr) {
      var b64 = fileStr;

      // Insert a link that allows the user to download the PDF file
      var link = document.createElement('a');
      link.innerHTML = '';
      link.download = 'Estado_' + month + '_' + currentYear + '.pdf';
      link.href = 'data:application/pdf;base64,' + b64;
      link.click();
    }
  }

  getFilePDF = async () => {
    const { currentYear } = this.state;
    const axiosHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.props.token,
        'X-App-Contract': this.props.contrato,
      }
    }
    const endpoint = this.props.endpointPDF;
    const selContract = this.state.selectedContract;
    const date = moment();
    const formattedMonth = date.format('MM');
    date.subtract(5, 'days').endOf('month');
    this.setState({
      isLoading: true
    });
    const strDate = date.format('YYYYMMDD');
    await axios.post(
      endpoint,
      {
        "contrato": Number(selContract),
        "fecha": strDate,
        "pdf": "1"
      },
      axiosHeaders
    ).then(resp => {
      if (resp.status === 200) {
        if (resp.data.code === "401") {
          this.props.closeSession();
        } else {
          this.convertFilePDF(resp.data.data, formattedMonth, currentYear);
        }
      }
    }).catch(error => {
      console.log('getResultsInfoData Error: ', error)
      this.props.closeSession();
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    });
  }

  handleAuthorization = async () => {
    this.getFilePDF();
  }

  componentDidMount() {
    const contract = this.props.contrato;
    const currentYear = new Date().getFullYear();
    this.setState({
      selectedContract: contract,
      currentYear: currentYear,
    })
  }

  render() {
    const {
      buttonColor,
      classes,
    } = this.props;
    const { topDescription, title, btnTitle } = this.props.data
    return (
      <React.Fragment>
        {topDescription && <GridItem xs={12}>
          <p className={classes.description}>
            {htmlText(topDescription)}
          </p>
        </GridItem>}
        <div className={classNames(classes.cintillo)}>
          <div className={classes.container}>
            {title && <h3 className={classes.title}>{title}</h3>}
            {btnTitle && <Button
              disableRipple
              onClick={() => this.handleAuthorization()}
              border={buttonColor ? false : true}
              color={buttonColor ? buttonColor : "borderWhite"}
            >
              {htmlText(btnTitle)}
            </Button>}
          </div>
        </div>
        {this.state.isLoading && <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%'
          }}>
          <Loading isOffice={true} />
        </div>}
      </React.Fragment>
    );
  }
};

export default withStyles(style)(ButtonBeltOffice);
