import React from "react";
import * as axios from 'axios';
import { createBrowserHistory } from "history";
import CaptchaComp from 'utils/CaptchaComp';
import formatPhone2 from 'utils/formatPhone2';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import { createMuiTheme } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.jsx";
import AlertPop from './AlertPop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faPhoneSquareAlt, faComments} from '@fortawesome/free-solid-svg-icons'
// sections for this page
import formStyle from "./formStyle.jsx"
// import GridContainer from "components/Grid/GridContainer";

const nameRef = React.createRef();
const phoneRef = React.createRef();
const emailRef = React.createRef();
const interestRef = React.createRef();
const messageRef = React.createRef();
const privacyRef = React.createRef();
const gCaptchaResponseRef = React.createRef();
class FormContact extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      // publicKey: "6LcS_v8UAAAAAI4U0BPb9-zek-6ANlwY_O71EKV2",
      publicKey: "",
      formSendSuccess: false,
      buttonDisabled: true,
      openAlerts: {
        status:false, 
        anchorEl: null, 
        content: "",
      },
      values: {
        name: "",
        email: "",
        phone: "",
        interest: "",
        message: "",
        privacy: false,
        groupId: '33818',
        sentFromPage: createBrowserHistory().location.pathname,
      }
    }
    this.captcha = React.createRef();
  }

  scrollToId = (idName) => {
    if (idName){
      if (idName.current) {
        // this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
        idName.current.scrollIntoView({ behavior: 'smooth'})
      }
    }
  }

  openPopAlert = (name, content) => {
    const toggleStatus = !this.state.openAlerts.status
    const anchorEl = document.getElementById(name);
    // const styledContent = <div style={{backgroundColor: "orange"}}><h4>{content}</h4></div>
    this.setState({openAlerts: {status: toggleStatus, anchorEl: anchorEl, content: content}})
  };

  closeAlerts = () => {
    this.setState({openAlerts: {status: false, anchorEl: null, content: ""}})
  }

  getCaptchaKey = async () => {
    let publicKey = this.state.publicKey;
    const captchaUrl=this.props.contactFormData.captchaUrl;
    if (!publicKey || publicKey === ''){
      await axios.get(captchaUrl)
      .then(resp => {
        publicKey = resp.data.data.value;
      })
      .catch(error =>{
        console.log('Error getCaptchaKey: ',error)
      });
      this.setState({publicKey: publicKey})
      return publicKey
    }
  }

  verifyCallback = (token) => {
    this.setState({values: {...this.state.values,'gCaptchaResponse':token}, buttonDisabled: false});
    return;
  }

  handleFormInputChange = (event, name) => {
    const invalidChars = /[$%&/()=?¿!¡*{}[\]:;_\-`¨+çÇªº\\·#^¬'|]/g;
    const invalidCharsMessage = /[$%&/()=*{}[\]:;`¨+çÇªº\\·#^¬'|]/gm;
    const invalidCharsEmail = /[$%&/()=?¿!¡*{}:;¨`+çÇªº\\·#^¬'´|]/g;
    const emailReg = /[A-Za-z0-9Ññ._+-]+@[A-Za-z0-9Ññ.-]+\.[A-Za-zÑñ]{2,4}/g;
    const numbers = /^$|^[0-9]+$/g;
    const invalidNumbers = /[0-9]/g; 
    switch(name){
      case 'name':
        if(invalidChars.test(event.target.value) || invalidNumbers.test(event.target.value) || event.target.value.length >150){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
      case 'phone':
        let tempPhone = event.target.value
        if (tempPhone.length === 4) {
          tempPhone = tempPhone.substr(1,2)
        }
        tempPhone = tempPhone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g, '');
      if(tempPhone.match(numbers) && tempPhone.length < 19){
        const phoneVal= formatPhone2(tempPhone);
        this.setState({values: {...this.state.values,[name]:phoneVal}})
        //this.setState({values: {...this.state.values,[name]:tempPhone}})
        return;
      } else {
        return;
      }
      case 'email':
        if(invalidCharsEmail.test(event.target.value) || event.target.value.length > 100)
          return;
        if(!event.target.value.match(emailReg)){
          this.setState({required: {...this.state.required,[name]:true}})
        }
        else {
          this.setState({required: {...this.state.required,[name]:false}})
        }
        this.setState({values: {...this.state.values,[name]:event.target.value}})
        return;
      case 'message':
        if(event.target.value.length>500 || invalidCharsMessage.test(event.target.value)){
          return;
        }
        else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
      case 'privacy':
        this.setState({values: {...this.state.values,[name]:!this.state.values[name]}})
        return;
      default:
        if(event.target.value.length>100 || invalidChars.test(event.target.value)){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
    }
  };

  sendForm = async () => {
    const contactFormEndPoint = this.props.contactFormData.endPoint
    const drawerHandle = this.props.drawerHandle
    const values = this.state.values
    const emailReg = /[A-Za-z0-9Ññ._+-]+@[A-Za-z0-9Ññ.-]+\.[A-Za-zÑñ]{2,4}/g;
    if (!values.name) {
      /* this.scrollToId(nameRef) */
      nameRef.current.focus()
      this.openPopAlert("name", "el campo nombre es requerido")
      return;
    } else if (!values.email) {
      emailRef.current.focus()
      this.openPopAlert("email", "el campo email es requerido")
      return;
    } else if (!values.phone){
      phoneRef.current.focus()
      this.openPopAlert("phone", "el campo teléfono es requerido")
    } else if (!values.email.match(emailReg)) { 
      emailRef.current.focus()
      this.openPopAlert("email", 'Verifica la dirección de correo electrónico. la dirección "'+values.email+'" no es una direccion de correo electrónico válida.')
      return;
    } else if (!values.interest) {
      this.scrollToId(interestRef)
      setTimeout(()=>{this.openPopAlert("interest", "el campo interés es requerido")},500)
      return;
    } else if (!values.message) {
      messageRef.current.focus()
      this.openPopAlert("message", "el campo mensaje es requerido")
      return;
    } else if (!values.privacy) {
      privacyRef.current.focus()
      this.openPopAlert("privacy", "Debe aceptar las politicas de privacidad para continuar")
      return;
    } else if (!values.gCaptchaResponse) {
      gCaptchaResponseRef.current.focus()
      this.openPopAlert("gCaptchaResponse", "Debe aceptar el captcha para continuar")
      return;
    } else {
        await axios.post(contactFormEndPoint,values).then(resp => {
          if (resp.status === 200){
            this.setState({
              formSendSuccess: true, 
              values:{
                name: "",
                email: "",
                phone: "",
                interest: "",
                message: "",
                privacy: false,
              }
            })
            if (window.grecaptcha){
              window.grecaptcha.reset();
            }
            drawerHandle(true);
        } else {
          
        }
      }).catch(error =>{
        console.log(error)
      });
    }
  }

  dropdownListStructure=()=>{
    const { classes } = this.props;
    const contactFormLabels = this.props.contactFormData.data
    if (contactFormLabels.interests) {
      return contactFormLabels.interests.sort((item1, item2)=>item1.id - item2.id).map((item, key)=>
        <Button 
          disableRipple
          className={classes.userPopover} 
          color="transparent" 
          key={key}
          onClick={()=>this.setState({values: {...this.state.values, interest: item.text}})}
        >
          {item.text}
        </Button>
      )
    } else {return []}
    
  }

  componentDidMount(){
    // this.triggerNotificationMessageSent();
    if (!this.state.publicKey) {
      const theCaptchaKey = this.getCaptchaKey();
      if (theCaptchaKey) {this.setState({publicKey: theCaptchaKey })}
    }
    if (this.props.contactFormData){
      this.setState({values: {
        ...this.state.values,
        'portletKey':this.props.contactFormData.portletKey
      }})
    }

  }

  render() {
    const {publicKey} = this.state;
    const { classes } = this.props;
    const contactFormLabels = this.props.contactFormData.data
    const { formSendSuccess } = this.state
    const DROPLIST = this.dropdownListStructure();
    return (
      <React.Fragment>
        <AlertPop 
          open={this.state.openAlerts.status} 
          anchorEl={this.state.openAlerts.anchorEl} 
          handleClose={()=>this.closeAlerts()}
        >
          {this.state.openAlerts.content}
        </AlertPop>
        <GridItem xs={12} md={8}>
          <label className={classes.inputBootstrapIcon}>
            <input 
              type="text"
              id="name"
              ref={nameRef}
              name="name"
              required
              value={this.state.values.name}
              onChange={(e)=>this.handleFormInputChange(e,"name")}
              placeholder={contactFormLabels.name} 
            />
            <span>
              <FontAwesomeIcon icon={faUser} />
            </span>
          </label>
        </GridItem>
        <GridItem xs={12} md={8}>
          <label className={classes.inputBootstrapIcon}>
            <input 
              type="email"
              id="email"
              ref={emailRef}
              name="email"
              value={this.state.values.email}
              onChange={(e)=>this.handleFormInputChange(e,"email")}
              placeholder={contactFormLabels.email}
            />
            <span>
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </label>
          
        </GridItem>
        <GridItem xs={12} md={8}>
          <label className={classes.inputBootstrapIcon}>
            <input 
              type="text"
              id="phone"
              ref={phoneRef}
              name="phone"
              value={this.state.values.phone}
              onChange={(e)=>this.handleFormInputChange(e,"phone")}
              placeholder={contactFormLabels.phone} 
            />
            <span>
              <FontAwesomeIcon icon={faPhoneSquareAlt} />
            </span>
          </label>
        </GridItem>
        <GridItem xs={12} sm={8} >
          <List id="interest">
            <ListItem ref={interestRef} style={{justifyContent: "center"}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
              <CustomDropdown
                 
                noLiPadding
                navDropdown
                hoverColor={"transparent"}
                buttonText={this.state.values.interest ? this.state.values.interest : contactFormLabels.interest}
                buttonProps={{
                  className: classes.dropdown,
                  color: "primaryPrimary"
                }}
                dropdownList={ DROPLIST }
              />
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12} md={8}>
          <label className={classes.inputBootstrapIcon}>
            <textarea 
              style={{
                paddingTop: "10px", 
                paddingBottom: "10px", 
                paddingRight: "10px", 
                height: "96px"
              }} 
              id="message"
              ref={messageRef}
              name="message"
              value={this.state.values.message}
              onChange={(e)=>this.handleFormInputChange(e,"message")}
              placeholder={contactFormLabels.message}
            />
            <span>
              <FontAwesomeIcon icon={faComments} />
            </span>
          </label>
        </GridItem>
        <GridItem xs={12}>
          <label className={classes.privacyWhite}>
            <input  id="privacy" ref={privacyRef} name="privacy" type="checkbox" value={this.state.values.privacy} onChange={(e)=>this.handleFormInputChange(e,"privacy")} />
            {contactFormLabels.privacyTxt}
            <a href={contactFormLabels.privacyLinkUrl} target="_blank" rel="noopener noreferrer" >{contactFormLabels.privacyLinkTxt}</a>
          </label>
        </GridItem>
        {publicKey ? <GridItem xs={12}>
          <div style={{display:"inline-block"}} id="gCaptchaResponse" ref={gCaptchaResponseRef}>
          <CaptchaComp 
            siteKey = {this.state.publicKey}
            verifyCallback={this.verifyCallback}
          /> 
          </div>
          </GridItem>: <></>}
          
        <GridItem xs={12}>
            <Button 
              disableRipple  
              /* disabled={!this.state.values.privacy || buttonDisabled} */
              size={null} 
              color="primaryPrimary" 
              onClick={()=>{this.sendForm()}} 
            >
              {contactFormLabels.sendBtnTxt}
            </Button>
        </GridItem>
        {formSendSuccess && <GridItem xs={12} md={12}>
          <h5 className={classes.titeMessage}>{contactFormLabels.thanksMsgTitle}</h5>
          <h6 className={classes.textMessage}>{contactFormLabels.thanksMsg}</h6>
        </GridItem>}
      </React.Fragment>
    );
  }
}

export default withStyles(formStyle)(FormContact);
