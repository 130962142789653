import React, { Component } from "react";
import htmlText from 'utils/htmlFormattedText';
import HeaderContactForm from 'components/Header/HeaderContactForm';
// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import style from "./style.jsx"; 
import GridItem from "components/Grid/GridItem.jsx";


class ButtonBelt extends Component {
  constructor(props){
    super(props);
    this.state = {
      hostingVariables: this.props.hostingVariables,
      contactOpen: false
    };
  }

  handleDrawerContactToggle = () => {
    this.setState({ contactOpen: !this.state.contactOpen });
  }

  render(){
  const {
    buttonColor,
    classes,
  } = this.props;
  const { 
    topDescription, 
    title, 
    btnTitle, 
    // btnUrl 
  } = this.props.data

  return (
    <React.Fragment>
      { topDescription && <GridItem xs={12}> 
        <p className={classes.description}>
          {htmlText(topDescription)}
        </p> 
      </GridItem>}
      <div className={classNames(classes.cintillo)}>
        <div className={classes.container}>
          {title &&  <h3 className={classes.title}>{title}</h3>}
          {btnTitle && <Button
                disableRipple
                /* href={btnUrl} */
                onClick={this.handleDrawerContactToggle}
                border={buttonColor ? false : true}
                color={buttonColor ? buttonColor : "borderWhite"}
            >
                {htmlText(btnTitle)}
            </Button>}
        </div>
      </div>
      <Drawer
        variant="temporary"
        anchor={"top"}
        open={this.state.contactOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={this.handleDrawerContactToggle}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={this.handleDrawerContactToggle}
          className={classes.closeButtonDrawer}
        >
          <Close />
        </IconButton>
        <HeaderContactForm hostingVariables={this.props.hostingVariables} />
      </Drawer>
    </React.Fragment>
  );
}
};

export default withStyles(style)(ButtonBelt);
