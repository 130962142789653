  
const inputBootstrap = {
    transition: "all .5s ease-out",
    borderRadius: 0,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #e4e4e4',
    width: '100%',
    fontSize: '.96rem',
    color: '#626262',
    outline: "none",
    height: "42px",
    fontWeight: 400,
    padding: "7px 5px 7px 8px",
    '&:focus':{
        color: '#3c3c3c',
        border: "1px solid #136ec3",
        boxShadow: "0 0 3px #136ec3 inset",
        '&~span svg':{
            color: "#136ec3"
        }
    },
    '&:hover':{
        borderColor: "#136ec3"
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
};
const inputBootstrapIcon = {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    '& input, textarea':{
        fontFamily: '"Open Sans", sans-serif',
        transition: "all 0.3s ease-out 0s",
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #e4e4e4',
        width: '100%',
        fontSize: '.96rem',
        color: '#626262',
        outline: "none",
        height: "42px",
        fontWeight: 400,
        padding: "7px 0 7px 36px",
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
        '&:focus':{
            color: '#3c3c3c',
            border: "1px solid #136ec3",
            boxShadow: "0 0 3px #136ec3 inset",
            '&~span svg':{
                color: "#136ec3"
            },
            "&::placeholder": {
              color: "#909090",
            }
        },
        '&:hover':{
            borderColor: "#136ec3"
        },
        "&::placeholder": {
          color: "#3c3c3c",
        }
    },
    '& input':{
        "&::placeholder": {
          color: '#949494 !important'
        },
    },
    '& textarea':{
        "&::placeholder": {
          color: '#949494 !important'
        },
        transition: "all 0.3s ease-out 0s",
        '&:focus':{
            height: "120px !important",
            transition: "all 0.3s ease-out 0s",
        }
    },
    '& span': {
        position: "absolute",
        left: 0,
        width: "42px",
        height: "42px",
        lineHeight: "42px",
        color: "inherit",
        textAlign: "center",
        '& svg': {
            fontSize: "12px"
        }
    }
};

const inputGroup = {
    position: "relative",
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "stretch",
    width: "100%"
}

const contentIcon = {
    textAlign: "center",
    width: "50px",
    height: "42px",
    lineHeight: "28px",
    padding: "6px 12px",
    fontSize: "14px",
    color: "#555",
    backgroundColor: "#eee",
    border: "1px solid #ccc"
}

const checkInput = {
    fontSize: "40px",
    margin: "4px 0 10px 0 !important",
    float: "right"
}
const labelCheck = {
    fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
    display: "inline-block",
    fontSize: ".96rem",
    lineHeight: "1.2em",
    margin: "0 !important",
    color: "#626262",
    fontWeight: 400,
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
}
  
export {
    inputBootstrap,
    inputBootstrapIcon,
    inputGroup,
    contentIcon,
    checkInput,
    labelCheck,
}
  