import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import ContentQuoteSimple from "components/ContentText/ContentQuoteSimple.jsx";
import ContentQuoteMultimedia from "components/ContentText/ContentQuoteMultimedia.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// sections for this page
import style from "./style.jsx"


function AboutUsBlock(props){
  if (!props.data || !props.data.content) return <></>;
  const contentStructure=(props)=>{
    let content = []
    props.data.content.map(item =>{
      if (item.type === 'ContentQuoteSimple'){
        const {title, centerDescription, description } = item
        content.push(<ContentQuoteSimple
          data={{
            title: title,
            centerDescription: centerDescription,
            description: description
          }}
        />)
      }
      if (item.type === 'ContentQuoteMultimedia'){
        content.push(<ContentQuoteMultimedia
          data={item}
        />)
      }
      return null;
    })
    return content;
  }
  
  return (
    <React.Fragment>
      <GridItem xs={12} md={4}>
          <img src={props.data.imageUrl} alt="" title="" style={{margin: '0 auto', maxWidth: '100%'}} />
        </GridItem>
        <GridItem xs={12} md={8}>
          <GridContainer spacing={4}>
            {contentStructure(props)}
          </GridContainer>
        </GridItem>
    </React.Fragment>
  )
}

export default withStyles(style)(AboutUsBlock);