import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
// import Button from '@material-ui/core/Button';

// import IconButton from "@material-ui/core/IconButton";
// import Close from "@material-ui/icons/Close";

const PopoverAlert = withStyles({
  root: {
    boxShadow: 'none',
  },
  paper:{
    backgroundColor:"white",
    overflow: "visible",
    marginTop: 14,
    display: "flex",
    padding: 6,
    maxWidth: "80%",
    '&:after':{
      content:'""',
      position: 'absolute',
      top: -10,
      left: 30,
      border: "10px solid transparent",
      borderBottomColor: "white",
      borderTop: 0,
      
    },
    '& label':{
      minWidth: 20,
      height: 20,
      backgroundColor: "#fbb753",
      color: "white",
      lineHeight: "20px",
      fontSize: "14px",
      marginRight: "6px",
      textAlign: "center"
    }
  },
})(Popover);

const useStyles = makeStyles((theme) => ({
  typography: {
    // padding: theme.spacing(2),
    padding:0,
    margin: "1px 0 0 0",
  },
}));

export default function AlertPop(props) {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);

  /* const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; */

  // const open = Boolean(anchorEl);
  const open = props.open
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Open Popover
      </Button> */}
      <PopoverAlert
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={()=>props.handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <label>
          <FontAwesomeIcon icon={faExclamation} />
        </label>
        <Typography className={classes.typography}>
          {props.children}
        </Typography>
      </PopoverAlert>
    </div>
  );
}
