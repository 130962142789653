import {
    primaryColor,
    secondaryColor,
    cardTitle,
    defaultFont
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const cardIconStyle = {
    cardStrategy:{
        display: "block !important",
        marginBottom: "0 !important",
        marginTop: "0 !important",
        padding: "0 0 3.5rem 0 !important",
        border: "none",
        transition: "all 0.3s ease-out 0s",
        color: '#272727',
        '& svg': {
            width: '40%',
            fill: secondaryColor[0],
            margin: "0 auto 20px !important"
        },
        '&:hover':{
            '& $boxIcon':{
                backgroundColor: primaryColor[0]
            }
        }
        // '&:hover':{
        //     backgroundColor: primaryColor[0],
        //     color: '#fff',
        //     '& svg':{
        //         fill: 'white'
        //     },
        //     '& a, button':{
        //         backgroundColor: secondaryColor[0] + " !important",
        //         borderColor: secondaryColor[0] + " !important",
        //         color: "white !important",
        //     }
        // }
    },
    
    cardTitle: {
        ...cardTitle,
        textTransform: "uppercase",
        margin: 0 + " !important",
        fontSize: "1.5rem !important",
        lineHeight: "1.3em !important",
        fontWeight: "400 !important"
    },
    description: {
        ...defaultFont,
        marginTop: 5,
        // overflow: "hidden",
        // display: "-webkit-box",
        // WebkitLineClamp: 4,
        // WebkitBoxOrient: "vertical",
        lineHeight: "1.65em !important",
    },
    boxIcon:{
        width: 135,
        height: 135,
        fontSize: 46,
        lineHeight: 135,
        float: "left",
        marginRight: 20,
        '& img': {
            display: "block"
        }
    },
    boxIconReverse:{
        float: "right",
        marginLeft: 20,
    },
    boxText:{
        display: "block",
        margin: "0",
        padding: "0 0 0 90px",
        textAlign: "left",
        "@media (max-width: 479px)": {
          width:"100%",
          textAlign: "left",
          padding: 0,
          margin: "20px 0",
          float: "left"
        }
    },
    boxTextReverse:{
        padding: "0 90px 0 0",
        textAlign: "right",
        "@media (max-width: 479px)": {
          width:"100%",
          textAlign: "left",
          padding: 0,
          margin: "20px 0",
          float: "left"
        }
    },
  };
  
  export default cardIconStyle;
  