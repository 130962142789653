  
  const style = {

    w100:{
        width: "100%"
    },
    contentBtn:{
        display: "flex",
        justifyContent: "flex-end"
    },
    btnRedes:{
        width: "30px",
        height: "30px",
        paddingLeft: "3px",
        paddingRight: "3px",
        margin: 0,
        borderRadius: "0 !important",
        boxShadow: "none !important",
        '& i':{
            fontSize: "16px",
            lineHeight: "30px",
            marginRight: "0 !important",
        }
    },
  };
  
  export default style;
  