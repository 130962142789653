import {
    secondaryColor,
    cardTitle,
    defaultFont
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const cardBibliographyStyle = {
    cardBibliography:{
        marginBottom: "0 !important",
        marginTop: "0 !important",
        padding: "0 !important",
        transition: "all 0.3s ease-out 0s",
        color: '#272727',
        backgroundColor: '#f5f5f5',
        // '& svg': {
        //     height: '10rem',
        //     fill: secondaryColor[0],
        //     marginBottom: "20px !important"
        // },
        '&:hover':{
            backgroundColor: secondaryColor[0],
            color: '#fff',
            '& img':{
                filter: 'grayscale(0)',
            },
            '& $btnSpecial':{
                bottom: 0,
                transition: "all 0.3s ease-out 0s",
                color: 'white',
                '& svg': {
                    marginRight: "5px"
                }
            },
            '& $description':{
                color: 'white !important'
            }
        }
    },

    image: {
        width: '100%',
        height: '320px',
        overflow: 'hidden',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            // filter: 'grayscale(1)',
        }
    },
    btnSpecial: {
        position: 'absolute',
        transition: "all 0.3s ease-out 0s",
        width: '100%',
        color: 'white',
        zIndex: 1,
        bottom: -60,
        left: 0,
        fontSize: '1.06rem',
        padding: '1rem .5rem',
        textAlign: 'center',
        fontWeight: '400 !important',
        backgroundColor: 'rgba(0,101,195,0.9)'
    },
    
    contentBody: {
        padding: '2.5rem',
        minHeight: '16.5rem'
    },
    cardTitle: {
        ...cardTitle,
        margin: 0 + " !important",
        marginTop: "10px !important",
        fontWeight: "400 !important",
        fontSize: "1.2rem !important",
        lineHeight: "1.3em !important",
    },
    
    contentDescription: {
        margingBottom: "1rem !important",
        height: '4.5rem'
    },
    
    description: {
        ...defaultFont,
        marginBottom: '0 !important',
        lineheight: "1.6em !important"
    },
  };
  
  export default cardBibliographyStyle;
  