import React from "react";
import HeaderOfficeLinks from "components/Header/HeaderOfficeLinks";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  // faBars, 
  // faEnvelope, 
  faAngleUp } from '@fortawesome/free-solid-svg-icons'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "components/CustomButtons/Button.jsx";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// import HeaderLoginComponent from './HeaderLoginComponent';
import HeaderSearchComponent from './HeaderSearchComponent';
// import HeaderContactForm from './HeaderContactForm';
// @material-ui/icons
// import Menu from "@material-ui/icons/Menu";
// import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

class HeaderOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostingVariables: this.props.hostingVariables,
      triggerButtons: false,
      mobileOpen: false,
      searchOpen: false,
      contactOpen: false
    };

    this.myRef = props.scrollRef;
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleDrawerContactToggle = this.handleDrawerContactToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
  }

  scrollToMyRef = () => {
    if (document.getElementById('theHeaderTopFast')){
      var dropdownElement = document.getElementById('theHeaderTopFast');
      dropdownElement.scrollIntoView({ behavior: "smooth" });
    }
    else if (this.myRef){
      if (this.myRef.current) {
        // this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
        this.myRef.current.scrollIntoView({ behavior: 'smooth'}); 
      }
    }
    
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  handleDrawerContactToggle() {
    this.setState({ contactOpen: !this.state.contactOpen });
  }
  
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    const triggerButtons = this.state.triggerButtons;
    
    if (windowsScrollTop > changeColorOnScroll.height+48) { 
      if (!triggerButtons){
        this.setState({triggerButtons: true})
      }
    } else if (triggerButtons) {
      this.setState({triggerButtons: false})
    }
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color+"Scroll"]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color+"Scroll"]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const { 
      classes, 
      color, 
      links, 
      fixed, 
      absolute, 
      isOffice, 
      gotoDashboard,
      gotoChangeNIP,
      gotoChangePass,
      userName, 
      closeSession,
      inactivityView 
    } = this.props;

    const { 
      urlLogo,
      loginBtnTitle,
      loginBtnUrl,
      // contactFormData,
    } = this.state.hostingVariables
    const appBarClasses = classNames({
      [classes.appBarOffice]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    const hideButtonStyle = this.state.triggerButtons ? {display: 'inline-block'} : {display: 'none'};
    const tempClass = isOffice ? classes.drawerPaperGray : classes.drawerPaper;
    if (inactivityView) {
      return  (<React.Fragment>
          {fixed && <div className={classes.contentNoFixedOffice} id="theHeaderTopFast"></div>}
          <AppBar className={appBarClasses}>
                <Toolbar className={classes.containerOffice}>
                  <Button disableRipple href="/portal-clientes" color="logoBtn">
                    <img src={urlLogo} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logo} />
                  </Button>
                  <div className={classes.spaceItems}>
                    { isOffice ? <React.Fragment>
                    </React.Fragment> : <React.Fragment>
                    {/* <HeaderLoginComponent loginBtnTitle={loginBtnTitle} loginBtnUrl={loginBtnUrl}  classes={classes} /> */}
                    <Button disableRipple href={loginBtnUrl} color="secondary" >
                      {loginBtnTitle}
                    </Button> 
                    <HeaderSearchComponent hostingVariables={this.state.hostingVariables} classes={classes} />
                    <Button disableRipple  size="" color="header" aria-label="open drawer" onClick={this.handleDrawerToggle}>
                      <i class="fa fa-bars" aria-hidden="true"></i>
                    </Button>
                    </React.Fragment>}
                  </div>
                </Toolbar>
          </AppBar>
        </React.Fragment>);
    } else {
      return (
        <React.Fragment>
          {/* <PreHeader/> */}
          {fixed && <div className={classes.contentNoFixedOffice} id="theHeaderTopFast"></div>}
          <AppBar className={appBarClasses}>
                <Toolbar className={classes.containerOffice}>
                  <Button disableRipple href="/portal-clientes" color="logoBtn">
                    <img src={urlLogo} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logo} />
                  </Button>
                  <div className={classes.spaceItems}>
                    { isOffice ? <React.Fragment>
                      <Hidden smDown>
                        <List id="interest" style={{display:"inline-block"}}>
                          <ListItem
                            // ref={interestRef}
                            style={{justifyContent: "center", paddingLeft: 0, paddingRight: 0}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
                            <CustomDropdown
                              noLiPadding
                              navDropdown
                              hoverColor={"primary"}
                              buttonText={userName}
                              buttonProps={{
                                disableRipple: true,
                                className: classes.dropdown,
                                color: "headerLogin"
                              }}
                              dropdownList={[
                                <Button 
                                  disableRipple
                                  className={classes.userPopover} 
                                  color="transparent" 
                                  key={1}
                                  onClick={gotoDashboard}
                                >
                                  Dashboard
                                </Button>,
                                <Button 
                                disableRipple
                                className={classes.userPopover} 
                                color="transparent" 
                                key={1}
                                onClick={gotoChangePass}
                              >
                                Cambiar Contraseña
                              </Button>,
                                <Button 
                                  disableRipple
                                  className={classes.userPopover} 
                                  color="transparent" 
                                  key={1}
                                  onClick={gotoChangeNIP}
                                >
                                  Cambiar NIP
                                </Button>
                                ]
                              }
                            />
                          </ListItem>
                        </List>
                        <Button onClick={closeSession} disableRipple color="headerLogin" >
                          CERRAR SESIÓN
                        </Button> 
                      </Hidden>
                    <Button disableRipple  size="" color="headerLogin" aria-label="open drawer" onClick={this.handleDrawerToggle}>
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </Button>
                    </React.Fragment> : <React.Fragment>
                    {/* <HeaderLoginComponent loginBtnTitle={loginBtnTitle} loginBtnUrl={loginBtnUrl}  classes={classes} /> */}
                    <Button disableRipple href={loginBtnUrl} color="secondary" >
                      {loginBtnTitle}
                    </Button> 
                    <HeaderSearchComponent hostingVariables={this.state.hostingVariables} classes={classes} />
                    <Button disableRipple  size="" color="header" aria-label="open drawer" onClick={this.handleDrawerToggle}>
                      <i class="fa fa-bars" aria-hidden="true"></i>
                    </Button>
                    </React.Fragment>}
                  </div>
                </Toolbar>
            
              <Drawer
                variant="temporary"
                anchor={"top"}
                open={this.state.mobileOpen}
                classes={{
                  paper: tempClass
                }}
                onClose={this.handleDrawerToggle}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.closeButtonDrawer}
                >
                  <Close />
                </IconButton>
                <div className={classes.appResponsive}>
                <HeaderOfficeLinks 
                  closeDrawer={()=>this.handleDrawerToggle()}
                  hostingVariables={this.props.hostingVariables} 
                  linksNavBar={links} 
                  dropdownHoverColor="info" 
                  key={'HL'+Math.floor(Math.random()*901)}
                /></div>
              </Drawer>
            
          </AppBar>
          
          <div style={hideButtonStyle} className={classNames(classes.up, 'animated fast fadeInUp')} onClick={()=>this.scrollToMyRef()}>
          <FontAwesomeIcon icon={faAngleUp} />
          </div>
        </React.Fragment>
      );
    }
  }
}

HeaderOffice.defaultProp = {
  color: "white"
};

HeaderOffice.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.any,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
      "black"
    ])
  })
};

export default withStyles(headerStyle)(HeaderOffice);
