import React from "react";
import formatphone2 from 'utils/formatPhone2';
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableClientStyle";
import Title from "components/Title";
// import SectionBanner from "components/Banner/SectionBanner";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class TableClient extends React.Component {
  render() {
    const { classes, data, customerInfoData } = this.props;
    const {
      title, 
      description, 
      // bannerShowWeb, 
      // bannerShowMobile
    } = data;
    let showBeneficiarios = false;
    let showApoderados = false;
    if (customerInfoData && customerInfoData.beneficiarios && customerInfoData.beneficiarios.listaBeneficiaros){
     showBeneficiarios = customerInfoData.beneficiarios.listaBeneficiaros.length > 0;
    }
    if (customerInfoData && customerInfoData.apoderados && customerInfoData.apoderados.listaApoderados){
     showApoderados = customerInfoData.apoderados.listaApoderados.length > 0;
    }
    return (
      <React.Fragment>
        { title && <Title title={title} alignment="" description={description} />}
        <GridItem xs={12} md={8}>
          <div className={classes.box}>
            <GridContainer spacing={2} style={{margin: "-8px"}}>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>NOMBRE</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.nombre}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              {showApoderados && <React.Fragment><GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>APODERADO(S)</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                {customerInfoData.apoderados.listaApoderados.map((item,index)=>{
                  return (
                    <h6 key={index} className={classes.cellText}>{item.Nombre}</h6>
                  )
                })}
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem></React.Fragment>}
              {showBeneficiarios && <React.Fragment><GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>BENEFICIARIO(S)</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                {customerInfoData.beneficiarios.listaBeneficiaros.map((item,index)=>{
                  return (
                    <h6 key={index} className={classes.cellText}>{item.Nombre}</h6>
                  )
                })}
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem></React.Fragment>}
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>E-MAIL</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.email}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>DIRECCIÓN</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.direccion}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>TELÉFONO DE CONTACTO</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>Oficina: {formatphone2(customerInfoData.telOfi)}</h6>
                <h6 className={classes.cellText}>Celular: {formatphone2(customerInfoData.telCel)}</h6>
                <h6 className={classes.cellText}>Respaldo: {formatphone2(customerInfoData.telDom)}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>CUENTA (S) BANCARIAS</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                {customerInfoData.cuenta.listaCuenta.map((item, key)=>{
                  return (
                    <React.Fragment key={'cta'+key} >
                      <h6 className={classes.cellText}>{item.Banco}</h6>
                      <h6 className={classes.cellText}>Clabe:</h6>
                      <h6 className={classes.cellText}>{item.Clabe}</h6>
                    </React.Fragment>
                  )
                })}
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>RFC</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.RFC}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>CURP</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.CURP}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>TIPO DE SERVICIO</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.servicio}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h5 className={classes.cellTitle}>PERFIL DE INVERSIÓN</h5>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h6 className={classes.cellText}>{customerInfoData.perfil}</h6>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.divider}></div>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableClient);