import React from "react";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// core components
import Title from "components/Title";
import GridItem from "components/Grid/GridItem.jsx";

import CardIcon from "components/Cards/CardIcon"

// images 

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class CarouselCardIcon extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      show: -1,
  }
  }

  CarouselCardIconStructure = (data,key) => {
    return( <CardIcon plain {...data} key={key} />)
    /*...data:
      type,
      id,
      image,
      title,
      jumpLine,
      txtBtn,
      urlBtn,
    */
  }
  componentDidMount(){
    if (this.props.data && this.props.data.data){
      this.setState({show: this.props.data.data.length})
    }
    
  }
  
  render() {
    var cards=[]

    if (this.props.data && this.props.data.data){
      this.props.data.data.sort((card1, card2) => card1.id - card2.id)
      .map((item,key)=>{
        cards.push(this.CarouselCardIconStructure(item,key));
        return null;
      })
    }
    const { title, description } = this.props.data;
    // const slidesToShowNum = isHome ? 4 : 3;
    const settings = {
      dots: true,
      infinite: this.state.show >3 ? true : false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      centerMode: false,
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              infinite: this.state.show >3 ? true : false,
            }
          },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            infinite: this.state.show >2 ? true : false,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1, 
            inifinite: this.state.show >1 ? true : false,
          }
        }
      ]
    };
    return (
      <React.Fragment>
        { title && <Title large title={title} alignment="center" description={description} />}
        <GridItem xs={12}>
          <div id="carousel">
            <Carousel {...settings} className="espaciadoX2 dotsDown"> 
              {cards}
              {/* <CardIcon
                title="PRODUCTOS"
                jumpLine="& SERVICIOS"
                txtBtn="Más Información"
                urlBtn="/productos-servicios"
                image={<svg x="0px" y="0px" viewBox="0 0 144 137.9">
                        <g>
                          <g>
                            <path className="st0" d="M57,137.9H24.5c-0.8,0-1.5-0.7-1.5-1.5V55.1c0-0.4,0.2-0.9,0.5-1.1l51-42.8c0.4-0.4,1.1-0.5,1.6-0.2
                              c0.5,0.2,0.9,0.8,0.9,1.4L76.7,50c0,0.7-0.5,1.3-1.2,1.5c-10.8,2.2-18.6,11.7-18.6,22.7c0,10.8,7.7,20.3,18.2,22.6
                              c0.7,0.2,1.2,0.8,1.2,1.5l0,5.9c0,0.8-0.7,1.5-1.5,1.5H60.1c-0.9,0-1.6,0.7-1.6,1.6v6.2c0,0.9,0.7,1.6,1.6,1.6h14.6
                              c0.4,0,0.8,0.2,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1l-0.1,7c0,0.8-0.7,1.5-1.5,1.5H60.1c-0.9,0-1.6,0.7-1.6,1.6v9.7
                              C58.5,137.2,57.8,137.9,57,137.9z M26,134.9h29.5v-8.2c0-2.5,2.1-4.6,4.6-4.6h13l0-4H60.1c-2.5,0-4.6-2.1-4.6-4.6v-6.2
                              c0-2.5,2.1-4.6,4.6-4.6h13.2l0-3.3C62,96.4,53.9,86,53.9,74.2c0-12,8.2-22.5,19.8-25.4L74,15.5L26,55.8V134.9z"/>
                          </g>
                          <g>
                            <path className="st0" d="M118.5,137.9h-15.2c-0.8,0-1.5-0.7-1.5-1.5v-9.7c0-0.9-0.7-1.6-1.6-1.6H85.5c-0.4,0-0.8-0.2-1.1-0.4
                              s-0.4-0.7-0.4-1.1l0-7c0-0.8,0.7-1.5,1.5-1.5h14.7c0.9,0,1.6-0.7,1.6-1.6v-6.2c0-0.9-0.7-1.6-1.6-1.6H85.5c-0.4,0-0.8-0.2-1.1-0.4
                              s-0.4-0.7-0.4-1.1l0-5.9c0-0.7,0.5-1.3,1.2-1.5c10.5-2.3,18.1-11.9,18.1-22.6s-7.6-20.3-18.1-22.6c-0.7-0.2-1.2-0.8-1.2-1.5v-5.6
                              c0-0.3,0.1-0.7,0.3-0.9l33-43c0.4-0.5,1.1-0.7,1.7-0.5c0.6,0.2,1,0.8,1,1.4v134.9C120,137.2,119.3,137.9,118.5,137.9z
                                M104.8,134.9H117V5.9L87,45v3.9c11.3,3.1,19.3,13.5,19.3,25.3c0,11.8-8,22.2-19.3,25.3l0,3.3h13.2c2.5,0,4.6,2.1,4.6,4.6v6.2
                              c0,2.5-2.1,4.6-4.6,4.6H87l0,4h13.2c2.5,0,4.6,2.1,4.6,4.6V134.9z"/>
                          </g>
                          <g>
                            <path className="st0" d="M142,137.9H2c-1.1,0-2-0.9-2-2s0.9-2,2-2h140c1.1,0,2,0.9,2,2S143.1,137.9,142,137.9z"/>
                          </g>
                          <g>
                            <path className="st0" d="M80.1,80.3c-0.9,0-1.5,0.7-1.5,1.5v4.6c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5v-4.6
                              C81.7,81,81,80.3,80.1,80.3z"/>
                          </g>
                          <path className="st0" d="M82.4,72.6h-4.6c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3h6.9c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5h-6.9
                            c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h4.6c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3H74c-0.9,0-1.5,0.7-1.5,1.5c0,0.9,0.7,1.5,1.5,1.5
                            h8.5c3,0,5.4-2.4,5.4-5.4S85.4,72.6,82.4,72.6z"/>
                          <g>
                            <path className="st0" d="M80.1,60.3c-0.9,0-1.5,0.7-1.5,1.5v4.6c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5v-4.6
                              C81.7,61,81,60.3,80.1,60.3z"/>
                          </g>
                        </g>
                        </svg>}
              />
              <CardIcon
                title="PERSONAS"
                jumpLine="& FAMILIAS"
                txtBtn="Más Información"
                urlBtn="/personas-y-familia"
                image={<svg x="0px" y="0px" viewBox="0 0 144 137.9" >
                  <g>
                    <g>
                      <g>
                        <path className="st0" d="M74.5,137.9h-50c-0.8,0-1.5-0.7-1.5-1.5V88.1c0-0.4,0.2-0.9,0.5-1.1l51-42.8c0.4-0.4,1.1-0.5,1.6-0.2
                          c0.5,0.2,0.9,0.8,0.9,1.4l-1,91.1C76,137.2,75.3,137.9,74.5,137.9z M26,134.9h47l1-86.3L26,88.8V134.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M118.5,137.9h-33c-0.4,0-0.8-0.2-1.1-0.4s-0.4-0.7-0.4-1.1l0-58.9c0-0.3,0.1-0.7,0.3-0.9l33-43
                          c0.4-0.5,1.1-0.7,1.7-0.5c0.6,0.2,1,0.8,1,1.4v101.9C120,137.2,119.3,137.9,118.5,137.9z M87,134.9h30V39L87,78L87,134.9z"/>
                      </g>
                    </g>
                    <g>
                      <path className="st0" d="M142,138H2c-1.1,0-2-0.9-2-2s0.9-2,2-2h140c1.1,0,2,0.9,2,2S143.1,138,142,138z"/>
                    </g>
                    <g>
                      <path className="st0" d="M49.7,45.9C38.9,45.9,30,37.8,29,27l-0.3-3.3c-0.5-5.8,1.4-11.6,5.3-15.9C38,3.4,43.6,1,49.4,1h0.6
                        c5.8,0,11.4,2.5,15.4,6.8c3.9,4.3,5.9,10.1,5.3,15.9L70.4,27C69.4,37.8,60.5,45.9,49.7,45.9z M49.4,4c-5,0-9.8,2.1-13.2,5.8
                        c-3.4,3.7-5,8.7-4.6,13.6l0.3,3.3c0.9,9.2,8.5,16.1,17.7,16.1c9.2,0,16.9-6.9,17.7-16.1l0.3-3.3c0.5-5-1.2-9.9-4.6-13.6
                        C59.8,6.1,55.1,4,50.1,4H49.4z"/>
                    </g>
                    <g>
                      <path className="st0" d="M101.7,29.9c-7.2,0-13.2-5.4-13.8-12.6l-0.2-2.1c-0.4-3.9,0.9-7.8,3.6-10.6C93.9,1.6,97.6,0,101.5,0h0.4
                        c3.9,0,7.6,1.7,10.3,4.5c2.6,2.9,3.9,6.8,3.6,10.6l-0.2,2.1C114.9,24.5,109,29.9,101.7,29.9z M101.5,3c-3.1,0-6,1.3-8,3.6
                        s-3.1,5.3-2.8,8.3l0.2,2.1c0.5,5.6,5.2,9.9,10.8,9.9s10.3-4.2,10.8-9.9l0.2-2.1c0.3-3-0.7-6.1-2.8-8.3c-2.1-2.3-5-3.6-8.1-3.6
                        H101.5z"/>
                    </g>
                  </g>
                  </svg> }
              />
              <CardIcon
                title="Inversionista"
                jumpLine="institucional"
                txtBtn="Más Información"
                urlBtn="/inversionista_institucional"
                image={<svg x="0px" y="0px" viewBox="0 0 144 137.9">
                  <g>
                    <g>
                      <g>
                        <path className="st0" d="M74.5,137.9h-50c-0.8,0-1.5-0.7-1.5-1.5V55.1c0-0.4,0.2-0.9,0.5-1.1l51-42.8c0.4-0.4,1.1-0.5,1.6-0.2
                          c0.5,0.2,0.9,0.8,0.9,1.4l-1,124.1C76,137.2,75.3,137.9,74.5,137.9z M26,134.9h47l1-119.3L26,55.8V134.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M118.5,137.9h-33c-0.4,0-0.8-0.2-1.1-0.4s-0.4-0.7-0.4-1.1l0-42.2V44.5c0-0.3,0.1-0.7,0.3-0.9l33-43
                          c0.4-0.5,1.1-0.7,1.7-0.5c0.6,0.2,1,0.8,1,1.4v134.9C120,137.2,119.3,137.9,118.5,137.9z M87,134.9h30V5.9L87,45v49.2L87,134.9z"
                          />
                      </g>
                    </g>
                    <g>
                      <path className="st0" d="M142,137.9H2c-1.1,0-2-0.9-2-2s0.9-2,2-2h140c1.1,0,2,0.9,2,2S143.1,137.9,142,137.9z"/>
                    </g>
                    <g>
                      <g>
                        <path className="st0" d="M63.1,49c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C61,48,62,49,63.1,49z"/>
                      </g>
                      <g>
                        <path className="st0" d="M50.8,59.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,59,49.6,59.9,50.8,59.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,59.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C61,59,62,59.9,63.1,59.9z"
                          />
                      </g>
                      <g>
                        <path className="st0" d="M50.8,70.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,70,49.6,70.9,50.8,70.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M38.4,70.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C36.3,70,37.3,70.9,38.4,70.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,70.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C61,70,62,70.9,63.1,70.9z"
                          />
                      </g>
                      <g>
                        <path className="st0" d="M38.4,81.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C36.3,81,37.3,81.9,38.4,81.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M50.8,81.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,81,49.6,81.9,50.8,81.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,81.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C61,81,62,81.9,63.1,81.9z"
                          />
                      </g>
                      <g>
                        <path className="st0" d="M63.1,92.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C61,92,62,92.9,63.1,92.9z"
                          />
                      </g>
                      <g>
                        <path className="st0" d="M50.8,92.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,92,49.6,92.9,50.8,92.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M38.4,92.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C36.3,92,37.3,92.9,38.4,92.9z"/>
                      </g>
                      <g>
                        <path className="st0" d="M50.8,103.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,102.9,49.6,103.8,50.8,103.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,103.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C61,102.9,62,103.8,63.1,103.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M38.4,103.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C36.3,102.9,37.3,103.8,38.4,103.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M38.4,114.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2-2.1-2c-1.1,0-2.1,0.9-2.1,2
                          C36.3,113.9,37.3,114.8,38.4,114.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M50.8,114.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2-2.1-2c-1.1,0-2.1,0.9-2.1,2
                          C48.7,113.9,49.6,114.8,50.8,114.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,114.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2-2.1-2c-1.1,0-2.1,0.9-2.1,2C61,113.9,62,114.8,63.1,114.8z"
                          />
                      </g>
                      <g>
                        <path className="st0" d="M38.4,125.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C36.3,124.9,37.3,125.8,38.4,125.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M50.8,125.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C48.7,124.9,49.6,125.8,50.8,125.8z"/>
                      </g>
                      <g>
                        <path className="st0" d="M63.1,125.8c1.2,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1
                          C61,124.9,62,125.8,63.1,125.8z"/>
                      </g>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,58.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,58.9,106.5,58.9z"/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,69.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,69.9,106.5,69.9z"/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,80.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,80.9,106.5,80.9z"/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,91.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,91.9,106.5,91.9z"/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,102.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,102.9,106.5,102.9z
                        "/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,113.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,113.9,106.5,113.9z
                        "/>
                    </g>
                    <g>
                      <path className="st0" d="M106.5,124.9h-21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5S107.3,124.9,106.5,124.9z
                        "/>
                    </g>
                  </g>
                  </svg>}
              /> */}
            </Carousel>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(CarouselCardIcon);

