import {
  box,
  divider,
} from "components/Table/tableStyle"

  const tableClientStyle = {
    box: {
      ...box,
      paddingBottom: '30px'
    },
    divider: {
      ...divider,
      marginBottom: '3px !important'
    },
    cellTitle: {
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.26rem !important",
      lineHeight: "1.2em !important",
      marginTop: "9px !important",
      marginBottom: "0px !important",
      color: "#003B72",
      fontWeight: "700",
      textAlign: "right",
      "@media (max-width: 1199px)": {
        textAlign: "left",
      },
    },
    cellText: {
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.1rem !important",
      lineHeight: "1.3em !important",
      marginTop: "9px !important",
      marginBottom: "0px !important",
      color: "#272727",
      fontWeight: "400",
      textTransform: "none"
    },
  };
  
  export default tableClientStyle;
  