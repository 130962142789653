import {
  container,
  primaryColor,
  whiteColor,
  btnLink,
  grayColor,
  twitterColor,
  dribbbleColor,
  instagramColor,
  bgGray,
  bgDark,
  hexToRgb,
  spaceBetwen,
  transition
} from "assets/jss/material-kit-pro-react.jsx";

// import bg from "assets/img/png/footerbg.png"

const footerStyle = {
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    textAlign: "center"
  },
  rightLinks: {
    float: "right!important",
    "& ul": {
      marginBottom: 0,
      marginTop: 10,
      padding: 0,
      listStyle: "none",
      height: 38,
      "& li": {
        display: "inline-block"
      }
    },
    "& i": {
      fontSize: "20px"
    }
  },
  contentCircle: {
    minWidth: "6.2rem",
    height: "6.2rem",
    lineHeight: "6.2rem",
    textAlign: "center",
    border: "1px solid #727272",
    borderRadius: "100%",
    "& svg": {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "1em"
    },
    "& img":{
      height: "26px", 
    }
  },
  footer: {
    padding: "83px 0",
    // textAlign: "center",
    display: "flex",
    flexDirection: "column",
    zIndex: "2",
    position: "relative",
    "& ul": {
      marginBottom: "0",
      padding: 0,
      listStyle: "none"
    },
    "& h4": {
      fontWeight: 400,
      position: 'relative',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontSize: "1.5rem !important",
      lineHeight: "1.36em",
      marginTop: 0,
      marginBottom: "2.6rem",
      '&:after': {
          content: '" "',
          position: "absolute",
          bottom: -12,
          left: 0,
          width: 27,
          height: 3,
          backgroundColor: 'white'
      },
      "@media (max-width: 959px)":{
        marginTop: "30px "
      },
    },
    "@media (max-width: 766px)":{
      padding: "34px 0"
    },
  },
  linksVertical:{
    '& li': {
      borderBottom: '1px solid #2e2e2e',
      padding: '.7rem 0',
    },
    // '& li:last-child': {
    //   borderBottom: 'none'
    // }
  },
  link:{
    ...transition,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: '#727272 !important',
    display: 'block',
    '& svg':{
      fontSize: "8px",
      marginBottom: "2px",
      marginRight: "12px"
    },
    '&:hover': {
      ...transition,
      color: '#fff !important',
    }
  },
  text:{
    fontSize: '.9rem',
    fontWeight: 400,
    color: '#727272 !important',
    opacity: '1 !important',
    // '&:hover': {
    //   opacity: ".7 !important"
    // }
  },
  contentIcon:{
    display: 'flex',
    fontSize: '1rem',
    opacity: '.7 !important',
    '& svg':{
      fontSize: '.9rem',
      lineHeight: '1em',
      color: '#727272',
      marginRight: '.9rem',
      marginTop: '.2rem'
    },
    '& img':{
      height: "1rem",
      marginTop: '.2rem',
      marginRight: '.9rem',
    }
  },
  contactContent: {
    ...bgDark,
    padding: "44.5px 0",
    textAlign: "center",
    // marginLeft: -15,
    // marginRight: -15
  },
  copyContent: {
    ...bgDark,
    padding: "30px 0",
    textAlign: "center",
    marginLeft: -15,
    marginRight: -15,
    '& small':{
      fontSize: ".96rem",
      lineHeight: "1.6em",
      color:"#727272"
    }
  },
  big: {
    padding: "1.875rem 0",
    "& h5, & h4": {
      fontWeight: 700,
      fontFamily: "Open Sans Slab,Times New Roman,serif",
      marginBottom: "15px"
    },
    "& p": {
      color: grayColor[0]
    }
  },
  content: {
    textAlign: "left"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  dark: {
    // background:
    //   "radial-gradient(ellipse at center," +
    //   grayColor[4] +
    //   " 0," +
    //   grayColor[5] +
    //   " 100%)",
    // backgroundSize: "550% 450%",
    ...bgGray,
    backgroundSize: 'cover',
    paddingBottom: 0,
    paddingTop: 0,
    width:"100%",
    color: whiteColor,
    "& h3": {
      fontSize: '1.2rem',
      fontWeight: 700,
      marginTop: "10px",
      '@media (max-width: 959px)': {
          fontSize: "15px",
      },
    },
    "& p": {
      color: whiteColor
    },
    "& i, h5, h4": {
      color: whiteColor + " !important"
    },
    "& a": {
      color: whiteColor,
      fontSize: 14,
      opacity: ".86",
      "&:visited": {
        color: whiteColor
      },
      "&:focus, &:hover": {
        opacity: 1,
      }
    },
    "& hr": {
      borderColor: "rgba(" + hexToRgb(whiteColor) + ",0.2)"
    },
    "& $btnTwitter, & $btnDribbble, & $btnInstagram": {
      color: whiteColor
    }
  },
  degradado: {
    // backgroundImage: "linear-gradient(to right, #d8d8d8 0%, #d8d8d8 50%,white 50%,white 100%)",
    backgroundColor: "#fff",
    color: grayColor[1],
    textDecoration: "none",
    "& a": {
      "&:visited": {
        color: grayColor[1]
      },
      "&:hover, &:focus": {
        color: grayColor[20]
      }
    }
  },
  container: {
    ...container,
    width: "100%"
  },
  contentInversion: {
    display: "flex",
    flexDirection: "row"
  },
  contentAval: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#d8d8d8",
    width: "250px",
    minWidth: "160px",
    "@media (max-width: 360px)":{
      minWidth: "135px",
    },
  },
  aval: {
    padding: "1rem 0",
    width: "100%",
    maxWidth: "170px"
  },
  avalMin: {
    padding: "0.5rem 0 0.5rem 1rem",
    width: "120px",
    marginTop: "1.5rem",
    "@media (max-width: 370px)":{
      width: "105px",
    },
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(250,250,250,0.6)"
  },
  corfi: {
    padding: "1rem 0",
    width: "100%",
    maxWidth: "170px",
    borderBottom: "1px solid rgba(250,250,250,0.45)"
  },
  corfiMin: {
    padding: "0.5rem 1rem 0.5rem 0",
    width: "210px",
    borderRight: "1px solid white",
    marginTop: "1.5rem",
    "@media (max-width: 500px)":{
      width: "190px",
    },
    "@media (max-width: 380px)":{
      width: "155px",
    },
  },
  contentLogos: {
    display: "flex",
    justifyContent: "space-around",
    flexFlow: "wrap",
    width: "100%",
    padding: "1rem 0",
    position: "relative",
    alignItems: "center",
    backgroundColor: "white"
  },
  titleLogos: {
    // position: "absolute",
    // top: "0",
    // left: ".5rem"
    width: "100%",
    marginTop: "-1rem",
    padding: "0 1rem",
  },
  spaceBetwen:{
    ...spaceBetwen,
    padding: "15px",
    "@media (max-width: 779px)":{
      flexDirection: "column"
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  iconSocial: {
    width: "41px",
    height: "41px",
    fontSize: "24px",
    minWidth: "41px",
    padding: 0,
    overflow: "hidden",
    position: "relative"
  },
  btnTwitter: {
    ...btnLink,
    color: twitterColor
  },
  btnDribbble: {
    ...btnLink,
    color: dribbbleColor
  },
  btnInstagram: {
    ...btnLink,
    color: instagramColor
  },
  footerBrand: {
    height: "50px",
    padding: "15px 15px",
    fontSize: "18px",
    lineHeight: "50px",
    marginLeft: "-15px",
    color: grayColor[1],
    textDecoration: "none",
    fontWeight: 700,
    fontFamily: "Open Sans Slab,Times New Roman,serif"
  },
  pullCenter: {
    display: "inline-block",
    float: "none"
  },
  clearFix: {
    clear: "both"
  },
  formFix: {
    paddingTop: 0,
    '& >div': {
      backgroundColor: whiteColor,
      borderRadius: "5px",
      '&:before': {
        position: "relative"
      },
      '&:after': {
        position: "relative"
      },
      '& svg': {
        fill: primaryColor[0]
      }
    }
  },
  subscribeButton: {
    margin: 0,
    marginBottom: "15px"
  },
  aClasses: {
    marginRight: 20,
  },
  logo: {
    maxHeight: 60,
    "@media (max-width: 360px)":{
      maxHeight: 50,
    },
  },
  socialButton: {
    marginBottom: "0px",
    '& button': {
      marginRight: '0.5rem',
      '&:last-child': {
        marginRight: 0,
      }
    },
    '& a': {
      marginRight: "0.5rem",
      '&: last-child': {
        marginRight: 0,
      }
    }
  },
  version: {
    color: "#aebfff",
    padding: "0 1rem",
    display: "inline-block"
  },
  expansionDark: {
    backgroundColor: "transparent",
    '&:before':{
      backgroundColor: primaryColor[0]
    },
    '& >div':{
      padding: 0,
      '& >div':{
        color: "white",
        '& >p':{
          fontSize: "15px",
          fontWeight: 700,
        }
      }
    }
  },
  contentLinks: {
    padding: 0
  },
  iconRight: {
      '& svg': {
          margin: '0 -4px 0 4px'
      }
  },
  centerMovil: {
    "@media (max-width: 479px)":{
      textAlign: "center"
    },
  },
  contactItem: {
    display:'flex',
    // justifyContent: 'center',
      "@media (max-width: 959px)":{
        justifyContent: 'flex-start',
        // marginTop: "1rem",
      },
  },
  blend:{
    mixBlendMode: 'darken',
  },
  spaceMovil:{
    display: "block",
    whidth: "100%",
    height: "2.4rem",
    "@media (min-width: 767px)":{
      display: 'none',
    },
  },
  contactInfo: {
    marginLeft: '1rem',
    textAlign: 'left',
    "& h4": {
      fontFamily: `'Lato', "sans-serif"`,
      fontWeight: 400,
      display: 'block',
      textTransform: 'uppercase',
      fontSize: "1.5rem !important",
      lineHeight: "1.36em",
      marginBottom: "7px",
      '&:after': {
          content: '" "',
          position: "absolute",
          bottom: -5,
          left: 0,
          width: 27,
          height: 3,
          backgroundColor: 'transparent'
      },
    }
  }
};
export default footerStyle;
