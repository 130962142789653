import React from "react";
import { createBrowserHistory } from "history";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Cards/Card.jsx";
import icon from "assets/img/svg/icon_time.svg"
// sections for this page
import style from "./messageInactivityStyle.jsx"

var hist=createBrowserHistory();

class MessageInactivity extends React.Component {
  
  goToLogin = () => {
    const loginUrl = this.props.loginUrl;
    sessionStorage.removeItem('BurSes-13axWl87T3Pz');
    hist.push(loginUrl);
    window.location.assign(loginUrl);
  }

  render() {
    const { classes } = this.props;
    
    return (
      <GridItem sm={12} md={7}>
        <Card  
          plain
          className={classes.cardForm}
          blog
          border
        >
          <GridContainer spacing={3}>

            <GridItem xs={12}>
              <img style={{width: "100px"}} src={icon} alt="time"/>
            </GridItem>
            <GridItem xs={12}>
              <h4 className={classes.title}>TU SESIÓN SE HA CERRADO POR INACTIVIDAD</h4>
              <h6 className={classes.text}>Para continuar, inicia sesión nuevamente.</h6>
              <Button disableRipple  size="" color="primary" onClick={()=>this.goToLogin()} >
                Ingresar de nuevo
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    );
  }
}

export default withStyles(style)(MessageInactivity);
