import {
    container,
    marginSection,
    defaultFont,
    bgGray,
    marginHeaderExact,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const styleHome = {
    marginHeaderExact: {
      ...marginHeaderExact,
    },
    marginSection,
    // section: {
    //   ...marginSection,
    // },
    description: {
      ...defaultFont,
    },
    bgGray:{
      ...bgGray,
      width: "100%"
    },
    fullWidth: {
      width: "100%"
    },
    container: {
      ...container,
      zIndex: 1
    },
  };
  
  export default styleHome;