import {
  container,
  marginSection,
  defaultFont,
  bgGray,
  marginHeaderExact,
} from "assets/jss/material-kit-pro-react.jsx";
import image from '../img/jpg/logo_back.jpg'

const styleHome = {
  marginHeaderExact: {
    ...marginHeaderExact,
  },
  marginSection,
  // section: {
  //   ...marginSection,
  // },
  description: {
    ...defaultFont,
  },
  bgGray:{
    ...bgGray,
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  container: {
    ...container,
    zIndex: 1
  },
  bgImage:{
    backgroundImage: "url(" + image + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundSize: "340px"
  }
};

export default styleHome;