
    const box = {
      padding: "10px",
      border: "1px solid #e4e4e4"
    };
    const divider = {
      opacity: "0.7",
      borderBottom: "1px solid #e4e4e4"
    }

    const tableTitle = {
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.46rem !important",
      lineHeight: "1.36em !important",
      marginTop: "0px !important",
      marginBottom: "0px !important",
      fontWeight: "400",
      color: "#272727",
    }

    const title = {
        fontSize: "12px",
        lineHeight: "14px",
        marginTop: "0px !important",
        marginBottom: "0px !important",
        color: "#727272",
        fontWeight: "700",
        height: 30,
        // "@media (max-width: 1199px)": {
        //   textAlign: "left",
        // },
      };
    const text = {
        fontSize: ".96rem",
        lineHeight: "1.2em",
        marginTop: "0px !important",
        marginBottom: "0px !important",
        color: "#727272",
        fontWeight: "400",
        textTransform: "none",
        height: 30,
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
      };
  
    export { 
        box,
        divider,
        tableTitle,
        title,
        text,
    }
  