import {
  grayColor,
  secondaryColor,
  complementarioColor,
  infoColor,
  successColor,
  warningColor,
  whiteColor,
  dangerColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

const paginationStyle = {
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "0",
    paddingTop: "1.5rem",
    listStyle: "none",
    borderRadius: "0.25rem",
    borderTop: "1px solid #e4e4e4",
    marginBottom: "0 !important",
  },
  paginationItem: {
    display: "inline"
  },
  paginationLink: {
    ":first-of-type": {
      marginleft: "0"
    },
    
    fontFamily: '"Open Sans", sans-serif',
    // border: "0",
    borderRadius: "0px !important",
    transition: "all 0.3s ease-out 0s",
    padding: "0px 11px",
    margin: "0 3px",
    minWidth: "2.8rem",
    height: "2.8rem",
    minHeight: "auto",
    lineHeight: "2.7rem",
    fontWeight: "400",
    fontSize: ".96rem",
    textTransform: "uppercase",
    background: "transparent",
    position: "relative",
    float: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    border: '1px solid #e4e4e4',
    "&,&:hover,&:focus": {
      color: "#727272"
    },
    "&:hover,&:focus": {
      zIndex: "3",
      backgroundColor: grayColor[2],
      borderColor: grayColor[6]
    },
    "&:hover": {
      cursor: "pointer"
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
  },
  primary: {
    "&,&:hover,&:focus": {
      backgroundColor: secondaryColor[0],
      borderColor: secondaryColor[0],
      color: whiteColor,
      // boxShadow:
      //   "0 4px 5px 0 rgba(" +
      //   hexToRgb(primaryColor[0]) +
      //   ", 0.14), 0 1px 10px 0 rgba(" +
      //   hexToRgb(primaryColor[0]) +
      //   ", 0.12), 0 2px 4px -1px rgba(" +
      //   hexToRgb(primaryColor[0]) +
      //   ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  complementario: {
    "&,&:hover,&:focus": {
      backgroundColor: complementarioColor[0],
      borderColor: complementarioColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(complementarioColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(complementarioColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(complementarioColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  info: {
    "&,&:hover,&:focus": {
      backgroundColor: infoColor[0],
      borderColor: infoColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  success: {
    "&,&:hover,&:focus": {
      backgroundColor: successColor[0],
      borderColor: successColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  warning: {
    "&,&:hover,&:focus": {
      backgroundColor: warningColor[0],
      borderColor: warningColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  danger: {
    "&,&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      borderColor: dangerColor[0],
      color: whiteColor,
      boxShadow:
        "0 4px 5px 0 rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.14), 0 1px 10px 0 rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 2px 4px -1px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)"
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default"
    }
  },
  disabled: {
    "&,&:hover,&:focus": {
      color: grayColor[10],
      cursor: "not-allowed",
      backgroundColor: whiteColor,
      borderColor: grayColor[6]
    }
  }
};

export default paginationStyle;
