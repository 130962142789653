import {
  box,
  divider,
  tableTitle,
  text,
} from "components/Table/tableStyle"
  const tableGeneralStyle = {
    box: {
      ...box
    },
    divider: {
      ...divider
    },
    tableTitle: {
      ...tableTitle
    },
    text: {
      ...text,
      height: "auto !important",
      marginBottom: "10px !important"
    },

    textRight: {
      textAlign: "right"
    },
    
    // TableInvestmentFunds
    textCenter: {
      textAlign: "center"
    },
    titleBig: {
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.26rem !important",
      lineHeight: "1.2em !important",
      marginTop: "10px !important",
      marginBottom: "16px !important",
      color: "#272727",
      fontWeight: "400",
    },

    logoInvestment: {
      width: "100%",
      margin: 0,
      "@media (max-width: 599px)": {
        width: "50%",
      },
    },
  };
  
  export default tableGeneralStyle;
  