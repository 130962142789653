import React, {Component} from 'react';
import Loading from 'components/Loader/Loading';
import * as axios from 'axios';
import { createBrowserHistory } from "history";
import HeaderOffice from "components/Header/HeaderOffice";
import FormChangePassword from 'components/Form/FormChangePassword';
import FormChangeNIP from 'components/Form/FormChangeNIP';
import DashboardBlock from 'components/Blocks/DashboardBlock';
import TableClient from 'components/Table/TableClient';
import TableActualPosition from "components/Table/TableActualPosition";
import TableMovements from 'components/Table/TableMovements';
import TableInfoResult from "components/Table/TableInfoResult"
import ButtonBeltOffice from 'components/Belts/ButtonBeltOffice';
import TableAcountState from 'components/Table/TableAcountState';
import TableTaxCertificates from 'components/Table/TableTaxCertificates';
import MessageInactivity from 'components/MessageInactivity';
import GridItem from 'components/Grid/GridItem';
import SingleImageBanner from 'components/Banner/SingleImageBanner';
import GridContainer from 'components/Grid/GridContainer';
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import style from "./officeStyle";



var hist=createBrowserHistory();

class Office extends Component {
  constructor(props){
      super(props);

      this.state = {
        loadedData: false,
        loadInactivity: false,
        dashboardUrl: "",
        dashboardData: {
          resMoney: 0,
          resCapital: 0,
          resInvestment: 0,
          resCash: 0,
          resTotal: 0
        },
        customerInfoData:{},
        actualPositionData:{},
        resultsInfoData:{},
        token: "",
        sessionContract: "", 
        contrato: "",//100223
        customerNum: "", // 20045,
        userName: "",
        contratos: null,
        loginDate: "",
        currentPage:'Dashboard',
        bannerTitle1: '',
        bannerTitle2: '',
        bannerImageUrl: '',
        bannerMobileImgUrl: '',
        MOBILE_LINKS: []
      }
  }
  
  closeSession=()=>{
    axios.post(this.props.footerData.urlCloseSession,
      {
        'contract': this.state.sessionContract,
      },
      {
        "headers": {
          "Authorization": this.state.token,
        }
      }).then(resp => {
        if (resp.status === 200) {
          //const loginUrl = this.props.hostingVariables.loginBtnUrl;
          sessionStorage.removeItem('BurSes-13axWl87T3Pz');
          hist.push("/");
          window.location.assign("/");
        }
      }).catch(error => {
        console.log(error);
      });
  }

  callInactivity=()=>{
    this.setState({loadInactivity: true})
  }

  checkSession=()=>{
    // const loginUrl = this.props.hostingVariables.loginBtnUrl;
    const sessionData = JSON.parse(sessionStorage.getItem('BurSes-13axWl87T3Pz'));
    if (sessionStorage.getItem('BurSes-13axWl87T3Pz')){
      // const sessionData = JSON.parse(sessionStorage.getItem('BurSes-13axWl87T3Pz'));
      const currentDate = new Date().getTime();
      if (sessionData.end>currentDate){
        if (!this.state.token){
          this.setState({
            dashboardUrl: sessionData.dashboardUrl,
            token: sessionData.token,
            sessionContract: sessionData.contrato,
            contrato: sessionData.contrato,
            customerNum: sessionData.customerNum,
            userName: sessionData.userName,
            contratos: sessionData.contratos,
            loginDate: sessionData.start,
            changePassReq: sessionData.changePassReq,
            changeNipReq: sessionData.changeNipReq,
            currentPage: sessionData.changePassReq ? 'FormChangePassword' : sessionData.changeNipReq ? 'FormChangeNIP' : 'Dashboard'
          },
          ()=> this.getDashboardData(sessionData.contrato)
          )
        }
      } else {
        /* sessionStorage.removeItem('BurSes-13axWl87T3Pz');
        hist.push(loginUrl);
        window.location.assign(loginUrl); */
        this.callInactivity();
      }
    } else {
      this.closeSession();
    }
  }

  formatDate = (testDate) => {
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear().toString();
    let hours = testDate.getHours();
      if (hours < 10) hours = '0'+hours.toString();
    let minutes = testDate.getMinutes();
      if (minutes < 10) minutes = '0'+minutes.toString();
    let seconds = testDate.getSeconds();
      if (seconds < 10) seconds = '0'+seconds.toString();
    return day+"/"+month+"/"+year.substr(2,2)
  }

  getResultsInfoData= async ()=>{
    const endpoint = this.props.data.dataResultsInfo.endpoint;
    let response = {};
    let d = new Date();
    const month = d.getMonth();
      d.setMonth(month);
      d.setDate(0);
      
    const checkDate = this.formatDate(d);
    await axios.post(
      endpoint,
      {
        "contrato": this.state.sessionContract,
        "fecha": checkDate
      },
      {
        "headers": {
          "Authorization": this.state.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        this.setState({resultsInfoData: resp.data})
      }
    }).catch(error =>{
      console.log('getResultsInfoDataError: ',error)
      this.callInactivity();
    });

    return response;
  }

  getCustomerInfoData= async ()=>{
    const endpoint = this.props.data.dataCustomerInfo.endpoint;
    let response = {};
    await axios.post(
      endpoint,
      {
        "contrato": this.state.sessionContract
      },
      {
        "headers": {
          "Authorization": this.state.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        if (resp.data.code === "401") {
          this.closeSession();
        } else {
          this.setState({customerInfoData: resp.data})
        }
      }
    }).catch(error =>{
      console.log('getCustomerInfoDataError: ',error)
      this.callInactivity();
    });
    return response;
  }

  getDashMoneyData= async (contrato)=>{
    const endpoint = this.props.data.dataActualPosition.endpoint;
    let response = {};
    await axios.post(
      endpoint,
      {
        "contrato": contrato,
        "tipo": 2, 
      },
      {
        "headers": {
          "Authorization": this.state.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        if (resp.data.code === "401") {
          this.closeSession();
        } else {
          response = resp
        }
      }
    }).catch(error =>{
      console.log('getDashMoneyDataError: ', error)
      this.callInactivity();
      /* hist.push("/error");
      window.location.assign("/error"); */
    });
    return response;
  }

  validateSession = (contrato) => {
    setInterval(() => {
      axios.post(
        this.props.footerData.urlValidateSession, 
        {
          'contract': contrato
        }
      ).then(resp => {
        if (resp.status === 200) {
          if (resp.data.code === "401") {
            //const loginUrl = this.props.hostingVariables.loginBtnUrl;
            sessionStorage.removeItem('BurSes-13axWl87T3Pz');
            hist.push("/");
            window.location.assign("/");
            clearInterval();
          }
        }
      }).catch(err => {
        console.log(err);
      })
    }, 5000);
  }

  getDashboardData= async (contrato)=>{
    let resMoney = "0";
    let resCapital = "0";
    let resInvestment = "0";
    let resCash = "0";
    let resTotal = "0";

    const responseMoney = await this.getDashMoneyData(contrato);
    /* const responseCapital = await this.getDashCapitalData();
    const responseInvestment = await this.getDashInvestmentData(); */
    
    if (responseMoney.data){
      if (responseMoney.data.posicion){
        responseMoney.data.posicion.map(item=>{
          if (item.SUBTOTALDIN !== null && Number(item.SUBTOTALDIN) !== 0) {
            resMoney = item.SUBTOTALDIN;
          }
          if (item.SUBTOTALCAP !== null && Number(item.SUBTOTALCAP) !== 0) {
            resCapital = item.SUBTOTALCAP;
          }
          if (item.SUBTOTALFON !== null && Number(item.SUBTOTALFON) !== 0) {
            resInvestment = item.SUBTOTALFON;
          }
          return null;
        })
      }
      if (responseMoney.data.saldo){
        if (responseMoney.data.saldo[0]){
          if (responseMoney.data.saldo[0].SubTotal){
            resCash = responseMoney.data.saldo[0].SubTotal;
          }
        }
      }
      if (responseMoney.data.total){
        resTotal = responseMoney.data.total
      }
        
      this.setState({
        dashboardData: {
          resMoney: resMoney,
          resCapital: resCapital,
          resInvestment: resInvestment,
          resCash: resCash,
          resTotal: resTotal,
          activeContract: contrato
        },
        actualPositionData: responseMoney.data,
        loadedData: true
      });

      this.getCustomerInfoData();
      this.getResultsInfoData();
    }

    this.validateSession(contrato);
  }

  updateContainerWidth=()=>{
    let LINKS = this.state.MOBILE_LINKS;
    if (window.innerWidth<960) {
      LINKS = [
        {
          href:()=>this.changePage("CustomerInfo"),
          name:"Información del cliente"
        },
        {
          href:()=>this.changePage("ActualPosition"),
          name:"Posición Actual"
        },
        {
          href:()=>this.changePage("Movements"),
          name:"Movimientos"
        },
        {
          href:()=>this.changePage("ResultsInfo"),
          name:"Informe de Resultados"
        },
        {
          href:()=>this.changePage("AccountStatement"),
          name:"Estados de Cuenta"
        },
        {
          href: ()=>this.changePage("TaxCertificates"),
          name: "Constancias Fiscales"
        },
        {
          href: ()=>this.closeSession(),
          name:"Cerrar Sesión"
        },
        {
          name: this.state.userName,
          href: "",
          dropDownList: [
            {
              ref:"multi",
              href:()=>this.changePage("Dashboard"),
              name:"Dashboard"
            },
            {
              ref:"multi",
              href:()=>this.changePage("FormChangePassword"),
              name:"Cambiar Contraseña"
            },
            {
              ref:"multi",
              href:()=>this.changePage("FormChangeNIP"),
              name:"Cambiar NIP"
            }
          ],
        }
      ]
    } else {
      LINKS = [
        {
          href:()=>this.changePage("CustomerInfo"),
          name:"Información del cliente"
        },
        {
          href:()=>this.changePage("ActualPosition"),
          name:"Posición Actual"
        },
        {
          href:()=>this.changePage("Movements"),
          name:"Movimientos"
        },
        {
          href:()=>this.changePage("ResultsInfo"),
          name:"Informe de Resultados"
        },
        {
          href:()=>this.changePage("AccountStatement"),
          name:"Estados de Cuenta"
        },
        {
          href: ()=>this.changePage("TaxCertificates"),
          name: "Constancias Fiscales"
        },
        
      ]
    }
    if (LINKS !== this.state.MOBILE_LINKS){
      this.setState({
        MOBILE_LINKS : LINKS
      })
    }
  }

  bannerStructure=(pageName)=>{
    const pageData = this.props.data[pageName];
    if (
      this.state.bannerTitle1 !== pageData.bannerTitle1 ||
      this.state.bannerTitle2 !== pageData.bannerTitle2 ||
      this.state.bannerImageUrl !== pageData.bannerImageUrl ||
      this.state.bannerMobileImgUrl !== pageData.bannerMobileImgUrl
      ) {
        this.setState({
          bannerTitle1: pageData.bannerTitle1,
          bannerTitle2: pageData.bannerTitle2,
          bannerImageUrl: pageData.bannerImageUrl,
          bannerMobileImgUrl: pageData.bannerMobileImgUrl,
        })
      }
      return;
  }

  createBanner=()=>{
    return (
      <SingleImageBanner bannerData={{
        animation: "", // opciones: rollIn, fadeInDown o fadeInLeft
        title1: this.state.bannerTitle1,
        sizeTitle1: "normal", // opciones: small , normal o big
        boldTitle1: true,
        blueTitle1: false,
        title2: this.state.bannerTitle2,
        sizeTitle2: "small", // opciones: small , normal o big
        boldTitle2: false,
        blueTitle2: false,
        imageUrl: this.state.bannerImageUrl,
        mobileImgUrl: this.state.bannerMobileImgUrl,
        imageLogo: "",
        videoBg: "",
        btnTitle: "",
        btnUrl: "/",
        videoBtnText: "",
        video: "",
        alignContent:"", //opciones vacío ("") o "center"
        textCenter: true,
        textRight: false,
        isOffice: true
      }} />
    )
  }

  scrollTop=()=>{
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  bodyStructure=(currentPage)=>{
    const {classes} = this.props
    const { 
      loginDate, 
      customerNum,
      userName, 
      contrato, 
      changePassReq,
      changeNipReq,
      contratos, 
      dashboardData,
      resultsInfoData,
      token,
     } = this.state
    const { 
      dataDashboard,
      dataChangeNip, 
      dataChangePass,
      dataCustomerInfo, 
      dataActualPosition,
      dataMovements,
      dataResultsInfo,
      dataAccountStatement,
      dataTaxCertificates
    } = this.props.data
    let BANNER = null;

    switch (currentPage) {
      case 'Dashboard':
        this.scrollTop();
        this.bannerStructure('dataDashboard')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
            <GridItem xs={12} style={{padding:"0 30px"}}>
            <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                <DashboardBlock 
                  data={dataDashboard} 
                  loginDate={loginDate}
                  userName={userName} 
                  contrato={contrato} 
                  contratos={contratos}
                  resultsData={dashboardData}
                  changeData={this.getDashboardData}
                />
              </GridContainer>
            </GridItem>
          </React.Fragment>
        )

      case 'FormChangeNIP':
        this.scrollTop();
        return (
          <GridItem xs={12} >
            <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
              <FormChangeNIP 
                groupId={this.props.hostingVariables.groupId}
                data={dataChangeNip} 
                contrato={contrato} 
                changeNipReq={changeNipReq} 
                redirectUrl={(page)=>this.changePage(page)}
                token={token}
                closeSession={()=>this.closeSession()}
              />
            </GridContainer>
          </GridItem>
        )
      
      case 'FormChangePassword':
        this.scrollTop();
        return (
          <GridItem xs={12} >
            <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
              <FormChangePassword 
                groupId={this.props.hostingVariables.groupId}
                data={dataChangePass}
                contrato={contrato}
                changePassReq={changePassReq}
                token={token} 
                redirectUrl={(page)=>this.changePage(page)}
                closeSession={()=>this.closeSession()}
              />
            </GridContainer>
          </GridItem>
        )
      
      case 'CustomerInfo':
        this.scrollTop();
        this.bannerStructure('dataCustomerInfo')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
              <GridItem xs={12} >
                <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                  <TableClient data={dataCustomerInfo} customerInfoData={this.state.customerInfoData}/>
                </GridContainer>
              </GridItem>
          </React.Fragment>
          
        )

      case 'ActualPosition':
        this.scrollTop();
        this.bannerStructure('dataActualPosition')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
            <GridItem xs={12} style={{padding:"0 30px"}}>
              <GridContainer justify="center" spacing={4} className={classNames(classes.containerFluid, classes.marginSection)} key={'GC'+Math.floor(Math.random()*901)}>
                <TableActualPosition 
                  data={dataActualPosition} 
                  actualPositionData={this.state.actualPositionData}
                  resultsData={dashboardData}
                />
              </GridContainer>
            </GridItem>
          </React.Fragment>
        )
      
      case 'Movements':
        this.scrollTop();
        this.bannerStructure('dataMovements')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
            <GridItem xs={12} style={{padding:"0 30px"}}>
              <GridContainer justify="center" spacing={4} className={classNames(classes.containerFluid, classes.marginSection)} key={'GC'+Math.floor(Math.random()*901)}>
                <TableMovements data={dataMovements} contrato={contrato} token={token} closeSession={()=>this.closeSession()}/>
              </GridContainer>
            </GridItem>
          </React.Fragment>
        )

      case 'ResultsInfo':
        this.scrollTop();
        this.bannerStructure('dataResultsInfo')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
            <GridItem xs={12} >
              <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                <TableInfoResult 
                  title={dataResultsInfo.title} 
                  resultsInfoData={resultsInfoData} 
                  endpoint={this.props.data.dataResultsInfo.endpoint} 
                  sessionContract={this.state.dashboardData.activeContract} 
                  token={this.state.token} 
                  callInactivity={()=>this.callInactivity()}
                  closeSession={()=>this.closeSession()}
                />
              </GridContainer>
            </GridItem>
          </React.Fragment>
        )

      case 'AccountStatement':
        this.scrollTop();
        this.bannerStructure('dataAccountStatement')
        BANNER = this.createBanner()
        return (
          <React.Fragment>
            {BANNER}
            <ButtonBeltOffice 
              data={{
                title: dataAccountStatement.beltTitle, 
                btnTitle: dataAccountStatement.beltBtnTxt
              }} 
              activationEndpoint={dataAccountStatement.endpointPreFetch} 
              endpointPDF={dataAccountStatement.endpoint} 
              contrato={contrato}
              customerNum={customerNum}
              closeSession={()=>this.closeSession()}
            />
            <GridItem xs={12} >
              <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                <TableAcountState 
                  data={dataAccountStatement} 
                  contrato={contrato} 
                  customerNum={customerNum} 
                  contratos={contratos} 
                  token={token}
                  closeSession={()=>this.closeSession()}
                />
              </GridContainer>
            </GridItem>
          </React.Fragment>
        )

        case 'TaxCertificates':
          this.scrollTop();
          this.bannerStructure('dataTaxCertificates')
          BANNER = this.createBanner()
          return (
            <React.Fragment>
              {BANNER}
              <GridItem xs={12} >
                <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                  <TableTaxCertificates 
                    data={dataTaxCertificates} 
                    userName={userName} 
                    token={token}
                    contrato={contrato} 
                    contratos={contratos}
                    closeSession={()=>this.closeSession()}
                  />
                </GridContainer>
              </GridItem>
            </React.Fragment>
          )
  
      default:
        break
    }
    return;
  }

  changePage=(page)=>{
    axios.post(
      this.props.footerData.urlValidateSession, 
      {
        'contract': this.state.sessionContract
      }
    ).then(resp => {
      if(resp.status === 200) {
        if(resp.data.code === "401") {
          this.closeSession();
        } else {
          this.setState({currentPage: page});
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  componentDidMount(){
    this.checkSession();
    this.updateContainerWidth();
    window.addEventListener('resize', this.updateContainerWidth);
    if (hist.location.pathname === "/portal-clientes") {
      this.setState({
        isOffice: true
      })
    }
  }
    
  render() {
    const {classes} = this.props;
    const CURRENTPAGE = this.state.currentPage;
    const CONTENT = this.bodyStructure(CURRENTPAGE);
    const MOBILE_LINKS = this.state.MOBILE_LINKS
    const loginUrl = this.props.hostingVariables.loginBtnUrl;
    let VALID = this.state.loadedData;
    let INACTIVE = this.state.loadInactivity;
    const isOffice = this.state.isOffice;
    const temporal =<div style={{width: '100%', height: '100vh'}}><Loading isOffice={isOffice}/></div>;

    if (VALID){
      return(
        <React.Fragment>
          <HeaderOffice
            changeColorOnScroll={{height: 1}}
            hostingVariables={this.props.hostingVariables}
            links={MOBILE_LINKS}
            scrollRef = {this.myRef}
            fixed
            isOffice
            gotoDashboard={()=>this.changePage("Dashboard")}
            gotoChangeNIP={()=>this.changePage("FormChangeNIP")}
            gotoChangePass={()=>this.changePage("FormChangePassword")}
            userName={this.state.userName}
            closeSession={()=>this.closeSession()}
            color="black"
          />
            {CONTENT}
        </React.Fragment>
            
      )
    } else if (INACTIVE){
      return (
        <React.Fragment>
          <HeaderOffice
            changeColorOnScroll={{height: 1}}
            hostingVariables={this.props.hostingVariables}
            links={MOBILE_LINKS}
            scrollRef = {this.myRef}
            fixed
            isOffice
            gotoDashboard={()=>this.changePage("Dashboard")}
            gotoChangeNIP={()=>this.changePage("FormChangeNIP")}
            gotoChangePass={()=>this.changePage("FormChangePassword")}
            userName={this.state.userName}
            closeSession={()=>this.closeSession()}
            color="black"
            inactivityView={INACTIVE}
          />
          <GridItem xs={12} >
            <GridContainer justify="center" spacing={4} className={classNames(classes.marginSection, classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
              <MessageInactivity loginUrl={loginUrl} />
            </GridContainer>
          </GridItem>
        </React.Fragment>
      )
    } else {
      return temporal;
    }
  }
}
export default withStyles(style)(Office);