import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
//styles
import style from "../../assets/jss/material-kit-pro-react/modalStyle.jsx";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Video from "../Video/index.jsx";

function CustomDialog({ ...props }) {

  const { classes, open, onClose, video } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: classes.modalRoot,
        paper: classes.modalVideo + " "
      }}
    >

      
        <IconButton aria-label="close" className={classes.modalVideoCloseButton} onClick={onClose} >
          <CloseIcon className={classes.modalClose} />
        </IconButton>
        
        <Video modal video={video} />

    </Dialog>
  );
}

export default withStyles(style)(CustomDialog);
