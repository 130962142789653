import React from "react";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
// @material-ui/icons
// core components
import Card from "components/Cards/Card.jsx";
import CardBody from "components/Cards/CardBody.jsx";
// import Altivator from 'utils/imageAltivator';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// images


import cardBibliographyStyle from "./cardBibliographyStyle.jsx";

function CardIcon({ ...props }) {

  const { 
      classes,
      image,
      title,
      description,
      innerBtnTxt,
      btnUrl, } = props;
    return (
        <Card profile plain className={classes.cardBibliography}>
            <div className={classes.image}>
                <a onClick={()=>breadcrumbsUpdate()} href={btnUrl} className={classes.btnSpecial}><FontAwesomeIcon icon={faInfoCircle} />{innerBtnTxt} </a>
                <LazyLoadImage src={image} alt=""/>
            </div>
            <CardBody plain className={classes.contentBody}>
                <h5 className={classes.cardTitle}>{title}</h5>
                <br></br>
                {description && <p className={classes.description}>{description}</p>}
            </CardBody>
        </Card>
      );
}

export default withStyles(cardBibliographyStyle)(CardIcon);
