import {
    container,
    primaryColor,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const styleBanner = theme => ({
    container: {
      ...container,
      height: "100%",
      '&> div': {
          height: "100%",
      }
    },
    contentVideo:{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        '& video': {
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
        }
    },
    layerGray: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    layerDegrade: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,59,114,0.14) 70%, rgba(0,59,114,0.2) 100%)',
        opacity: .5,
    },
    bannerBg: {
        backgroundColor: "#003B72",
        height: "250px",
        borderRadius: 0,
        margin: "0 !important",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    bannerBgOffice: {
        // backgroundColor: "#003B72",
        backgroundColor: "#232323",
        height: "250px",
        borderRadius: 0,
        margin: "0 !important",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    bannerFull: {
        height: "730px",
        [theme.breakpoints.down("xs")]:{
            height: "calc(100vh - 110px)",
        }
    },
    contentTitle: {
        fontFamily: "Lato,sans-serif",
        margin: "2.5rem 0",
        fontWeight: "400",
        '& h1': {
            margin: '8px 0',
            lineHeight: "1em",
            fontSize: "1.8rem",
            color: "white",
            '@media (max-width: 959px)': {
                // fontSize: "1.6rem",
            },
        }
    },
    // description: {
    //     lineHeight: "1.2em",
    //     fontWeight: 400
    // },
    // contentBtn: {
    //     textAlign: "right",
    //     fontWeight: 400,
    // },
    logoSlider: {
        width: '50%',
        display: "block",
        '@media (max-width: 479px)': {
            width: '100%',
        },
    },
    responsiveImg: {
        maxWidth: '100%'
    },
    imgCenter: {
        margin: '0 auto'
    },
    imgRight: {
        marginLeft: 'auto'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    bold: {
        fontWeight: '600 !important'
    },
    blue: {
        color: primaryColor[0] + ' !important',
    },
    // sizeTitle
    small: {
        fontSize: '1.5rem !important',
        lineHeight: '1.4em !important'
    },
    normal: {
        fontSize: '1.86rem !important',
        lineHeight: '1.1em !important'
    },
    big: {
        fontSize: '2.76rem !important',
        lineHeight: '1em !important'
    },
    iconBtn: {
        // maxWidth: '100%',
        width: "1.3rem",
        marginLeft: "4px"
    }
  });
  
  export default styleBanner;
  