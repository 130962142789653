  
  const style = {
    parallax: {
      width: "100%",
      height: "auto",
      // overflow: "hidden",
      position: "relative",
      backgroundPosition: "center",
      backgroundAttachment:"fixed",
      backgroundSize: "cover",
      backgroundRepeat:"no-repeat",
      margin: "0",
      padding: "1rem 0",
      border: "0",
    },
    darkColor: {
      color: "white !important",
      "& h1, h2, a, p":{
        color: "white !important",
      },
      "&:before": {
        background: "rgba(0,0,0, 0.5)"
      },
      "&:after,&:before": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: "''"
      }
    },
  };
  
  export default style;
  