import React from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import buttonStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.jsx";

class RegularButton extends React.Component {
  constructor(props){
    super(props)

    this.state={
      tempClass: true
    }
  }

  componentDidMount(){
    if (this.props.border){
      setTimeout(()=>this.setState({
        tempClass: false
      }), 5000)
    } else {
      this.setState({
        tempClass: false
      })
    }
    
    
  }

render(){
  const {
    classes,
    color,
    outlined,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    fileButton,
    btnTable,
    className,
    border,
    ...rest
  } = this.props;
  const btnClasses = classNames({
    // [classes.tempHover]: false,
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.outlined]: outlined,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.fileButton]: fileButton,
    [classes.btnTable]: btnTable,
    [className]: className
  });

  if (border){
    return (
      <LazyLoadComponent>
        <Button {...rest} className={btnClasses}>
          {children}
        </Button>
      </LazyLoadComponent>
    )
  } else {
    return (
      <Button {...rest} className={btnClasses}>
        {children}
      </Button>
    );
  }
  
}
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["", "sm", "lg", "xs"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  fileButton: PropTypes.bool
};

export default withStyles(buttonStyle)(RegularButton);
