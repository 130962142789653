import { 
  primaryColor,
  title,
} from "assets/jss/material-kit-pro-react";

import {
  box,
  divider,
} from "components/Table/tableStyle";
   
    const DashboardBlockStyle = {
      titleLine:{
        ...title,
        color: '#272727',
        fontWeight: 600,
        textTransform: 'uppercase',
        margin: '0 !important',
        fontSize: "1.87rem !important",
        position: 'relative',
        marginBottom: '1.8rem !important',
        '&:after': {
          content: '" "',
          position: "absolute",
          bottom: -18,
          left: 0,
          width: 45,
          height: 3,
          backgroundColor: primaryColor[0]
        },
      },
      name:{
        color: "#003b72",
        fontWeight: 400,
        fontSize: "1.86rem",
        lineHeight: "1.1em",
        textTransform: "uppercase",
        margin: 0,
        marginBottom: 14,
      },
      titleDate:{
        color: "#272727",
        fontWeight: 400,
        fontSize: "1.1rem",
        lineHeight: "1.3em",
        textTransform: "initial",
        margin: 0,
        marginBottom: 14,
      },
      date:{
        fontFamily: '"Open Sans", sans-serif',
        color: "#727272",
        fontWeight: 400,
        fontSize: ".96rem",
        lineHeight: "1.6em",
        textTransform: "initial",
        margin: 0,
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
      },
      /* begin tableSelectStyles */
      box: {
        ...box,
        paddingBottom: '1.45rem',
        '& button':{
          margin: '0 !important',
          borderRadius: '0 "important',
        }
      },
      divider: {
        ...divider,
        margin: '.5rem 0 !important'
      },
      marginX: {
        marginLeft: "15px !important",
        marginRight: "15px !important"
      },
      cellTitle: {
        fontFamily: `'Lato', sans-serif`,
        fontSize: "1.26rem !important",
        lineHeight: "1.2em !important",
        marginTop: "8px !important",
        marginBottom: "0px !important",
        color: "#003B72",
        fontWeight: "700",
      },
      cellTitleInner: {
        fontFamily: `'Lato', sans-serif`,
        fontSize: "1.1rem !important",
        lineHeight: "1.3em !important",
        marginTop: "11px !important",
        marginBottom: "15px !important",
        color: "#272727",
        fontWeight: "400",
        textAlign: "right",
        marginLeft: "16px",
        "@media (max-width: 1199px)": {
          textAlign: "left",
        },
        "@media (max-width: 599px)": {
          marginTop: "0 !important",
          marginBottom: "0 !important",
        }
      },
      cellMont: {
        marginLeft: '14px',
        fontFamily: `'Lato', sans-serif`,
        fontSize: "1.1rem !important",
        lineHeight: "1.3em !important",
        marginTop: "11px !important",
        marginBottom: "15px !important",
        color: "#272727",
        fontWeight: "700",
        "@media (max-width: 599px)": {
          marginTop: "0 !important",
          marginBottom: "0 !important",
        }
      },
      cellText: {
        fontFamily: `'Lato', sans-serif`,
        fontSize: "1.1rem !important",
        lineHeight: "1.3em !important",
        marginTop: "0px !important",
        marginBottom: "0px !important",
        color: "#272727",
        fontWeight: "400",
        textTransform: "none"
      },

      /* end tableSelectStyles */
    };
    
    export default DashboardBlockStyle;
    