import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";

const badgeStyle = {
  badge: {
    padding: "5px 15px !important",
    margin: "0 10px 10px 0",
    color: "#727272 !important",
    border: "1px solid #e4e4e4",
    fontSize: ".96rem",
    fontWeight: "500",
    lineHeight: "1.6em",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "2px !important",
    transition: "all 0.3s ease-out 0s",
    cursor: "pointer",
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: "white !important",
  },
  primaryTags: {
    backgroundColor: "#fff",
    '&:hover':{
      backgroundColor: primaryColor[0],
      color: "white !important",
      transition: "all 0.3s ease-out 0s"
    }
  },
  warning: {
    backgroundColor: warningColor[0]
  },
  danger: {
    backgroundColor: dangerColor[0]
  },
  success: {
    backgroundColor: successColor[0]
  },
  info: {
    backgroundColor: infoColor[0]
  },
  rose: {
    backgroundColor: roseColor[0]
  },
  gray: {
    backgroundColor: grayColor[7]
  }
};

export default badgeStyle;
