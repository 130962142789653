import {
    primaryColor,
    complementarioColor,
    title,
    cardTitle,
    defaultFont,
    blackColor,
    hexToRgb,
    spaceBetwen
} from "assets/jss/material-kit-pro-react.jsx";

const notiCardStyle = {

    vertical: {
        height: "100%",
        // maxWidth: "367px",
        margin: "0 auto",
        "@media (max-width: 576px)": {
        //   maxWidth: "540px"
        },
    },

    horizontal: {
        display: "flex",
        flexDirection: "row",
        marginTop: "0 !important",
        marginBottom: "30px !important",
        // '& > div:first-child': {
        //     width: 140,
        // },
        '&:last-of-type': {
            marginBottom: "0 !important",
            '& > div:last-child': {
                // paddingTop: 0,
                // paddingBottom: 0,
                // borderBottom: "0 !important",
                '& p': {
                    margin: 0,
                }
            },
        }
    },
    reverse: {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: "0 !important",
        marginBottom: "10px !important",
        '& > div:first-child': {
            // width: 140,
            '& img': {
                margin: "0 0 0 10px"
            }
        },
        '& > div:last-child': {
            paddingTop: 0,
            paddingBottom: 0,
            // borderBottom: "1px solid #e4e4e4",
            width: "20%",
        },
        '&:last-of-type': {
            marginBottom: "0 !important",
            '& > div:last-child': {
                paddingTop: 0,
                paddingBottom: 0,
                borderBottom: "0",
                '& p': {
                    margin: 0,
                }
            },
        }
    },
    imagen: {
        width: "100%",
        height: 160,
        // marginTop: "24px",
        objectFit: "cover",
        borderRadius: "0 !important",
        boxShadow: "none !important"
    },
    imagenLeft: {
        width: "130px !important",
        height: "100px",
        objectFit: "cover",
        borderRadius: "0 !important",
        marginRight: 10,
        "@media (max-width: 768px)": {
            display: 'none',
        },
    },
    miniImage: {
        width: "105px !important",
        height: "75px !important",
        objectFit: "contain",
        backgroundColor: "#f7f7f7",
        borderRadius: "0 !important",
        marginRight: 10,
    },
    largeImage: {
        width: "280px !important",
        height: "150px !important",
        objectFit: "cover",
        borderRadius: "0 !important",
        marginRight: 10,
        boxShadow: "none !important",
    },
    imageNotiCardFull: {
        width: "315px !important",
        height: "215px !important",
        objectFit: "cover",
        borderRadius: "0 !important",
        marginRight: 20,
    },
    analistImage: {
        width: "75px !important",
        height: "90px",
        objectFit: "cover",
        borderRadius: "0 !important",
        marginRight: 10,
    },
    contentImage: {
        objectFit: "contain !important",
    },
    // SizeTitle
    sm: {
        ...cardTitle,
        color: primaryColor[0] + "  !important",
        margin: 0 + " !important",
        width: "100%",
        fontSize: "14px !important",
        lineHeight: "18px",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
    },
    lg: {
        ...cardTitle,
        color: primaryColor[0] + "  !important",
        margin: 0 + " !important",
        width: "100%",
        fontSize: "1.3rem !important",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 599px)': {
            fontSize: "1.15rem !important",
        },
    },
    // WrapTitle
    2: {
        overflow: "hidden",
        whiteSpace: "initial !important",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        lineHeight: "26px",
        minHeight: "54px !important"
    },
    // 3 line title notiCard Home
    3: {
        overflow: "hidden",
        whiteSpace: "initial !important",
        display: "-webkit-box",
        WebkitLineClamp: "3 !important",
        WebkitBoxOrient: "vertical",
        // lineHeight: "90px",
        minHeight: "6.2rem !important"
    },
    lineThree: {
        overflow: "hidden",
        whiteSpace: "initial !important",
        display: "-webkit-box",
        WebkitLineClamp: "3 !important",
        WebkitBoxOrient: "vertical",
        lineHeight: "22px",
        minHeight: "66px !important"
    },
    4: {
        overflow: "hidden",
        whiteSpace: "initial !important",
        display: "-webkit-box",
        WebkitLineClamp: "4 !important",
        WebkitBoxOrient: "vertical",
        lineHeight: "22px",
        minHeight: "66px !important"
    },
    // CH 3/DIC/2019 add full
    full: {
        overflow: "inherit !important",
        display: "inline !important",
        whiteSpace: "inherit",
    },
    informeDate: {
        display: "inline-block",
        marginRight: 2,
        marginBottom: ".5rem",
        '&:after': {
            content: '" "',
            position: "absolute",
            width: 30,
            height: 2,
            backgroundColor: complementarioColor
        },
        '& h4': {
            margin: 0,
            textTransform: "inherit",
            fontSize: ".70rem",
            fontWeight: "700"
        },
    },
    noPaddingX:{
        paddingLeft: "0 !important",
        paddingRight: "0 !important"
    },
    empresa: {
        display: "inline-block",
        marginRight: 2,
        marginBottom: ".5rem",
        '&:after': {
            content: '" "',
            position: "absolute",
            width: 50,
            height: 3,
            backgroundColor: complementarioColor
        },
        '& h4': {
            margin: 0,
            fontSize: "1.2rem",
            textTransform: "capitalize"
        },
    },
    descriptionComplete: {
        marginTop: 5,
        color: "rgba(" + hexToRgb(blackColor) + ", 0.76)",
        lineHeight: "18px",
        // CH 14/NOV/2019 Se justifica texto
        textAlign: "justify",
        '& a':{
          color: "#364A92",
          '&:hover':{
            color: "#4bbef8"
          },
          '&:active':{
            color: "#4bbef8"
          }
        },
    },
    description: {
        ...defaultFont,
        marginTop: 5,
        // overflow: "hidden",
        // display: "-webkit-box",
        // WebkitLineClamp: 4,
        // WebkitBoxOrient: "vertical",
        lineHeight: "1.65em !important",
        // minHeight: "6.6rem"
    },
    descriptionWrap3: {
        marginTop: 5,
        color: "rgba(" + hexToRgb(blackColor) + ", 0.76)",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        lineHeight: "18px",
        // minHeight: "72px"
    },
    descriptionLeft: {
        ...defaultFont,
        lineHeight: "1.65em !important",
        marginTop: 5,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        maxHeight: "3rem"
    },
    descriptionHeightCarrousel: {
        marginBottom: "0 !important",
        // minHeight: "18rem",

        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        '& p':{
            marginBottom: "0 !important",
        },
        // "@media (max-width: 1199px)": {
        //     minHeight: "20.3rem",
        // },
        // "@media (max-width: 991px)": {
        //     minHeight: "21.5rem",
        // },
        // "@media (max-width: 960px)": {
        //     minHeight: "16rem",
        // },
        // "@media (max-width: 799px)": {
        //     minHeight: "17rem",
        // },
        // "@media (max-width: 734px)": {
        //     minHeight: "19rem",
        // },
        // "@media (max-width: 688px)": {
        //     minHeight: "21rem",
        // },
        // "@media (max-width: 639px)": {
        //     minHeight: "25.2rem",
        // },
        // "@media (max-width: 575px)": {
        //     minHeight: "12rem",
        // },
        // "@media (max-width: 490px)": {
        //     minHeight: "16rem",
        // },
        // "@media (max-width: 390px)": {
        //     minHeight: "20rem",
        // },
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    icons: {
        fontSize: "10px",
        fill: primaryColor[0] + ' !important',
        marginRight: 3
    },
    spaceBetwen: {
        ...spaceBetwen
    },
    linkIcon: {
        color: primaryColor[0],
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        fontSize: "80%",
        '& svg': {
            fontSize: "17px"
        }
    },
    w100: {
        width: "100%"
    },
    contentBtn: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    btnRedes: {
        width: "30px",
        height: "30px",
        paddingLeft: "3px",
        paddingRight: "3px",
        margin: 0,
        borderRadius: "0 !important",
        boxShadow: "none !important",
        '& i': {
            fontSize: "16px",
            lineHeight: "30px",
            marginRight: "0 !important",
        }
    },
    paddingXMin: {
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    participacion: {
        ...cardTitle,
        color: primaryColor[0] + " !important",
        fontSize: "1.4rem !important",
        marginTop: "0 !important",
        '& span': {
            fontSize: "1.6rem !important",
        }
    },
    web: {
        color: "black",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 500,
        '&:hover': {
            color: complementarioColor
        }
    },
    iconRight: {
        '& svg': {
            margin: '0 -4px 0 4px'
        }
    },
    alignLink: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tag: {
        display: "inline-block",
        border: "1px solid",
        borderColor: complementarioColor,
        borderRadius: "1rem",
        padding: "0 8px",
        fontSize: "0.7rem",
        lineHeight: "1.2rem",
        color: complementarioColor,
        marginRight: "3px",
        marginBottom: "5px"
    },
    line: {
        borderBottom: "1px solid #e4e4e4",
        borderRadius: "0 !important"
    },
    // SingleArticleCarousel
    imageSingle: {
        width: "100%",
        height: 210,
        objectFit: "cover",
        borderRadius: "0 !important",
        boxShadow: "none !important",
        "@media (max-width: 620px)": {
            height: 210,
        },
    },
    spaceSettings: {
        width: "100%",
        height: "56px"
    },
    descriptionSingle: {
        marginTop: 5,
        color: "rgba(" + hexToRgb(blackColor) + ", 0.76)",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 7,
        WebkitBoxOrient: "vertical",
        lineHeight: "18px",
        minHeight: "126px",
        '& a':{
          color: "#364A92",
          '&:hover':{
            color: "#4bbef8"
          },
          '&:active':{
            color: "#4bbef8"
          }
        },
    },
    resetPadding: {
        padding: "0 1rem !important"
    },
    contentPlay: {
        cursor: "pointer",
        position: "relative",
        '&:hover $play': {
            width: "82px !important",
            transition: "all 0.3s ease-out 0s",
        }
    },
    play: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "75px !important",
        paddingRight: "10px",
        transform: "translate(-50%, -50%)",
        transition: "all 0.3s ease-out 0s",
        "@media (max-width: 768px)": {
            display: 'none',
        },
    },

    cardBody:{
        padding: "2.5rem"
    },
    
    cardTitle: {
        ...title,
        margin: 0 + " !important",
        fontSize: "1.5rem !important",
        fontWeight: "400 !important",
        lineHeight: "1.35em !important",
        marginBottom: "5px !important",
        color: "#272727",
        // overflow: "hidden",
        // whiteSpace: "initial !important",
        // display: "-webkit-box",
        // WebkitLineClamp: "3 !important",
        // WebkitBoxOrient: "vertical",
        // minHeight: "6rem !important",
        '&:hover':{
            color: "#5a5959",
        },
        '& a':{
            color: "inherit"
        }
    },
    autor: {
        ...defaultFont,
        margin: "0 0 1.5rem 0 !important",
        color: primaryColor[0],
        fontSize: ".9rem !important",
        lineHeight: "1.65rem !important",
        '& svg': {
            fontSize: ".8rem"
        }
    },
    flexContentBlueText: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0',
        margin: "0 0 1.5rem 0 !important",
        '& p':{
            ...defaultFont,
            margin: "0 1rem 0 0 !important",
            color: primaryColor[0],
            fontSize: ".9rem !important",
        },
        '& span':{
            color: "#727272",
            marginRight: "8px",
            fontSize: "1.1rem"
        }
    },
    contentTextBtn:{
        display: 'flex',
    },
    marginBtnTopWeb:{
        marginTop: "2rem",
        "@media (max-width: 959px)": {
            marginTop: '0',
        },
    }
};

export default notiCardStyle;
