import {
  box,
  divider,
  tableTitle,
  title,
  text,
} from "components/Table/tableStyle"

import {
  inputBootstrap,
  inputGroup,
  contentIcon,
} from "components/CustomInput/style"

import { primaryColor } from "assets/jss/material-kit-pro-react";

  const tableGeneralStyle = {
    box: {
      ...box,
    },
    divider: {
      ...divider
    },
    tableTitle: {
      ...tableTitle,
      color: "#003B72"
    },
    title: {
      ...title,
    },
    titleBlue: {
      ...title,
      color: "#136EC3"
    },
    text: {
      ...text
    },

    titleLine: {
      lineHeight: "1.05em",
      fontFamily: `'Lato', sans-serif`,
      color: "#003B72",
      fontWeight: 600,
      margin: '0 !important',
      fontSize: "1.46rem !important",
      position: 'relative',
      textTransform: 'uppercase',
      marginBottom: '1rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    },

    // TableActualPosition and Movements
    cellTitle: {
      ...title,
      lineHeight: "1.1rem !important",
    },
    cellText: {
      ...text,
      lineHeight: "1.1rem !important",
    },
    sizeLgMin:{
      fontSize: "12px",
      // "@media (min-width: 1280px)": {
      //   fontSize: "11px",
      // },
    },
    mdRight: {
      "@media (max-width: 1279px)": {
        textAlign: "right",
      },
      "@media (max-width: 999px)": {
        textAlign: "left",
      },
      "@media (max-width: 639px)": {
        textAlign: "right",
      },
    },
    textRight: {
      textAlign: "right"
    },

    animateContent:{
      height: "100px",
      overflow: "hidden",
      transition: "height 2s",
    },
    animateContentActive:{
      transition: "height 2s",
      height: "300px",
    },
    
    
    // TableInvestmentFunds
    textCenter: {
      textAlign: "center"
    },
    titleBig: {
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.26rem !important",
      lineHeight: "1.2em !important",
      marginTop: "10px !important",
      marginBottom: "10px !important",
      color: "#272727",
      fontWeight: "400",
    },

    logoInvestment: {
      width: "100%",
      margin: 0,
      "@media (max-width: 599px)": {
        width: "50%",
      },
    },

    // TableInfoResult
    titleRow:{
      ...title,
      fontSize: "14px",
      lineHeight: "17px",
      height: "auto"
    },
    textRow:{
      ...text,
      fontSize: "14px",
      lineHeight: "17px",
      height: "auto"
    },

    actionBtn:{
      cursor: "pointer",
      padding: "10px 16px",
      color: "#7c7c7c",
      backgroundColor: "#ffffff",
      border: "1px solid #BEBEBE",
      borderRadius: "2px",
      margin: "-5px 0 0 0",
      float: "right",
      transition: "all 0.3s ease-out 0s",
      display: "inline-block",
      fontSize: ".96rem",
      fontWeight: 400,
      textAlign: "center",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
      "&:hover":{
        color: "#136ec3",
        backgroundColor: "#ffffff",
        border: "1px solid #136ec3",
        transition: "all 0.3s ease-out 0s",
      }
    },

    inputBootstrap: {
      ...inputBootstrap,
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    inputGroup:{
        ...inputGroup
    },
    contentIcon:{
        ...contentIcon,
        borderTopLeftRadius: "5px ",
        borderBottomLeftRadius: "5px",
    },
    label:{
      color: "#727272",
      fontSize: ".96rem",
      lineHeight: "1.3em",
      fontWeight: 400,
      margin: "0 0 10px",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
    },
    pl:{
      paddingLeft: "15px"
    }
  };
  
  export default tableGeneralStyle;
  