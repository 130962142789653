import { container, primaryColor, defaultFont } from "assets/jss/material-kit-pro-react";

  
  const style = {
    wrapper: {
      width: "100%",
      padding: "80px 0",
    },
    bgGray: {
      backgroundColor: "#f6f6f6",
    },
    container:{
      ...container,
    },
    description: {
        ...defaultFont,
        fontSize: "1.1rem !important",
        marginBottom: "2.5rem",
        lineHeight: "1.4em !important",
        textAlign: "center",
        width: "80%",
        margin: "0 auto 70px auto"
    },
    box: {
      position: 'relative',
      margin: '0 auto',
      textAlign: 'center',
      '&:hover':{
        '& $iconBox': {
          backgroundColor: primaryColor[0],
          transition: 'all 0.3s ease-out 0s',
        },
        '& $digit': {
          backgroundColor: '#ffffff',
          color: primaryColor[0],
          transition: 'all 0.3s ease-out 0s',
        }
      },
      '& h4': {
        fontSize: '1.5rem',
        lineHeight: '1.4em',
        marginTop: '20px',
        marginBottom: '18px',
        color: '#272727',
        fontWeight: 400,
      }
    },
    iconBox: {
      position: 'relative',
      width: 135,
      height: 135,
      lineHeight: 135,
      borderRadius: '100%',
      backgroundColor: 'white',
      margin: '0 auto',
      transition: 'all 0.3s ease-out 0s',
      '& img': {
        display: 'block'
      }
    },
    digit: {
      position: 'absolute',
      zIndex: 2,
      color: '#fff',
      left: 50,
      top: 115,
      width: 32,
      height: 32,
      fontWeight: 400,
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '32px',
      borderRadius: '100%',
      backgroundColor: primaryColor[0],
      transition: 'all 0.3s ease-out 0s'
    }
  };
  
  export default style;
  