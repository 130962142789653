import React from "react";
import { createBrowserHistory } from "history";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import badgeStyle from "assets/jss/material-kit-pro-react/components/badgeStyle.jsx";

function Badge({ ...props }) {
  const { classes, color, children, className, searchHost, searchUrl } = props;
  var hist=createBrowserHistory();
  const badgeClasses = classNames({
    [classes.badge]: true,
    [classes[color]]: true,
    [className]: className !== undefined
  });

  var BADGE = {}
  if ( searchUrl && searchHost) {
    BADGE = <span className={badgeClasses} type='button' style={{cursor: 'pointer'}} onClick={function() {
      hist.push(searchUrl);
      window.location.assign(searchHost+hist.location.search);
    }}>{children}</span>
  } else {
    BADGE = <span className={badgeClasses}>{children}</span>
  }
  return BADGE;
}

Badge.defaultProps = {
  color: "gray"
};

Badge.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "primaryTags",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  className: PropTypes.string
};

export default withStyles(badgeStyle)(Badge);
