import React from "react";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// core components
import Title from "components/Title";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardBibliography from "components/Cards/CardBibliography"

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class CarouselCardBibliography extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      show: -1,
  }
  }

  CarouselCardBibliographyStructure = (data,key) => {
    return( <CardBibliography plain {...data} innerBtnTxt={this.props.data.innerBtnTxt} key={key} />)
  }

  componentDidMount(){
    if (this.props.data && this.props.data.data){
      this.setState({show: this.props.data.data.length})
    }
    
  }
  render() {
    var cards=[]

    if (this.props.data && this.props.data.data){
      this.props.data.data.sort((card1, card2) => card1.id - card2.id)
      .map((item,key)=>{
        cards.push(this.CarouselCardBibliographyStructure(item,key));
        return null;
          })
    }
    const { title, description, mainBtnTxt, mainBtnUrl } = this.props.data
    // const slidesToShowNum = isHome ? 4 : 3;
    const settings = {
      dots: true,
      infinite: this.state.show >4 ? true : false,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      centerMode: false,
      cssEase: "linear",
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              infinite: this.state.show >3 ? true : false,
            }
          },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            infinite: this.state.show >2 ? true : false,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1, 
            inifinite: this.state.show >1 ? true : false,
          }
        }
      ]
    };
    return (
      <GridItem xs={12} style={{paddingTop: "28px"}}>
        <GridContainer spacing={2}>
          { title && <Title large title={title} alignment="center" description={description} />}
          <GridItem xs={12} style={{paddingTop: "15px"}}>
            <div id="carousel">
              <Carousel {...settings} className="espaciadoX2 dotsDown"> 
                {cards} 
                {/* <CardBibliography
                  title="Ernesto O´Farril Santoscoy"
                  description="Presidente del Consejo de Administración y Socio Fundador del Grupo Bursamétrica"
                  urlBtn="#"
                  image={ernesto}
                />
                <CardBibliography
                  title="Raúl Garduño Vergara"
                  description="Director General de la Casa de Bolsa"
                  urlBtn="#"
                  image={raul}
                />
                <CardBibliography
                  title="Luis Villaseñor"
                  description="Director General Adjunto de la Casa de Bolsa"
                  urlBtn="#"
                  image={luis}
                />
                <CardBibliography
                  title="Sandra Alatorre Esquivel"
                  description="Directora de Promoción"
                  urlBtn="#"
                  image={sandra}
                />
                <CardBibliography
                  title="Sandra Alatorre Esquivel"
                  description="Directora de Promoción"
                  urlBtn="#"
                  image={sandra}
                />*/}
              </Carousel>
            </div>
          { mainBtnTxt &&
            <div style={{textAlign:'center'}}>
              <Button onClick={()=>breadcrumbsUpdate()} disableRipple  size="" border color="borderSecondary" href={mainBtnUrl} >
                {mainBtnTxt}
              </Button>
            </div>
          }
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }
}

export default withStyles(style)(CarouselCardBibliography);

