import React from "react";
import PropTypes from 'prop-types'
// react component for creating beautiful carousel
import Carousel from "react-slick";
// core components
import Title from "components/Title";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import CardTimeLine from "components/Cards/CardTimeLine"

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

function SampleNextArrow(props) {
  const { onClick, nextBtnTxt } = props;
  return (
    <Button
      className="nav-next1"
      onClick={onClick}
      border
      color="borderSecondary"
    >
      {nextBtnTxt} <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  );
}
SampleNextArrow.propTypes = {
  onClick: PropTypes.func,
  nextBtnTxt: PropTypes.string
};
SampleNextArrow.defaultProps = {
  onClick: undefined,
};
function SamplePrevArrow(props) {
  const { onClick, previousBtnTxt } = props;
  return (
    <Button
      className="nav-prev1"
      onClick={onClick}
      border
      color="borderSecondary"
    >
      <FontAwesomeIcon icon={faArrowLeft} /> {previousBtnTxt}
    </Button>
  );
}
SamplePrevArrow.propTypes = {
  onClick: PropTypes.func,
  previousBtnTxt: PropTypes.string
};
SamplePrevArrow.defaultProps = {
  onClick: undefined,
};

class CarouselTimeLine extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      show: -1,
  }
  }

  CarouselTimeLineStructure = (data,key) => {
    return( <CardTimeLine {...data} key={key} />)
  }

  render() {
    if (!this.props.timeLineContent) return <></>;
    const {
      title, 
      description, 
      previousBtnTxt, 
      nextBtnTxt, 
      timeLineContent,
      classes
    } = this.props;

    var cards=[]

    timeLineContent.sort((card1, card2) => card1.id - card2.id)
    .map((item,key)=>{
      cards.push(this.CarouselTimeLineStructure(item,key));
      return null;
    })

    
    // const slidesToShowNum = isHome ? 4 : 3;
    const settings = {
      dots: false,
      // infinite: this.state.show >4 ? true : false,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      centerMode: false,
      nextArrow: <SampleNextArrow estilos={classes} nextBtnTxt={nextBtnTxt} />,
      prevArrow: <SamplePrevArrow estilos={classes} previousBtnTxt={previousBtnTxt} />,
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              // infinite: this.state.show >3 ? true : false,
              infinite: true,
            }
          },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            // infinite: this.state.show >2 ? true : false,
              infinite: true,
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1, 
            // inifinite: this.state.show >1 ? true : false,
              infinite: true,
          }
        }
      ]
    };
    return (
      <React.Fragment>
        { title && <Title large title={title} alignment="center" description={description} />}
        <GridItem xs={12}>
          <div id="carousel">
            <Carousel {...settings} className="espaciadoX2 navButton"> 
              {cards}
            </Carousel>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(CarouselTimeLine);

