import React from "react";
// react component for creating beautiful carousel
// @material-ui/core components 
// import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";

// import style from "../style.jsx";
import Loading from "components/Loader/Loading.jsx";


class IViewer extends React.Component {
  constructor(props){
    super(props);
    this.state={
      displayIframe: 'visible',
      loading: true,
      iframeRef: null,
      containerHeight: "",
      updateCounter: 0,
    }
    //this.hideSpinner = this.hideSpinner.bind(this);
  }

  updateContainerHeight=()=>{
    var iFrameID = document.getElementById('iframeBottom');
      if(iFrameID && iFrameID.contentWindow.document.body) {
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
      if (this.state.containerHeight !== iFrameID.height && iFrameID.height !== "0px"){
        this.setState({containerHeight: iFrameID.height})
      }
    }
    
  }
  
  componentDidMount() {
    // window.addEventListener('iframe', this.hideSpinner)
    window.addEventListener('resize', this.updateContainerHeight);
    window.addEventListener('scroll', this.updateContainerHeight);
    this.setState({
      displayIframe: 'hidden',
    });
  }

  componentWillUnmount(){
    if (this.state.timer) {clearInterval(this.state.timer)}
    this.setState({displayIframe: 'hidden'});
    window.removeEventListener('resize', this.updateContainerHeight);
  }

  hideSpinner = () => {
      this.setState({loading: false, displayIframe: 'visible' });
      this.updateContainerHeight();
  }

  render() {
    this.updateContainerHeight();
    const { urlIframe } = this.props.data;
    let displayIt = 'block'
    return (
        <GridItem xs={12}>
          {displayIt === 'block' && this.state.loading ? <Loading /> : null} 
          {urlIframe && <div style={{ overflow: 'hidden', visibility: this.state.displayIframe, display: displayIt, width: "100%", height: this.state.containerHeight ? this.state.containerHeight : "100px", backgroundColor: "#f6f6f6" }}>
            <iframe title="iframeBottom" onLoad={this.hideSpinner} id="iframeBottom" width="100%" height= "100%" src={urlIframe} frameBorder="0" scrolling="no" />
          </div>}
        </GridItem>
    );
  }
}

// export default withStyles(style)(IViewer);
export default IViewer;

