import React from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// core components
import Title from "components/Title";
import GridItem from "components/Grid/GridItem.jsx";

import CardIcon from "components/Cards/CardIcon"

// images 

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

class ListCardIcon extends React.Component {

  CarouselCardIconStructure = (data,key) => {
    return( 
      <GridItem xs={12} sm={4} md={4} key={'CarouselIcon-'+Math.floor(Math.random()*901)}>
        <CardIcon plain {...data} key={key} />
      </GridItem>
    )
  }

  render() {
    var CARDS=[]

    if (this.props.data && this.props.data.data){
      this.props.data.data.sort((card1, card2) => card1.id - card2.id)
      .map((item,key)=>{
        CARDS.push(this.CarouselCardIconStructure(item,key));
        return null;
      })
    }
    const { title, description } = this.props.data;
    return (
      <MuiThemeProvider theme={theme}>
        { title && <Title large title={title} alignment="center" description={description} />}
        {CARDS}
      </MuiThemeProvider>
    );
  }
}

export default withStyles(style)(ListCardIcon);

