  
  const videoStyle = {
    videoModal:{
        height: "490px !important",
        "@media (max-width: 959px)":{
            height: "450px !important",
        },
        "@media (max-width: 859px)":{
            height: "400px !important",
        },
        "@media (max-width: 599px)":{
            height: "290px !important",
        },
        "@media (max-width: 425px)":{
            height: "250px !important",
        },
    },
  };
  
  export default videoStyle;
  