import React from "react";
import htmlText from 'utils/htmlFormattedText';
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
// @material-ui/icons
// core components
import Card from "components/Cards/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
// images


import cardStrategyStyle from "./cardStrategyStyle.jsx";

function CardStrategy({ ...props }) {
    

  const { 
      classes,
      image,
      title,
      description,
      reverse,
      btnUrl,
      btnTxt} = props;
    return (
        <Card profile border className={classes.cardStrategy}>
            {image && <div className={classNames(classes.boxIcon, reverse && classes.boxIconReverse)}>
                <img src={image} alt="" title="" />
            </div>}
            <div className={classNames(classes.boxText, reverse && classes.boxTextReverse)}>
                <h4 className={classes.cardTitle}>{title}</h4>
                <p className={classes.description}>{htmlText(description)} </p>
                {(btnUrl && btnTxt) && <Button disableRipple  size="" border color="borderSecondary" href={btnUrl} target='_blank' rel="noopener noreferrer" >
                {btnTxt}
                </Button>}
            </div>
        </Card>
      );
}

export default withStyles(cardStrategyStyle)(CardStrategy);
