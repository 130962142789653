import React from "react";
// import moment from 'moment';
import 'moment/min/locales' 
// core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./DashboardBlockStyle";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
// import logo from "assets/img/svg/logo_toro_bursametrica.svg"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
// import { faSortDown } from "@fortawesome/free-solid-svg-icons";

/* import TableSelect from "./TableSelect";
import TableSummary from "./TableSummary"; */

class DashboardBlock extends React.Component {
  constructor(props){
    super(props);

    this.state={
      selectedContract: "",
      lastCheckDate: 0,
      resMoney:"$000,000,000.00",
      resCapital:"$000,000,000.00",
      resInvestment:"$000,000,000.00",
      resCash:"$000,000,000.00",
      resTotal:"$000,000,000.00",
    }
  }

  setInitialCheckDate=(contract)=>{
    if (contract !== this.state.selectedContract){
      let nextDate = new Date().getTime();
      this.setState({
        selectedContract: contract,
        lastCheckDate: nextDate
      })
    }
  }

  setLastCheckDate=(contract)=>{
    if (contract !== this.state.selectedContract){
      let nextDate = new Date().getTime();
      this.setState({
        selectedContract: contract,
        lastCheckDate: nextDate
      })
      this.props.changeData(contract)
    }
  }

  

  summaryStructure=()=>{
    const { classes, resultsData } = this.props;
    const {resMoney, resCapital, resInvestment, resCash, resTotal } = resultsData;

    return (
      <GridItem sm={6} md={5}>
              <GridContainer spacing={4}>
                {/* <TableSummary /> */}
                <GridItem xs={12}>
                  <div className={classes.box}>
                    <GridContainer spacing={2} style={{margin:"-8px"}}>
                      <GridItem xs={12} className="pb0">
                        <h5 className={classNames(classes.cellTitle, classes.marginX)}>RESUMEN SALDO</h5>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classNames(classes.divider, classes.marginX)}></div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellTitleInner}>MERCADO DE DINERO</h6>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellMont}>{resMoney} </h6>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.divider}></div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellTitleInner}>MERCADO DE CAPITALES</h6>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellMont}>{resCapital} </h6>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.divider}></div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellTitleInner}>FONDOS DE INVERSIÓN</h6>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellMont}>{resInvestment} </h6>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.divider}></div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellTitleInner}>EFECTIVO</h6>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellMont}>{resCash} </h6>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.divider}></div>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellTitleInner}>TOTAL INVERSIÓN</h6>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <h6 className={classes.cellMont}> {resTotal} </h6>
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
    )
  }

  dropdownListStructure=()=>{
    const { classes, contratos, contrato } = this.props;
    if (contratos) {
        return contratos.sort((item1, item2)=>item1 - item2).map((item, key)=>
        <Button 
          disableRipple
          className={classes.userPopover} 
          color="transparent" 
          key={key}
          onClick={()=>this.setLastCheckDate(item)}
        >
          {item}
        </Button>
      )  
    } else if (contrato)  {
      return [<Button 
        disableRipple
        className={classes.userPopover} 
        color="transparent"
        onClick={()=>this.setLastCheckDate(contrato)}
      >
        {contrato}
      </Button>]
    } else {return []}
  }

  componentDidMount(){
    const {activeContract} = this.props.resultsData.activeContract
    this.setState({ selectedContract: activeContract }, this.setInitialCheckDate(activeContract))
  }
  
  render() {
    const { 
      classes, 
      data, 
      userName, 
      loginDate 
    } = this.props;
    const {lastCheckDate} = this.state;
    const {logoImgUrl, title, contractSelectTitle } = data;
    const {activeContract} = this.props.resultsData;
    const DROPLISTCONTRACTS = this.dropdownListStructure();
    const SUMMARYSTRUCTURE = this.summaryStructure();
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formatedLoginDate = new Date(loginDate).toLocaleString('es-MX', dateOptions)
    const formatedLoginTime = new Date(loginDate).toLocaleTimeString('es-MX') // 1596129643170
    const formatedLastCheckDate = new Date(lastCheckDate).toLocaleString('es-MX', dateOptions)
    const formatedLastCheckTime = new Date(lastCheckDate).toLocaleTimeString('es-MX')
    /* moment.locale('es') 
    console.log('date: ', moment(1596259643170).format("dddd, MMMM DD YYYY, hh:mm:ss")) */

    return (
      <React.Fragment>
        <GridItem xs={12}>
          <GridContainer spacing={4} alignItems="center">
            <GridItem xs={3} sm={2}>
              <img className="responsiveImage" style={{maxHeight:"253px"}} src={logoImgUrl} alt="" title="" />
            </GridItem>
            <GridItem xs={9} sm={10}>
              <GridContainer spacing={2} style={{margin:"-8px"}}>
                {/* <Title title="¡BIENVENIDA!" line/> */}
                <GridItem xs={12}>
                {title && <h3 className={classes.titleLine}>{title} </h3>}
                </GridItem>
                <GridItem xs={12}>
                  <h3 className={classes.name}>{userName}</h3>
                </GridItem>
                <GridItem sm={5}>
                  <h6 className={classes.titleDate}>
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    {" "}Fecha y Hora de Consulta
                  </h6>
                  <p className={classes.date} style={{textTransform: 'capitalize' }}>{formatedLoginDate} | {formatedLoginTime}</p>
                </GridItem>
                <GridItem sm={5}>
                  <h6 className={classes.titleDate}>
                    <i className="fa fa-calendar-o" aria-hidden="true"></i>
                    {" "}Fecha y Hora de Última Consulta
                  </h6>
                  <p className={classes.date} style={{textTransform: 'capitalize' }}>{formatedLastCheckDate} | {formatedLastCheckTime} </p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12}>
          <GridContainer spacing={4} justify="flex-end">
            <GridItem sm={6} md={5}>
              <GridContainer spacing={4}>
                {/* <TableSelect /> */}
                <GridItem xs={12}>
                  <div className={classes.box}>
                    <GridContainer spacing={2} style={{margin:"-8px"}}>
                      <GridItem xs={12} className="pb0">
                        {contractSelectTitle && <h5 className={classNames(classes.cellTitle, classes.marginX)}>{contractSelectTitle}</h5>}
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classNames(classes.divider, classes.marginX)}></div>
                      </GridItem>
                      <GridItem xs={12}>
                        <List style={{padding: 0}} className={classes.marginX}>
                          <ListItem key={1+'ListItem'+Math.floor(Math.random()*901)} style={{padding: 0}}>
                            <CustomDropdown
                              noLiPadding
                              navDropdown
                              hoverColor={"transparent"}
                              buttonText={activeContract ? activeContract : "SELECCIONA CONTRATO(S)"}
                              buttonProps={{
                                className: classes.dropdown,
                                color: "primaryPrimary"
                              }}
                              dropdownList={ DROPLISTCONTRACTS }
                            />
                          </ListItem>
                        </List>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.divider}></div>
                      </GridItem>
                      
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

            {SUMMARYSTRUCTURE}

          </GridContainer>
        </GridItem>
        
      </React.Fragment>
    );
  }
}
export default withStyles(style)(DashboardBlock);