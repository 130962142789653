import React from "react";
import * as axios from 'axios';
import CaptchaComp from 'utils/CaptchaComp';
import AlertPop from './AlertPop';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Cards/Card.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
// sections for this page
import formStyle from "./formStyle.jsx"

const nipRef = React.createRef();
const nipNuevoRef = React.createRef();
const confirmNipRef = React.createRef();
const gCaptchaResponseRef = React.createRef();

class FormChangeNIP extends React.Component {
  constructor(props){
    super(props);

    this.state={
      publicKey: "",
      buttonDisabled: false,
      openAlerts: {
        status:false, 
        anchorEl: null, 
        content: "",
      },
      values:{
        contrato:"",
        nip:" ",
        nipNuevo: "",
      },
      tempSession: {
        changePassReq: "",
        changeNipReq: "",
        email: "",
        token: "",
        contrato: "",
        customerNum: "",
        userName: "",
        contratos: "",
        phoneTester: ""
      },
      confirmNip: ""
    }
  }

  scrollToId = (idName) => {
    if (idName){
      if (idName.current) {
        // this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
        idName.current.scrollIntoView({ behavior: 'smooth'})
      }
    }
  }

  openPopAlert = (name, content) => {
    const toggleStatus = !this.state.openAlerts.status
    const anchorEl = document.getElementById(name);
    // const styledContent = <div style={{backgroundColor: "orange"}}><h4>{content}</h4></div>
    this.setState({openAlerts: {status: toggleStatus, anchorEl: anchorEl, content: content}})
  };

  closeAlerts = () => {
    this.setState({openAlerts: {status: false, anchorEl: null, content: ""}})
  }

  saveSession=()=>{  
    const data= {
      ...this.state.tempSession,
      "changeNipReq": false,
    }
    sessionStorage.setItem('BurSes-13axWl87T3Pz',JSON.stringify( data ));
   }

  getCaptchaKey = async () => {
    let publicKey = this.state.publicKey;
    const captchaUrl=this.props.data.captchaUrl;
    if (!publicKey || publicKey === ''){
      await axios.get(captchaUrl)
      .then(resp => {
        publicKey = resp.data.data.value;
      })
      .catch(error =>{
        console.log('Error getCaptchaKey: ',error)
      });
      this.setState({publicKey: publicKey})
      return publicKey
    }
  }
  
  verifyCallback = async (token) => {
    const captchaEndpoint = this.props.data.captchaEndpoint;
    const groupId = this.props.groupId;
    await axios.post(
      captchaEndpoint,
      {
        "groupId": groupId,
        "gCaptchaResponse": token
      }
    ).then(resp => {
      if (resp.status === 200){
        if (resp.data.code === 200 || resp.data.code === "200"){
          this.setState({values: {...this.state.values,'gCaptchaResponse':token}, buttonDisabled: false});
        }
      }
    }).catch(error =>{
      console.log(error)
    }); 
    return;
  }

  sendForm = async () => {
    this.setState({buttonDisabled: true})
    const {changeNipReq, data } = this.props;
    const { currentLabel, newLabel, confirmLabel, endpoint } = data;
    const values = this.state.values;
    const confirmNip = this.state.confirmNip;
    const invalidNumbers = /^$|^(01234|12345|23456|34567|45678|56789|67890|78901|89012|90123)+$/ig
    const invalidNumbersRev = /^$|^(98765|87654|76543|65432|54321|43210|32109|21098|10987|09876)+$/ig

    if (!values.nip && !changeNipReq) {
      nipRef.current.focus()
      this.openPopAlert("nip", "el campo "+currentLabel+" es requerido.")
      this.setState({buttonDisabled: false})
      return;
    } else if (values.nip.length !==5 && !changeNipReq) {
      nipRef.current.focus()
      this.openPopAlert("nip", "el campo "+currentLabel+" debe ser únicamente de 5 dígitos.")
      this.setState({buttonDisabled: false})
      return;
    } else if (values.nipNuevo.length !==5 ) {
      nipNuevoRef.current.focus()
      this.openPopAlert("nipNuevo", "el campo "+newLabel+" debe ser únicamente de 5 dígitos.")
      this.setState({buttonDisabled: false})
      return;
    } else if (!values.nipNuevo) {
      nipNuevoRef.current.focus()
      this.openPopAlert("nipNuevo", "el campo "+newLabel+" es requerido.")
      this.setState({buttonDisabled: false})
      return;
    } else if (invalidNumbers.test(values.nipNuevo) || invalidNumbersRev.test(values.nipNuevo)) {
      nipNuevoRef.current.focus()
      this.openPopAlert("nipNuevo", "el campo "+newLabel+" no deben ser números consecutivos.")
      this.setState({buttonDisabled: false})
      return;
    } else if (!confirmNip) {
      confirmNipRef.current.focus()
      this.openPopAlert("confirmNip", "el campo "+confirmLabel+" es requerido.")
      this.setState({buttonDisabled: false})
      return;
    } else if (confirmNip !== values.nipNuevo) {
      confirmNipRef.current.focus()
      this.openPopAlert("confirmNip", "el campo "+newLabel+" y "+confirmLabel+" deben ser iguales.")
      this.setState({buttonDisabled: false})
      return;
    } else if (!values.gCaptchaResponse) {
      gCaptchaResponseRef.current.focus()
      this.openPopAlert("gCaptchaResponse", "Debe aceptar el captcha para continuar.")
      this.setState({buttonDisabled: false})
      return;
    } else {
        await axios.post(
          endpoint,
          {
            "contrato": values.contrato,
            "nip": values.nip,
            "nipNuevo": values.nipNuevo,
            "email": this.state.tempSession.email
          },
          {
            "headers": {
              "Authorization": this.props.token
            }
          }
        ).then(resp => {
          if (resp.status === 200){
            if(resp.data.code === "401") {
              this.props.closeSession();
            } else {
              if (resp.data.respuesta === "Ok"){
                this.setState({values: {...this.state.values,nip:""}, confirmNip: ""});
                this.saveSession();
                // this.openPopAlert("nipNuevo", "Su NIP se ha cambiado exitosamente.")
                this.setState({buttonDisabled: false})
                this.props.redirectUrl("Dashboard"); // remove/comment if previous line is enabled (uncommented)
              } else {
                this.openPopAlert("gCaptchaResponse", "Ocurrió un error. Por favor, intente más tarde.")
              }
            }
          }
          this.setState({buttonDisabled: false})
        }).catch(error =>{
          this.openPopAlert("gCaptchaResponse", "Ocurrió un error en el servidor. Por favor, intente más tarde.")
          console.log(error)
        }); 
    }
  }

  handleFormInputChange = (event, name) => {
    
    const numbers = /^$|^[0-9]+$/g;
    

    switch(name){
      case "nip": case "nipNuevo":
        if(
          event.target.value.match(numbers) &&
          event.target.value.length < 6
        ){
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        } else {
          return;
        }
      case "confirmNip":
        if(
          event.target.value.match(numbers) &&
          event.target.value.length < 6
        ){
          this.setState({confirmNip: event.target.value})
          return;
        } else {
          return;
        }
      default:
        return;
    }
  }

  componentDidMount(){
    const { contrato } = this.props;
    let tempSessionData = {}
    let nipi = ""
    const sessionData = JSON.parse(sessionStorage.getItem('BurSes-13axWl87T3Pz'));
    if (!this.state.publicKey) {
      const theCaptchaKey = this.getCaptchaKey();
      if (theCaptchaKey) {
        this.setState({ publicKey: theCaptchaKey, })
      }
    }
    if (this.props.data.portletKey){
      this.setState({ portletKey: this.props.data.portletKey })
    }
    if(sessionData.token) {
      if (sessionData.changeNipReq){
        nipi = " "
      }
      tempSessionData = {...sessionData}
    }
    this.setState({
      values: { ...this.state.values, contrato: contrato, nip: nipi },
      tempSession: {...tempSessionData}
    })
  }

  render() {
    const { classes, data, changeNipReq } = this.props;
    const { publicKey } = this.state;
    const { 
      icon, 
      title, 
      currentTitle, 
      currentLabel, 
      newTitle, 
      newLabel,
      confirmTitle,
      confirmLabel,
      sendBtnTxt, 
    } = data;
    return (
      <React.Fragment>
        <AlertPop 
          open={this.state.openAlerts.status} 
          anchorEl={this.state.openAlerts.anchorEl} 
          handleClose={()=>this.closeAlerts()}
        >
          {this.state.openAlerts.content}
        </AlertPop>
        <GridItem sm={12} md={7}>
          <Card  
            plain
            className={classes.cardForm}
            blog
            border
          >
            <GridContainer spacing={3}>
              <GridItem xs={12}>
                <img className={classes.icon} src={icon} alt="" title="" />
                <h4 className={classes.formTitleBlue}>{title}</h4>
              </GridItem>
              
              {!changeNipReq&&<GridItem xs={12} className={classes.root}>
                <label className={classes.labelTextBlue}>{currentTitle}</label>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="text" 
                    id="nip"
                    ref={nipRef}
                    name="nip"
                    disabled={changeNipReq}
                    value={this.state.values.nip ? this.state.values.nip : ""}
                    onChange={(e)=>this.handleFormInputChange(e,"nip")}
                    placeholder={currentLabel} />
                  <span>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </label>
              </GridItem>}
              <GridItem xs={12} className={classes.root}>
                <label className={classes.labelTextBlue}>{newTitle}</label>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="text" 
                    id="nipNuevo"
                    ref={nipNuevoRef}
                    name="nipNuevo"
                    value={this.state.values.nipNuevo ? this.state.values.nipNuevo : ""}
                    onChange={(e)=>this.handleFormInputChange(e,"nipNuevo")}
                    placeholder={newLabel}/>
                  <span>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </label>
              </GridItem>
              <GridItem xs={12} className={classes.root}>
                <label className={classes.labelTextBlue}>{confirmTitle}</label>
                <label className={classes.inputBootstrapIcon}>
                  <input 
                    type="text"
                    id="confirmNip"
                    ref={confirmNipRef}
                    name="confirmNip"
                    value={this.state.confirmNip ? this.state.confirmNip : ""}
                    onChange={(e)=>this.handleFormInputChange(e,"confirmNip")}
                    placeholder={confirmLabel} />
                  <span>
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                </label>
              </GridItem>
              {publicKey ? <GridItem xs={12}>
                <div style={{display:"inline-block"}} id="gCaptchaResponse" ref={gCaptchaResponseRef}>
                  <CaptchaComp 
                    siteKey = {this.state.publicKey}
                    verifyCallback={this.verifyCallback}
                  /> 
                </div>
              </GridItem>: <></>}
              <GridItem xs={12}>
                <Button 
                  onClick={()=>this.sendForm()}
                  disabled={this.state.buttonDisabled}
                  disableRipple  
                  size="" 
                  color="primary" 
                >
                  {sendBtnTxt}
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </React.Fragment>
    );
  }
}

export default withStyles(formStyle)(FormChangeNIP);
