import React from 'react';
import './Loading.css';
class Loading extends React.Component {
	
  render() {
    return(
		<div className={this.props.isOffice ? "fondoLoadingOffice" : "fondoLoading"}>
			<div className="ctn_loader">
			<div className="back0"></div>
			<div className="loader-line"></div>
				<div className="back02"></div>
			</div>
		</div>
    );
  }
}
export default Loading;