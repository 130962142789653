import React from 'react';
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
import * as axios from 'axios';
// import { createBrowserHistory } from "history";
// nodejs library that concatenates classes
import classNames from "classnames";
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCalendarAlt, faComments } from '@fortawesome/free-solid-svg-icons'
import formatPhone2 from 'utils/formatPhone2';
// sections for this page
// import navPillsStyle from "assets/jss/material-kit-pro-react/components/navPillsStyle.jsx";
import formStyle from "./formStyle.jsx"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button.jsx";
import CaptchaComp from 'utils/CaptchaComp.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// const nameRef = React.createRef();

class FormUne extends React.Component {
  constructor(props) {
    super(props)

    this.state={
      value: 0,
      values: {
        name: "",
        phone: "",
        postalCode: "",
        customerNum: "",
        contractNum: "",
        selectedOption: {},
        topicOptions: [],
        groupId: '33818',
        privacy: false,
        portletKey:this.props.data.portletKey,
      },
      requiredGeneral: false,
      requiredTopic: false,
      requiredDescription: false,
      requiredFile: false,
      formSendSuccess: false,
      thankyouName: '',
      thankyouFolio: ''
    }

    this.myRef = React.createRef();
  }

  scrollToMyRef = () => {
    if (this.myRef){
      if (this.myRef.current) {
        this.myRef.current.scrollIntoView({ behavior: 'smooth'}) 
      }
    }
  }

  getCaptchaKey = async () => {
    let publicKey = this.state.publicKey;
    const captchaUrl=this.props.data.captchaKey;
    if (!publicKey || publicKey === ''){
      await axios.get(captchaUrl)
      .then(resp => {
        publicKey = resp.data.data.value;
      })
      .catch(error =>{
        console.log('Error getCaptchaKey: ',error)
      });
      this.setState({publicKey: publicKey})
      return publicKey
    }
  }

  verifyCallback = (token) => {
    this.setState({values: {...this.state.values,'gCaptchaResponse':token}, buttonDisabled: false});
    return;
  }

  validateReqFields = (tab) => {
    const values = this.state.values;
    const emailReg = /[A-Za-z0-9Ññ._+-]+@[A-Za-z0-9Ññ.-]+\.[A-Za-zÑñ]{2,4}/g;
    if (tab===0 && (!values.name || 
      !values.address || 
      !values.phone || 
      !values.state || 
      !values.delegation || 
      !values.district || 
      !values.postalCode || 
      !values.email || 
      !values.customerNum || 
      !values.contractNum || 
      !values.promotor || 
      !values.date)
      ) {
      this.setState({value: 0, requiredGeneral: true})
      this.scrollToMyRef();
      return;
    } else if (tab===0 && !values.email.match(emailReg)) { 
      this.setState({value: 0, requiredGeneral: true})
      this.scrollToMyRef();
      return;
    } else if (tab===1 && values.topicOptions.length < 1) {
      this.setState({value: 1, requiredTopic: true})
      this.scrollToMyRef();
      return;
    } else if (tab===2 && (!values.description || !values.privacy)) {
      this.setState({value: 2, requiredDescription: true})
      this.scrollToMyRef();
      return;
    } else {
      return true;
    }
  }

  handleTabChange = (event, newValue) => {
    let prevValue = this.state.value;
    if (this.validateReqFields(prevValue) || (newValue < prevValue)){ 
      this.setState({value: newValue})
      this.scrollToMyRef();
     }
  };

  handlePrevTab = () => {
    let newValue = 0
    if (this.state.value > 0) {
      newValue = this.state.value-1
    }
    this.setState({value: newValue})
    this.scrollToMyRef();
  }

  handleNextTab = () => {
    let prevValue = this.state.value;
    if (this.validateReqFields(prevValue)){ 
      let newValue = this.state.value+1
      this.setState({value: newValue})
      this.scrollToMyRef();
    } 
  }

  handleFile=(event) => {
    const maxFileSize = this.props.data.maxFileSize*1048576;
    
    this.setState({
      values: {
        ...this.state.values,
        fileName: '', 
        fileBase64: '',
      },
        requiredFile: false
    });
    if (event && event.target && event.target.files[0]){
      if (event.target.files[0].size > maxFileSize){
        this.setState({value: 2, requiredFile: true})
        this.scrollToMyRef();
        event.target.value = "";
      } else {
        var self = this;
        var reader = new FileReader();
        var file = event.target.files[0];
        var fileName = event.target.files[0].name;
        reader.onload = function(upload) {
          let tempFB64 = upload.target.result
          tempFB64 = tempFB64.split(",")[1];
          
            self.setState({
              values: {
                ...self.state.values,
                fileName: fileName, 
                fileBase64: tempFB64,
              },
              requiredFile: false
              
            });
        };
        reader.readAsDataURL(file);
     }
  }
  }

  handleFormInputChange = (event, name) => {
    const invalidChars = /[$%&/()=?¿!¡*{}@[\]:;_\-`¨+çÇªº\\·#^¬"|]/g;
    const invalidCharsAddress = /[$%&/()=?¿!¡*{}@[\]:;_`¨+çÇªº\\·^¬"|]/g;
    const invalidCharsMessage = /[$%&/()=*{}[\]:;`¨+çÇªº\\·#^¬'|]/gm;
    const invalidCharsEmail = /[$%&/()=?¿!¡*{}:;¨`+çÇªº\\·#^¬'´"|]/g;
    const emailReg = /[A-Za-z0-9Ññ._+-]+@[A-Za-z0-9Ññ.-]+\.[A-Za-zÑñ]{2,4}/g;
    const numbers = /^$|^[0-9]+$/g;
    const invalidNumbers = /[0-9]/g; 

    switch(name){
      case 'name': case 'delegation': case 'district': case 'customerProfile': case 'promotor': case 'topicOther':
        if(invalidChars.test(event.target.value) || invalidNumbers.test(event.target.value) || event.target.value.length >150){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false, requiredTopic: false})
          return;
        }
      case 'address':
        if(invalidCharsAddress.test(event.target.value) || event.target.value.length >250){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false})
          return;
        }
      case 'phone':
        let tempPhone = event.target.value
        if (tempPhone.length === 4) {
          tempPhone = tempPhone.substr(1,2)
        }
        tempPhone = tempPhone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g, '');
      if(tempPhone.match(numbers) && tempPhone.length < 19){
        const phoneVal= formatPhone2(tempPhone);
        this.setState({values: {...this.state.values,[name]:phoneVal}, requiredGeneral: false})
        //this.setState({values: {...this.state.values,[name]:tempPhone}})
        return;
      } else {
        return;
      }
      case 'postalCode':
        if(event.target.value.match(numbers) && event.target.value.length < 6){
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false})
          return;
        }else{
          return;
        }
      case 'email':
        if(invalidCharsEmail.test(event.target.value) || event.target.value.length > 100)
          return;
        if(!event.target.value.match(emailReg)){
          this.setState({required: {...this.state.required,[name]:true}})
        }
        else {
          this.setState({required: {...this.state.required,[name]:false}})
        }
        this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false})
        return;
      case 'customerNum':
        if(event.target.value.match(numbers) && event.target.value.length < 21){
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false})
          return;
        }else{
          return;
        }
      case 'contractNum':
        if(event.target.value.match(numbers) && event.target.value.length < 11){
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredGeneral: false})
          return;
        }else{
          return;
        }
      case 'date':
        const formatedDate = this.formatDate(document.getElementById("date").valueAsDate)
        this.setState({values: {...this.state.values,[name]:formatedDate}, requiredGeneral: false})
      break;
      case 'description':
        if(event.target.value.length>500 || invalidCharsMessage.test(event.target.value)){
          return;
        }
        else{
          this.setState({values: {...this.state.values,[name]:event.target.value}, requiredDescription: false })
          return;
        }
      case 'privacy':
        this.setState({values: {...this.state.values,[name]:!this.state.values[name]}, requiredDescription: false })
        return;
      default:
        if(event.target.value.length>100 || invalidChars.test(event.target.value)){
          return;
        }else{
          this.setState({values: {...this.state.values,[name]:event.target.value}})
          return;
        }
    }
  };

  handleFormCheckboxChange = (event, name) => {
    let checkStatus = !this.state.values.selectedOption[name]
    let checkOptionValue = [...this.state.values.topicOptions]
    const checkOptionIndex = checkOptionValue.indexOf(name);
    if ((checkOptionIndex < 0) && checkStatus === true ) {
      checkOptionValue.push(name);
      this.setState({
        values: {
          ...this.state.values,
          "selectedOption":{...this.state.values.selectedOption, [name]: checkStatus},
          "topicOptions": checkOptionValue
        },
        requiredTopic: false
      })
    } else {
      this.state.values.topicOptions.splice(checkOptionIndex, 1)
      this.setState({
        values: {...this.state.values,
          "selectedOption":{...this.state.values.selectedOption, [name]: checkStatus},
        },
      })
    }
    
  }

  sendForm = async () => {
    const formUneEndPoint = this.props.data.endPoint;
    let values = this.state.values;
    if (
        this.validateReqFields(0) && 
        this.validateReqFields(1) && 
        this.validateReqFields(2)
      ){
        if (values.topicOther && values.topicOptions.indexOf("Other") > -1) {
          const checkOptionIndex = values.topicOptions.indexOf("Other");
          values.topicOptions.splice(checkOptionIndex, 1)
          values.topicOptions.push(values.topicOther)
        }
        await axios.post(formUneEndPoint,values).then(resp => {
          if (resp.data.code === 200){
            this.setState({
              thankyouName: values.name,
              thankyouFolio: resp.data.folio,
              formSendSuccess: true, 
              values:{}})
            if (window.grecaptcha){
              window.grecaptcha.reset();
            }
        }
      }).catch(error =>{
        console.log(error)
      });
    }
  }

  formatDate = (testDate) => {
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear();

    return year+"-"+month+"-"+day;
  }

  getMaxDate = () => {
    const currentDate= new Date();
    return this.formatDate(currentDate)
  }

  componentDidMount(){
    if (!this.state.publicKey) {
      const theCaptchaKey = this.getCaptchaKey();
      if (theCaptchaKey) {this.setState({publicKey: theCaptchaKey })}
    }
   /*  if (this.props.data){
      this.setState({values: {
        ...this.state.values,
        portletKey:this.props.data.portletKey
      }})
    } */
    let currentDate = this.getMaxDate();
    this.setState({
      values: {
        ...this.state.values,
        date: currentDate
      }
    })
  }

  render(){
    const {publicKey} = this.state;
    const maxDate = this.getMaxDate();
    const {
      classes,
      data,
      color,
      horizontal,
      alignCenter,
      // btnFondo,
    } = this.props;

    const { tabs, buttons, labels, topicOptions, stateList, maxFileSize } = data
    const flexContainerClasses = classNames({
      [classes.flexContainer]: true,
      [classes.horizontalDisplay]: horizontal !== undefined
    });
              
    const pillsClasses = classNames({
      [classes.btnFondo]: true,
      [classes.pills]: true,
      [classes.horizontalPills]: horizontal !== undefined,
      /* [classes.pillsWithIcons]: prop.tabIcon !== undefined */
    });

    const classesDate = this.state.values.date ? classes.inputBootstrap : classNames(classes.inputBootstrap, classes.mandatory)
    
    if (this.state.formSendSuccess){
      const {thankyouName, thankyouFolio} = this.state;
      return (
        <React.Fragment>
          <GridItem xs={12} style={{textAlign:"center"}}>
            <p className={classes.defaultFont}>Hola, <strong>{thankyouName}</strong></p>
            <p className={classes.defaultFont}>{labels.thankYouMessage+" "}<strong>{thankyouFolio}</strong>.</p>
          </GridItem>
          <GridItem xs={12} style={{textAlign:"center"}}>
            <Button onClick={()=>breadcrumbsUpdate()} href={buttons.thankYouBtnUrl} disableRipple  size="" color="borderSecondary" >
              {buttons.thankYouBtnTxt}
            </Button>
          </GridItem>
        </React.Fragment>
      );
    } else {
      return (
        <GridItem xs={12}>
          <div className={classes.root}>
            <Tabs
              classes={{
                root: classes.root,
                fixed: classes.fixed,
                flexContainer: flexContainerClasses,
                indicator: classes.displayNone
              }}
              value={this.state.value}
              onChange={this.handleTabChange}
              centered={alignCenter}
            >
              <Tab
                disableRipple
                label={tabs.generalData}
                key={0}
                classes={{ root: pillsClasses, selected: classes[color] }}
              />
              <Tab
                disableRipple
                label={tabs.topic}
                key={1}
                classes={{ root: pillsClasses, selected: classes[color] }}
              />
              <Tab
                disableRipple
                label={tabs.description}
                key={2}
                classes={{ root: pillsClasses, selected: classes[color] }}
              />
            </Tabs>
            <span ref={this.myRef} style={{position: "absolute", top: "-110px", left: 0}}/>
            <TabPanel className={classes.tabContent} value={this.state.value} index={0} key={'OptiPills-'+0}>            
              <GridContainer spacing={2}>
                {this.state.requiredGeneral && <GridItem xs={12}>
                  <div className={classes.errorBox}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <label>*</label>
                    <span>{labels.errorGeneralData}</span>
                  </div>
                </GridItem>}
                <GridItem xs={12}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="nameUNE"
                    name="name"
                    required
                    value={this.state.values.name ? this.state.values.name : ""}
                    onChange={(e)=>this.handleFormInputChange(e,"name")}
                    placeholder={labels.name}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="address"
                    name="address"
                    required
                    value={this.state.values.address ? this.state.values.address : ""}
                    onChange={(e)=>this.handleFormInputChange(e,"address")}
                    placeholder={labels.address}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="phoneUNE"
                    name="phone"
                    required
                    value={this.state.values.phone ? this.state.values.phone : ''}
                    onChange={(e)=>this.handleFormInputChange(e,"phone")}
                    placeholder={labels.phone}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <List id="state" style={{padding: 0, height: "100%"}}>
                    <ListItem style={{justifyContent: "center", padding: 0}} className={classes.dropdownFull} key={1+'ListItem'+Math.floor(Math.random()*901)} >
                      <CustomDropdown
                        noLiPadding
                        navDropdown
                        caret={false}
                        hoverColor={"transparent"}
                        buttonText={this.state.values.state ? this.state.values.state : labels.state}
                        buttonProps={{
                          className: classes.dropdownFull,
                          color: "primaryPrimary"
                        }}
                        dropdownList={ stateList.sort((item1, item2)=>item1.id - item2.id).map((item, key)=> {
                            return (<Button 
                              disableRipple
                              className={classes.userPopover} 
                              color="transparent" 
                              key={0}
                              onClick={()=>this.setState({values: {...this.state.values, state: item.name}})}
                            >
                              {item.name}
                            </Button>
                            )
                          })
                        }
                      />
                    </ListItem>
                  </List>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="delegation"
                    name="delegation"
                    required
                    value={this.state.values.delegation ? this.state.values.delegation : ''}
                    onChange={(e)=>this.handleFormInputChange(e,"delegation")}
                    placeholder={labels.delegation}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="district"
                    name="district"
                    required
                    value={this.state.values.district}
                    onChange={(e)=>this.handleFormInputChange(e,"district")}
                    placeholder={labels.district}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    required
                    value={this.state.values.postalCode}
                    onChange={(e)=>this.handleFormInputChange(e,"postalCode")}
                    placeholder={labels.postalCode}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="emailUNE"
                    name="email"
                    required
                    value={this.state.values.email}
                    onChange={(e)=>this.handleFormInputChange(e,"email")}
                    placeholder={labels.email}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="customerNum"
                    name="customerNum"
                    required
                    value={this.state.values.customerNum}
                    onChange={(e)=>this.handleFormInputChange(e,"customerNum")}
                    placeholder={labels.customerNum}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="contractNum"
                    name="contractNum"
                    required
                    value={this.state.values.contractNum}
                    onChange={(e)=>this.handleFormInputChange(e,"contractNum")}
                    placeholder={labels.contractNum}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <input 
                    className={classes.inputBootstrap}
                    type="text"
                    id="customerProfile"
                    name="customerProfile"
                    required
                    value={this.state.values.customerProfile}
                    onChange={(e)=>this.handleFormInputChange(e,"customerProfile")}
                    placeholder={labels.customerProfile}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <input
                    className={classes.inputBootstrap}
                    type="text"
                    id="promotor"
                    name="promotor"
                    required
                    value={this.state.values.promotor}
                    onChange={(e)=>this.handleFormInputChange(e,"promotor")}
                    placeholder={labels.promotor}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <div className={classes.inputGroup}>
                    <div className={classes.contentIcon}>
                      <FontAwesomeIcon icon={faCalendarAlt}/>
                    </div>
                    <input 
                      style={{borderTopRightRadius: "4px", borderBottomRightRadius: "4px"}}
                      type="date" 
                      className={classesDate} 
                      id="date"
                      name="date"
                      max={maxDate}
                      disabled
                      value={maxDate}  
                      onChange={(e)=>this.handleFormInputChange(e,"date")}
                      placeholder={labels.date}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <Button disableRipple className="mr" size="" color="gray" href='/' >
                    {buttons.cancel}
                  </Button>
                  <Button onClick={this.handleNextTab} disableRipple  size="" color="primaryPrimary" >
                    {buttons.next}
                  </Button>
                </GridItem>
              </GridContainer>
            </TabPanel>

            <TabPanel className={classes.tabContent} value={this.state.value} index={1} key={'OptiPills-'+1}>
              <GridContainer spacing={2}>
                {this.state.requiredTopic && <GridItem xs={12}>
                  <div className={classes.errorBox}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <label>*</label>
                      <span>{labels.errorTopic}</span>
                  </div>
                </GridItem>}
                <GridItem xs={12}>
                  <h6 className={classes.texth6}>{labels.topicTitle}</h6>
                </GridItem>
                {topicOptions.sort((check1, check2)=>check1.id-check2.id).map((checkBox, index) => {
                    return (
                      <React.Fragment>  
                        <GridItem xs={1}>
                          <input
                            type="checkbox"
                            className={classes.checkInput}
                            id={"topicOption-"+checkBox.id}
                            name={"topicOption-"+checkBox.id}
                            checked={this.state.values.selectedOption[checkBox.id+" - "+checkBox.topicName] ? this.state.values.selectedOption[checkBox.id+" - "+checkBox.topicName] : false} 
                            value={this.state.values.selectedOption[checkBox.id+" - "+checkBox.topicName] ? this.state.values.selectedOption[checkBox.id+" - "+checkBox.topicName] : false} 
                            onChange={(e)=>this.handleFormCheckboxChange(e,checkBox.id+" - "+checkBox.topicName)} 
                          />
                        </GridItem>
                        <GridItem xs={11}>
                          <label className={classes.labelCheck} htmlFor={"topicOption-"+checkBox.id}>
                            {checkBox.topicName}
                          </label>
                        </GridItem>
                      </React.Fragment>
                    )
                  })
                }
                {/* <GridItem xs={1}>
                  <input
                    type="checkbox"
                    className={classes.checkInput}
                    id="topicOptionOther"
                    name="topicOptionOther"
                    checked = {this.state.values.selectedOption["Other"] ? this.state.values.selectedOption["Other"] : false} 
                    value={this.state.values.selectedOption["Other"] ? this.state.values.selectedOption["Other"] : false} 
                    onChange={(e)=>this.handleFormCheckboxChange(e,"Other")} 
                  />
                </GridItem>
                <GridItem xs={11}>
                  <input
                    className={classes.inputBootstrap}
                    type="text"
                    id="topicOther"
                    name="topicOther"
                    required
                    disabled={!this.state.values.selectedOption["Other"]}
                    value={this.state.values.topicOther}
                    onChange={(e)=>this.handleFormInputChange(e,"topicOther")}
                    placeholder={labels.topicOther}
                  />
                </GridItem> */}
                <GridItem xs={12}>
                  <Button onClick={this.handlePrevTab} disableRipple className="mr" size="" color="gray" >
                    {buttons.previous}
                  </Button>
                  <Button onClick={this.handleNextTab} disableRipple  size="" color="primaryPrimary" >
                    {buttons.next}
                  </Button>
                </GridItem>
              </GridContainer>
            </TabPanel>


            <TabPanel className={classes.tabContent} value={this.state.value} index={2} key={'OptiPills-'+2}>
              <GridContainer spacing={2}>
                {this.state.requiredDescription && <GridItem xs={12}>
                  <div className={classes.errorBox}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <label>*</label>
                    <span>{labels.errorDescription}</span>
                  </div>
                </GridItem>}
                {this.state.requiredFile && <GridItem xs={12}>
                  <div className={classes.errorBox}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <label>*</label>
                    <span>{labels.errorFileTooBig}</span>
                  </div>
                </GridItem>}
                <GridItem xs={12}>
                  <h6 className={classes.texth6}>{labels.descriptionTitle}</h6>
                </GridItem>
                <GridItem xs={12}>
                  <label className={classes.inputBootstrapIcon}>
                    <textarea 
                      style={{ 
                        paddingTop: "10px", 
                        paddingBottom: "10px", 
                        paddingRight: "10px", 
                        height: "96px"
                      }} 
                      id="description"
                      name="description"
                      value={this.state.values.description ? this.state.values.description : ''}
                      onChange={(e)=>this.handleFormInputChange(e,"description")}
                      placeholder={labels.descriptionLabel}
                    />
                    <span>
                      <FontAwesomeIcon icon={faComments} />
                    </span>
                  </label>
                </GridItem>
                <GridItem xs={12}>
                <input
                  id="file"
                  type="file"
                  /* accept=".doc,.docx,.pdf" */
                  onChange={(event) => this.handleFile(event)}
                  required
                  fullWidth
                  InputLabelProps={{
                      shrink: true,
                  }}
                  margin="normal"
                /> 
                {/* <span>Adjunta un archivo</span> */}
                <div><small>Tamaño máximo {maxFileSize} MB</small></div>
                </GridItem>
                <GridItem xs={12}>
                <label className={classes.privacy}>
                  <input  id="privacy" name="privacy" type="checkbox" checked={this.state.values.privacy} value={this.state.values.privacy} onChange={(e)=>this.handleFormInputChange(e,"privacy")} />
                  {labels.privacyTxt}
                  <a href={labels.privacyLinkUrl} target="_blank" rel="noopener noreferrer" >{labels.privacyLinkTxt}</a>
                </label>
                </GridItem>
                {publicKey ? <GridItem xs={12}>
                  <CaptchaComp 
                    siteKey = {this.state.publicKey}
                    verifyCallback={this.verifyCallback}
                  />
                </GridItem>: <></>}
                <GridItem xs={12}>
                  <Button onClick={this.handlePrevTab} disableRipple className="mr" size="" color="gray" >
                    {buttons.previous}
                  </Button>
                  <Button disableRipple  size="" color="primaryPrimary" onClick={()=>this.sendForm()} >
                    {buttons.send}
                  </Button>
                </GridItem>
              </GridContainer>
            </TabPanel>
          </div>
        </GridItem>
      );
    }
  }
}
FormUne.defaultProps = {
  active: 0,
  color: "primary"
};

FormUne.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool
};

export default withStyles(formStyle)(FormUne);
