import React from 'react';

export default function htmlText(text){

  if (text){
    const textWithBreaks = text.replace(/(\r\n|\n|\r|\[\$NEW_LINE\$\])/gm, '</br>')
              .replace(/!b\*!/g,'</strong>').replace(/!\*b!/g,'<strong>')
              .replace(/!c\*!/g,'</i>').replace(/!\*c!/g,'<i>')
              .replace(/!cb\*!/g,'</strong></i>').replace(/!\*cb!/g,'<i><strong>');

    return <span dangerouslySetInnerHTML={{ __html: textWithBreaks}} />
  } else {
    return null;
  }

}