import React, {Component} from 'react';
import {Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import * as axios from 'axios';
import ReactNotification from 'react-notifications-component';
import Loading from 'components/Loader/Loading';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import MultiComponent from 'MultiComponent';
//


var hist = createBrowserHistory();
var compo = [];
var footerData = {};
var metaData = {};
var linksNavBar = []; 
const axiosHeaders = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
 }
//Necesario para guardar los links desde el servidor
export default class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            structure: [],
            load: false,
            hostingVariables: {
              host: '',
              language: 'es_ES',
              groupId: '33818',
              // groupId: '38211',
              lifeRayHost: '',
              // elasticHost: 'http://10.20.50.230/elasticsearch', //uncomment for QA 
              elasticHost: 'https://www.bursametrica.com/elasticsearch', // for production
              lifeRayApp: 'liferaygg-20101',
              lifeRayCredentials: 'pTQA7YKHSVSTfVUcXC03Kw',
              // lifeRayCredentials: 'AdIKZzfnSqWEljcpnIYGrw'
              urlLogo : "",
              loginBtnTitle: "Acceso a clientes",
              loginBtnUrl: "/acceso-a-clientes",
              searchHost: "/resultados-de-busqueda", 
              searchTitle: "",
              searchLabel: "",
              searchBtnLabel:"",
              //data for contact form:
              contactFormData: null
            }
          };

          this.getLayout = this.getLayout.bind(this);
    }

    saveBreadcrumbs=(pathName)=>{
      if (localStorage.getItem('BurSes-breWl87T3Pz')){
        const breadcrumbsData = JSON.parse(localStorage.getItem('BurSes-breWl87T3Pz'));
        let tempPath = breadcrumbsData.pathArray;
        if (breadcrumbsData.isInternalLink === true){
          /* if (tempPath.indexOf(pathName)<0){ */
            tempPath.push(pathName);
            localStorage.setItem('BurSes-breWl87T3Pz',JSON.stringify( {"pathArray": tempPath, isInternalLink: false} ));
          /* } */
        } else {
          localStorage.removeItem('BurSes-breWl87T3Pz');
          tempPath=[pathName];
          localStorage.setItem('BurSes-breWl87T3Pz',JSON.stringify( {"pathArray": tempPath, isInternalLink: false} ));
        }
      } else {
        const tempPath=[pathName];
        localStorage.setItem('BurSes-breWl87T3Pz',JSON.stringify( {"pathArray": tempPath, isInternalLink: false} ));
      }
    }
    
    async getLayout(lang='es_ES') {
      this.setState({load: false});
      await axios.get(
        this.state.hostingVariables.lifeRayHost+"/o/LiferayServices/contact/0"
      )
      .then(resp => {
        this.setState({ 
          hostingVariables:{
            ...this.state.hostingVariables,
            contactFormData: resp.data
          }
        });
      }).catch(error=> {
        console.log(error);
        /* hist.push('/error'); */
      });
      
      await axios.get(this.state.hostingVariables.lifeRayHost+"/o/LiferayServices/header/menu/"+this.state.hostingVariables.groupId, axiosHeaders).then(resp => {
        linksNavBar = resp.data.data;
        this.setState({ 
          hostingVariables:{
            ...this.state.hostingVariables,
            urlLogo : resp.data.urlLogo,
            loginBtnTitle: resp.data.loginBtnTitle,
            loginBtnUrl: resp.data.loginBtnUrl,
            searchHost: resp.data.searchPage,
            searchTitle: resp.data.searchTitle,
            searchLabel: resp.data.searchLabel,
            searchBtnLabel: resp.data.searchBtnLabel,
          }
        });
        
      }).catch(error=> {
        console.log(error);
        /* hist.push('/error'); */
      });

      await axios.get(
        this.state.hostingVariables.lifeRayHost+"/o/LiferayServices/footer/menu/"+this.state.hostingVariables.groupId
      )
      .then(resp => {
        footerData = resp.data.data
      })
      .catch(error =>{
        console.log(error);
        footerData=[];
        /* hist.push('/error'); */
      });

      // this.initializeReactGA(); 
      await axios.get(
        this.state.hostingVariables.lifeRayHost+"/o/LiferayServices/layout/"+this.state.hostingVariables.groupId+hist.location.pathname+hist.location.search
      )
      .then(resp => {
          compo = resp.data.layoutTemplate.content;
          
            this.saveBreadcrumbs(hist.location.pathname);
          
          //compo=[]
            
        
          //metaData = resp.data.layoutTemplate.seo
          this.setState({ load: true });
      })
      .catch(error =>{
        console.log(error);
        compo=[];
        /* hist.push('/error'); */
      });
      
  }

    componentDidMount(){
      this.lang = JSON.parse(localStorage.getItem('CoLangIdntfier'));
      let isOfficeCheck = false;
      if (hist.location.pathname === "/portal-clientes") {
        isOfficeCheck = true
      }
      if (localStorage.getItem('CoLangIdntfier')) {
          this.setState({
            isOffice: isOfficeCheck,
            hostingVariables:{
              ...this.state.hostingVariables,
              language: this.lang} 
          })
      } else {
        this.setState({
          isOffice: isOfficeCheck,
          hostingVariables:{
            ...this.state.hostingVariables,
            language: 'es_ES'} 
        })
        this.lang='es_ES'
      }
      
      this.getLayout(this.lang)
  }
  // initializeReactGA() {
  //     ReactGA.initialize('UA-1');
  //     ReactGA.pageview(hist.location.pathname);
  // }
    render(){
      const isOffice = this.state.isOffice
      const temporal =<div style={{width: '100%', height: '100vh'}}><Loading isOffice={isOffice}/></div>;
      const callMulti = <React.Fragment>
      {/* <ReactNotification /><MultiComponent handleLanguageChange = {this.handleLanguageChange} metaData={metaData} urlLogo={this.state.urlLogo} followUsTitle={this.state.followUsTitle} linksNavBar={linksNavBar} structure={compo} hostingVariables={this.state.hostingVariables} /> */}
      <ReactNotification />
        <MultiComponent metaData={metaData} footerData={footerData} followUsTitle={this.state.followUsTitle} linksNavBar={linksNavBar} structure={compo} hostingVariables={this.state.hostingVariables} />
    </React.Fragment>
      return(
        <div>
            <Router history={hist}>
              <Switch>
                <Route 
                  path='/error'
                  render={() => <ErrorPage /> }
                  // render={ () => <div style={{marginTop: 150, color: 'red'}}><h2>Aquí va la página de error</h2></div>} 
                />
                <Route
                  path='/test'
                  render={ () => <div style={{marginTop: 150, color: 'red'}}><h2>Versión 3.1.1</h2></div>} 
                />

                <Route
                  path={hist.location.pathname}
                  render={() => this.state.load ? callMulti : temporal}
                />
              </Switch>
            </Router>
        </div>
      );
    }
}