import React from "react";
import ReCAPTCHA from "react-google-recaptcha";


const DELAY = 500;

class CaptchaComp extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false"
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
  }

  handleChange = value => {
    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  render() {
    const SITE_KEY = this.props.siteKey;
    const { load } = this.state || {};
    const {verifyCallback} = this.props;
    return (
      <>
        {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="light"
            ref={this._reCaptchaRef}
            sitekey={SITE_KEY}
            onChange={(value)=>verifyCallback(value)}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
      </>
    )
  } 
  
}
export default CaptchaComp