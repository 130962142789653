import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import videoStyle from "./videoStyle.jsx"; 

class Video extends Component {
  render(){
  const {
    classes,
    video,
    isSearch,
    modal,
  } = this.props;
  if (video.includes("//youtu") || video.includes("//www.youtu")){
    return (
      <embed width="100%" height={isSearch ? "250px" : "400px"} className={modal && classes.videoModal} style={{minHeight: "250px", backgroundColor: "black"}} alt="VideoPlayer" title="VideoPlayer" src={video+"?rel=0"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    );
  } else if (video.includes("//player.vimeo") || video.includes("//www.player.vimeo")){
    return (
      <embed width="100%" height={isSearch ? "250px" : "400px"} className={modal && classes.videoModal} style={{minHeight: "250px", backgroundColor: "black"}} alt="VideoPlayer" title="VideoPlayer" src={video+"?rel=0"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    );
  } else if (video.includes(".mp4")){
    return (
      <video width="100%" height={isSearch ? "250px" : "400px"} className={modal && classes.videoModal} style={{backgroundColor: "black"}} alt="VideoPlayer" title="VideoPlayer" controls>
        <source src={video} type="video/mp4"></source>
      </video>
    )
  } else return (<div/>)
}
};

export default withStyles(videoStyle)(Video);