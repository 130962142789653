import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import Title from "components/Title";
import withStyles from "@material-ui/core/styles/withStyles";
import videoStyle from "./videoStyle.jsx"; 
import Video from "./index.jsx";

class VideoContent extends Component {
  render(){
  const {
    title,
    video,
  } = this.props;
  return (
    <React.Fragment>
      {title && <Title line title={title} alignment="left" style={{paddingBottom: "0"}} />}
      <GridItem xs={12}>
        <Video video={video}/>
      </GridItem>
    </React.Fragment>
  )
}
};

export default withStyles(videoStyle)(VideoContent);