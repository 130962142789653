import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";

import titleStyle from "./titleStyle.jsx"; 


class Title extends Component {
  render(){
  const {
    classes,
    title,
    line,
    alignment,
    large,
    description,
    style
  } = this.props;

  return (
    <React.Fragment>
        { large ? 
            title && <GridItem xs={12} style={style}>
              <h2 className={classNames(classes.titleLarge, line && classes.titleLine)} style={{textAlign: alignment ? alignment : "left" }}>{title}</h2>
              </GridItem>
            : title && <GridItem xs={12} style={style}>
              <h3 className={classNames(classes.title, line && classes.titleLine)} style={{textAlign: alignment ? alignment : "left" }}>{title}</h3>
              </GridItem>
          
        }
        
        {
          description && <GridItem xs={12} style={style}><p className={classes.description}> {description}</p></GridItem>
        }
    </React.Fragment>
  );
}
};

export default withStyles(titleStyle)(Title);
