import {
    defaultFont,
    cardTitle,
    primaryColor,
    container,
    title
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const circleTagListStyle = {
    imageBg: {
      width: '100%',
      height: '100%',
      maxHeight: '530px',
      minHeight: '200px',
      backgroundSize: 'cover'
    },
    circleTagItem: {
      display: 'flex',
      flexDirection: 'row'
    },
    circleTag: {
      minWidth: '80px',
      height: '80px',
      borderRadius: '50%',
      marginRight: '20px',
      backgroundColor: "transparent",
      border: "1px solid #727272",
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2rem',
      fontWeight: 400,
      '& img': {
        width: 26
      }
    },
    titleLine:{
      ...title,
      // color: '#ffffff',
      fontWeight: 400,
      // textTransform: 'uppercase',
      fontSize: "1.5rem !important",
      lineHeight: "1.36em !important",
      position: 'relative',
      textTransform: 'none',
      marginTop: '1rem !important',
      marginBottom: '2.7rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    },
    itemTitle: {
        ...cardTitle,
        color: primaryColor[0] + "  !important",
        marginTop: 0 + " !important",
        width: "100%",
        fontWeight: "400 !important",
        fontSize: "1.5rem !important",
        lineHeight: "1.36em !important",
    },
    description: {
      ...defaultFont,
      // textAlign: 'justify',
      lineHeight: '1.56rem !important'
    },
    container: {
      ...container,
      // paddingLeft: 0,
      // paddingRight: 0,
      position: "relative",
      zIndex: 2
    },
  };
  
  export default circleTagListStyle;
  