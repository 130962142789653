import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";


import titleStyle from "./titleStyle.jsx"; 


class Title extends Component {
  render(){
  const {
    classes,
    title,
  } = this.props;

  return (
    <React.Fragment>
        { 
          title && <GridItem xs={12}><h5 className={classNames(classes.subTitle)}>{title}</h5></GridItem>
        }
    </React.Fragment>
  );
}
};

export default withStyles(titleStyle)(Title);
