import React from "react";
import htmlText from 'utils/htmlFormattedText';
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
import classNames from "classnames";
// core components
import Title from "components/Title";
import Video from "components/Video";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote"
import Button from "components/CustomButtons/Button.jsx";
import Altivator from 'utils/imageAltivator';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import minus from "assets/img/svg/minus.svg"
import plus from "assets/img/svg/plus.svg"
import customExpansionPanelStyle from "components/CustomExpansionPanel/customExpansionPanelStyle";
import Paragraph from "components/Typography/Paragraph.jsx";
// images


// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class ContentQuoteMultimedia extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      expanded: false,
      usedBGImage: ''
    }
  }
  handleChange = panel => (event, expanded) => {
    this.setState({expanded: expanded ? panel : false});
  };
  render() {
    const { classes, data, contentCenter, heigthImgAuto, style} = this.props;
    const {isList, title, titleMini, subtitle, imageTop, videoUrl, imageUrl, imageFull, reverse, button, outline, targetURL} = data;
    let DESCRIPTION=[]; 
    let QUOTEDESCRIPTION = [];
    if (data.data !== undefined){
      if (data.data.description !== undefined){
        DESCRIPTION = data.data.description.map((item,key) => {
          return (
            <p className={classes.description} key={key}>
              {htmlText(item.text)}
            </p>
          )
        })
      }
      if ( data.data.quoteDescription !== undefined){
        QUOTEDESCRIPTION = data.data.quoteDescription.map((item,key )=> {
          if (item.text) {
            return (
              <p className={classNames(classes.description, false ? classes.bulletReverse : classes.bullet)} key={key}>
                {htmlText(item.text)}
              </p>
            )
          } 
          return null;
        })
      }
    } else if (data.description !== undefined){ 
      DESCRIPTION = data.description.map((item,key) => {
        return (
          <p className={classes.description} key={key}>
            {htmlText(item.text)}
          </p>
        )
      })
    }
    
    if (data.quoteDescription !== undefined){
      QUOTEDESCRIPTION = data.quoteDescription.map((item,key )=> {
        if (item.text) {
          return (
            <p className={classNames(classes.description, false ? classes.bulletReverse : classes.bullet)} key={key}>
              {htmlText(item.text)}
            </p>
          )
        } 
        return null;
      })
    }

    let toggleContent = 
      (data.data && data.data.toggleContent && data.data.toggleContent.length > 0 ) ?
        data.data.toggleContent.map((item,key)=>
        <ExpansionPanel
          key={'panel' + key} 
          elevation="0" 
          className={classes.noBefore} 
          expanded={this.state.expanded === 'panel' + key} 
          onChange={this.handleChange('panel' + key)}
          >
          <ExpansionPanelSummary
            className={classes.noPadding}
            // expandIcon={<ExpandMoreIcon className={classes.expandBtn2}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {/* <ExpandMoreIcon className={classNames(classes.expandBtn2, (this.state.expanded === 'panel' + key) && classes.expandBtnActive)}/> */}
            {
              (this.state.expanded === 'panel' + key) ?
              <img src={minus} className={classes.expandBtnPlus} alt="" title="" /> :
              <img src={plus} className={classes.expandBtnPlus} alt="" title="" /> 
            }
            <Typography 
              className={classNames(classes.titleExpand2)}>
                {item.title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails 
            className={classes.contentWrap}
          >
            <Paragraph>
              {item.text}
            </Paragraph>  
          </ExpansionPanelDetails>
        </ExpansionPanel>  
        )
      : null;
      
    return (
      <React.Fragment>
      {/*title && <GridItem xs={12}> <Title titulo={title} reverse={false}/> </GridItem>*/}
      <GridItem xs={12} style={style} className={isList && classes.paddingTop}>
        
        <GridContainer direction={reverse && "row-reverse"} justify={contentCenter&&"center"}>
          
          {videoUrl && <GridItem xs={12} md={5} lg={6}> <Video video={videoUrl} /> </GridItem>}
          {(imageUrl && !imageTop) && <GridItem xs={12} sm={isList ? 2 : 12} md={isList ? 2 :5} lg={isList ? 2 :4}> <img className={imageFull ? classes.imageFull : heigthImgAuto ? classes.autoImage : classes.styleImage} src={imageUrl} alt={Altivator(imageUrl)} title={Altivator(imageUrl)} /> </GridItem>}
          {(imageUrl && imageTop)&& <GridItem xs={12}> <img src={imageUrl} alt="" className={classes.imageTop}/></GridItem>}
          { imageTop ? <React.Fragment>
            <GridItem xs={12}>
              {subtitle &&  <h4 className={classNames(classes.description, classes.textCenterMultimedia)} >
                              {subtitle}
                            </h4>
              }
            </GridItem> 
            {(DESCRIPTION.length > 0 && DESCRIPTION[0] !== undefined ) && <GridItem xs={12}>
              {DESCRIPTION}
            </GridItem>} 
            </React.Fragment> : 
            <GridItem xs={12} 
              sm={isList ? 10 : 12}
              md={isList ? 10 : toggleContent && imageUrl ? 7 : 
                  (toggleContent && !imageUrl ? 12 : 
                    (imageUrl ? 7 : 9) 
                  )} 
              lg={isList ? 10 : toggleContent && imageUrl ? 8 :
                  (toggleContent && !imageUrl ? 12 : 
                    (imageUrl ? 8 : 8)
                  )}
            >
            <GridContainer>
            {title && <Title line title={title} titleMini={titleMini} reverse={false}/>}
            <GridItem xs={12}>
              {(data.subtitle && DESCRIPTION.length > 0 && DESCRIPTION[0] ) && <h4 className={classes.subtitleList}>{data.subtitle}</h4>}
              {DESCRIPTION}
              {isList && <div className={classes.dividerList}></div>}
              { (QUOTEDESCRIPTION.length > 0 && QUOTEDESCRIPTION[0] ) ? 
                <Quote reverse={false}>
                  {QUOTEDESCRIPTION}
                </Quote>
                : null 
              }
                {button && <Button 
                  className={classes.uppercaseText} 
                  size={outline ? "xs" : "sm"} 
                  outlined={outline} 
                  simple={outline} 
                  round 
                  onClick={()=>breadcrumbsUpdate()} 
                  href={targetURL} 
                  color={outline ? "primary" : "complementario"}
                > {button} </Button>}
                {data.data && data.data.urlBtn && data.data.txtBtn &&
                  <Button 
                    className={classes.capitalizeText} 
                    size="" 
                    onClick={()=>breadcrumbsUpdate()} 
                    href={data.data.urlBtn} 
                    target="_blank" 
                    disableRipple 
                    color="primary"
                  >{data.data.txtBtn}</Button>
                }
              {
                toggleContent
              }
            </GridItem>
            </GridContainer>
          </GridItem>}
        </GridContainer>
      </GridItem>
      </React.Fragment>
    );
  }
}

export default withStyles({...style,...customExpansionPanelStyle})(ContentQuoteMultimedia);
