export default function formatPhone2(x){
  switch (x.length) {
    case 1: case 2:
      return x;
    case 3: case 4:
      return "("+x.substr(0,2)+") "+x.substr(2,x.length-2)
    case 5: case 6: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,x.length-4);
    case 7: case 8: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,x.length-6);
    case 9: case 10: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,2)+"-"+x.substr(8,x.length-8);
    case 11: case 12: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,2)+"-"+x.substr(8,2)+"-"+x.substr(10,x.length-10);
    case 13: case 14: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,2)+"-"+x.substr(8,2)+"-"+x.substr(10,2)+"-"+x.substr(12,x.length-12);
    case 15: case 16: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,2)+"-"+x.substr(8,2)+"-"+x.substr(10,2)+"-"+x.substr(12,2)+"-"+x.substr(14,x.length-12);
    case 17: case 18: 
      return "("+x.substr(0,2)+") "+x.substr(2,2)+"-"+x.substr(4,2)+"-"+x.substr(6,2)+"-"+x.substr(8,2)+"-"+x.substr(10,2)+"-"+x.substr(12,2)+"-"+x.substr(14,2)+"-"+x.substr(16,x.length-12);
    default:
      return;
  }
} 