import {
  cardTitle,
  defaultFont,
  roseColor,
  primaryColor,
  secondaryColor,
  complementarioColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";
import {
  inputBootstrapIcon,
  inputBootstrap,
  inputGroup,
  contentIcon,
  checkInput,
  labelCheck
} from "components/CustomInput/style"

const navPillsStyle = theme => ({
  root: {
    marginTop: "20px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important",
    '& input': {
      fontFamily: '"Roboto", Arial, Helvetica, sans-serif !important',
    },
    '& button': {
      boxShadow: "none !important",
      // fontFamily: '"Roboto", Arial, Helvetica, sans-serif !important',
      '& > span': {
        flexDirection: "row",
        '& svg':{
          width: "30px",
          height: "25px",
          margin: "0 !important"
        }
      }
    },
  },
  defaultFont: {
    ...defaultFont
  },
  flexContainer: {
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  displayNone: {
    display: "none !important"
  },
  fixed: {
    overflowX: "visible"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    fontFamily: '"Open Sans", sans-serif',
    float: "left",
    fontSize: ".96rem",
    fontWeight: 400,
    textTransform: "inherit !important",
    position: "relative",
    display: "block",
    borderRadius: "2px",
    minWidth: "100px",
    textAlign: "center",
    transition: "all 0.3s ease-out 0s",
    padding: "5px 15px",
    color: primaryColor[0],
    height: "3.4rem",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 5px 5px",
    minHeight: "unset",
    "@media (max-width: 480px)": {
      fontSize: "1rem"
    },
  },
  btnFondo:{
    border: "1px solid #e0e0e0",
    color: "#454545",
    '&:hover':{
      color: secondaryColor[0]
    }
  },
  // pillsWithIcons: {
  //   borderRadius: "4px"
  // },
  tabIcon: {
    width: "30px",
    height: "30px",
    display: "block",
    margin: "15px 0"
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  labelContainer: {
    padding: "0!important",
    color: "inherit"
  },
  label: {
    lineHeight: "24px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "500",
    position: "relative",
    display: "block",
    color: "inherit"
  },
  contentWrapper: {
    marginTop: "0",
    margin: "0 -8px 8px",
    "& .react-swipeable-view-container > div > div": {
      // paddingLeft: "15px",
      // paddingRight: "15px",
      overflow: "hidden"
    }
  },
  primary: {
    "&,&:hover": {
      color: whiteColor,
      border: "1px solid",
      borderColor: secondaryColor[0],
      backgroundColor: secondaryColor[0],
    }
  },
  complementario: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: complementarioColor,
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(complementarioColor) +
        ", 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.4)"
    }
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  },
  border: {
    border: "1px solid #CACACA",
    borderLeft: 0,
    borderRight: 0,
    "& > div": {
      marginTop: '12px !important'
    }
  },
  cardForm: {
      padding: "3rem !important",
      textAlign: "center",
      marginTop: "0 !important"
      // "@media (max-width: 576px)": {
      //   maxWidth: "540px"
      // },
  },
  formTitle: {
      ...cardTitle,
      fontSize: "1.5rem !important",
      lineHeight: "1.36em !important",
      textTransform: "uppercase",
      fontWeight: "normal !important",
      margin: "0 !important",
      color: "#272727"
  },
  formTitleBlue: {
      ...cardTitle,
      fontSize: "1.5rem !important",
      lineHeight: "1.36em !important",
      textTransform: "uppercase",
      fontWeight: "normal !important",
      margin: "0 !important",
      color: "#003B72",
  },
  labelText: {
      display: "block",
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.2rem",
      lineHeight: "1.3em",
      color: "#272727",
      marginBottom: "1.16rem"
  },
  labelTextBlue: {
      display: "block",
      fontFamily: `'Lato', sans-serif`,
      fontSize: "1.2rem",
      lineHeight: "1.3em",
      // color: "#272727",
      color: "#003B72",
  },
  inputBootstrapIcon: {
      ...inputBootstrapIcon
  },
  // Style UNE
  errorBox: {
      fontFamily: `"Open Sans", sans-serif`,
      padding: "1.1rem",
      marginBottom: "1.1rem",
      borderRadius: "4px",
      border: "1px solid #fa482b",
      fontSize: "1.1rem",
      lineHeight: "1em",
      fontWeight: 400,
      color: "#3f3f3f",
      '& label':{
          color: "#fa482b",
          fontSize: "1.2rem",
      },
      '& svg':{
          marginRight: ".9rem"
      },
  },
  texth6: {
      fontFamily: `'Lato', sans-serif`,
      color: "#272727",
      fontWeight: "normal",
      fontSize: "1.1rem",
      lineHeight: "1.3em",
      textTransform: "inherit"
  },
  inputBootstrap: {
      ...inputBootstrap
  },
  mandatory: {
    '&:after':{
      content:'"*"',
      position: "absolute",
      top: 10,
      left: "7.2rem",
      "@media (max-width: 580px)": {
        left: "7.5rem",
      },
    }
  },
  dropdownFull: {
      width: "100%",
      height: "100%",
      display: "inline-block",
      position: "relative",
      '& > div, > div > div':{
          height: "100%",
          width: "100%"
      },
      '& button':{
          width: "100% !important",
          height: "100%",
          margin: "0 !important",
          borderRadius: "0 !important",
          fontFamily: '"Roboto", Arial, Helvetica, sans-serif !important',
      }
  },
  dropdown: {
      display: "inline-block",
      position: "relative",
      '& button':{
          margin: "0 !important"
      }
  },
  inputGroup:{
      ...inputGroup
  },
  contentIcon:{
      ...contentIcon,
      borderTopLeftRadius: "4px !important",
      borderBottomLeftRadius: "4px !important",
  },
  checkInput:{
      ...checkInput
  },
  labelCheck:{
      ...labelCheck
  },
  privacy:{
      fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
      display: "inline-block",
      verticalAlign: "top",
      position: "relative",
      fontWeight: 400,
      width: "100%",
      color: "#003b72",
      fontSize: ".96rem",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
      '& a':{
          color: "#333",
          marginLeft: 7,
          '&:hover':{
              backgroundColor: "#e6e6e6"
          }
      }
  },
  privacyWhite:{
      fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
      display: "inline-block",
      fontWeight: 400,
      verticalAlign: "top",
      position: "relative",
      width: "100%",
      color: "#fff",
      fontSize: ".96rem",
      "@media (max-width: 480px)": {
        fontSize: "1rem"
      },
      '& a':{
          color: "#99CEFF",
          marginLeft: 7,
          '&:hover':{
              color: "#FFF"
          }
      }
  },
  titeMessage:{
      fontSize: "1.2rem",
      lineHeight: "1.2em",
      fontWeight: 400,
  },
  textMessage:{
      fontSize: "1.1rem",
      lineHeight: "1.2em",
      fontWeight: 400,
  },
  icon: {
      width: "100px",
      marginBottom: "20px"
  }

});

export default navPillsStyle;
