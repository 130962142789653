import React from "react";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Cards/Card.jsx";
import CardBody from "components/Cards/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Video from "components/Video";

import cardBgStyle from "./cardBgStyle.jsx";

function monthToString(month){
    let monthString = ""
    switch (month) {
        case 1:
          monthString = "Ene"
        break;
        case 2:
          monthString = "Feb"
        break;
        case 3:
          monthString = "Mar"
        break;
        case 4:
          monthString = "Abr"
        break;
        case 5:
          monthString = "May"
        break;
        case 6:
          monthString = "Jun"
        break;
        case 7:
          monthString = "Jul"
        break;
        case 8:
          monthString = "Ago"
        break;
        case 9:
          monthString = "Sep"
        break;
        case 10:
          monthString = "Oct"
        break;
        case 11:
          monthString = "Nov"
        break;
        case 12:
          monthString = "Dic"
        break;
        default:
            break;
    }

    return monthString;
}
function CardBg({ ...props }) {
    const {
      classes,
      mini,
      large,
      fullHeight,
      image,
      title,
      description,
      title2,
      description2,
      descriptionOutside,
      category,
      noLayer,
      // iconRight,
      buttonText,
      buttonColor,
      urlTitle,
      urlBtn,
    //   variablesEntorno,
      urlVideo,
      dateDay,
      dateMonth,
      dateYear,
      // thumbnail
    } = props;
    // const raiz = variablesEntorno ? variablesEntorno.host : null;
    // const imagenFixed = imagen.split(" ").join("_")
    // const THUMBNAIL = ThumbGen(thumbnail, "medium")
    // const styleBg = urlVideo ? null : {backgroundImage: "url(" + THUMBNAIL + ")"}
    const stringMonth = monthToString(dateMonth);
    const styleBg = urlVideo ? null : {backgroundImage: "url(" + image + ")"}
    return (
        <React.Fragment>
        <Card background style={styleBg} className={classNames(classes.cardBg, (urlVideo || noLayer) && classes.noLayer, urlVideo && classes.cardVideo, mini && classes.cardBgMini, large && classes.cardBgLarge, fullHeight && classes.fullHeight)} >
        <CardBody background className={classNames(classes.resetCardBodyBackground, urlVideo && classes.resetCardBodyVideo, (dateDay && dateMonth && dateYear) && classes.resetCardBodyDate)}>
            {(dateDay && dateMonth && dateYear) && <div className={classes.date}>
                    <p className={classes.day}>{dateDay}</p>
                    <p className={classes.year}>{stringMonth}, {dateYear}</p>
                </div>}
            { urlVideo && <Video videoUrl={urlVideo} /> }

            { category &&  <div className={classes.informe}>
                                <h4 className={classes.category}>{category}</h4>
                            </div>
            }

            <a onClick={()=>breadcrumbsUpdate()} href={urlTitle}>
                <h3 className={urlVideo ? classes.cardTitleVideo : classes.cardTitle}>
                    {title}
                </h3>
            </a>

            {description && <p className={classes.cardDescription}>
                {description}
            </p>}

            {title2 && <a onClick={()=>breadcrumbsUpdate()} href={urlTitle}>
                <h3 className={urlVideo ? classes.cardTitleVideo : classes.cardTitle}>
                    {title2}
                </h3>
            </a>}

            {description2 && <p className={classes.cardDescription}>
                {description2}
            </p>}
            
            { buttonText && <Button
                disableRipple
                onClick={()=>breadcrumbsUpdate()}
                href={urlBtn}
                border={buttonColor ? false : true}
                color={buttonColor ? buttonColor : "borderWhite"}
                // className={iconRight ? classes.iconRight : classes.iconLeft}
            >
                {/* { iconRight ? "" : <FormatAlignLeft /> } */}
                {buttonText}
                {/* { iconRight && <NavigateNext />} */}
            </Button>}
            </CardBody>
        </Card>
        { descriptionOutside && <p className={classes.descriptionOutside}>{descriptionOutside}</p>}
        </React.Fragment>
    );
}

export default withStyles(cardBgStyle)(CardBg);
