import React from "react";
import htmlText from 'utils/htmlFormattedText';
import classNames from "classnames";
// core components
import Title from "components/Title";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./style.jsx";

class ContentQuoteSimple extends React.Component {
  render() {
    const { classes, data } = this.props;

    const DESCRIPTION = data.description.map((item,key) => {
      if (item.text) {
        return (
          <p className={classNames(classes.description, data.centerDescription && classes.textCenter)} key={key+'P-ContentQuoteSimple'+Math.floor(Math.random()*901)} >
            {htmlText(item.text)}
          </p>
        )
      } else {return <></>}
    })
    return (
        <GridItem xs={12} key={'ContentQuoteSimpleGrid'+Math.floor(Math.random()*901)} >
          {data.title && <GridContainer><Title line title={data.title} alignment="left" /></GridContainer>}
          {data.subtitulo && <h4 className={classes.subtitle}>{data.subtitulo}</h4>}
          {DESCRIPTION}
        </GridItem>
    );
  }
}

export default withStyles(style)(ContentQuoteSimple);
