import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import Card from "components/Cards/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import VideoDetailDialog from 'components/Dialog/VideoDetailDialog.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import video1 from "assets/img/svg/video_white.svg"
// Style
import bannerStyle from "./bannerStyle.jsx";
import VideoDetailDialog from "components/Dialog/VideoDetailDialog.jsx";

class Banner extends React.Component {
  constructor(props){
    super(props);

    this.state={
      width: 0,
      usedBGImage: '',
      open: false
    }
  }
  
  handleClickOpen = () => {
    this.props.pauseBanner(true);
    this.setState( () => ({ open: true }));
  };
  handleClose = () => {
    this.props.pauseBanner(false);
    this.setState( () => ({ open: false }));
  };

  updateWindowDimensions=()=>{
    if (this.state.width !== window.innerWidth){
      this.setState({ width: window.innerWidth});
    }
    this.updateBackgroundImage();
  }

  updateBackgroundImage=()=>{
    let testWidth = this.state.width;
    if (testWidth>768 && this.state.usedBGImage !== this.props.imageUrl){
      this.setState({usedBGImage: this.props.imageUrl})
    }else if (testWidth<769 && testWidth > 310 && this.state.usedBGImage !== this.props.mobileImgUrl){
      this.setState({usedBGImage: this.props.mobileImgUrl})
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({
      width: window.innerWidth,
    });
    this.updateBackgroundImage();
  }

  componentWillUnmount(){
    this.setState({displayIframe: 'hidden'});
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  render(){
    const {
      classes,
      alignContent,
      imageLogo,
      textRight,
      textCenter,
      animation,
      title1,
      sizeTitle1,
      boldTitle1,
      blueTitle1,
      title2,
      sizeTitle2,
      boldTitle2,
      blueTitle2,
      description,
      btnTitle,
      video,
      btnUrl,
      videoBg,
      videoBtnText,
      full,
      isOffice
    } = this.props;

    const dynamicClass = isOffice === true ? classes.bannerBgOffice : classes.bannerBg;
    this.updateWindowDimensions();

    
  const title1Classes = classNames ({
    [classes[sizeTitle1]]: sizeTitle1,
    [classes.bold]: boldTitle1,
    [classes.blue]: blueTitle1,
  })
  const title2Classes = classNames ({
    [classes[sizeTitle2]]: sizeTitle2,
    [classes.bold]: boldTitle2,
    [classes.blue]: blueTitle2,
  })
    
    return (
      // <Card style={{ backgroundImage: "url(" + this.state.usedBGImage + ")" }} className={classes.bannerBg} >
      <Card style={{ backgroundImage: "url(" + this.state.usedBGImage + ")" }} className={classNames(dynamicClass, full && classes.bannerFull)} >
        { videoBg && <div className={classes.contentVideo}>
          <video controls muted autoPlay loop>
            {/* <source src="foo.ogg" type="video/ogg" /> */}
            <source src={videoBg} type="video/mp4" />
            Tu navegador no implementa el elemento <code>video</code>
          </video>
        </div>}
        { !full && <div className={classes.layerGray}></div>}
        {full && <div className={classes.layerDegrade}></div>}
          <div className={classes.container}>
            <GridContainer justify={alignContent ? alignContent : null} alignItems="center">
              <GridItem lg={12} md={12} sm={12}>
                <div className={classNames(textCenter && classes.textCenter, textRight && classes.textRight)}>
                  {imageLogo && <img className={classNames(textCenter && classes.imgCenter, textRight && classes.imgRight, classes.logoSlider, 'animated delay-1s fast', animation)} src={imageLogo} alt="" />}
                    <div className={classNames(classes.contentTitle, 'animated delay-1s fast', animation)}>
                      { title1 && <h1 className={title1Classes}>{title1}</h1> }
                      { title2 && <h1 className={title2Classes}>{title2}</h1> }
                    </div>
                    { description && <p className={classes.description}> {description} </p> }
                    {btnTitle && <div className={classNames('animated delay-1s fast', animation)}> <Button size="" disableRipple href={btnUrl} target="_blank" color="primary">
                                    {btnTitle}
                                  </Button>
                                </div>
                    }
                    {videoBtnText && <div className={classNames('animated delay-1s fast', animation)}> 
                      <Button style={{margin:"1rem 0 0 0"}} size="" disableRipple target="_blank" color="primary" onClick={this.handleClickOpen}>
                        {videoBtnText} <img alt="video" title="video" src={video1} className={classes.iconBtn}/>
                      </Button>
                    </div>
                    }
                    <VideoDetailDialog
                      open={this.state.open}
                      onClose={()=>this.handleClose()}
                      video={video ? video : null}
                    /> 
                </div>
              </GridItem>
            </GridContainer>
        </div>
      </Card>
    );
  }
}

export default withStyles(bannerStyle)(Banner);
