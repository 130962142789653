import React from "react";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// core components
import Title from "components/Title";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NotiCard from "components/Cards/NotiCard"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class ListDescriptiveCard extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      show: -1,
    }
  }

  CarouselNotiCardStructure = (data,key,mainBtnUrl) => {
    let gridSize = 6;
    if (this.props.data && this.props.data.data){
      if (this.props.data.data.length === 3) gridSize=4
      if (this.props.data.data.length > 3) gridSize=3
    }
    return( 
      <GridItem xs={12} sm={6} md={gridSize}>
        <NotiCard blog subtitleUrl={mainBtnUrl} {...data} key={key} />
      </GridItem>
    )
  }
  render() {
    const { title, description, mainBtnTxt, mainBtnUrl } = this.props.data;
    var CARDS=[]

    if (this.props.data && this.props.data.data){
      this.props.data.data.sort((card1, card2) => card1.id - card2.id)
      .map((item,key)=>{
        if (key <4){
          CARDS.push(this.CarouselNotiCardStructure(item,key,mainBtnUrl));
        }
        return null;
      })
    }
    return (
      <React.Fragment>
        { title && <Title large title={title} alignment="center" description={description} />}
        {CARDS}
        { mainBtnTxt &&
          <GridItem xs={12}>
            <div style={{textAlign:'center'}}>
              <Button disableRipple  size="" border color="borderSecondary" onClick={()=>breadcrumbsUpdate()} href={mainBtnUrl} >
                {mainBtnTxt}
              </Button>
            </div>
          </GridItem>
        }
      </React.Fragment>
    );
  }
}

export default withStyles(style)(ListDescriptiveCard);

