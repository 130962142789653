import React from "react";
import classNames from "classnames";
import htmlText from 'utils/htmlFormattedText';
import Title from "components/Title";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote"
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";
import Button from "components/CustomButtons/Button.jsx";

class ContentQuote extends React.Component {
  render() {
    const { classes, data, medium } = this.props;
    let DESCRIPTION=[]; 
    let QUOTEDESCRIPTION = [];
    if (data.data !== undefined){
      if (data.data.description !== undefined){
        DESCRIPTION = data.data.description.map((item,key) => {
          
          return (
            <p className={classes.description} key={key}>
              {htmlText(item.text)}
            </p>
          )
        })
      }
      if ( data.data.quoteDescription !== undefined){
        QUOTEDESCRIPTION = data.data.quoteDescription.map((item,key )=> {
          if (item.text) {
            return (
              <p className={classNames(classes.description, false ? classes.bulletReverse : classes.bullet)} key={key}>
                {htmlText(item.text)}
              </p>
            )
          } 
          return null;
        })
      }
    } else if (data.description !== undefined){ 
      DESCRIPTION = data.description.map((item,key) => {
        return (
          <p className={classes.description} key={key}>
            {htmlText(item.text)}
          </p>
        )
      })
    }
    
    if (data.quoteDescription !== undefined){
      QUOTEDESCRIPTION = data.quoteDescription.map((item,key )=> {
        if (item.text) {
          return (
            <p className={classNames(classes.description, false ? classes.bulletReverse : classes.bullet)} key={key}>
              {htmlText()}
            </p>
          )
        } 
        return null;
      })
    }
    return (
      <React.Fragment>
        { (data.title || 
        data.subtitleTop || 
        (DESCRIPTION.length > 0 && DESCRIPTION[0] !== undefined ) || 
        data.subtitle || 
        (QUOTEDESCRIPTION.length > 0 && QUOTEDESCRIPTION[0] !== undefined )) && <GridItem xs={12} md={12} lg={12}>
          {data.title && <GridContainer><Title title={data.title} /></GridContainer>}
          {data.subtitleTop && <h4 className={classes.subtitleTop}>{data.subtitleTop}</h4>}
          {(data.bulletsTop && DESCRIPTION.length > 0 && DESCRIPTION[0] !== undefined ) ? <Quote>
            {DESCRIPTION}
          </Quote> : (DESCRIPTION.length > 0 && DESCRIPTION[0] !== undefined ) ? DESCRIPTION : null}
          {data.subtitle && <h4 className={classes.subtitle}>{data.subtitle}</h4>}
          {(data.bullets && QUOTEDESCRIPTION.length > 0 && QUOTEDESCRIPTION[0] !== undefined ) ? <Quote>
            {QUOTEDESCRIPTION}
          </Quote> : (QUOTEDESCRIPTION.length > 0 && QUOTEDESCRIPTION[0] !== undefined ) ? QUOTEDESCRIPTION : null}
        </GridItem>}
        {(data.urlBtn && data.txtBtn) &&<GridItem xs={12} md={medium ? 10 : 12} lg={medium ? 8 : 12} style={{textAlign: data.btnPosition ? data.btnPosition : 'center' }} >
          <Button size="smNull" href={data.urlBtn} target="_blank" border color="borderSecondary" >{data.txtBtn}</Button>
      </GridItem>}
        {data.documentsTexts && <GridItem>
          {data.documentsTexts.map(text => {
            return <p>{text.txtDocument+" "}<a href={text.linkUrl}>{text.linkText}</a></p>
          }) }
          </GridItem>}
      </React.Fragment>
    );
  }
}

export default withStyles(style)(ContentQuote);
