const Altivator = (imageUrl) => {
  if (imageUrl && imageUrl !== ''){
    var regex = /\/(.*?)\./;
    var matched = regex.exec(imageUrl)[1]
    let imageName = matched.substring(
      matched.lastIndexOf("/") + 1,
    ).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split("_").join(' ')
    imageName = imageName.charAt(0).toUpperCase() + imageName.substring(1);
    return imageName;
  }
}

export default Altivator;