import {
    primaryColor,
    title,
    defaultFont,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const titleStyle = {
    colorPrimary: {
        color: primaryColor[0] + "!important",
    },
    title:{
        ...title,
        color: '#272727',
        fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
        margin: '0 !important',
        fontSize: "1.85rem !important",
    },
    titleLarge:{
        ...title,
        color: '#272727',
        textAlign: 'center',
        margin: '2rem 0 0 0 !important',
        fontSize: "2.3rem !important",
        "@media (max-width: 599px)": {
          fontSize: "1.7rem !important",
        }
    },
    description:{
        ...defaultFont,
        textAlign: 'center',
        fontSize: '1.1rem',
        marginBottom: '50px !important',
        // marginBottom: '3.5rem !important',
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        "@media (max-width: 766px)": {
          width: "100%",
        }
    },
    subTitle:{
        ...title,
        minHeight: "auto !important",
        fontWeight: "400 !important",
        color: '#272727',
        fontSize: "1.2rem !important",
        lineHeight: "1.3em !important",
        marginTop: "1rem !important",
        marginBottom: "1rem !important",
        "@media (max-width: 599px)": {
          fontSize: "1.3rem !important",
        }
    },
    titleLine:{
      position: 'relative',
      textTransform: 'none',
      marginBottom: '2.7rem !important',
      '&:after': {
        content: '" "',
        position: "absolute",
        bottom: -18,
        left: 0,
        width: 45,
        height: 3,
        backgroundColor: primaryColor[0]
      },
    }

  };
  
  export default titleStyle;
  