import {
  secondaryColor,
  complementarioColor,
  primaryColor,
  cardTitle,
  defaultFont,
  blackColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const cardBgStyle = {

  cardBg: {
      borderRadius: 0,
      margin: "0 !important",
      '& > div': {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
      },
      '&:after': {
          borderRadius: '0 !important',
      },
    //   '& p': {
    //       fontWeight: 500,
    //       "@media (max-width: 768px)": {
    //         lineHeight: '1.4em',
    //       },
    //   }
  },
  cardVideo: {
      boxShadow: "none !important",
      '& > div': {
        justifyContent: "flex-start",
      },
  },
  fullHeight: {
    height: "100%",
  },
  noLayer: {
      "&:after": {
          backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0) !important",
      }
  },

  cardBgMini: {
      height: "initial",
      borderRadius: 0,
      margin: "0 !important",
      '& > div': {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          '& h3': {
              fontSize: "1.3rem !important"
          }
      },
      '&:after': {
          borderRadius: '0 !important',
      }
  },

  cardBgLarge: {
      minHeight: 340,
    //   minHeight: 400,
  },

  cardTitleVideo: {
      ...cardTitle,
      color: primaryColor[0] + "  !important",
      margin: 0 + " !important",
      marginBottom: "18px !important",
      fontSize: "1.86rem !important",
      lineHeight: "1.1em !important"
    //   overflow: "hidden",
    //   display: "-webkit-box", 
    //   WebkitLineClamp: 2,
    //   WebkitBoxOrient: "vertical",
    //   "@media (max-width: 576px)": {
    //     WebkitLineClamp: 3,
    //   },
  },
  cardTitle: {
      ...cardTitle,
      color: whiteColor + "  !important",
      margin: 0 + " !important",
      marginBottom: "1rem !important",
      textTransform: "uppercase",
      fontSize: "1.86rem !important",
      lineHeight: "1.1em !important"
    //   overflow: "hidden",
    //   display: "-webkit-box", 
    //   WebkitLineClamp: 2,
    //   WebkitBoxOrient: "vertical",
    //   "@media (max-width: 576px)": {
    //     WebkitLineClamp: 3,
    //   },
  },
  titleDefault: {
      ...cardTitle,
    //   fontSize: '1.2rem',
      margin: 0 + " !important",
      marginTop: "1rem !important",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical"
  },
  cardDescription: {
      ...defaultFont,
      color: "#ccc !important",
      lineHeight: '1.65em !important',
      marginBottom: "28px !important"
  },
  informe: {
      display: "inline-block",
      position: "relative",
      '&:after': {
          content: '" "',
          position: "absolute",
          width: 50,
          height: 2,
          backgroundColor: secondaryColor,
          left: 0,
      },
      '& h4': {
          margin: 0,
          textTransform: "capitalize",
          fontSize: "1.1rem",
          fontWeight: "700",
      },
  },
  descriptionOutside: {
      marginTop: "3rem",
      fontSize: "14px",
      color: "rgba(" + hexToRgb(blackColor) + ", 0.76)",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 6,
      WebkitBoxOrient: "vertical",
      textAlign: "justify",
      "@media (max-width: 599px)":{
        lineHeight: "18px",
        fontSize: "14px"
      },
  },

  resetCardBodyBackground: {
      maxWidth: "90%",
      minHeight: "27rem !important"
  },
  resetCardBodyDate: {
      maxWidth: "100%",
      width: "100%",
      minHeight: "286.39px !important"
  },
  resetCardBodyVideo: {
      width: "100%",
      maxWidth: "100%",
      padding: 0,
      margin: 0,
      '& h3': {
        color: primaryColor[0] + "  !important",
        padding: "0 1rem"
      },
      '& p': {
        color: "rgba(" + hexToRgb(blackColor) + ", 0.76) !important",
        padding: "0 1rem"
      }
  },

  wraperSteps: {
      padding: "1.5rem 0",
      display: "flex",
      alignItems: "center",
      fontSize: "1.1rem"
  },

  next: {
      color: complementarioColor,
      fontSize: "1.8rem"
  },

  formFix: {
      minWidth: "260px",
      paddingTop: "16px !important",
      '& input': {
          color: "white !important"
      }
  },

  iconRight: {
      '& svg': {
          margin: '0 -4px 0 4px !important'
      }
  },

  iconLeft: {
    '& svg': {
        margin: '0 4px 0 0px !important'
    }
    },

    date: {
        width: "90px",
        height: "90px",
        backgroundColor: primaryColor[0],
        display: "inline-flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "absolute",
        top: "2.1rem",
        left: "2.1rem",
        fontWeight: "400",
        '& p': {
          color: "white !important",
          marginBottom: 0,
          fontWeight: "400",
        },
        '&:after':{
            content: "' '",
            border: '.8rem solid',
            borderColor: 'transparent',
            borderTopColor: primaryColor[0],
            position: 'absolute',
            bottom: '-1.5rem',
            left: 'calc(50% - .8rem)'
        }
    },
    day: {
        fontSize: "1.7rem",
        fontWeight: "400",
        // marginBottom: ".5rem !important"
    },
    year: {
        fontSize: ".9rem",
        fontWeight: "400",
    }
};

export default cardBgStyle;
