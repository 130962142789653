import React, { Component } from "react";
import { createBrowserHistory } from "history";
import htmlText from 'utils/htmlFormattedText';
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
// @material-ui/icons
// import Calendar from "@material-ui/icons/CalendarToday";
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// core components
import Card from "components/Cards/Card.jsx";
import CardHeader from "components/Cards/CardHeader.jsx";
import CardBody from "components/Cards/CardBody.jsx";
import CardFooter from "components/Cards/CardFooter.jsx";
// import Muted from "components/Typography/Muted.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import CardBg from "components/Cards/CardBg";
import BtnRedes from 'components/CustomButtons/BtnRedes.jsx'
import notiCardStyle from "./notiCardStyle.jsx"; 
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import VideoDetailDialog from 'components/Dialog/VideoDetailDialog.jsx';

import imgDefault from "assets/img/jpg/ernesto.jpg";
import play from "assets/img/png/youtube_dark.png";
import Altivator from 'utils/imageAltivator';
import ThumbGen from 'utils/thumbPicker';
import Modal from 'components/Modal'
class Noticard extends Component {
  constructor(props){
    super(props);
    this.state = {
      src: this.props.image,
      imgError: false,
      open: false,
      modalOpen: false
    }

    this.onError = this.onError.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ 
      modalOpen: !this.state.modalOpen,
    });
  }

  handleClickOpen = () => {
    this.setState( () => ({ open: true }));
  };
  handleClose = () => {
    this.setState( () => ({ open: false }));
  };

  handleDownloadFile = (url, title) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = title;
    link.rel="noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onError(){
    if(!this.state.imgError){
      this.setState({
        src: imgDefault,
        imgError: true
      });
    }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  encodeHtmlEntity = (str) => {
    // str = str.replace(/\//g, '%2F');
    str = str.replace(/=/g, '%3D');
    str = str.replace(/\?/g, '%3F');
    str = str.split(' ').join('%20');
    return str;
  };

  checkPDF = (isSearch, urlBtn) => {
    let valid = false
    if (isSearch && (urlBtn.includes('.pdf') || urlBtn.includes('.PDF'))) {
      valid = true;
    } 
    return valid;
  }
  
  render(){
  const {
    classes, 
    image,
    imageUrl,
    bgImage,
    plainImage,
    titulo,
    title,
    autor,
    titleUrl,
    subtitleUrl,
    reverse,
    miniImage,
    largeImage,
    contentImage,
    sizeTitle,
    wrapTitle,
    wrapDescription,
    download,
    center,
    subtitle,
    descripcion,
    description,
    plain,
    horizontal,
    cardSearch,
    noImagen,
    blog,
    share,
    noMargin,
    participacion,
    empresa,
    descripcionEmpresa,
    descriptionHeightCarrousel,
    web,
    urlWeb,
    urlMore,
    buttonText,
    buttonColor,
    urlBtn,
    more,
    analist,
    date,
    tags,
    line,
    contentWeb,
    // imageNotiCardFull,
    rssLink,
    urlVideo,
    documentUrl,
    thumbnail,
    isSearch,
    searchTarget
  } = this.props;
  const {variablesEntorno} = this.props;
  let dateDay='';
  let dateMonth='';
  let dateYear='';
  if (date && typeof(date)==="number"){
    const spreadDate = new Date(date)
    dateDay = spreadDate.getDate();
    dateMonth = spreadDate.getMonth()+1;
    dateYear = spreadDate.getFullYear();
  }
  

  const { src } = this.state; 

  const cardClasses = classNames ({
    [classes.horizontal]: horizontal,
    // CH 14/NOV/2019 Se agrega clase para poner maxWidth
    [classes.vertical]: !horizontal,
    [classes.cardSearch]: cardSearch,
    [classes.reverse] : reverse,
    [classes.line]: line
  })
  const titleClasses = classNames ({
    [classes.cardTitle]: title,
    [classes.textCenter]: center,
    [classes[sizeTitle]]: sizeTitle,
    [classes[wrapTitle]]: wrapTitle,
  })

  const descriptionClasses = classNames({
    [classes.description]: !horizontal,
    [classes.descriptionLeft]: horizontal,
    [classes.descriptionHeightCarrousel]: descriptionHeightCarrousel,
    [classes[wrapDescription]]: wrapDescription,
    [classes.lineThree]: cardSearch,
  })

  var hist = createBrowserHistory();
  const HOST = "http://"+window.location.hostname;
  const TITLE = titulo ? titulo : title ? title : null;
  const DESCRIPTION = descripcion ? descripcion : description ? description : null;
  const IMAGE = image ? image : imageUrl ? imageUrl : bgImage ? bgImage : null;
  const linkTitle= TITLE ? '&title='+TITLE : "";
  const currentLink=variablesEntorno ? hist.location.pathname+hist.location.search : null;
  const linkedinSharingLink = urlMore ? 'https://www.linkedin.com/sharing/share-offsite/?url='+this.encodeHtmlEntity(HOST+urlMore) : 'https://www.linkedin.com/shareArticle?mini=true&url='+this.encodeHtmlEntity(HOST+currentLink+linkTitle)
  const linkedInLink = linkedinSharingLink
  const whatsappLink = urlMore ? "https://api.whatsapp.com/send?text="+TITLE+' '+HOST+urlMore : "https://api.whatsapp.com/send?text="+TITLE+' '+HOST+currentLink;
  const twitterLink = urlMore ? 'https://twitter.com/intent/tweet?text='+TITLE+'&url='+HOST+urlMore : 'https://twitter.com/intent/tweet?text='+TITLE+'&url='+HOST+currentLink
  const facebookLink = urlMore ? 'https://www.facebook.com/sharer/sharer.php?u='+HOST+urlMore : 'https://www.facebook.com/sharer/sharer.php?u='+HOST+currentLink
  const htmlDescription = (contentWeb) => (<div dangerouslySetInnerHTML={ {__html: contentWeb} }/>)
  const CONTENT = htmlDescription(contentWeb);
  const OPEN = this.state.open
  const THUMBNAIL = ThumbGen(thumbnail, "small")
  const isPDF = this.checkPDF(isSearch, urlBtn)
  return (
    <React.Fragment>
      <Card  
      plain={plain}
      className={cardClasses}
      blog={blog}
      noMargin={noMargin}
      border
      >
        { 
          !noImagen ? 
          <CardHeader image plain={plain} style={{margin: 0}}> 
            { 
              bgImage ? 
              <CardBg image={IMAGE} dateDay={dateDay} dateMonth={dateMonth} dateYear={dateYear} noLayer titulo={title} thumbnail={thumbnail} /> :
              urlVideo ?
              <div className={classes.contentPlay} onClick={this.handleClickOpen} >
                <LazyLoadImage className={ classNames(horizontal ? classes.imagenLeft : classes.imagen, miniImage && classes.miniImage, largeImage && classes.largeImage, contentImage && classes.contentImage, analist && classes.analistImage)} src={IMAGE ? THUMBNAIL : src} alt={Altivator(IMAGE)} title={Altivator(IMAGE)} onError={this.onError}/>
                <img src={play} className={classNames(classes.play, !horizontal && classes.noPaddingX)} alt="youtube" title="youtube play" />
              </div> : ""
            }
            {
              plainImage && <img alt="" title="" src={plainImage} />
            }
            <VideoDetailDialog
                  open={OPEN}
                  onClose={this.handleClose}
                  video={documentUrl ? documentUrl : urlVideo ? urlVideo : null}
                />
          </CardHeader> : '' 
        }
          
        <CardBody plain={plain} className={classes.cardBody}>
          { 
            horizontal ? 
            <GridContainer spacing={4}>
              <GridItem xs={12} md={9}>
              { participacion && <h4 className={classes.participacion}><span>{participacion}</span></h4>}
              { empresa && <div className={classes.empresa}> <h4>{empresa}</h4> </div>}
              { 
                titleUrl ? 
                TITLE && 
                <div>
                <a onClick={()=>breadcrumbsUpdate()} href={titleUrl ? titleUrl : ''}>
                  <h4 title={TITLE} className={titleClasses} style={{display: "inline"}}>{this.capitalize(titulo)}</h4>
                </a></div> : 
                TITLE && 
                <div>
                <h4 title={TITLE} className={titleClasses}>
                  {this.capitalize(TITLE)}
                </h4>
                </div>
              }
              { blog && <div className={classes.flexContentBlueText}>
            {date && <p className={classes.autor}><FontAwesomeIcon icon={faCalendarAlt} />{" "}{date}</p>}
                  { autor && <React.Fragment><span>|</span> <p className={classes.autor}><FontAwesomeIcon icon={faUser} />{" "}{autor}</p> </React.Fragment>}
                  {/* <React.Fragment><span>|</span> <p className={classes.autor}><FontAwesomeIcon icon={faComments} /> 15 Comentarios</p> </React.Fragment> */}
                </div>}
              { subtitle && <div className={classes.informeDate}> <h4>{subtitle}{date ? ' / ' : ''}</h4> </div>}
              { 
                !cardSearch && 
                tags && 
                <div> 
                  {
                    Array.isArray(tags) ? 
                    tags.map(item=>{ 
                      return item ? (<div className={classes.tag}>#{this.capitalize(item)}</div>) : ''
                    }) 
                    : 
                    <div className={classes.tag}>#{this.capitalize(tags)}</div>
                  }
                </div>
              }
              <div className={classes.contentTextBtn}>
              <p className={descriptionClasses}> {DESCRIPTION}</p>
              </div> 
              </GridItem>
              {(isSearch && buttonText && urlBtn) &&<GridItem xs={12} md={3}>
                <Button 
                  // href={urlBtn} 
                  // target={searchTarget} 
                  // rel="noopener noreferrer" 
                  onClick={this.handleDrawerToggle}
                  disableRipple 
                  size="" 
                  border 
                  color="borderSecondary" 
                  className={classes.marginBtnTopWeb}
                >
                  {buttonText}
                </Button>
                <Modal
                  bgWhite
                  footerLogo
                  open={this.state.modalOpen}
                  onClose={()=>this.handleDrawerToggle()}
                >
                  <div style={{textAlign:"left"}}>
                    {TITLE && <h2>{TITLE}</h2>}
                    <p><span>{date}</span> | <a rel="category tag">{autor}</a></p>
                    <p>{DESCRIPTION}</p>
                    {isPDF && <a href={urlBtn} target="_blank" rel="noopener noreferrer">Descargar PDF</a> }
                  </div>
                </Modal>
              </GridItem>}
            </GridContainer> :
            <React.Fragment>
                { (subtitleUrl && subtitle) && <h4 className={classes.cardTitle}> <a onClick={()=>breadcrumbsUpdate()} href={subtitleUrl} >{subtitle}</a></h4>}
                { (!subtitleUrl && subtitle) && <h4 className={titleClasses}>{subtitle}</h4>}
                { autor && <p className={classes.autor}><FontAwesomeIcon icon={faUser} /> {autor}</p> }
              { 
                !titleUrl ? 
                !bgImage && 
                "" :
                // <h3 title={TITLE} className={titleClasses}>{TITLE}</h3> :
                !bgImage &&
                <a onClick={()=>breadcrumbsUpdate()} href={titleUrl ? titleUrl : ''}>
                  <h3 title={TITLE} className={titleClasses}>{TITLE}</h3>
                </a>
              }
            </React.Fragment> 
          }
          { horizontal ? "" : DESCRIPTION && <p className={descriptionClasses} > {htmlText(DESCRIPTION)}</p>}
          { contentWeb && <div className={classes.contentText}>{CONTENT}</div>}
          { descripcionEmpresa && <p className={classes.descriptionComplete}> {descripcionEmpresa} </p>}
          { web && <a href={urlWeb ? urlWeb : '#'} target="_blank" rel="noopener noreferrer" className={classes.web}> {web} </a>}
          {
            download && 
            <div className={classes.contentBtn}>
              <Button
                round
                size="sm"
                color={buttonColor ? buttonColor : "complementario"}
                onClick={() => this.handleDownloadFile(urlBtn, TITLE)}
              >
                Descargar PDF
              </Button>
            </div>
          }
          { 
            (!isSearch && buttonText && urlBtn) && 
            <Button disableRipple onClick={()=>breadcrumbsUpdate()} href={urlBtn} target="_self" size="" border color="borderGray" style={{marginTop: "1.4rem"}}>{buttonText}</Button>
          }
          {
            cardSearch &&
            <GridContainer justify="space-between">
              <GridItem xs={12} sm={6} lg={8}>
                {
                  tags && 
                  <div>{
                    Array.isArray(tags) ? tags.map((item, index)=>{
                      return <div key={`tagKey_${index}`} className={classes.tag}>#{item}</div>
                    }) 
                    : 
                    <div key={'tagKey'} className={classes.tag}>#{tags}</div>
                  }</div>
                }
              </GridItem>
              {
                more && 
                <GridItem xs={12} sm={6} lg={4} className={classes.alignLink}>
                  <a onClick={()=>breadcrumbsUpdate()} href={urlMore} className={classes.linkIcon}>Leer Más <KeyboardArrowRight /></a>
                </GridItem>
              }
            </GridContainer>
          }
          { 
            analist && 
            <div className={classes.spaceBetwen}>
              
              <Button round outlined simple size="xs" onClick={()=>breadcrumbsUpdate()} href={urlBtn ? urlBtn : '#'} color={buttonColor ? buttonColor : "primary"}>
                Ver Informes
              </Button>
            </div>
          } 
        </CardBody>

        { 
          share && 
          <CardFooter>
            <div className={classes.contentBtn}>
              <BtnRedes rssLink={(rssLink && rssLink !== '') ? HOST+rssLink : null} twitterLink={twitterLink} linkedInLink={linkedInLink} whatsappLink={whatsappLink} facebookLink={facebookLink} />
            </div>
          </CardFooter> 
        }
      </Card>
    </React.Fragment>
  );
}
}

export default withStyles(notiCardStyle)(Noticard);
