import {
    cardTitle,
    defaultFont,
} from "assets/jss/material-kit-pro-react.jsx";
const style = {
    cardForm: {
        padding: "3rem !important",
        textAlign: "center",
        marginTop: "0 !important"
        // "@media (max-width: 576px)": {
        //   maxWidth: "540px"
        // },
    },
    title: {
        ...cardTitle,
        fontSize: "1.5rem !important",
        lineHeight: "1.36em !important",
        textTransform: "uppercase",
        fontWeight: "normal !important",
        margin: "0 !important",
        color: "#003B72",
        marginBottom: "1.2rem !important",
    },
    text: {
        ...defaultFont,
        display: "block",
        fontSize: "1.1rem !important",
        fontFamily: `"Lato", sans-serif !important`,
        color: "#272727 !important",
        marginBottom: ".6rem",
        textTransform: "none !important",
    },
};

export default style;
