import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/TimeLines
// core components
import Card from "components/Cards/Card.jsx";
import CardBody from "components/Cards/CardBody.jsx";
import Altivator from 'utils/imageAltivator';
// images


import cardTimeLineStyle from "./cardTimeLineStyle.jsx";

function CardTimeLine({ ...props }) {

  const { 
      classes,
      year,
      image,
      description, } = props;
    return (
        <Card profile className={classes.cardTimeLine}>
            { image && <img src={image} alt={Altivator(image)} title={Altivator(image)} className={classes.imageLine} />}
            { year && <div className={classes.contentYear}>
                    <p className={classes.year}>- {year} -</p>
                </div>
            }
            <CardBody plain className={classes.noPaddingX}>
                <p className={classes.description}>{description}</p>
            </CardBody>
        </Card>
      );
}

export default withStyles(cardTimeLineStyle)(CardTimeLine);
