import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import FormContact from "components/Form/FormContact"

import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
// import logoSmall from "assets/img/png/logo_small.png"


class Title extends Component {
  render(){

//   const onKeyDownHandler = e => {
//     if (e.key === 'Enter') {
//       let value = searchString;
//       hist.push(props.hostingVariables.searchHost+'?mainSearch="'+value+'"');
//                     window.location.assign(hist.location.pathname+hist.location.search);
//     }
//   };

  const { classes, hostingVariables, drawerHandle, 
    // handleThanks
   } = this.props;
  const { contactFormData, urlLogo } = hostingVariables;
  const logoSmall = urlLogo;
  
  return (
    <React.Fragment>
        <div className={classes.headerDrawer}>
          <h5 className={classes.titleSearch}>{contactFormData.data.title}</h5>
        </div>
        <div className={classes.bodyDrawer}>
            <GridContainer justify="center" spacing={2} style={{textAlign:"center"}}>
                <FormContact drawerHandle={drawerHandle} contactFormData={contactFormData ? contactFormData : {}} />
            </GridContainer>
        </div>
        <div className={classes.footerMenuMovil}>
            <img src={logoSmall} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logoSearch} />
        </div>
    </React.Fragment>
  );
}
};

export default withStyles(headerStyle)(Title);
