import React from "react";
import * as axios from 'axios';
// core components
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableGeneralStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Button from "components/CustomButtons/Button.jsx";
// import Loading from "components/Loader/Loading";

class TableTaxCertificates extends React.Component {
  constructor(props){
    super(props);

    this.state={
      selectedContract: "",
      selectedYear: "",
      pdfArray: [],
      xmlArray: []
    }
  }

  getCertType=(type)=>{
    let certType="";

    switch (type) {
      case 1:
        certType = "Dividendos";
        break;

      case 2:
        certType = "Dividendos 2013";
        break;

      case 3:
        certType = "Dividendos SIC";
        break;

      case 4:
        certType = "Derivados";
        break;

      case 5:
        certType = "Enajenación";
        break;

      case 6:
        certType = "Fibras";
        break;

      case 7:
        certType = "Intereses";
        break;

      default:
        break;
    }

    return certType;
  }

  convertFilePDF=(fileStr, certType)=>{
    var b64 = fileStr;
    const crtTypeName = this.getCertType(certType);
    const certDate = this.state.selectedYear;
    // Insert a link that allows the user to download the PDF file
    var link = document.createElement('a');
    link.innerHTML = '';
    link.download = 'Constancia_'+crtTypeName+'_'+certDate+'.pdf';
    link.href = 'data:application/pdf;base64,' + b64;
    link.click();
  }

  convertFileXML=(fileStr, certType)=>{
    var b64 = fileStr;
    const crtTypeName = this.getCertType(certType);
    const certDate = this.state.selectedYear;
    // Insert a link that allows the user to download the XML file
    var link = document.createElement('a');
    link.innerHTML = '';
    link.download = 'Constancia_'+crtTypeName+'_'+certDate+'.xml';
    link.href = 'data:application/xml;base64,' + b64;
    link.click();
  }

  getTaxCertData= async (fileType, certType, )=>{
    const endpoint = this.props.data.endpoint;
    let response = null;
    let year = this.state.selectedYear;
    await axios.post(
      endpoint,
      {
        "contrato": this.state.selectedContract,
        "anoConsulta": year,
        "pdf": fileType,
        "constanciaTipo": certType
      },
      {
        "headers": {
          "Authorization": this.props.token
        }
      }
    ).then(resp => {
      if (resp.status === 200){
        if(resp.data.code === "401") {
          this.props.closeSession();
        } else {
          if (resp.data){
            if (resp.data.constancia){
              response = resp.data.constancia
              if (fileType === 0){
                this.convertFileXML(response, certType);
              } else if (fileType===1){
                this.convertFilePDF(response, certType);
              }
            }
          }
        }
      }
    }).catch(error =>{
      console.log(error)
    });
    
    return response;
  }

  getyearList=(currentYear)=>{
    const numOfYears = currentYear - 2018
    
    let yearList= []
    for (let i = 1 ; i <= numOfYears; i++) {
      if (i<=5){
        const tempYear = currentYear-(i);
        yearList.push(tempYear)
      }
    }
    return yearList;
  }

  yearListStructure=()=>{
    const { classes} = this.props
    const currentYear = new Date().getFullYear();
    const tempYearList = this.getyearList(currentYear);

    return (
      tempYearList.sort((year1,year2)=>year2-year1).map(year=>{
        return <Button 
          disableRipple
          className={classes.userPopover} 
          color="transparent" 
          key={year}
          onClick={()=>this.setState({selectedYear: year})}
        >
          {year}
        </Button>
      })
    )
  }
  
  dropdownListStructure=()=>{
    const { classes, contrato, contratos } = this.props;
    if (contratos) {
        return contratos.sort((item1, item2)=>item1 - item2).map((item, key)=>
        <Button 
          disableRipple
          className={classes.userPopover} 
          color="transparent" 
          key={key}
          onClick={()=>this.setState({selectedContract: item})}
        >
          {item}
        </Button>
      )  
    } else if (contrato){
      return [<Button 
      disableRipple
      className={classes.userPopover} 
      color="transparent"
      onClick={()=>this.setState({selectedContract: contrato})}
    >
      {contrato}
    </Button>]
    } else {
      return []
    }
  }

  structureTopRowList=()=>{
    let rowList =[];
    const {classes} = this.props;

    for (let i = 1; i < 5; i++) {
      const certTitle = this.getCertType(i);
      rowList.push(  
        <React.Fragment key={'top-'+i}>
          <GridItem xs={6}>
            <p className={classNames(classes.text, classes.pl)}>{certTitle}</p>
          </GridItem>
          <GridItem xs={3}>
            <Button onClick={()=>this.getTaxCertData(1,i)} disableRipple size="" border color="borderGrayPrimary" style={{height: "42px"}}>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </GridItem>
          <GridItem xs={3}>
            <Button onClick={()=>this.getTaxCertData(0,i)} disableRipple size="" border color="borderGrayPrimary" style={{height: "42px"}}>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </GridItem>

          {(i < 4 ) && <GridItem xs={12}>
            <div className={classes.divider}></div>
          </GridItem>}
        </React.Fragment>
      )
    }
    
    return rowList;
  }

  structureBottomRowList=()=>{
    let rowList =[];
    const {classes} = this.props;

    for (let i = 5; i < 8; i++) {
      const certTitle = this.getCertType(i)
      rowList.push(  
        <React.Fragment key={'bottom-'+i}>
          <GridItem xs={6}>
            <p className={classNames(classes.text, classes.pl)}>{certTitle}</p>
          </GridItem>
          <GridItem xs={3}>
            <Button onClick={()=>this.getTaxCertData(1,i)} disableRipple size="" border color="borderGrayPrimary" >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </GridItem>
          <GridItem xs={3}>
            <Button onClick={()=>this.getTaxCertData(0,i)} disableRipple size="" border color="borderGrayPrimary" >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </GridItem>

          {(i < 7 ) && <GridItem xs={12}>
            <div className={classes.divider}></div>
          </GridItem>}
        </React.Fragment>
      )
    }
    return rowList;
  }

  componentDidMount(){
    const contract = this.props.contrato;
    let date= new Date();
    const dateYear = date.getFullYear()-1
    this.setState({
      selectedContract: contract,
      selectedYear: dateYear,
    })
  }

  render() {
    const { classes, data, userName } = this.props;
    const { title, otherTitle } = data;
    const {selectedContract, selectedYear} = this.state;
    const DROPLISTCONTRACTS = this.dropdownListStructure();
    const DROPLISTYEAR = this.yearListStructure();
    // let VALID = this.state.loadedData;
    // const temporal =<div style={{width: '100%', height: '100vh'}}><Loading /></div>;
    // const pdfArray = this.state.pdfArray;
    // const xmlArray = this.state.xmlArray;
    const TOPROWLIST = this.structureTopRowList();
    const BOTTOMROWLIST = this.structureBottomRowList();
    return (
      <GridItem xs={12}>
        <GridContainer spacing={4}>
          <GridItem xs={12}> <h2 className={classes.titleLine}>{title}</h2> </GridItem>
          <GridItem xs={6} sm={3}>
          <List id="interest" style={{padding: "0px"}}>
            <ListItem style={{padding: "0px"}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={"transparent"}
                buttonText={selectedContract ? selectedContract : "CONTRATO (S)"}
                buttonProps={{
                  className: classes.dropdown,
                  color: "primaryPrimary"
                }}
                dropdownList={ DROPLISTCONTRACTS }
              />
            </ListItem>
          </List>
          </GridItem>
          <GridItem xs={6} sm={3}>
            <List id="interest" style={{padding: "0px"}}>
              <ListItem style={{padding: "0px"}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={"transparent"}
                  buttonText={selectedYear ? selectedYear : "AÑO"}
                  buttonProps={{
                    className: classes.dropdown,
                    color: "primaryPrimary"
                  }}
                  dropdownList={ DROPLISTYEAR }
                />
              </ListItem>
            </List>
            {/* <Button disableRipple size="" color="primary" >
              AÑO <FontAwesomeIcon icon={faSortDown} style={{margin: "-3px 0 0 4px"}}/>
            </Button> */}
          </GridItem>
          { userName && <GridItem xs={12}><h4 className={classes.tableTitle}>{userName}</h4></GridItem>}
          <GridItem xs={12}>
            <div className={classes.box}>
              <GridContainer spacing={2} style={{margin: "-8px"}}>
                <GridItem xs={6}>
                  <p className={classNames(classes.titleBlue, classes.pl)}>DOCUMENTO</p>
                </GridItem>
                <GridItem xs={3}>
                  <p className={classes.titleBlue}>ARCHIVO PDF</p>
                </GridItem>
                <GridItem xs={3}>
                  <p className={classes.titleBlue}>ARCHIVO XML</p>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
                {TOPROWLIST}
                {/* <GridItem xs={6}>
                  <p className={classes.text}>Constancia informativa de Ganancias y pérdidas fiscales por enajenación de acciones.</p>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>

                <GridItem xs={6}>
                  <p className={classes.text}>Constancia informativa de Ganancias y pérdidas fiscales por enajenación de acciones.</p>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem> */}

              </GridContainer>
            </div>
          </GridItem>
        
          { otherTitle && <GridItem xs={12}><h4 className={classes.tableTitle}>{otherTitle}</h4></GridItem>}
          <GridItem xs={12}>
            <div className={classes.box}>
              <GridContainer spacing={2} style={{margin: "-8px"}}>
                <GridItem xs={6}>
                  <p className={classNames(classes.titleBlue, classes.pl)}>DOCUMENTO</p>
                </GridItem>
                <GridItem xs={3}>
                  <p className={classes.titleBlue}>ARCHIVO PDF</p>
                </GridItem>
                <GridItem xs={3}>
                  <p className={classes.titleBlue}>ARCHIVO XML</p>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>
                {BOTTOMROWLIST}
                {/* <GridItem xs={6}>
                  <p className={classes.text}>Dividendos o Utilidades distribuidas</p>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.divider}></div>
                </GridItem>

                <GridItem xs={6}>
                  <p className={classes.text}>Enajenación de acciones u Operaciones en BMV & BIVA</p>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem>
                <GridItem xs={3}>
                  <Button disableRipple size="" border color="borderGrayPrimary" >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </GridItem> */}

              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }
}
export default withStyles(style)(TableTaxCertificates);