import {
    marginHeaderExact,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const style = theme => ({
    marginHeaderExact: {
      ...marginHeaderExact,
    },
    fullContent: {
      height: "730px",
      [theme.breakpoints.down("xs")]:{
          height: "calc(100vh - 110px)",
      }
    },
    contentSmall: {
      height: '250px',
    },
  });
  
  export default style;
  