import React from "react";
import htmlText from 'utils/htmlFormattedText';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./listStyle.jsx";

class ListIcon extends React.Component {

  structureSubList=(sublist)=>{
    let structure = null;
    if (sublist) {
      structure = sublist.map(subitem=>{
        return (
          <li>
            <FontAwesomeIcon icon={faArrowRight} style={{width:"12px", height:"14px"}}/>
              {htmlText(subitem)}
          </li>
        )
      })
    }
    return structure;
  }
  structureList = (list) => {
    let structure = null;
    if (list) {
      structure = list.map(item => {
        let sublist = null;
        if (item.subList.length > 0){
          sublist = this.structureSubList(item.subList);
        }
        return (
          <li>
            <FontAwesomeIcon icon={faArrowCircleRight} style={{width:"12px", height:"14px"}}/>
            {htmlText(item.text)}
            {sublist && <ul>
                {sublist}
              </ul>
            }
          </li>
        )
      })
    }
    return structure;
  }
  render() {
    const { classes } = this.props
    const {
       title,
       descriptionTop,
       descriptionBottom,
       featureList
    } = this.props.data;

    const listStructure = this.structureList(featureList);
    
    return (
      <React.Fragment>
        
          { title && <GridItem xs={12}>
              <h5 className={classes.title}>
                {title}
              </h5>
              </GridItem>
          }
          { descriptionTop && <GridItem xs={12}>
              <p className={classes.description}>
                {htmlText(descriptionTop)}
              </p>
              </GridItem>
          }
          <GridItem xs={12}>
            <ul className={classes.iconList}>
              {listStructure}
            </ul>
          </GridItem>
          { descriptionBottom && <GridItem xs={12}>
              <p className={classes.description}>
                {htmlText(descriptionBottom)}
              </p>
              </GridItem>
          }
      </React.Fragment>
    );
  }
}

export default withStyles(style)(ListIcon);

