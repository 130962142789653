import React from 'react'
import whithStyles from "@material-ui/core/styles/withStyles"
import style from "./simpleListStyle"
import GridItem from "components/Grid/GridItem"

class SimpleList extends React.Component {

  listStructure=(data)=>{
    let listStructure=[];
    if (data && data !== undefined){
      listStructure = data.map((item,index)=>{
        if (item.dropdownLits && item.dropdownLits.length>0){
          return (
            <>
              <li key={"simpleList-"+index}><a href={item.href}><i className="fa fa-caret-right"></i> <strong>{item.name}</strong></a></li>
              <ul>
                {item.dropdownLits.map((subItem,index)=> <li key={"simpleList-sub-"+index}><a href={subItem.href}><i className="fa fa-caret-right"></i> {subItem.name}</a></li> )}
              </ul>
            </>
          );
        } else return <li key={"simpleList-"+index}><a href={item.href}><i className="fa fa-caret-right"></i> <strong>{item.name}</strong></a></li>
      })
    }
    return listStructure;
  }

  render() {
    const {classes, data} = this.props;
    let CONTENT = [];
    if (data && data !== undefined) {
      CONTENT = this.listStructure(data.data);
    }
    
    return (
      <GridItem xs={12}>
        <ul className={classes.siteMap}>
          {CONTENT}
        </ul>
      </GridItem>
    )
  }
}

export default whithStyles(style)(SimpleList)