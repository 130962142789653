import imgDefault from "assets/img/jpg/ernesto.jpg"

const ThumbGen = (thumbnail, size) => {
  let THUMBNAIL = '';
    const TNsize=size
    if (thumbnail !== undefined){
      if (thumbnail.fileName && thumbnail.fileEntryId){
        const FILENAME = thumbnail.fileName.split(' ').join('%20')
        THUMBNAIL = "/o/adaptive-media/image/"+thumbnail.fileEntryId+"/"+TNsize+"/"+FILENAME;
      } else if (thumbnail.url !== undefined) {
        THUMBNAIL = thumbnail.url
      } else {
        THUMBNAIL = imgDefault;
      }
    }else {
      THUMBNAIL = imgDefault;
    }
  return THUMBNAIL;
} 

export default ThumbGen;