  
  const style = {
    bgImage: {
      width: "100%",
      minHeight: "100%",
      backgroundPosition: "center left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    bgGray: {
      backgroundColor: "#f6f6f6",
      marginBottom: "2rem"
    },
    bgWhite: {
      backgroundColor: "#ffffff",
      marginBottom: "2rem"
    },
    bgContent: {
      padding: "6.5rem 6.5rem 6.5rem 0",
      "@media (max-width: 766px)": {
        padding: "6.5rem 40px 6.5rem",
      }
    },
    contentMov:{
      "@media (max-width: 766px)": {
        width: "94%",
      }
    },
  };
  
  export default style;
  