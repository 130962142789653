import { 
  container, 
  primaryColor, 
  defaultFont, 
  title,
  transition,
  boxShadow, 
} from "assets/jss/material-kit-pro-react";

  
  const style = {
    cintillo: {
      width: "100%",
      padding: "38px 0",
      textAlign: "center",
      backgroundColor: primaryColor[0],
    },
    container:{
      ...container,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap"
    },
    title: {
      ...title,
      color: "white !important",
      marginTop: "0 !important",
      padding: "2.3rem 3rem !important",
      margin: "0 !important",
      fontSize: "1.86rem !important",
      fontWeight: "400 !important",
      "@media (max-width: 599px)": {
        fontSize: "1.56rem !important",
      }
    },
    description: {
        ...container,
        ...defaultFont,
        marginBottom: "2.5rem",
        lineHeight: "1.65em !important",
        textAlign: "center"
        // minHeight: "6.6rem"
    },
    drawerPaper: {
      border: "none",
      bottom: "0",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      ...boxShadow,
      // position: "fixed",
      position: "relative",
      display: "inline-table",
      top: "2rem",
      marginBottom: "4rem !important",
      height: "auto",
      right: "0",
      // left: "calc(50% - 150px)",
      margin: "0 auto",
      // width: drawerWidth,
      width: "calc(100% - 6rem)",
      maxWidth: "898px",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      backgroundColor: "#003b72",
      color: "white",
      ...transition,
      /* [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 4rem)",
      }, */
    },
    closeButtonDrawer: {
      position: "absolute",
      right: "8px",
      top: "9px",
      zIndex: "1"
    },
  };
  
  export default style;
  