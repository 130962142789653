import { whiteColor, grayColor } from "assets/jss/material-kit-pro-react.jsx";
import { primaryColor, complementarioColor } from "../material-kit-pro-react";

const modalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "auto"
    },
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100%",
    marginTop: "130px !important"
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px"
  },
  modalTitle: {
    fontSize: "14px",
    color:"#555555",
    margin: "0",
    marginTop: "15px",
    lineHeight: "1.5",
    textAlign: "center"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: grayColor[0]
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: ".5rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5"
  },
  modalClose: {
    width: "16px",
    height: "16px"
  },
  modalVideoCloseButton: {
    "&, &:hover": {
      color: 'white'
    },
    "&:hover": {
      opacity: "1"
    },
    position: "absolute",
    right: "0",
    zIndex: 9,
    cursor: "pointer",
    padding: ".5rem",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".9"
  },
  modalBody: {
    maxHeight: 350,
    paddingTop: "0px",
    paddingRight: "24px",
    paddingBottom: "0px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "auto"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
  },
  modalFooterSubscribe: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "20px 20px 10px 20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "20px 0 10px 0",
    }
  },
  modalTextSmall: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#aaa'
  },
  modalTextSmallCheck: {
    fontSize: 12,
    marginLeft: 10,
    lineHeight: '12px',
    fontWeight: 400,
  },
  modalLink:{
    color: complementarioColor,
    '&:hover':{
      fontWeight: 600,
    }
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  centerMdUp: {
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    }
  },
  marginTitleCheckCenterMdUp: {
    margin: "10px 0 0 9px !important",
    [theme.breakpoints.up("md")]: {
      margin: "10px 0 0 0 !important",
    }
  },
  marginPoliticas: {
    margin: "0 0 0 -4px !important",
  },
  modalLarge: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "800px"
    }
  },
  modalSmall: {
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      margin: "auto"
    },
    margin: "0 auto"
  },
  modalSmallBody: {
    marginTop: "20px"
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto"
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px"
  },
  modalLogin: {
    maxWidth: "360px",
    overflowY: "visible",
    width: "100%",
    "& $modalCloseButton": {
      color: whiteColor,
      top: "-10px",
      right: "10px",
      textShadow: "none",
      position: "relative"
    },
    "& $modalHeader": {
      borderBottom: "none",
      paddingTop: "24px",
      paddingRight: "24px",
      paddingBottom: "0",
      paddingLeft: "24px"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    },
    "& $modalFooter": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalVideo: {
    maxWidth: "900px",
    width: "100%",
  },
  modalLoginCard: {
    marginBottom: "0",
    margin: "0",
    "& $modalHeader": {
      paddingTop: "0"
    }
  },
  modalSignup: {
    maxWidth: "900px",
    width: "100%",
    "& $modalHeader": {
      paddingTop: "0"
    },
    "& $modalTitle": {
      textAlign: "center",
      width: "100%",
      marginTop: "0.625rem"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalSignupCard: {
    padding: "40px 0",
    margin: "0"
  },
  fullWidth: {
    width: "100%"
  },
  spaceBetwen: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  contentAvatar: {
    textAlign: "center",
    '& h3': {
      color: primaryColor[0],
      fontSize: "1rem",
      margin: ".5rem 0 .1rem",
      fontWeight: 600,
      fontFamily: '"Open Sans", sans-serif',
    },
    '& p': {
      color: grayColor[0],
      margin: ".1rem 0",
      fontWeight: 500,
    }
  },
  avatar:{
    width: 100,
    height: 100,
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
    '& img': {
      width: '100%',
      objectFit: 'cover'
    }
  },
  subtitle:{
    color: primaryColor[0],
    fontWeight: 600
  },
  text:{
    fontSize: '0.9rem',
    lineHeight: '1.1rem',
    fontWeight: '400 !important',
    color: grayColor[0],
  },
  categoryTitle:{
    fontSize: "14px",
    color: "#364a92",
    fontWeight: "600"
  },
  noPadding: {
    padding: "0 !important"
  },
  noMargin: {
    margin: "0 !important"
  },
  sizeText: {
    fontSize: "12px",
    marginLeft: "3px"
  },
  iconCheckbox: {
    height: "14px",
    width: "14px",
    border: "2px solid gray",
    borderRadius: "2px",
    marginLeft: "3px"
  },
  iconChecked: {
    fontSize: "10px",
    lineHeight: "10px",
    color: "white",
    height: "14px",
    width: "14px",
    border: "2px solid #3f51b5",
    borderRadius: "2px",
    marginLeft: "3px",
    backgroundColor: "#3f51b5"
  },
  iconRight: {
      '& svg': {
          margin: '0 -4px 0 4px'
      }
  },
  textEllipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  copySuccess: {
    margin: "0px",
    color: "#364A92"
  },
});

export default modalStyle;
