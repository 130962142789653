import React from "react";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./SectionBannerStyle";
import classNames from "classnames";
import Carousel from "react-slick";
import Banner from "./Banner";

// import banner1 from "assets/img/jpg/bg_home1.jpg"
// import image from "assets/img/png/logo_big.png"
// import video1 from "assets/video/city.mp4"

class SectionBanner extends React.Component {
  constructor(props){
    super(props);

    this.state={
      banners: [],
      delayList: [],
      bannerTime: this.props.data.data[0].delay,
      onVideoPause: false,
      reloadCarousel: true,
    }
  }

  changeTime = (index) => {
    let currentTime = this.state.delayList[index];
    if (currentTime !== this.state.bannerTime){
      this.setState({
        bannerTime: currentTime,
      })
    }
  }

  pauseBanner = (flag) => {
    console.log('pausing banner: ', flag)
    if (this.state.onVideoPause !== flag){
      this.setState({onVideoPause: flag})
    }
    if (!flag) {
      this.setState({reloadCarousel: !this.state.reloadCarousel})
    }
    
  }

  createBannerList = (data) => {
    const { full,  } = data;
    const testWidth = window.innerWidth > 768;
    let banners=[]
    if(data.data !== undefined) {
      let newDelayList= [...this.state.delayList];
      data.data.map((bannerData,key)=>{
        if (testWidth){
          if (bannerData.showWeb){
            banners.push(<Banner {...bannerData} pauseBanner={this.pauseBanner} key={key} full={full}/>)
          }
        } else if (!testWidth){
          if (bannerData.showMobile){
            banners.push(<Banner {...bannerData} pauseBanner={this.pauseBanner} key={key} full={full}/>)
          }
        }
        if (bannerData.delay && bannerData.delay>0){
          newDelayList.push(bannerData.delay)
        } else {
          newDelayList.push(3000)
        }
        return null;
      })
      if (newDelayList !== this.state.delayList){
        this.setState({delayList: newDelayList})
      }
    }
    return banners;
  }

  componentDidMount(){
    const {data} = this.props;
    let banners = this.createBannerList(data);
    this.setState({banners})
  }

	/* bannerStructure = (data ,key)=> {
    let noBoxVal = null;
    if (this.props.data.settings && this.props.data.settings.noBox){
      noBoxVal = this.props.data.settings.noBox;
    }
    return( <Banner {...data} noBox={noBoxVal} key={key}/>)
	} */
  render() {
    const { classes, marginHeader, data } = this.props;
    // const { showWeb, showMobile } = data.data;
    const { full,  } = data;
    const banners= this.state.banners;
    const videoControl = !this.state.onVideoPause; 
	  
    const settings =  {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplaySpeed: this.state.bannerTime,
      afterChange: index=>this.changeTime(index),
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: videoControl,
      arrows: true,
      responsive: [
        {
          breakpoint: 860,
          settings: {
            arrows: false,
            dots: true
          }
        }
      ]
    };

    if (banners) {
      return (
        <GridItem xs={12} id="carousel">
          <Carousel key={'Carousel-'+this.state.reloadCarousel} ref={slider => (this.slider = slider)} {...settings} className={classNames(marginHeader && classes.marginHeaderExact, "banner", full ? classes.fullContent : classes.contentSmall)} >
            {banners}
          </Carousel>
        </GridItem>
      );
    } else {
      return null;
    }
  }
}
export default withStyles(style)(SectionBanner);