
  const styleHome = {
    contentLogo: {
      width: "100%",
      padding: "49px 0 !important",
      margin: "0px",
      float: "left",
      textAlign: "center",
      "& li":{
        display: "inline-block",
        padding: 0,
        margin: 0,
        width: "16%",
        "@media (max-width: 639px)":{
          padding: "30px 0",
          width: "32%",
        },
        "& a":{
          outline: "none"
        },
        "& img":{
          display: "inline-block",
          width: "90%",
          margin: "0 5px"
        }
      }
    },
  };
  
  export default styleHome;
  