import React from 'react';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableActualPositionStyle";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from 'components/Grid/GridContainer';
import Pagination from "components/Pagination/Pagination";

class TableActualPositionContent extends React.Component{
  constructor(props){
    super(props);

    this.state= {
      currentPage: 1,
      rowContent: [],
      rowContentArray: null,
    }
  }

  handlePrevPage = () => {
    let page=this.state.currentPage;
    const prevPage = page-1;
    this.setState({currentPage: prevPage})
  }

  handleCurrentPage= (num) => {
    this.setState({currentPage: num})
  }

  handleNextPage = () => {
    let page=this.state.currentPage;
    const nextPage = page+1;
    this.setState({currentPage: nextPage})
  }

  dataStructure=(data= this.props.data)=>{
    if (data) {
      var CONT_ROWS = []
      if (data){
        if (data && Array.isArray(data)){
          CONT_ROWS = data;
        }
      }
      let rowContentArray=[]
      if(CONT_ROWS) {
        let limit = window.innerWidth < 800 ? 1 : 10; 
        for(let i=0, j=CONT_ROWS.length; i<j; i+=limit){
          rowContentArray.push(CONT_ROWS.slice(i, i + limit));
        }
        this.setState({
          totalRows: CONT_ROWS.length,
          rowContent: CONT_ROWS,
          rowContentArray: rowContentArray,
        })
      }
    }
  }

  formatDate = (testDate) => {
    let month = testDate.getMonth() + 1;
    if (month < 10) month = '0'+month.toString();
    let day = testDate.getDate();
      if (day < 10) day = '0'+day.toString();
    const year = testDate.getFullYear();

    return day+"/"+month+"/"+year;
  }

  structureRows=(CONTENIDO, moneyMarket)=>{
    const {classes} = this.props;
    const currentDate = this.formatDate(new Date());
    let tempContent = CONTENIDO.map(item=>
      <>
      <Hidden mdUp>
            <GridItem xs={5} lg={12}>
              <GridContainer spacing={2} style={{margin:"-8px"}}>
              <GridItem lg={1}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>FECHA</p>
                </GridItem>
                <GridItem lg={1}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>EMISORA</p>
                </GridItem>
                <GridItem lg={1}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>SERIE</p>
                </GridItem>
                {moneyMarket && <React.Fragment>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>PLAZO</p>
                  </GridItem>
                  <GridItem lg={1}>
                    <p className={classNames(classes.cellTitle, classes.mdRight)}>TASA</p>
                  </GridItem>
                </React.Fragment>}
                <GridItem lg={1}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>TITULOS</p>
                </GridItem>
                <GridItem lg={1}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>COSTO POR TITULOS</p>
                </GridItem>
                <GridItem lg={moneyMarket ? 1 : 2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>COSTO PROMEDIO</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>VALOR MERCADO</p>
                </GridItem>
                <GridItem lg={2}>
                  <p className={classNames(classes.cellTitle, classes.mdRight)}>PLUSVALIA / MINUSVALIA</p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </Hidden>
          <GridItem xs={7} lg={12}>
            <GridContainer spacing={2} style={{margin:"-8px"}}>
            <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{currentDate}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.EMISORA}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.SERIE}</p>
              </GridItem>
              {moneyMarket && <React.Fragment>
                <GridItem lg={1}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.PLAZO}</p>
                </GridItem>
                <GridItem lg={1}>
                  <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.TASA}</p>
                </GridItem>
              </React.Fragment>}
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.TITULOS}</p>
              </GridItem>
              <GridItem lg={1}>
                <p className={classNames(classes.cellText, classes.sizeLgMin)}>{item.COSTOXTITULOS}</p>
              </GridItem>
              <GridItem lg={moneyMarket ? 1 : 2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin, classes.textRightS)}>{item.COSTOPROMEDIO}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin, classes.textRightS)}>{item.VALORMERCADO}</p>
              </GridItem>
              <GridItem lg={2}>
                <p className={classNames(classes.cellText, classes.sizeLgMin, classes.textRightS)}>{item["PLUS/MINU"]}</p>
              </GridItem>
            </GridContainer>
          </GridItem>  
        </>       
      )
    return tempContent;
  }

  componentWillReceiveProps (newProps) {
    if( newProps.data !== this.props.data ) {
      const data = newProps.data;
      if(data){
        this.dataStructure(data)
      }
    }
  }

  componentDidMount(){
    this.dataStructure()
  }

  render(){
    const {data, moneyMarket} = this.props;
    const {paginatorPrev, paginatorNext} = data
    //Code for Pagination
    const pageNumbers = [];
    const actualPage = this.state.currentPage;
    var paginationContent = [];
    let CONTENIDO;
    let pageLimit = window.innerWidth < 800 ? 1 : 10;
    if (this.state.rowContentArray!== null && this.state.rowContentArray.length>0){
      CONTENIDO= this.state.rowContentArray[actualPage-1];
    } else {CONTENIDO = []}
    if (this.state.totalRows !== null) {
      for (let i = 1; i <= Math.ceil(this.state.totalRows / pageLimit); i++) {
        pageNumbers.push(i);
      }
      if (pageNumbers.length > 1){
        if (actualPage === 1){
          paginationContent.push({disabled: true, text: paginatorPrev ? paginatorPrev : "Anterior" })
        }else{
          paginationContent.push({onClick: ()=>this.handlePrevPage(), text: paginatorPrev ? paginatorPrev : "Anterior" })
        }
        pageNumbers.map((item, index)=>{
          if (window.innerWidth < 800){
            if(item===actualPage){
              paginationContent.push({active:true, text: item})
            }else if((index < 4 && actualPage<2 ) || (index >= actualPage-2 && index < actualPage+2 )){
              paginationContent.push({onClick:()=>this.handleCurrentPage(item), text: item})
            }
          } else if((index<9 && actualPage<5) || (index >= actualPage-5 && index <= actualPage+3 )){
            if(item===actualPage){
              paginationContent.push({active:true, text: item})
            }else{
              paginationContent.push({onClick:()=>this.handleCurrentPage(item), text: item})
            }
          }
          
          return null;
        })
        if (actualPage < pageNumbers.length){
          paginationContent.push({onClick: ()=>this.handleNextPage(), text: paginatorNext ? paginatorNext : "Siguiente" })
        }else{
          paginationContent.push({disabled: true, text: paginatorNext ? paginatorNext : "Siguiente" })
        }
      }
    }
    //End code for pagination


    if (data && Array.isArray(data)) {
      return (
        <>
          {this.structureRows(CONTENIDO, moneyMarket)}
          <GridItem xs={12}>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
              <Pagination 
                key={Math.floor(Math.random()*901)}
                pages={paginationContent}
              />
            </div>
          </GridItem>
        </>
      )
    } else {
      return <div />;
    }
  }
}
export default withStyles(style)(TableActualPositionContent);