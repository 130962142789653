import React from "react";

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./tableInvestmentFundsStyle";
// import Title from "components/Title";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink} from '@fortawesome/free-solid-svg-icons'
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Modal from "components/Modal";
// import Drawer from "@material-ui/core/Drawer";
// import Close from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";
// import LogoSmall from "assets/img/png/logo_small.png"

class TableInvestmentFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }
  handleDrawerToggle = (link) => {
    this.setState({ 
      modalOpen: !this.state.modalOpen,
      modalLink: link
    });
  }

  innerTableStructure=(innerRows)=>{
    const { classes } = this.props;
    const innerStructure = innerRows.map(row => {
      return (
        <React.Fragment>
          <GridItem xs={9} md={10} style={{paddingLeft:"30px"}}>
            <h5 className={classes.titleBig}>
              {row.title}
            </h5>
            <p className={classes.text}>
              {row.text}
            </p>
          </GridItem>
          <GridItem xs={3} md={2}>
            <Button disableRipple size="" color="primary" onClick={()=>this.handleDrawerToggle(row.link)}>
              <FontAwesomeIcon icon={faLink} />
            </Button>
          </GridItem>
  
          <GridItem xs={12}>
            <div className={classes.divider}></div>
            <br/>
          </GridItem>
        </React.Fragment>
      )
    })
    return innerStructure;
  }

  tableStructure=(data,key)=>{
    const { classes } = this.props;
    const { modalData } = this.props.data
    let InnerTable = []
    
    if (data.rows && data.rows.length > 0) InnerTable = this.innerTableStructure(data.rows)

    return (
      <React.Fragment key={key}>
        <GridItem xs={12} sm={2} className={classes.textCenter}>
          <img className={classes.logoInvestment} src={data.mainImage} alt="" title="" />
        </GridItem>
        <GridItem xs={12} sm={10}>
          <div className={classes.box}>
            <GridContainer spacing={0}>
            { data.columnTitle && <React.Fragment>
              <GridItem xs={9} md={10} style={{paddingLeft:"30px"}}>
                <h5 className={classes.titleBig}>
                  <strong>{data.columnTitle}</strong>
                </h5>
              </GridItem>
              <GridItem xs={3} md={2}>
                <Button disableRipple size="" color="primary" onClick={()=>this.handleDrawerToggle(data.columnLink)}>
                  <FontAwesomeIcon icon={faLink} />
                </Button>
              </GridItem>
      
              <GridItem xs={12}>
                <div className={classes.divider}></div>
                <br/>
              </GridItem>
            </React.Fragment>
            }
              {InnerTable}
            </GridContainer>
          </div>
        </GridItem>
        <Modal
          title={modalData.title}
          footerLogo
          bgGray
          open={this.state.modalOpen}
          onClose={()=>this.handleDrawerToggle()}
        >
          <div style={{textAlign:"center"}}>
            <p>{modalData.description}</p>
            <p><strong>{modalData.buttonsTitle}</strong></p>
            <Button disableRipple onClick={()=>this.handleDrawerToggle()} size="" border color="borderWhite" style={{marginRight:"2px"}} >
              {modalData.cancelBtnTxt}
            </Button>
            <Button disableRipple href={this.state.modalLink} onClick={()=>this.handleDrawerToggle()} target="_blank" rel="noopener noreferrer" size="" border color="borderWhite" >
              {modalData.openLinkBtnTxt}
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  render() {
    var TABLE=[]
    const { classes } = this.props;
    const { title,  tableData } = this.props.data
    if (tableData){
      tableData.sort((mainRow1, mainRow2) => mainRow1.id - mainRow2.id)
      .map((item,key)=>{
        TABLE.push(this.tableStructure(item,key));
        return null;
      })
    }

    return (
      <React.Fragment>
        { title && <GridItem xs={12}><h4 className={classes.tableTitle}>{title}</h4></GridItem>}
        {TABLE}
        


        
      </React.Fragment>
    );
  }
}
export default withStyles(style)(TableInvestmentFunds);