import React from "react";
import classNames from "classnames";
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// core components
import Title from "components/Title";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import NotiCard from "components/Cards/NotiCard"

// @material-ui icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import style from "./style.jsx";

class CarouselNotiCard extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      show: -1,
      activeSlide: 0
  }
  }

  CarouselNotiCardStructure = (data,key) => {
    return( <NotiCard blog wrapTitle={"3"} title descriptionHeightCarrousel {...data} key={key} />)
  }

  componentDidMount(){
    if (this.props.data && this.props.data.data){
      this.setState({show: this.props.data.data.length})
    }
    
  }

  render() {
    const { activeSlide } = this.state;
    var cards=[]

    if (this.props.data && this.props.data.data){
      this.props.data.data.sort((card1, card2) => card2.id - card1.id)
      .map((item,key)=>{
        cards.push(this.CarouselNotiCardStructure(item,key));
        return null;
      })
    }
    // const { title, isHome } = this.props.data;
    const {
       title, description, mainBtnTxt, mainBtnUrl
    } = this.props.data;
    // const slidesToShowNum = isHome ? 4 : 3;
    const settings = {
      dots: true,
      infinite: this.state.show >3 ? true : false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      centerMode: false,
      pauseOnFocus: true,
      pauseOnHover: true,
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              infinite: this.state.show >3 ? true : false,
            }
          },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            infinite: this.state.show >2 ? true : false,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1, 
            inifinite: this.state.show >1 ? true : false,
          }
        }
      ]
    };
    function canBeShown(currentSlide, slideNumber) {
      const TO_SHOW = 4;
      const rangeMax = Math.ceil((currentSlide + 1) / TO_SHOW) * TO_SHOW;
      const rangeMin = rangeMax - (TO_SHOW + 1);
      return slideNumber < rangeMax && slideNumber > rangeMin;
    }
    return (
      <GridItem xs={12} style={{paddingTop: "28px"}}>
        <GridContainer spacing={2}>
          { title && <Title large title={title} alignment="center" description={description} />}
          <GridItem xs={12}>
            <div id="carousel">
              <Carousel
                {...settings}
                className="espaciadoX2 dotsDown limitDots"
                customPaging={i => (
                  <button
                    className={classNames(
                      "dot-dist-" + Math.abs(i - activeSlide),
                      "dot-show-" + canBeShown(activeSlide, i)
                    )}
                  >
                    {" "}
                  </button>
                )}
                afterChange={current => this.setState({ activeSlide: current })}
              > 
                {cards}
              </Carousel>
            </div>
          { mainBtnTxt &&
            <div style={{textAlign:'center'}}>
              <Button onClick={()=>breadcrumbsUpdate()} disableRipple  size="" border color="borderSecondary" href={mainBtnUrl} >
                {mainBtnTxt}
              </Button>
            </div>
          }
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }
}

export default withStyles(style)(CarouselNotiCard);

