import {
    container,
    complementarioColor
  } from "assets/jss/material-kit-pro-react.jsx";
import { primaryColor } from "assets/jss/material-kit-pro-react";
  
  const styleCustomExpansionPanel = {
    container: {
      ...container,
      height: "100%",
      '&> div': {
          height: "100%",
      }
    },
    titleExpand: {
        fontFamily: "Montserrat,sans-serif",
        color: "#4168A2",
        fontSize: "20px",
        "@media (max-width: 599px)":{
          fontSize: "16px",
        },
    },
    titleExpand2: {
        fontFamily: "Open Sans,sans-serif",
        color: primaryColor[0],
        fontWeight: "700",
        fontSize: ".96rem",
        lineHeight: "1.6em",
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
    },
    titleExpand3: {
        fontFamily: "Montserrat,sans-serif",
        color: "#364A92",
        fontWeight: "700",
        fontSize: "20px",
        "@media (max-width: 599px)":{
          fontSize: "16px",
        },
    },
    titleExpand4: {
      fontFamily: "Montserrat,sans-serif",
      color: "#4168A2",
      fontWeight: "700",
      fontSize: ".9rem"
  },
    expandBtn: {
        background: "#1E5C7B",
        borderRadius: "20px",
        color: "white",
        marginRight: 10,
        transform: 'rotate(-90deg)',
    },
    expandBtnActive: {
      transform: 'rotate(0) !important',
    },
    expandBtnPlus: {
        // background: "none",
        // color: "#3c4858",
        // fontSize: 16,
        // marginTop: 1,
        width: "18px",
        height: "18px",
        marginRight: "11px",
    },
    expandBtn2: {
        background: "none",
        color: "#3c4858",
        fontSize: 16,
        marginTop: 1,
        marginRight: 5,
        transform: 'rotate(-90deg)',
    },
    expandBtn3: {
        background: "none",
        color: "#364A92"
    },
    expandBtn4: {
        background: "none",
        color: "#4168A2",
        fontSize: 16,
        marginTop: 3,
        marginRight: 10,
        transform: 'rotate(-90deg)',
    },
    noPadding: {
        padding: "0 .8rem !important",
        minHeight: "43px !important",
        border: "1px solid #e4e4e4",
        '&[class*="expanded"]':{
          background: "#f5f5f5",
          borderBottom: "none",
          '&~div':{
            borderLeft: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
            borderBottom: "1px solid #e4e4e4",
            backgroundColor: "white",
            padding: "15px 10px 5px 10px",
          },
        },
        '& >div': {
          margin: "0 !important",
        }
    },
    noYPadding: {
        padding: "0 10px !important"
    },
    contentWrap: {
        flexDirection: "column",
        // textAlign: "justify",
        padding: "0 5px 10px 24px !important",
        '& p, div':{
          color: "#7b7e85",
          lineHeight: "1.6em"
        }
    },
    mobileView: {
      '& >div': {
        '& h4': {
          '@media (max-width: 767px)': {
            fontSize: "0.9rem",
            textAlign: "left",
          }
        },
        '& blockquote': {
          '& p': {
            '@media (max-width: 767px)': {
              fontSize: "0.7rem"
            }
          }
        },
        '@media (max-width: 767px)': {
          paddingLeft: 0,
          paddingRight: 0,
        }
      },
      '@media (max-width: 767px)': {
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }
    },
    noBefore: {
      margin: "0px !important",
      '&:before': {
          display: "none"
      }
    },
    iconBtn: {
        maxHeight: "1.3rem",
        marginTop: "-2px"
      },
      titleCDT: {
        fontFamily: "Montserrat,sans-serif",
        color: "#555555",
        fontSize: "16px",
        marginLeft: "10px",
        "@media (max-width: 599px)":{
          fontSize: "14px",
        },
    },
    cdtContentWrap: {
        display: "flex",
        alignItems: "center"
    },

    contentList: {
        display: 'block',
        marginTop: '1rem'
      },
      icon: {
        color: '#000',
      },
      list: {
        display: 'inline-block',
        fontSize: '.9rem',
        color: '#3C4858',
        marginLeft: 8,
        '& p':{
            margin: 0,
        },
        '& a': {
          display: 'block',
          color: '#3C4858'
        },
        '& a:hover': {
          color: '#3C4858'
        }
      },
      colorComplementary: {
        color: complementarioColor
      }
  };
  
  export default styleCustomExpansionPanel;
  