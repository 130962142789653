
  const styleHome = {
    title: {
      fontFamily: `'Lato', sans-serif`,
      marginBottom: 0,
      fontSize: "1.2rem",
      lineHeight: "1.2em",
      color: "#272727",
      fontWeight: 400, 
    },
    description: {
      fontFamily: '"Open Sans", sans-serif',
      margin: 0,
      fontSize: "1.1rem",
      lineHeight: "1.4em",
      color: "#727272",
      fontWeight: 400, 
    },
    iconList: {
      width: "100%",
      padding: 0,
      margin: 0,
      listStyleType: "none",
      '& li': {
        padding: 0,
        margin: "0 0 15px 0",
        display: "block",
        lineHeight: "1.6em",
        fontSize: ".96rem",
        fontWeight: 400,
        color: "#727272",
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
        '& svg': {
          color: "#136ec3",
          margin: "0 15px 0 0"
        }
      }
    },
    iconMultiList: {
      width: "100%",
      padding: 0,
      margin: 0,
      listStyleType: "none",
      '& > li': {
        padding: 0,
        margin: "0 0 15px 0",
        display: "block",
        lineHeight: "1.6em",
        fontSize: ".96rem",
        fontWeight: 700,
        color: "#727272",
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
        '& svg': {
          color: "#136ec3",
          margin: "0 15px 0 0"
        },
        '& > ul': {
          marginLeft: "20px",
          '& > li': {
            padding: 0,
            margin: "0 0 15px 0",
            display: "block",
            lineHeight: "1.6em",
            fontSize: ".96rem",
            fontWeight: 400,
            color: "#727272",
            "@media (max-width: 480px)": {
              fontSize: "1rem"
            },
          }
        }
      }
    }
  };
  
  export default styleHome;
  