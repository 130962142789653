import {
    primaryColor,
    cardTitle,
    defaultFont
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const cardTimeLineStyle = {
    cardTimeLine:{
        margin: "0 !important",
        padding: ".5rem !important",
        height: "280px",
        borderBottom: "1px solid #e4e4e4"
    },
    
    year: {
        ...cardTitle,
        color: "#272727 !important",
        textTransform: "uppercase",
        margin: 0 + " !important",
        fontWeight: "400 !important",
        fontSize: "1.86rem !important"
    },
    contentYear:{
        borderBottom: "3px solid #003B72",
        height: "3.5rem"
    },
    imageLine:{
        height: "3.5rem"
    },
    noPaddingX: {
        paddingLeft:0,
        paddingRight:0,
    },
    description: {
        ...defaultFont,
        fontSize: "1.2rem !important",
        fontFamily: `'Lato', sans-serif !important`,
        lineHeight: "1.3em !important",
        color: primaryColor[0] + " !important"
    }
  };
  
  export default cardTimeLineStyle;
  