// import React from 'react';
import { createBrowserHistory } from "history";

var hist = createBrowserHistory();

export default function breadcrumbsUpdate(){
  console.log('entered BreadcrumbsUpdate');
  if (hist.location.pathname !== "/" && hist.location.pathname !== "/home"){
    if (localStorage.getItem('BurSes-breWl87T3Pz')){
        const breadcrumbsData = JSON.parse(localStorage.getItem('BurSes-breWl87T3Pz'));
        let tempPath = breadcrumbsData.pathArray;
        localStorage.setItem('BurSes-breWl87T3Pz',JSON.stringify( {pathArray:tempPath, isInternalLink: true} ));
    }
  }
  return;
}