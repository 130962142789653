import {
    primaryColor,
    secondaryColor,
    cardTitle,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const cardIconStyle = {
    cardIcon:{
        marginBottom: "0 !important",
        marginTop: "0 !important",
        padding: "40px !important",
        transition: "all 0.3s ease-out 0s",
        color: '#272727',
        height: '100%',
        '& svg, $image': {
            width: '40%',
            fill: secondaryColor[0],
            margin: "0 auto !important"
        },
        '& a, button':{
            // color: "white !important",
            '&:hover':{
                backgroundColor: secondaryColor[0] + " !important",
                borderColor: secondaryColor[0] + " !important",
                color: "white !important",
            }
        },
        '&:hover':{
            backgroundColor: primaryColor[0],
            color: '#fff',
            '& svg':{
                fill: 'white'
            },
            '& a, button':{
                color: "white !important",
            },
            '& $view':{
                opacity: 0,
                transition: "all 0.3s ease-out 0s",
            },
            '& $viewHover':{
                opacity: 1,
                transition: "all 0.3s ease-out 0s",
            }
        }
    },
    contentImageHover: {
        position: 'relative',
        display: 'inline-block',
        width: '40%',
        margin: '0 auto',
        transition: "all 0.3s ease-out 0s",
    },
    view: {
        width: '100%',
        transition: "all 0.3s ease-out 0s",
    },
    viewHover: {
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        opacity: 0,
        transition: "all 0.3s ease-out 0s",
    },
    image:{},

    cardBlue: {
        marginBottom: "0 !important",
        marginTop: "0 !important",
        padding: "40px !important",
        transition: "all 0.3s ease-out 0s",
        backgroundColor: primaryColor[0],
        height: '100%',
        color: '#fff',
        '& svg, img': {
            width: '40%',
            fill: secondaryColor[0],
            margin: "0 auto !important"
        },
        '& a, button':{
            color: "white !important",
            '&:hover':{
                backgroundColor: secondaryColor[0] + " !important",
                borderColor: secondaryColor[0] + " !important",
                color: "white !important",
            }
        },
    },
    
    cardTitle: {
        ...cardTitle,
        textTransform: "none !important",
        margin: 0 + " !important",
        fontSize: "1.5rem !important",
        lineHeight: "1.3em !important",
        fontWeight: "400 !important"
    },
    
    iconRight: {
        '& svg':{
            margin: '0 -4px 0 4px'
        }
    },
    cardIconFooter: {
        marginTop: "20px !important",
        paddingBottom: "0 !important"
    },
    noPaddingX: {
        paddingLeft:0,
        paddingRight:0,
    }
  };
  
  export default cardIconStyle;
  