import React, { Component } from "react";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/styles/styleHome";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Header from "components/Header/Header";
import SectionBanner from "components/Banner/SectionBanner";
import Migas from "components/Breadcrumbs";
import Footer from "components/Footer/Footer";
import Title from "components/Title";
import HeaderLinks from "components/Header/HeaderLinks";
import ListCardIcon from "components/List/ListCardIcon.jsx";
import CarouselCardIcon from "components/Carousel/CarouselCardIcon";
import CarouselCardBibliography from "components/Carousel/CarouselCardBibliography";
import IViewer from "components/Belts/IViewer";
// import ListNotiCard from "components/List/ListNotiCard.jsx";
import CarouselNotiCard from "components/Carousel/CarouselNotiCard";
import ContentQuoteSimple from "components/ContentText/ContentQuoteSimple";
import SearchEconomy from "components/Search/SearchEconomy";
import AboutUsBlock from "components/Blocks/AboutUsBlock";
import TimeLineBlock from "components/Blocks/TimeLineBlock";
import ContentMultimedia from "components/ContentText/ContentMultimedia";
import FluidCardBg from "components/Cards/FluidCardBg";
import ContentQuoteMultimedia from "components/ContentText/ContentQuoteMultimedia";
import CircleTagList from "components/List/CircleTagList";
import ButtonBelt from "components/Belts/ButtonBelt"
import ContentQuote from "components/ContentText/ContentQuote";
import StrategyBlock from "components/Blocks/StrategyBlock";
/* import OptiPills from "components/NavPills/OptiPills"; */
import FormUne from "components/Form/FormUne";
import ImageAndContent from "components/ImageAndContent";
import ListDescriptiveCard from "components/List/ListDescriptiveCard";
import Scheme from "components/Scheme";
import TableInvestmentFunds from "components/Table/TableInvestmentFunds";
import Search from "components/Search/Search";
import FormLogin from "components/Form/FormLogin";
// import FormChangeNIP from "components/Form/FormChangeNIP";
import Office from "components/Office/Office";
import SimpleList from "components/List/SimpleList";


class MultiComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estructure: props.structure,
      classes : props.classes,
      hostingVariables: this.props.hostingVariables,
      updateBreadcrumbs: false,
      isOffice: false,
    };

    this.myRef = React.createRef()
  }

  estructuracion() {
    var bodyStructure = [];
    const { estructure } = this.state;

    estructure.map((item, index) => {
      const { type } = item;
      bodyStructure.push(this.componentCreater(item, type, index));
      return null;
    });
    return bodyStructure;
  }

  componentCreater = (item, type, index, margin) => {
    switch (type) {
      case 'Header':
        return <Header data={item} fixed color="primary" key={'Head-'+Math.floor(Math.random()*901)} />
      case 'Breadcrumbs':
        return <Migas show={this.state.updateBreadcrumbs} hostingVariables={this.state.hostingVariables} data={item} key={'BreadCrumb-'+Math.floor(Math.random()*901)} />

      case 'Banner':
        return <SectionBanner data={item} full={item.full} key={'SectionBanner-'+Math.floor(Math.random()*901)} />
        
      case "Box":
        if (item.component) {
          var componentStruct = [];
          const current = item.component.filter((e) => e.type === 'Breadcrumbs').map(e=>e);
          if (current.length >= 1) {
             margin = false;
          } else { margin = true;}
          
          item.component.map((component, index) => {
            componentStruct.push(
              this.componentCreater(component, component.type, index, margin)
            );
            return null;
          });
          if (item.fullWidth) {
            return componentStruct;
          }
          else if (item.size && !item.container===true){
            return (
              <GridItem xs={12} key={'GIt'+Math.floor(Math.random()*901)}>
              <GridContainer {...item.size} key={'GC3-'+Math.floor(Math.random()*901)} >
                
                  {componentStruct}
                
              </GridContainer> 
              </GridItem>
            )
          } else if (item.container===true) {
            return (
              <GridItem xs={12} key={'GItCont'+Math.floor(Math.random()*901)}>
                <GridContainer {...item.size} justify="center" spacing={4} className={classNames(this.state.classes.marginSection, this.state.classes.container)} key={'GC'+Math.floor(Math.random()*901)}>
                  {componentStruct}
                </GridContainer>
              </GridItem>
            );
          }
          else {
            return componentStruct
          }
        }
        break;

      // Inicia Home

      case "ListCardIcon":
        return <ListCardIcon data={item} key={'ListCardIcon-'+Math.floor(Math.random()*901)} />

      case "CarouselCardIcon":
        return <CarouselCardIcon data={item} key={'CarouselCardIcon-'+Math.floor(Math.random()*901)} />

      case "CarouselCardBibliography":
        return <CarouselCardBibliography data={item} key={'CarouselCardBibliography-'+Math.floor(Math.random()*901)} />

      case "IViewer": 
        return <IViewer data={item} key={'IViewer-'+Math.floor(Math.random()*901)} />

      case "ListNotiCard":
        // return <ListNotiCard data={item} key={'ListNoticard-'+Math.floor(Math.random()*901)} />
        return <CarouselNotiCard data={item} key={'CarouselNotiCard-'+Math.floor(Math.random()*901)} />

      case "CarouselNotiCard":
        return <CarouselNotiCard data={item} key={'CarouselNotiCard2-'+Math.floor(Math.random()*901)} />

      /* case 'Footer':
        return <Footer data={item} key={'Footer-'+Math.floor(Math.random()*901)} theme="dark" /> */

      case 'Title':
        return <GridItem xs={12} key={'Git2'+Math.floor(Math.random()*901)}>
            <Title titulo={item.titulo} button={item.button} urlBtn={item.urlBtn} />
          </GridItem>
      
      case 'ContentQuoteSimple':
        return <ContentQuoteSimple data={item} key={'CQS-'+Math.floor(Math.random()*901)} />

      case 'AboutUsBlock':
        return <AboutUsBlock data={item} key={'AboutUsB-'+Math.floor(Math.random()*901)} />
      
      case 'TimeLineBlock':
        return <TimeLineBlock data={item} key={'TimeLine-'+Math.floor(Math.random()*901)} />
      
      case 'ContentMultimedia':
        return <ContentMultimedia data={item} key={'ContentMM-'+Math.floor(Math.random()*901)} />
      
      case 'FluidCardBg':
        return <FluidCardBg data={item} key={'FluidCard-'+Math.floor(Math.random()*901)} />

      case 'ContentQuoteMultimedia':
        return <ContentQuoteMultimedia data={item} key={'CQMM-'+Math.floor(Math.random()*901)} />
      
      case 'CircleTagList':
        return <CircleTagList data={item} key={'CTL-'+Math.floor(Math.random()*901)} />
      
      case 'ButtonBelt':
        return <ButtonBelt data={item} hostingVariables={this.state.hostingVariables} key={'BTNBELT-'+Math.floor(Math.random()*901)} />
      
      case 'ContentQuote':
        return <ContentQuote data={item} key={'CQ-'+Math.floor(Math.random()*901)} />

      case 'StrategyBlock':
        return <StrategyBlock  data={item} key={'StrategyBlock-'+Math.floor(Math.random()*901)} />

      case 'FormUne':
        return <FormUne data={item} key={'FormUne-'+Math.floor(Math.random()*901)} />

      case 'ImageAndContent':
        return <ImageAndContent data={item} key={'ImageContent-'+Math.floor(Math.random()*901)} />

      case 'ListDescriptiveCard':
        return <ListDescriptiveCard data={item} key={'ListDescr-'+Math.floor(Math.random()*901)} />
      
      case 'Scheme':
        return <Scheme data={item} key={'Scheme-'+Math.floor(Math.random()*901)} />
      
      case 'TableInvestmentFunds':
        return <TableInvestmentFunds data={item} key={'TIF-'+Math.floor(Math.random()*901)} />

      case 'SearchEconomy':
        return <SearchEconomy data={item} hostingVariables={this.state.hostingVariables} key={'SearchEcon-'+Math.floor(Math.random()*901)} /> 

      case 'Search':
        return <Search data={item} hostingVariables={this.state.hostingVariables} key={'Search-'+Math.floor(Math.random()*901)} /> 
      
      case 'Login':
        return <FormLogin data={item} footerData={this.props.footerData} hostingVariables={this.state.hostingVariables} key={'Login-'+Math.floor(Math.random()*901)} /> 
      
      case 'Office':
        if(!this.state.isOffice){
          this.setState({isOffice:true})
        }
        return <Office data={item} footerData={this.props.footerData} hostingVariables={this.state.hostingVariables} key={'Office-'+Math.floor(Math.random()*901)} />
      
      case 'SiteMap':
        return <SimpleList data={item.modelList} key={'Sitemap-'+Math.floor(Math.random()*901)} />

      default:
        return null;
    }
  };

  UNSAFE_componentWillMount() {
    this.setState({ estructure: this.estructuracion() });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.setState({updateBreadcrumbs: true})
  }

  render() {
    const { estructure, isOffice } = this.state;
    const { linksNavBar, footerData, classes } = this.props
      return (
        <React.Fragment key={'Header-'+Math.floor(Math.random()*901)}>
            {!isOffice && <Header
              changeColorOnScroll={{height: 1}}
              hostingVariables={this.state.hostingVariables}
              links={
                <HeaderLinks 
                  hostingVariables={this.state.hostingVariables} 
                  linksNavBar={linksNavBar} 
                  dropdownHoverColor="info" 
                  key={'HL'+Math.floor(Math.random()*901)}
                />
              }
              scrollRef = {this.myRef}
              fixed
              color="dark"
            />}
          
          <GridContainer key={'GC2'+Math.floor(Math.random()*901)} className={isOffice && classes.bgImage}>
            <div id="theHeaderTop" ref={this.myRef}></div>
            {estructure}
          </GridContainer>
          <Footer data={footerData} isOffice={isOffice} key={'Footer-'+Math.floor(Math.random()*901)} theme="dark" />
        </React.Fragment>);
  }
}

export default withStyles(style)(MultiComponent);