import {
    container,
    bgGray,
    marginHeaderExact,
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const preHeaderStyle = {
    container,
    wrapper: {
        width: "100%",
        minHeight: "59px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #e9e9e9",
    },
    bgGray: {
        ...bgGray,
        width: "100%",
        height: "auto",
        padding: "5px 0"
    },
    marginHeaderExact: {
        ...marginHeaderExact,
    },
    olNowrap: {
        paddingTop: "15px",
        '& nav':{
            '@media (max-width: 575px)':{
                padding: '10px 24px',
            },
        },
        '& ol':{
            justifyContent: 'flex-end',
        },
        '& li':{
            textAlign: "right",
        }      
    },
    link: {
        fontFamily: '"Open Sans", sans-serif',
        textTransform: "capitalize",
        color: "#161616",
        fontWeight: 400,
        wordBreak: "break-word",
        fontSize: ".96rem",
        lineHeight: "1.65em",
        // white-space: nowrap;
        // CH 3/DIC/2019 change media
        "@media (max-width: 959px)": {
            fontSize: ".9rem",
            width: "100%",
            /////////////////

            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // maxWidth: "80px",
            // textOverflow: "ellipsis",
            // display: "inline-block",

            //////////////////
          },
        '&:hover,&:active':{
            color: "#161616",
            fontWeight: 400,
            textDecoration: "none"
        },
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
    },
    ellipsis: {
        fontFamily: '"Open Sans", sans-serif', 
        width: "100%",
        fontSize: ".96rem",
        lineHeight: "1.65em",
        color: "#727272",
        ////////////////////

        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // maxWidth: "100px",
        // textOverflow: "ellipsis",

        ///////////////
        // CH 3/DIC/2019 change media
        "@media (max-width: 959px)": {
            fontSize: ".8rem",
        },
        "@media (max-width: 480px)": {
          fontSize: "1rem"
        },
    },
    seeMore: {
        color: "#727272",
        cursor: 'pointer',
        display: 'none',
        paddingRight: 10,
        '&:hover': {
            fontWeight: 600,
        }
    },
    show: {
        display: 'inline-block',
    }
  };
  
  export default preHeaderStyle;
  