import React, {useState} from "react";
import { createBrowserHistory } from "history";
import { ReactiveBase, DataSearch, } from '@appbaseio/reactivesearch';

import Button from "components/CustomButtons/Button.jsx";

import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from "@material-ui/core/IconButton";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function HeaderSearchComponent(props){
  var hist=createBrowserHistory();
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchString, setSearchString] = useState('')

  const clearBreadcrumbs=()=>{
    localStorage.clear('BurSes-breWl87T3Pz');
  }
  const handleDrawerSearchToggle = () => {
    setSearchOpen(!searchOpen)
  }
  const handleSearchValue = (value) =>{
    setSearchString(value)
  }
  const triggerSearch = () => {
    clearBreadcrumbs();
    hist.push(props.hostingVariables.searchHost+'?mainSearch="'+searchString+'"');
    window.location.assign(hist.location.pathname+hist.location.search);
  }
  const onKeyDownHandler = e => {
    if (e.key === 'Enter') {
      let value = searchString;
      clearBreadcrumbs();
      hist.push(props.hostingVariables.searchHost+'?mainSearch="'+value+'"');
      window.location.assign(hist.location.pathname+hist.location.search);
    }
  };

  return (
    <React.Fragment>
      <Button 
        disableRipple  
        size={null} 
        color="header" 
        onClick={handleDrawerSearchToggle}>
        <i className="fa fa-search" aria-hidden="true"></i>
      </Button>
      <Drawer
        variant="temporary"
        anchor={"top"}
        open={searchOpen}
        classes={{
          paper: props.classes.drawerPaper
        }}
        onClose={handleDrawerSearchToggle}
      >
        
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerSearchToggle}
          className={props.classes.closeButtonDrawer}
        >
          <Close />
        </IconButton>
        <div className={props.classes.headerDrawer}>
          <h5 className={props.classes.titleSearch}>{props.hostingVariables.searchTitle}</h5>
        </div>
        <div className={props.classes.bodyDrawer}>
          <div className={props.classes.contentSearch}>
            <ReactiveBase
              app="liferaygg-20101"
              credentials="_iDv3scyQkmCBP8ndFUapA"
              // url={this.props.hostingVariables.elasticHost}
            >
              <DataSearch
                componentId="mainSearch"
                className='searchInputClass'
                innerClass={{
                  input: 'searchInputInnerClass'
                }}
                dataField={["all"]}
                queryFormat="or"
                debounce={100}
                showIcon={false}
                /* iconPosition="right" */
                placeholder={props.hostingVariables.searchLabel}
                autosuggest={false}
                showFilter={true}
                onValueChange={handleSearchValue}
                onValueSelected={
                  function(value, cause, source) {
                    hist.push('/busqueda?mainSearch="'+searchString+'"');
                    window.location.assign(hist.location.pathname+hist.location.search);
                  }
                }
                onKeyPress={(e)=>onKeyDownHandler(e)}
              />
            </ReactiveBase>
            <Button 
              disableRipple  
              size={null} 
              color="search" 
              onClick={triggerSearch} 
            >
              <FontAwesomeIcon icon={faSearch} style={{marginRight:"5px"}}/>
                {props.hostingVariables.searchBtnLabel}
            </Button>
          </div>
        </div>
        <div className={props.classes.footerMenuMovil}>
          <img src={props.hostingVariables.urlLogo} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={props.classes.logoSearch} />
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default HeaderSearchComponent
