import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// react component for creating beautiful carousel
// core components
import GridItem from "components/Grid/GridItem.jsx";
import CardStrategy from "components/Cards/CardStrategy"

import style from "./StrategyBlockStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

class StrategyBlock extends React.Component {

  cardCreator=(item)=>{
    return <GridItem xs={12} md={6}>
      <CardStrategy
        title={item.title}
        description={item.description}
        btnUrl={item.btnUrl}
        btnTxt={item.btnTxt}
        image={item.image}
        reverse={item.reverse}
      />
    </GridItem>
  }
  render() {
    const { data, classes } = this.props;
    const { topTitle, blueText, lightBlueText } = data;
    var CARDS = [];
    if (data.data){
      data.data.sort((card1, card2) => card1.id - card2.id)
      .map(item => {
          CARDS.push(this.cardCreator(item))
          return null;
      })
    }
    return (
        <GridItem xs={12}>
          <GridContainer spacing={4} justify="">
            <GridItem xs={12}>
                <h4 className={classes.preTitle}>{topTitle}</h4>
                <h3 className={classes.title}>{blueText} {lightBlueText && <span className={classes.blueText}>{lightBlueText}</span>}</h3>
            </GridItem>
            {CARDS}
            {/* <GridItem xs={12} md={6}>
                <CardStrategy
                    title="Preservación"
                    description="Estrategias en valores e instrumentos que hacen énfasis en la generación de ingresos corrientes y moderado crecimiento de capital. Baja tolerancia al riesgo por parte del inversionista."
                    btnUrl="#"
                    image={"http://www.salazarmalaga.com/clientes/Bursametrica/NewSite/images/iconos/icono-desarrollo.svg"}
                    reverse
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CardStrategy
                    title="Preservación"
                    description="Estrategias en valores e instrumentos que hacen énfasis en la generación de ingresos corrientes y moderado crecimiento de capital. Baja tolerancia al riesgo por parte del inversionista."
                    btnUrl="#"
                    image={"http://www.salazarmalaga.com/clientes/Bursametrica/NewSite/images/iconos/icono-desarrollo.svg"}
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CardStrategy
                    title="Preservación"
                    description="Estrategias en valores e instrumentos que hacen énfasis en la generación de ingresos corrientes y moderado crecimiento de capital. Baja tolerancia al riesgo por parte del inversionista."
                    btnUrl="#"
                    image={"http://www.salazarmalaga.com/clientes/Bursametrica/NewSite/images/iconos/icono-desarrollo.svg"}
                    reverse
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CardStrategy
                    title="Preservación"
                    description="Estrategias en valores e instrumentos que hacen énfasis en la generación de ingresos corrientes y moderado crecimiento de capital. Baja tolerancia al riesgo por parte del inversionista."
                    btnUrl="#"
                    image={"http://www.salazarmalaga.com/clientes/Bursametrica/NewSite/images/iconos/icono-desarrollo.svg"}
                />
            </GridItem> */}
        </GridContainer>
        </GridItem> 
      );
    }
  }
  
  export default withStyles(style)(StrategyBlock);
  

