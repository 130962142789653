/* eslint-disable */
import React from "react";
import { createBrowserHistory } from "history";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// import 'components/Search/Search.css';

// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

// images
import LogoSmall from "assets/img/png/logo_small.png"

var hist=createBrowserHistory();
class HeaderOfficeLinks extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    hostingVariables: this.props.hostingVariables,
  }


  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  
  render() {
    // var onClickSections = {};
    const { classes, linksNavBar, closeDrawer } = this.props;
    const { urlLogo } = this.state.hostingVariables;
    var LinksButtons = linksNavBar ? linksNavBar.map((item,key) => {
      if(item.dropDownList && item.dropDownList.length > 0){
        return (
          <ListItem className={classes.listItem} style={{textAlign: "center"}} key={1+'ListItem'+Math.floor(Math.random()*901)} >
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={"primary"}
              buttonText={item.name}
              buttonProps={{
                disableRipple: true,
                className: classes.dropdown,
                color: "headerLoginMin"
              }}
              dropdownList={item.dropDownList.map(menu=>
                  <Button 
                    disableRipple
                    onClick={()=>{menu.href(); closeDrawer();}}
                    className={classes.dropdownLink} 
                    color="transparent" 
                    key={key} 
                  >
                      {menu.name}
                  </Button>
              )}
            />
          </ListItem>
        )
      }else{
        return(
          <Button onClick={()=>{item.href(); closeDrawer();}} className={classes.navLink} color="transparent" disableRipple key={key} >
            {/* <ListItem className={classes.listItem}> */}
              {item.name}
            {/* </ListItem> */}
          </Button>
        )
      }
          }) : ''

    return (
      <React.Fragment>
      <List className={classes.list + " " + classes.mlAutoX}>
          {LinksButtons}
      </List>

      {/* <div className={classes.footerMenuMovil}>
        <img src={urlLogo} alt="Logo_Bursametrica" title="Logo_Bursametrica" className={classes.logo} />
      </div> */}

    </React.Fragment>
  );
}
}

HeaderOfficeLinks.defaultProps = {
  hoverColor: "primary"
};

export default withStyles(headerLinksStyle)(HeaderOfficeLinks);
