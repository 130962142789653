import React from "react";
import htmlText from 'utils/htmlFormattedText';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import typographyStyle from "assets/jss/material-kit-pro-react/components/typographyStyle.jsx";

function Paragraph({ ...props }) {
  const { classes, children, justify } = props;
  if (children){
    return (
      <div className={classNames(classes.paragraph, justify && classes.textJustify)}>
        {htmlText(children)}
      </div>
    );
  } else {
    return (<div/>)
  }
  
}

Paragraph.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(typographyStyle)(Paragraph);
