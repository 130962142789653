import {
  defaultFont,
  transition,
  boxShadow,
} from "assets/jss/material-kit-pro-react.jsx";
// import { secondaryColor } from "assets/jss/material-kit-pro-react";

const modalStyle = theme => ({
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    // position: "fixed",
    position: "relative",
    display: "inline-table",
    top: "2rem",
    marginBottom: "4rem !important",
    height: "auto",
    right: "0",
    // left: "calc(50% - 150px)",
    margin: "0 auto",
    // width: drawerWidth,
    width: "calc(100% - 6rem)",
    maxWidth: "898px",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    backgroundColor: "rgba(0, 59, 114, 1)",
    color: "white",
    borderRadius: "6px",
    ...transition,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 4rem)",
    },
  },
  drawerPaperGray: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    // position: "fixed",
    position: "relative",
    display: "inline-table",
    top: "2rem",
    marginBottom: "4rem !important",
    height: "auto",
    right: "0",
    // left: "calc(50% - 150px)",
    margin: "0 auto",
    // width: drawerWidth,
    width: "calc(100% - 6rem)",
    maxWidth: "898px",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    backgroundColor: "rgba(63,63,63, 1)",
    color: "white",
    borderRadius: "6px",
    ...transition,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 4rem)",
    },
  },
  drawerPaperWhite: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    ...boxShadow,
    // position: "fixed",
    position: "relative",
    display: "inline-table",
    top: "2rem",
    marginBottom: "4rem !important",
    height: "auto",
    right: "0",
    // left: "calc(50% - 150px)",
    margin: "0 auto",
    // width: drawerWidth,
    width: "calc(100% - 6rem)",
    maxWidth: "898px",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    backgroundColor: "rgba(255,255,255, 1)",
    color: "black",
    borderRadius: "6px",
    ...transition,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 4rem)",
    },
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },
  headerDrawer: {
    padding: "15px",
  },
  bodyDrawer: {
    padding: "15px 30px",
    '& p':{
      ...defaultFont,
      color: 'inherit',
    }
  },
  titleDrawer: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "1.26rem",
    lineHeight: "1.3em",
    // textTransform: "uppercase",
    // marginTop: "2rem",
    // marginBottom: "0",
  },
  contentSearch: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    "& input": {
      fontFamily: '"Open Sans", sans-serif !important',
      color: '#555 !important',
      "&::placeholder": {
        color: '#949494 !important'
      }
    },
  },
  footerMenuMovil: {
    marginTop: "1.5rem",
    textAlign: "center"
  },
  logoSearch: {
    maxWidth: "160px",
    margin: "0 auto",
    padding: "0 0 20px 0",
    opacity: ".5"
  },
});

export default modalStyle;
