import {
    defaultFont,
    primaryColor,
    blackColor,
    hexToRgb
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const style = {
    description: {
      ...defaultFont,
      // textAlign: "justify",
      margin: "0 0 12px 0",
      lineHeight: "1.6em !important",
      // "& strong": {
      //   fontSize: "1rem"
      // },
      "& br":{
        content: "''",
        margin: "10px",
        display: "block",
        fontSize: "200%",
      }
    },
    descriptionJustify: {
      ...defaultFont,
      textAlign: "justify",
      margin: "0 0 12px 0",
      lineHeight: "1.6em !important",
      "& strong": {
        fontSize: "1rem"
      }
    },
    title: {
      fontFamily: "Lato, sans-serif",
      fontSize: "1.86rem",
      lineHeight: "1.1em",
      fontWeight: "normal",
      color: "#003b72",
      marginBottom: "8px",
      marginTop: 0,
      textTransform: "uppercase"
    },
    titleReverse: {
      fontFamily: "Lato, sans-serif",
      // fontSize: "1.86rem",
      lineHeight: "1.1em",
      fontSize: "1.9rem",
      fontWeight: "normal",
      color: "#003b72",
      marginBottom: "8px",
      marginTop: 0,
      textTransform: "uppercase",
      textAlign: "right",
      "@media (max-width: 959px)":{
        textAlign: "left"
      },
    },
    subtitleTop: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      color: "#3c4858",
      // marginTop: "25px"
    },
    subtitle: {
      fontFamily: "Lato, sans-serif",
      fontSize: "1.1rem",
      lineHeight: "1.3em",
      fontWeight: "normal",
      textTransform: "inherit",
      color: "#272727",
      marginBottom: "14px"
    },
    subtitleList: {
      fontFamily: "Lato, sans-serif",
      fontSize: "1.5rem",
      lineHeight: "1.35em",
      fontWeight: "400",
      textTransform: "inherit",
      color: "#272727",
      marginBottom: "18px"
    },
    subtitleTopReverse: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      color: "#3c4858",
      textAlign: "right",
      marginTop: "25px",
      "@media (max-width: 959px)":{
        textAlign: "left"
      },
    },
    subtitleReverse: {
      fontFamily: "Lato, sans-serif",
      fontSize: "1.1rem",
      lineHeight: "1.3em",
      fontWeight: "normal",
      color: "#272727",
      marginBottom: "14px",
      textAlign: "right",
      textTransform: "inherit",
      "@media (max-width: 959px)":{
        textAlign: "left"
      },
    },
    textRight: {
      textAlign: "right",
      '& p': {
        textAlign: "right !important"
      },
      '& > p': {
        textAlign: "justify !important",
      }
    },
    textCenter :{
      textAlign: "center",
      fontSize: "1.26rem",
      fontFamily: "Lato, sans-serif",
      lineHeight: "1.26em !important",
      color: "#272727",
      marginBottom: "0 !important",
      '@media (min-width: 600px)': {
        textAlign: "center"
      }
    },
    textCenterMultimedia :{
      textAlign: "center",
      fontSize: "1.5rem",
      fontFamily: "Lato, sans-serif",
      lineHeight: "1.36em !important",
      color: "#272727",
      marginBottom: "3rem !important",
      '@media (min-width: 600px)': {
        textAlign: "center"
      }
    },
    noQuoteDisplay: {
      '& > blockquote': {
        display: "none",
      }
    },
    btnAlternate: {
      border: "1px solid #364A92",
      background: "none",
      color: "#364A92",
      transition: "all 0.3s ease-out 0s",
      '&:hover': {
        color: "white",
        background: "#364A92",
      }
    },
    itemTable: {
      display: "table",
      width: "100%",
    },
    itemRow: {
      display: "table-row"
    },
    itemFlex: {
      display: "flex",
      justifyContent: "space-between"
    },
    text: {
      ...defaultFont,
      fontSize: "12px !important",
      display: "table-cell"
    },
    textBlue: {
      fontWeight: 600,
      color: primaryColor[0],
      display: "table-cell",
      "& svg": {
        marginBottom: -4,
      },
    },
    textTime: {
      ...defaultFont,
      fontSize: "12px !important",
      display: "table-cell",
      "& svg": {
        marginBottom: -6,
        fill: "currentColor",
        width: "1em",
        height: "1em",
        display: "inline-block",
        fontSize: "1.4rem",
        transition: "all 0.3s ease-out 0s",
        flexShrink: 0,
        userSelect: "none"
      },
    },
    flex: {
      display: "flex"
    },
    bullet: {
      position: "relative",
      '&:before': {
        content: "''",
        position: "absolute",
        width: 4,
        height: 4,
        borderRadius: 3,
        backgroundColor: "#cecece",
        top: 8,
        left: -10
      }
    },
    bulletReverse: {
      position: "relative",
      '&:before': {
        content: "''",
        position: "absolute",
        width: 4,
        height: 4,
        borderRadius: 3,
        backgroundColor: "#cecece",
        top: 8,
        right: -10
      },
      "@media (max-width: 959px)":{
        position: "relative",
        '&:before': {
          top: 8,
          left: -10
        }
      },
    },
    capitalizeText: {
      textTransform: 'inherit',
    },
    uppercaseText: {
      textTransform: 'inherit',
    },
    imageFull: {
      // height: "100%",
      objectFit: "cover",
      objectPosition: 'center',
      maxWidth:"100%",
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.12)",
      "@media (max-width: 959px)": {
        // display: 'none',
        position: "relative",
        maxWidth: "300px",
        margin: "0 auto",
      }
    },
    styleImage: {
      // height: "280px",
      objectFit: "cover",
      objectPosition: 'center',
      width:"100%",
      /* boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.12)", */
      // "@media (max-width: 768px)": {
      //   display: 'none',
      // }
    },
    autoImage: {
      height: "auto",
      objectFit: "cover",
      objectPosition: 'center',
      width:"100%",
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(blackColor) +
        ", 0.12)",
      "@media (max-width: 768px)": {
        display: 'none',
      }
    },
    imageTop:{
      width: 165,
      margin: "0 auto",
      display: "block"
    },
    marginBullet: {
      marginLeft: "12px",
    },
    btnCenter: {
      // '@media (max-width: 767px)': {
        display: "flex !important",
        justifyContent: "center"
      // }
    },
    dividerList: {
      marginTop: "2rem",
      borderBottom: "1px solid #e4e4e4",
      opacity: .7,
      width: "100%"
    },
    mb: {
      marginBottom: "43px"
    },
    paddingTop: {
      paddingTop: "0 !important"
    }
  }
  
  export default style;
  