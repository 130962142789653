import {
  container,
  marginHeader
} from "assets/jss/material-kit-pro-react.jsx";

const style = {
  marginHeader,
  container: {
    ...container,
    zIndex: 1
  },
  w100: {
    width: '100%',
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  mtW: {
    "@media (min-width: 960px)": {
      marginTop: '15px'
    }
  },
  contentSearch: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    "& input, input::placeholder": {
      fontFamily: '"Open Sans", sans-serif !important',
      color: '#555 !important',
    },
  },
  contentSearchSimple: {
    width: 'calc(70% + 68px)',
    display: 'flex',
    alignItems: 'stretch',
    "& input, input::placeholder": {
      fontFamily: '"Open Sans", sans-serif !important',
      color: '#555 !important',
    },
  },
  searchTopActive: {
    "@media (max-width: 960px)": {
      marginTop: '-35px'
    }
  },
  inputSimple: {
    "& > div":{
      "& input":{
        fontFamily: '"Open Sans", sans-serif !important',
        border: "1px solid #d9d9d9",
        height: "40px",
        boxSizing: "border-box",
        color: "#161616",
        fontSize: "13px",
        "&::placeholder": {
          fontFamily: '"Open Sans", sans-serif !important',
          color: '#727272 !important',
        },
      },
      "& > div > div":{
        top: "0 !important",
        padding: "0 !important",
        "& button": {
          fontFamily: '"Open Sans", sans-serif !important',
          border: "1px solid #d9d9d9 !important",
          fontWeight: "400",
          height: "40px !important",
          opacity: "1 !important",
          color: "#727272 !important"
        }
      }
    }
  }

};

export default style;
