import React from "react";
import htmlText from 'utils/htmlFormattedText';
import breadcrumbsUpdate from 'utils/breadcrumbsUpdate';
import { createBrowserHistory } from "history";
import classNames from "classnames";
// core components
import Title from "components/Title";
import Video from "components/Video";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Quote from "components/Typography/Quote"
import Button from "components/CustomButtons/Button.jsx";
import Altivator from 'utils/imageAltivator';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./style.jsx";

class ContentMultimedia extends React.Component {
  structureDescriptions=(data, reverse)=>{
    let tempDescriptions='';
    let quotedData=[];
    let tempData=[];
    const {classes} = this.props;
    if (data && Array.isArray(data)){
      data.map(item=>{
        if (item.quote && item.text) {
          quotedData.push(item) 
        } else {
          tempData.push(item)
        }
        return null;
      })
      tempDescriptions = <React.Fragment>
        {
          tempData && tempData.map((item, index)=>{
            if (item.text) {
              return <p className={classNames(classes.descriptionJustify, item.bullets ? classes.bullet : null, item.bullets ? classes.marginBullet : null)} key={'description-'+index}>
                {htmlText(item.text)}
              </p>
            } else {
              return null;
            }
          }) 
        }
        {(quotedData && quotedData.length >0)&& <Quote reverse={reverse}>
        {quotedData.map((item, index)=>{
          if (item.text) {
            return <p className={classNames(classes.descriptionJustify, item.bullets ? classes.bullet : null)} key={'quotedDescription-'+index}>
              {htmlText(item.text)}
            </p>
          } else {
            return null;
          }
        })}
        </Quote>}
      </React.Fragment>
      
    }
    return tempDescriptions;
  }

  structureLinks=(data)=>{
    let tempLinks;
    if (data) {
      tempLinks = data.map((item, index)=>{
        const {txtDocuments, linkOpenNewtab, linkText, linkUrl} = item;
        return <p key={1+'RF1'+Math.floor(Math.random()*901)}>{txtDocuments+" "}<a onClick={linkOpenNewtab ? ()=>breadcrumbsUpdate() : null} href={linkUrl} target={linkOpenNewtab ? "_blank" : null}>{linkText}</a></p>
      } )
    }
    return tempLinks;
  }

  structureTopContent=(data)=>{
    let tempTopContent;
    const {classes} = this.props;
    
    if (data){
      tempTopContent = data.map((item, index) => {
        const {title, subtitleTop} = item;
        const DESCRIPTIONS = this.structureDescriptions(item.descriptionTop, false);
        return <GridContainer spacing={4} key={index+'GC1a'+Math.floor(Math.random()*901)}>
          {title && <Title line title={title} />}
          { (subtitleTop || DESCRIPTIONS) && <GridItem xs={12} style={style}>
            {subtitleTop && <h4 className={false ? classes.subtitleTopReverse : classes.subtitleTop}>{htmlText(subtitleTop)} </h4>}
            {DESCRIPTIONS}
          </GridItem>}

        </GridContainer>
        
      })
    }
    return tempTopContent;
  }

  structureBottomContent=(data)=>{
    let tempBottomContent;
    const {classes} = this.props;
    if (data) {
      tempBottomContent = data.map((item, index)=>{
        const {
          reverse,
          contentCenter, // this is for Justify style in GridContainer
          titleBottom,
          subtitleBottom,
          descriptionBottom,
          imageFull, // if true, image takes full height
          imageLarge, // if true image Grid size is 6
          imageUrl,
          videoUrl,
          btnPosition,
          openNewtab,
          txtBtn,
          urlBtn,
          additionalLinksBottom,
          tabId
        } = item;
        
        const DESCRIPTIONS = this.structureDescriptions(descriptionBottom, reverse);
        const LINKS = this.structureLinks(additionalLinksBottom, reverse);
        return <GridContainer spacing={4} direction={reverse ? "row-reverse" : null} justify={contentCenter ? "center" : null} key={index+'GC3a'+Math.floor(Math.random()*901)} className={imageFull && classes.mb}> 
          {videoUrl && <GridItem xs={12} md={imageLarge ? 6 : 4}> <Video video={videoUrl} /> </GridItem>}
          {(imageUrl && !videoUrl) && <GridItem xs={imageFull ? 12 : 5} md={imageLarge ? 6 : 4}>
            <img className={imageFull ? classes.imageFull : classes.autoImage } src={imageUrl} alt={Altivator(imageUrl)} title={Altivator(imageUrl)} />
          </GridItem>}
          {tabId>=0 ? <div id={tabId} style={{marginTop: '-100px'}}></div> : null}
          <GridItem xs={12} md={imageLarge ? 6 : 8} >
            <GridContainer>
              {titleBottom && <GridItem xs={12} > <h3 className={reverse ? classes.titleReverse : classes.title}>{titleBottom}</h3></GridItem>}
              <GridItem xs={12}>
                {subtitleBottom && <h6 className={reverse ? classes.subtitleReverse : classes.subtitle}>{htmlText(subtitleBottom)}</h6>}
                {DESCRIPTIONS}
              </GridItem>
              {urlBtn && txtBtn &&<GridItem xs={12} style={{textAlign: btnPosition}}>
                  <Button className={classes.capitalizeText} size="smNull" onClick={openNewtab ? ()=>breadcrumbsUpdate() : null} href={urlBtn} target={openNewtab ? "_blank" : null} color="complementario" round>{txtBtn}</Button>
                </GridItem>}
              <GridItem xs={12}>
                {LINKS}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      })
    }
    return tempBottomContent;
  }
  
  scrollToId = (idName) => {
    if (idName>=0){
      let tabName = document.getElementById(idName)
      if (tabName){
        tabName.scrollIntoView({ behavior: 'smooth'});
      }
    }
  }

  componentDidMount(){
    let initialTab=0;
    const hist = createBrowserHistory();
    if (hist.location.search) {
      if (hist.location.search.includes("tab=") && Number(hist.location.search.substr(hist.location.search.length-1,1))){
        initialTab= Number(hist.location.search.substr(hist.location.search.length-1,1));
        setTimeout(() => {
          this.scrollToId(initialTab)
        }, 500);
      }
    }
    
  }
  render() {
    const { 
      // classes, 
      data} = this.props;
    const { 
      contentTop, // this contains the Info in the top side of the page
      contentBottom, // this contains the Info in the bottom side of the page
    } = data;
    const TOP_CONTENT = this.structureTopContent(contentTop);
    const BOTTOM_CONTENT = this.structureBottomContent(contentBottom);

    return(
      <React.Fragment>
        <GridItem xs={12} style={style} >
          {TOP_CONTENT}
          {BOTTOM_CONTENT}
        </GridItem>
      </React.Fragment>
    )
  }
}
export default withStyles(style)(ContentMultimedia);